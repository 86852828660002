export default class SidebarHelper
{
    static getActiveModelFromSidebarModel(state, candidateId) {
        if (candidateId === state.fullDetailId) {
            return state.fullSidebar;
        }

        if (candidateId === state.anonymizedDetailId) {
            return state.anonymizedSidebar;
        }

        if (candidateId === state.duplicatedDetailId) {
            return state.duplicatedSidebar;
        }

        return state;
    }
}
