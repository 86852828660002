export const REQUEST_COMPANY_SETTINGS_MODEL         = 'REQUEST_COMPANY_SETTINGS_MODEL';
export const RECEIVE_COMPANY_SETTINGS_MODEL         = 'RECEIVE_COMPANY_SETTINGS_MODEL';
export const REQUEST_COMPANY_PRICE_LIST_MODEL       = 'REQUEST_COMPANY_PRICE_LIST_MODEL';
export const RECEIVE_COMPANY_PRICE_LIST_MODEL       = 'RECEIVE_COMPANY_PRICE_LIST_MODEL';
export const CANCEL_SUBSCRIPTION                    = 'CANCEL_SUBSCRIPTION';
export const CANCEL_SUBSCRIPTION_SUCCESS            = 'CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_FAILURE            = 'CANCEL_SUBSCRIPTION_FAILURE';
export const DEACTIVATE_MARK                        = 'DEACTIVATE_MARK';
export const DEACTIVATE_MARK_SUCCESS                = 'DEACTIVATE_MARK_SUCCESS';
export const DEACTIVATE_MARK_FAILURE                = 'DEACTIVATE_MARK_FAILURE';
export const RESTORE_SUBSCRIPTION                   = 'RESTORE_SUBSCRIPTION';
export const RESTORE_SUBSCRIPTION_SUCCESS           = 'RESTORE_SUBSCRIPTION_SUCCESS';
export const RESTORE_SUBSCRIPTION_FAILURE           = 'RESTORE_SUBSCRIPTION_FAILURE';
export const CANCEL_TRIAL_SUBSCRIPTION              = 'CANCEL_TRIAL_SUBSCRIPTION';
export const CANCEL_TRIAL_SUBSCRIPTION_SUCCESS      = 'CANCEL_TRIAL_SUBSCRIPTION_SUCCESS';
export const CANCEL_TRIAL_SUBSCRIPTION_FAILURE      = 'CANCEL_TRIAL_SUBSCRIPTION_FAILURE';
export const ACTIVATE_COMPANY_EXPORT                = 'ACTIVATE_COMPANY_EXPORT';
export const ACTIVATE_COMPANY_EXPORT_SUCCESS        = 'ACTIVATE_COMPANY_EXPORT_SUCCESS';
export const ACTIVATE_COMPANY_EXPORT_FAILURE        = 'ACTIVATE_COMPANY_EXPORT_FAILURE';
export const DEACTIVATE_COMPANY_EXPORT              = 'DEACTIVATE_COMPANY_EXPORT';
export const DEACTIVATE_COMPANY_EXPORT_SUCCESS      = 'DEACTIVATE_COMPANY_EXPORT_SUCCESS';
export const DEACTIVATE_COMPANY_EXPORT_FAILURE      = 'DEACTIVATE_COMPANY_EXPORT_FAILURE';
export const SET_COMPANY_PUSH_EXPORT_URL            = 'SET_COMPANY_PUSH_EXPORT_URL';
export const SET_COMPANY_PUSH_EXPORT_URL_SUCCESS    = 'SET_COMPANY_PUSH_EXPORT_URL_SUCCESS';
export const SET_COMPANY_PUSH_EXPORT_URL_FAILURE    = 'SET_COMPANY_PUSH_EXPORT_URL_FAILURE';
export const SEND_PUSH_EXPORT_TEST_REACTION         = 'SEND_PUSH_EXPORT_TEST_REACTION';
export const SEND_PUSH_EXPORT_TEST_REACTION_SUCCESS = 'SEND_PUSH_EXPORT_TEST_REACTION_SUCCESS';
export const SEND_PUSH_EXPORT_TEST_REACTION_FAILURE = 'SEND_PUSH_EXPORT_TEST_REACTION_FAILURE';

export const requestCompanySettingsModel = () => ({
    type: REQUEST_COMPANY_SETTINGS_MODEL,

    meta: {
        requestRoute: 'v1/settings/model/company',
        onRequestSuccess: RECEIVE_COMPANY_SETTINGS_MODEL,
    },
});

export const requestCompanyPriceListModel = () => ({
    type: REQUEST_COMPANY_PRICE_LIST_MODEL,

    meta: {
        requestRoute: 'v1/pricelist/model',
        onRequestSuccess: RECEIVE_COMPANY_PRICE_LIST_MODEL,
    },
});

export const cancelSubscription = () => ({
    type: CANCEL_SUBSCRIPTION,

    meta: {
        requestRoute: 'v1/subscription/cancel',
        requestMethod: 'POST',
        onRequestSuccess: CANCEL_SUBSCRIPTION_SUCCESS,
        onRequestFailure: CANCEL_SUBSCRIPTION_FAILURE,
    },
});

export const cancelTrialSubscription = () => ({
    type: CANCEL_TRIAL_SUBSCRIPTION,

    meta: {
        requestRoute: 'v1/subscription/cancel/trial',
        requestMethod: 'POST',
        onRequestSuccess: CANCEL_TRIAL_SUBSCRIPTION_SUCCESS,
        onRequestFailure: CANCEL_TRIAL_SUBSCRIPTION_FAILURE,
    },
});

export const deactivateMark = () => ({
    type: DEACTIVATE_MARK,

    meta: {
        requestRoute: 'v1/subscription/deactivate/mark',
        requestMethod: 'POST',
        onRequestSuccess: DEACTIVATE_MARK_SUCCESS,
        onRequestFailure: DEACTIVATE_MARK_FAILURE,
    },
});

export const restoreSubscription = () => ({
    type: RESTORE_SUBSCRIPTION,

    meta: {
        requestRoute: 'v1/subscription/restore',
        requestMethod: 'POST',
        onRequestSuccess: RESTORE_SUBSCRIPTION_SUCCESS,
        onRequestFailure: RESTORE_SUBSCRIPTION_FAILURE,
    },
});

export const activateCompanyExport = () => ({
    type: ACTIVATE_COMPANY_EXPORT,

    meta: {
        requestRoute: 'v1/company/action/activate-exports',
        requestMethod: 'POST',
        onRequestSuccess: ACTIVATE_COMPANY_EXPORT_SUCCESS,
        onRequestFailure: ACTIVATE_COMPANY_EXPORT_FAILURE,
    },
});

export const deactivateCompanyExport = () => ({
    type: DEACTIVATE_COMPANY_EXPORT,

    meta: {
        requestRoute: 'v1/company/action/deactivate-exports',
        requestMethod: 'POST',
        onRequestSuccess: DEACTIVATE_COMPANY_EXPORT_SUCCESS,
        onRequestFailure: DEACTIVATE_COMPANY_EXPORT_FAILURE,
    },
});

export const setCompanyPushExportUrl = formData => ({
    type: SET_COMPANY_PUSH_EXPORT_URL,

    meta: {
        requestRoute: 'v1/company/action/set-company-push-export-url',
        requestMethod: 'POST',
        requestBody: formData,
        onRequestSuccess: SET_COMPANY_PUSH_EXPORT_URL_SUCCESS,
        onRequestFailure: SET_COMPANY_PUSH_EXPORT_URL_FAILURE,
    },
});

export const sendPushExportTestReaction = formData => ({
    type: SEND_PUSH_EXPORT_TEST_REACTION,

    meta: {
        requestRoute: 'v1/company/action/send-push-export-test-reaction',
        requestMethod: 'POST',
        requestBody: formData,
        onRequestSuccess: SEND_PUSH_EXPORT_TEST_REACTION_SUCCESS,
        onRequestFailure: SEND_PUSH_EXPORT_TEST_REACTION_FAILURE,
    },
});
