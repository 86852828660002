const TEMPLATE_SAVING_MODE_CREATE      = 'create';
const TEMPLATE_SAVING_MODE_UPDATE      = 'update';
const TEMPLATE_SAVING_MODE_NO_TEMPLATE = 'noTemplate';

export default class TemplateAssistantHelper
{
    /**
     * @param settings
     * @returns {string}
     */
    static getTemplateSavingMode(settings) {
        if (settings.isChangedTemplate === true) {
            return TEMPLATE_SAVING_MODE_UPDATE;
        }

        if (settings.isNewTemplate === true) {
            return TEMPLATE_SAVING_MODE_CREATE;
        }

        return TEMPLATE_SAVING_MODE_NO_TEMPLATE;
    }

    static get TEMPLATE_SAVING_MODE_CREATE() {
        return TEMPLATE_SAVING_MODE_CREATE;
    }

    static get TEMPLATE_SAVING_MODE_UPDATE() {
        return TEMPLATE_SAVING_MODE_UPDATE;
    }

    static get TEMPLATE_SAVING_MODE_NO_TEMPLATE() {
        return TEMPLATE_SAVING_MODE_NO_TEMPLATE;
    }

}
