import React from 'react';
import Translations from '../helpers/Translations';
import Constants from '../helpers/Constants';

class ToastMessageUpdate extends React.Component
{
    closeToast = e => {
        e.target.closest('.message').querySelector('.icon.close').click();

        if (this.props.closeToastType !== undefined && this.props.closeToastType !== null) {
            this.props.closeToastType();
        }
    };

    update = e => {
        this.closeToast(e);
        this.props.update();
    };

    render() {
        const {message, userActions} = this.props;

        return (
            <div className="toast-message-wrapper">
                <div className="toast-message">{Translations.getStatic(message)}</div>

                {
                    userActions.length > 0
                    && (
                        <div className="toast-actions">
                            {
                                userActions.map(action => {
                                    switch (action) {
                                        case Constants.USER_ACTION_UPDATE:
                                            return <a key={action} className="toast-action primary-link" onClick={e => this.update(e)}>{Translations.getStatic(action)}</a>;

                                        case Constants.USER_ACTION_OK:
                                        case Constants.USER_ACTION_IGNORE:
                                        default:
                                            return <a key={action} className="toast-action primary-link" onClick={e => this.closeToast(e)}>{Translations.getStatic(action)}</a>;
                                    }
                                })
                            }
                        </div>
                    )
                }
            </div>
        );
    }
}

export default ToastMessageUpdate;

