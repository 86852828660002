import React, {Component} from 'react';
import {connect} from 'react-redux';
import DetailCandidateDuplicateNoModal from '../Modals/DetailCandidateDuplicateNoModal';
import {closeNoModalSecond} from '../../actions/ModalActions';
import GoogleAnalytics from '../../helpers/GoogleAnalytics';

class NoModalSecondManager extends Component
{
    state = {
        openingNoModalSecondType: null,
        openingNoModalSecondProps: null,
    };

    componentDidUpdate(prevProps) {
        if (prevProps.currentNoModalSecond.noModalSecondIsOpen === false && this.props.currentNoModalSecond.noModalSecondIsOpen === true) {
            this.setState({
                openingNoModalSecondType: this.props.currentNoModalSecond.noModalSecondType,
                openingNoModalSecondProps: this.props.currentNoModalSecond.noModalSecondProps,
            });

            GoogleAnalytics.sendModalView(this.props.currentNoModalSecond.noModalSecondType);
        } else if (prevProps.currentNoModalSecond.noModalSecondIsOpen === true && this.props.currentNoModalSecond.noModalSecondIsOpen === false) {
            const closeWindowAnimationTime = 500;

            setTimeout(() => {
                this.setState({
                    openingNoModalSecondType: null,
                    openingNoModalSecondProps: null,
                });
            }, closeWindowAnimationTime);
        }
    }

    render() {
        const {openingNoModalSecondType, openingNoModalSecondProps} = this.state;

        const modalLookup = {
            DetailCandidateDuplicateNoModal,
        };

        if (openingNoModalSecondType !== null) {
            const ModalComponent = modalLookup[openingNoModalSecondType];

            return <ModalComponent {...openingNoModalSecondProps}/>;
        }

        return null;
    }
}

const mapStateToProps = state => ({
    currentNoModalSecond: state.noModalSecondLogic,
});

const mapDispatchToProps = {
    closeNoModalSecond,
};

export default connect(mapStateToProps, mapDispatchToProps)(NoModalSecondManager);
