import {OPEN_SIDEBAR_MENU, CLOSE_SIDEBAR_MENU, OPEN_SIDEBAR_SEARCH, CLOSE_SIDEBAR_SEARCH} from '../actions/SidebarHeaderActions';

const initialState = {
    isOpenMenu: false,
    isOpenSearch: false,
};

export const sidebarHeaderLogic = (state = initialState, action) => {
    switch (action.type) {
        case OPEN_SIDEBAR_MENU:
            return {
                isOpenMenu: true,
            };

        case CLOSE_SIDEBAR_MENU:
            return {
                isOpenMenu: false,
            };

        case OPEN_SIDEBAR_SEARCH:
            return {
                isOpenSearch: true,
            };

        case CLOSE_SIDEBAR_SEARCH:
            return {
                isOpenSearch: false,
            };

        default:
            return state;
    }
};
