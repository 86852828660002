import {CommonObjectType} from '../../interfaces/CommonTypes';

class Arrays
{
    static prepend(value: any, inArray: any[]): any[] {
        const newArray: any[] = inArray.slice();

        newArray.unshift(value);

        return newArray;
    }

    static append(inArray: any[], value: any): any[] {
        const newArray: any[] = inArray.slice();

        newArray.push(value);

        return newArray;
    }

    static removeDuplicates(array1: any[], array2: any[]): any[] {
        const concatenatedArray: any[] = array1.concat(array2);
        const uniqueArray: any[]       = [];

        for (let i of concatenatedArray) {
            if (!uniqueArray.includes(i)) {
                uniqueArray.push(i);
            }
        }

        return uniqueArray;
    }

    static getUniqueArray(array: any[]): any[] {
        return array.filter((value, index, self) => self.indexOf(value) === index);
    }

    static getUniqueArrayOfObjects(array: CommonObjectType[], compareKey: string = 'value') {
        let uniqueObjectFromArray: CommonObjectType = {};

        array.forEach(value => {
            if (value.hasOwnProperty(compareKey)) {
                uniqueObjectFromArray[value[compareKey]] = value;
            }
        });

        return Object.keys(uniqueObjectFromArray).map(key => uniqueObjectFromArray[key]);
    }

    static getUniqueArrayOfUnknownObjects(array: CommonObjectType[]): CommonObjectType {
        const arrayOfUniqueObjects: CommonObjectType[] = [];

        array.forEach(value => {
            let compareKey: string = '';

            if (value.hasOwnProperty('id')) {
                compareKey = 'id';
            } else if (value.hasOwnProperty('candidateId')) {
                compareKey = 'candidateId';
            }

            if (compareKey === '') {
                if (arrayOfUniqueObjects.find(object => JSON.stringify(object) === JSON.stringify(value)) === undefined) {
                    arrayOfUniqueObjects.push(value);
                }
            } else {
                if (arrayOfUniqueObjects.find(object => object[compareKey] === value[compareKey]) === undefined) {
                    arrayOfUniqueObjects.push(value);
                }
            }
        });

        return arrayOfUniqueObjects;
    }

    static reorderArray(array: any[], fromIndex: number, toIndex: number): any[] {
        const result: any[] = Array.from(array);
        const [removed]     = result.splice(fromIndex, 1);

        result.splice(toIndex, 0, removed);

        return result;
    };
}

export default Arrays;
