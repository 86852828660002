import Constants from '../../Constants';

export default class ChangeCandidateStageReducer
{
    static process(state, action) {
        if (state.hasOwnProperty('candidates') === false) {
            return state;
        }

        if (action.payload.reducerType === Constants.CANDIDATES_REDUCER_TYPE_B2B_DASHBOARD) {
            return this.processChangeStageActionOnB2bDashboard(state, action);
        }

        return state;
    }

    static processChangeStageActionOnB2bDashboard(state, action) {
        let result = {
            ...state,
        };

        if (action.payload.hasOwnProperty('stage') === true && state.hasOwnProperty('activeOffers') === true) {
            const stage          = action.payload.stage;
            let changedCandidate = state.candidates.find(candidate => candidate.id === action.payload.candidateId);

            if (changedCandidate === undefined) {
                changedCandidate = state.changedNewCandidates.find(candidate => candidate.id === action.payload.candidateId);
            }

            if (stage.stageId === Constants.CANDIDATE_STAGE_NEW_ID) { // nastane v pripade ze mas na b2b dashboard otvoreny detail kandidata, zmenis mu stage na inu ako Novy a nasledne ju vratis naspat na Novy
                if (changedCandidate !== undefined) {
                    result = {
                        ...result,
                        activeOffers: state.activeOffers.map(offer => {
                            if (changedCandidate.jobId === offer.jobId) {
                                return {
                                    ...offer,
                                    counts: {
                                        ...offer.counts,
                                        newCandidatesCount: offer.counts.newCandidatesCount + 1,
                                    },
                                };
                            }

                            return offer;
                        }),

                        newCandidatesTotalCount: result.newCandidatesTotalCount + 1,
                        candidatesInNewStage: result.candidatesInNewStage.concat([changedCandidate]),
                        candidates: result.candidates.concat([changedCandidate]),
                        candidateIdsPairs: result.candidateIdsPairs.concat([{
                            id: changedCandidate.id,
                            jobId: changedCandidate.jobId,
                        }]),

                        changedNewCandidates: state.changedNewCandidates.filter(candidate => candidate.id !== changedCandidate.id),

                    };
                }
            } else { // zmena stage z Novy na iny
                result = {
                    ...result,
                    activeOffers: state.activeOffers.map(offer => {
                        if (changedCandidate.jobId === offer.jobId) {
                            return {
                                ...offer,
                                counts: {
                                    ...offer.counts,
                                    newCandidatesCount: offer.counts.newCandidatesCount - 1,
                                },
                            };
                        }

                        return offer;
                    }),

                    newCandidatesTotalCount: result.newCandidatesTotalCount - 1,
                    candidatesInNewStage: result.candidatesInNewStage.filter(candidate => candidate.id !== changedCandidate.id),
                    candidates: result.candidates.filter(candidate => candidate.id !== changedCandidate.id),
                    candidateIdsPairs: result.candidateIdsPairs.filter(pair => pair.id !== changedCandidate.id),

                    changedNewCandidates: state.changedNewCandidates === undefined
                        ? [changedCandidate]
                        : state.changedNewCandidates.concat([changedCandidate]),

                };
            }
        }

        return result;
    };
}
