import React from 'react';
import PropTypes from 'prop-types';
import {Label} from 'semantic-ui-react';

const DashboardCalendarTileContent = props => {
    const {view, events} = props;

    if (view === 'decade' || view === 'century' || view === 'year') {
        return null;
    }

    return (
        <div>
            {
                events.slice(0, 5).map(event => <Label circular empty size={'mini'} key={event.id}/>)
            }
        </div>
    );
};

DashboardCalendarTileContent.propTypes = {
    events: PropTypes.array.isRequired,
};

export default DashboardCalendarTileContent;



