export const TRACK_LOCATION_HEADER: string                       = 'Header';
export const TRACK_LOCATION_JOB_DETAIL: string                   = 'Job detail';
export const TRACK_LOCATION_CLOSE_JOB_DIALOG: string             = 'Close job dialog';
export const TRACK_LOCATION_CANDIDATES_LIST: string              = 'Candidates list';
export const TRACK_LOCATION_PIPELINE: string                     = 'Pipeline';
export const TRACK_LOCATION_JOBS_LIST: string                    = 'Jobs list';
export const TRACK_LOCATION_DETAIL: string                       = 'Detail';
export const TRACK_LOCATION_MARK: string                         = 'Mark';
export const TRACK_NAME_CLOSE_JOB: string                        = 'Close job';
export const TRACK_NAME_INVITE_A_COLLEAGUE: string               = 'Invite a colleague';
export const TRACK_NAME_ADD_CANDIDATE: string                    = 'Add candidate';
export const TRACK_NAME_ADD_JOB: string                          = 'Add job';
export const TRACK_NAME_EXPORT_CANDIDATES: string                = 'Export candidates';
export const TRACK_NAME_CHANGE_STATUS: string                    = 'Change status';
export const TRACK_NAME_TAG: string                              = 'Tag';
export const TRACK_NAME_EXCLUDE_FROM_PROCEDURE: string           = 'Exclude from procedure';
export const TRACK_NAME_KEEP_IN_PROCEDURE: string                = 'Keep in procedure';
export const TRACK_NAME_CANCEL_WIZARD: string                    = 'Cancel wizard';
export const TRACK_NAME_CLOSE_PROCEDURE: string                  = 'Close procedure';
export const TRACK_ACTION_FEATURE_NOT_ALLOWED: string            = 'Feature not allowed';
export const TRACK_ACTION_EXTERNAL_USER: string                  = 'External user';
export const TRACK_ACTION_MANUALLY: string                       = 'Manually';
export const TRACK_ACTION_CVDB: string                           = 'CVDB';
export const TRACK_ACTION_TALENT_POOL: string                    = 'Talent pool';
export const TRACK_ACTION_MATCHED_OFFER: string                  = 'Matched offer';
export const TRACK_ACTION_REFUSED: string                        = 'Refused';
export const TRACK_FEATURE_EXCLUDE_FROM_PROCEDURE: string        = 'Exclude from procedure';
export const TRACK_FEATURE_DO_NOT_EXCLUDE_FROM_PROCEDURE: string = 'Do not exclude from procedure';
export const TRACK_FEATURE_SEND_EMAIL: string                    = 'Send email';
export const TRACK_FEATURE_DO_NOT_SEND_EMAIL: string             = 'Do not send email';
export const TRACK_FUNNEL_NAME_THUMB_DOWN: string                = 'Thumb down';
export const TRACK_FUNNEL_NAME_DROPDOWN_MENU: string             = 'Dropdown menu';
export const TRACK_FUNNEL_NAME_CLOSE: string                     = 'Close';
export const TRACK_FUNNEL_NAME_EXCLUDE: string                   = 'Exclude';
export const TRACK_FUNNEL_NAME_SEND_EMAIL_CHECKED: string        = 'Send email checked';
export const TRACK_FUNNEL_NAME_SEND_EMAIL_UNCHECKED: string      = 'Send email unchecked';
export const TRACK_FUNNEL_TYPE_EXCLUDE_CANDIDATE: string         = 'Exclude candidate';

