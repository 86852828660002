import React from 'react';
import DashboardJobsListItemSkeleton from './DashboardJobsListItemSkeleton';
import {SkeletonRectangular} from '@profesia/adamui/components/common/skeleton';
import {Stack} from '@mui/material';
import DashboardCalendar from '../../components/Dashboard/DashboardCalendar';

const DashboardSkeleton = ({itemsCount = 4}) => (
    <div className="page">
        <div className="template-header header-basic">
            <Stack p={4}>
                <SkeletonRectangular width={384} height={24} borderRadius={26}/>
            </Stack>
        </div>

        <div className="page-content">
            <div className="page-content_left height_full">
                <div className="list height_full">
                    <div className="list">
                        <div className="ui items list__jobs unstackable skeleton">
                            {[...Array(itemsCount)].map((e, i) => <DashboardJobsListItemSkeleton key={i}/>)}
                        </div>
                    </div>
                </div>
            </div>

            <div className="page-content_right skeleton">
                <DashboardCalendar
                    events={[]}
                    onLoadMoreEvents={() => null}
                    slideAnimationTime={300}
                    dashboardModel={{isSkeleton: true}}
                    displaySize={{isMobile: false}}
                />
            </div>
        </div>
    </div>
);

export default DashboardSkeleton;
