export const REQUEST_QUESTIONNAIRE_RESULTS = 'REQUEST_QUESTIONNAIRE_RESULTS';
export const RECEIVE_QUESTIONNAIRE_RESULTS = 'RECEIVE_QUESTIONNAIRE_RESULTS';

export const requestQuestionnaireResults = (candidateId = null) => ({
    type: REQUEST_QUESTIONNAIRE_RESULTS,

    meta: {
        requestRoute: 'v1/candidate/data/questionnaire-results',
        requestData: {
            candidateId: candidateId === null ? 0 : candidateId,
        },
        onRequestSuccess: RECEIVE_QUESTIONNAIRE_RESULTS,
    },
});
