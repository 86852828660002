export const REQUEST_CREATE_CANDIDATE_MODEL = 'REQUEST_CREATE_CANDIDATE_MODEL';
export const RECEIVE_CREATE_CANDIDATE_MODEL = 'RECEIVE_CREATE_CANDIDATE_MODEL';
export const SAVE_CANDIDATE                 = 'SAVE_CANDIDATE';
export const SAVE_CANDIDATE_SUCCESS         = 'SAVE_CANDIDATE_SUCCESS';
export const SAVE_CANDIDATE_FAILURE         = 'SAVE_CANDIDATE_FAILURE';
export const UPDATE_CANDIDATE_DATA          = 'UPDATE_CANDIDATE_DATA';
export const SET_CANDIDATE_ERRORS           = 'SET_CANDIDATE_ERRORS';

export const requestCreateCandidateModel = (jobId, candidateId) => ({
    type: REQUEST_CREATE_CANDIDATE_MODEL,
    meta: {
        requestRoute: 'v1/candidate/model/create',
        requestData: {
            jobId: jobId,
            candidateId: candidateId,
        },

        onRequestSuccess: RECEIVE_CREATE_CANDIDATE_MODEL,
    },
});

export const saveCandidate = (jobId, candidateId, candidateData) => ({
    type: SAVE_CANDIDATE,

    payload: {
        jobId: jobId,
        candidateId: candidateId,
    },

    meta: {
        requestRoute: 'v1/candidate/action/save',
        requestMethod: 'POST',
        requestData: {
            candidateId: candidateId,
            jobId: jobId,
        },
        requestBody: candidateData,

        onRequestSuccess: SAVE_CANDIDATE_SUCCESS,
        onRequestFailure: SAVE_CANDIDATE_FAILURE,
    },
});

export const updateCandidateData = candidateData => ({
    type: UPDATE_CANDIDATE_DATA,
    payload: {
        candidateData,
    },
});

export const setCandidateErrors = errors => ({
    type: SET_CANDIDATE_ERRORS,
    payload: {
        errors,
    },
});
