import Url from './Url';
import Strings from './Strings';

class Hotjar {
    static isHotjarAvailable(): boolean {
        return typeof window.hj === 'function';
    }

    static sendEvent(eventCategory: string, eventAction: string): void {
        if (Url.isOnline() && this.isHotjarAvailable()) {
            eventCategory = Strings.firstLetterUpperCase(eventCategory);
            eventAction   = Strings.firstLetterUpperCase(eventAction);

            window.hj('event', eventCategory + '-' + eventAction);
        }
    }
}

export default Hotjar;
