import { FormT } from '@profesia/mark-offer-form/components/content/form/types';
import { transformData } from './transformedData';
import Url from '../../../helpers/Url';

const postForm = async (data: FormT): Promise<number> => {
    const transformedData = transformData(data);

    const response = await fetch(`${Url.MARK_SERVER_PREFIX}/api/v1/position`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(transformedData),
    });

    if (!response.ok) {
        throw new Error('Server error: ' + response.status);
    }

    const jsonResponse: { id: number } = await response.json();

    return jsonResponse.id;
};

export default postForm;