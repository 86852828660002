export const REQUEST_DASHBOARD_MODEL          = 'REQUEST_DASHBOARD_MODEL';
export const RECEIVE_DASHBOARD_MODEL          = 'RECEIVE_DASHBOARD_MODEL';
export const LOAD_MORE_EVENTS                 = 'LOAD_MORE_EVENTS';
export const LOAD_MORE_EVENTS_SUCCESS         = 'LOAD_MORE_EVENTS_SUCCESS';
export const LOAD_MORE_EVENTS_FAILURE         = 'LOAD_MORE_EVENTS_FAILURE';
export const LOAD_MORE_JOBS                   = 'LOAD_MORE_JOBS';
export const LOAD_MORE_JOBS_SUCCESS           = 'LOAD_MORE_JOBS_SUCCESS';
export const LOAD_MORE_JOBS_FAILURE           = 'LOAD_MORE_JOBS_FAILURE';
export const LOAD_CANDIDATES_FOR_JOBS         = 'LOAD_CANDIDATES_FOR_JOBS';
export const LOAD_CANDIDATES_FOR_JOBS_SUCCESS = 'LOAD_CANDIDATES_FOR_JOBS_SUCCESS';
export const LOAD_CANDIDATES_FOR_JOBS_FAILURE = 'LOAD_CANDIDATES_FOR_JOBS_FAILURE';
export const GET_DATA_FOR_USER                = 'GET_DATA_FOR_USER';
export const GET_DATA_FOR_USER_SUCCESS        = 'GET_DATA_FOR_USER_SUCCESS';
export const GET_DATA_FOR_USER_FAILURE        = 'GET_DATA_FOR_USER_FAILURE';
export const CLOSE_JOBS                       = 'CLOSE_JOBS';
export const CLOSE_JOBS_SUCCESS               = 'CLOSE_JOBS_SUCCESS';
export const CLOSE_JOBS_FAILURE               = 'CLOSE_JOBS_FAILURE';

export const requestDashboardModel = () => ({
    type: REQUEST_DASHBOARD_MODEL,
    meta: {
        requestRoute: 'v1/dashboard/model',

        onRequestSuccess: RECEIVE_DASHBOARD_MODEL,
    },
});

export const loadMoreJobs = jobIds => ({
    type: LOAD_MORE_JOBS,

    payload: {
        jobIds,
    },

    meta: {
        requestRoute: 'v1/dashboard/data/more-jobs',
        requestMethod: 'GET',
        requestData: {
            jobIds: JSON.stringify(jobIds),
        },
        onRequestSuccess: LOAD_MORE_JOBS_SUCCESS,
        onRequestFailure: LOAD_MORE_JOBS_FAILURE,
    },
});

export const loadMoreEvents = (userId, month, year) => ({
    type: LOAD_MORE_EVENTS,

    meta: {
        requestRoute: 'v1/dashboard/data/events',

        requestData: {
            month: month,
            year: year,
            userId,
        },

        onRequestSuccess: LOAD_MORE_EVENTS_SUCCESS,
        onRequestFailure: LOAD_MORE_EVENTS_FAILURE,
    },
});

export const loadCandidatesForJobs = jobIds => ({
    type: LOAD_CANDIDATES_FOR_JOBS,
    payload: {
        jobIds,
    },
    meta: {
        requestRoute: 'v1/dashboard/data/candidates',
        requestData: {
            jobIds: JSON.stringify(jobIds),
        },

        onRequestSuccess: LOAD_CANDIDATES_FOR_JOBS_SUCCESS,
        onRequestFailure: LOAD_CANDIDATES_FOR_JOBS_FAILURE,
    },
});

export const getDataForUser = userId => ({
    type: GET_DATA_FOR_USER,
    payload: {
        userId,
    },
    meta: {
        requestRoute: 'v1/dashboard/data/get-data-for-user',
        requestData: {
            userId,
        },

        onRequestSuccess: GET_DATA_FOR_USER_SUCCESS,
        onRequestFailure: GET_DATA_FOR_USER_FAILURE,
    },
});

export const closeJobs = (actionData, jobIds) => ({
    type: CLOSE_JOBS,

    payload: {
        jobIds,
    },

    meta: {
        requestRoute: 'v1/dashboard/action/close-jobs',
        requestMethod: 'POST',
        requestBody: actionData,
        onRequestSuccess: CLOSE_JOBS_SUCCESS,
        onRequestFailure: CLOSE_JOBS_FAILURE,
    },
});
