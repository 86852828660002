import DataLayer from './DataLayer';
import Url from './Url';
import Constants from './Constants';
import {TRACK_LOCATION_CANDIDATES_LIST, TRACK_LOCATION_JOBS_LIST, TRACK_LOCATION_PIPELINE} from './ConstantsTrack';
import {CommonObjectType} from '../../interfaces/CommonTypes';

interface EventType
{
    hit: HitType;
    event: EventTypeEnum;
    _clear: boolean;
}

interface FunnelType
{
    hit: FunnelHitType;
    event: EventTypeEnum;
    _clear: boolean;
}

interface HitType
{
    component_type: ComponentTypeEnum;
    component_location: string;
    component_name: string;
    component_action?: string;
}

interface FunnelHitType
{
    funnel_step: number,
    funnel_step_name: string,
    funnel_type: string,
}

export enum ComponentTypeEnum
{
    button     = 'button',
    horizontal = 'Horizontal',
    vertical   = 'Vertical',
}

enum EventTypeEnum
{
    componentClick = 'component_click',
    funnel         = 'funnel',
    uxClick        = 'ux_click',
}

export default class Track
{
    static getTrackingLocation(): string {
        const action: string = Url.getUrlParameter('action');

        switch (action) {
            case Constants.ACTION_PIPELINE:
                return TRACK_LOCATION_PIPELINE;

            case Constants.ACTION_CANDIDATES:
                return TRACK_LOCATION_CANDIDATES_LIST;

            case Constants.ACTION_JOBS:
                return TRACK_LOCATION_JOBS_LIST;

            default:
                return action;
        }
    };

    static event(location: string, name: string, action: string | null = null, componentType: ComponentTypeEnum = ComponentTypeEnum.button): void {
        DataLayer.get();

        const hit: HitType = {
            component_type: componentType,
            component_location: location,
            component_name: name,
        };

        if (action !== null) {
            hit.component_action = action;
        }

        const event: EventType = {
            hit,
            event: EventTypeEnum.componentClick,
            _clear: true,
        };

        window.dataLayer?.push(event);
    }

    static uxClick(location: string, name: string, componentType: ComponentTypeEnum = ComponentTypeEnum.button, additionalParams: CommonObjectType = {}): void {
        DataLayer.get();

        const hit: HitType = {
            component_type: componentType,
            component_location: location,
            component_name: name,
            ...additionalParams,
        };

        const event: EventType = {
            hit,
            event: EventTypeEnum.uxClick,
            _clear: true,
        };

        window.dataLayer?.push(event);
    }

    static funnel(step: number, stepName: string, type: string): void {
        DataLayer.get();

        const hit: FunnelHitType = {
            funnel_step: step,
            funnel_step_name: stepName,
            funnel_type: type,
        };

        const funnel: FunnelType = {
            hit,
            event: EventTypeEnum.funnel,
            _clear: true,
        };

        window.dataLayer?.push(funnel);
    }
}
