export const REQUEST_DASHBOARD_MODEL               = 'B2B_REQUEST_DASHBOARD_MODEL';
export const RECEIVE_DASHBOARD_MODEL               = 'B2B_RECEIVE_DASHBOARD_MODEL';
export const GET_DATA_FOR_USER                     = 'B2B_GET_DATA_FOR_USER';
export const GET_DATA_FOR_USER_FAILURE             = 'B2B_DATA_FOR_USER_FAILURE';
export const GET_DATA_FOR_USER_SUCCESS             = 'B2B_DATA_FOR_USER_SUCCESS';
export const GET_CANDIDATES_DATA_FOR_MODAL         = 'B2B_GET_CANDIDATES_DATA_FOR_MODAL';
export const GET_CANDIDATES_DATA_FOR_MODAL_FAILURE = 'B2B_GET_CANDIDATES_DATA_FOR_MODAL_FAILURE';
export const GET_CANDIDATES_DATA_FOR_MODAL_SUCCESS = 'B2B_GET_CANDIDATES_DATA_FOR_MODAL_SUCCESS';
export const GET_CANDIDATES                        = 'B2B_GET_CANDIDATES';
export const GET_CANDIDATES_FAILURE                = 'B2B_GET_CANDIDATES_FAILURE';
export const GET_CANDIDATES_SUCCESS                = 'B2B_GET_CANDIDATES_SUCCESS';

export const requestDashboardModel = (userId = null) => {
    let action = {
        type: REQUEST_DASHBOARD_MODEL,
        meta: {
            requestRoute: 'b2b/v2/dashboard/model',
            onRequestSuccess: RECEIVE_DASHBOARD_MODEL,
        },
    };

    if (userId !== null) {
        action = {
            ...action,
            payload: {
                userId,
            },
            meta: {
                ...action.meta,
                requestData: {
                    userId,
                },
            }
        }

    }

    return action;
};

export const getDataForUser = userId => ({
    type: GET_DATA_FOR_USER,
    payload: {
        userId,
    },
    meta: {
        requestRoute: 'b2b/v2/dashboard/data/get-data-for-user',
        requestData: {
            userId,
        },

        onRequestSuccess: GET_DATA_FOR_USER_SUCCESS,
        onRequestFailure: GET_DATA_FOR_USER_FAILURE,
    },
});

export const getCandidatesDataForModal = (jobIds, jobIdsForFullLoad) => ({
    type: GET_CANDIDATES_DATA_FOR_MODAL,
    payload: {
        jobIds,
        jobIdsForFullLoad,
    },
    meta: {
        requestRoute: 'b2b/v2/dashboard/data/candidates-data-for-modal',
        requestData: {
            jobIds: JSON.stringify(jobIds),
            jobIdsForFullLoad: JSON.stringify(jobIdsForFullLoad),
        },

        onRequestSuccess: GET_CANDIDATES_DATA_FOR_MODAL_SUCCESS,
        onRequestFailure: GET_CANDIDATES_DATA_FOR_MODAL_FAILURE,
    },
});

export const getCandidates = candidateIds => ({
    type: GET_CANDIDATES,
    payload: {
        candidateIds,
    },
    meta: {
        requestRoute: 'b2b/v2/dashboard/data/candidates',
        requestData: {
            candidateIds: JSON.stringify(candidateIds),
        },

        onRequestSuccess: GET_CANDIDATES_SUCCESS,
        onRequestFailure: GET_CANDIDATES_FAILURE,
    },
});
