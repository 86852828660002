import React, {Component} from 'react';
import {connect} from 'react-redux';
import {createSourceTag, deleteSourceTag} from '../../actions/JobDetailActions';
import {closeModal, openModalSecond} from '../../actions/ModalActions';
import {InputField} from '@profesia/adamui/components/common/input';
import Form from '../Form';
import Checkbox from '@profesia/adamui/components/common/input/checkbox/Checkbox';
import Button from '../Button';
import Message from '../Message';
import Grid from '../Grid';
import Item from '../Item';
import Image from '../Image';
import Divider from '../Divider';
import PropTypes from 'prop-types';
import MarkModal from '../Modal/MarkModal';
import Translations from '../../helpers/Translations';
import SourceTagValidatorHelper from '../../helpers/components/SourceTagValidatorHelper';
import Strings from '../../helpers/Strings';
import linkImage from '../../../img/icons/link.svg';
import RemoveGlobalSourceTagDialog from '../Dialogs/RemoveGlobalSourceTagDialog';
import Sort from '../../helpers/Sort';
import Url from '../../helpers/Url';
import FormSkeleton from '../../skeletons/components/FormSkeleton';

class JobCandidatesSourcesReactionLinksModal extends Component
{
    state = {
        name: '',
        errors: [],
        removeSourceTagId: 0,
        removeGlobalSourceTagDialogOpen: false,
        isGlobal: false,
    };

    openRemoveGlobalSourceTagDialog = removeSourceTagId => {
        this.setState({
            removeSourceTagId,
            removeGlobalSourceTagDialogOpen: true,
        });
    };

    closeRemoveGlobalSourceTagDialog = () => {
        this.setState({
            removeSourceTagId: 0,
            removeGlobalSourceTagDialogOpen: false,
        });
    };

    handleSourceTagDelete = sourceTagId => {
        let formData = new FormData();

        formData.append('sourceTagId', sourceTagId);
        formData.append('jobId', this.props.jobId);

        this.props.deleteSourceTag(formData);

        this.closeRemoveGlobalSourceTagDialog();
    };

    handleChangeInputName = (e) => this.setState({[e.target.name]: e.target.value});

    handleSubmit = () => {
        const {name}             = this.state;
        const {settings}         = this.props;
        const sourceTagValidator = new SourceTagValidatorHelper(Strings.removeDiacritics(name.trim().toLowerCase()));

        try {
            sourceTagValidator.checkSourceTag(settings.sources.map(source => Strings.removeDiacritics(source.sourceName.toLowerCase())));

            let formData = new FormData();

            formData.append('sourceTagName', name.trim());
            formData.append('jobId', this.props.jobId);
            formData.append('isGlobal', this.state.isGlobal === true ? '1' : '0');

            this.props.createSourceTag(formData);

            this.setState({
                errors: [],
                isGlobal: false,
            });
        } catch (errors) {
            this.setState({
                errors,
            });
        }
    };

    render() {
        const {settings, isOpen, isFetchingSourceTag} = this.props;
        const {name, errors}                          = this.state;

        return (
            <MarkModal
                isOpen={isOpen}
                closeModal={this.props.closeModal}
                title={Translations.getStatic('reactionLinks')}
                actionButton={{visible: false}}
                isLoadingData={isFetchingSourceTag}

                content={
                    isFetchingSourceTag === true
                        ? <FormSkeleton/>
                        : <>
                            <Form onSubmit={this.handleSubmit}>
                                <Grid>
                                    <Grid.Row className="from-group__row">
                                        <Grid.Column width={12} verticalAlign="bottom">
                                            <Form.Field>
                                                <InputField
                                                    maxLength={128}
                                                    error={errors.includes('sourceTag')}
                                                    label={Translations.getStatic('addReactionLink')}
                                                    name="name"
                                                    onChange={this.handleChangeInputName}
                                                    placeholder={Translations.getStatic('fillSourceName')}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column width={4} verticalAlign="bottom">
                                            <Form.Field>
                                                <Button disabled={name.trim().length === 0} content={Translations.getStatic('add')} className="secondary-button mini"/>
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row className="from-group__row">
                                        <Grid.Column width={15}>
                                            <Form.Field>
                                                <Checkbox
                                                    onChange={(e) => this.setState({
                                                        isGlobal: e.target.checked,
                                                    })}
                                                    checked={this.state.isGlobal}
                                                    label={Translations.getStatic('addToAllJobs')}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column width={1}/>
                                    </Grid.Row>
                                    <Grid.Row className="from-group__row">
                                        <Grid.Column width={16} verticalAlign="bottom">
                                            <Message
                                                error
                                                icon={'exclamation'}
                                                visible={errors.includes('sourceTagAgainstSources')}
                                            >
                                                <Message.Content>
                                                    {Translations.getStatic('sourceTagIsFromSourcesError')}
                                                </Message.Content>
                                            </Message>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Form>
                            <Divider/>
                            <Grid className="padding-top_none">
                                <Grid.Row>
                                    <Grid.Column>
                                        <div className="ui items list attachment-list unstackable">
                                            {
                                                Sort.byColumn(settings.sourceTags, 'sourceTagName').map(sourceTag => (
                                                    <Item key={sourceTag.sourceTagId} className="attachment__box">
                                                        {this.props.displaySize.isMobile === false && <Image size="large" src={linkImage}/>}
                                                        <Grid verticalAlign="middle" className={'width_full' + (this.props.displaySize.isMobile === true ? ' overflow-hidden' : '')}>
                                                            <Grid.Row>
                                                                <Grid.Column computer={11}>
                                                                    <Item.Header as="h4" className="ellipsis">{sourceTag.sourceTagName}</Item.Header>
                                                                    <Item.Meta className="ellipsis">{Url.getFullUrl(sourceTag.reactionLink)}</Item.Meta>
                                                                </Grid.Column>
                                                                {
                                                                    this.props.displaySize.isMobile === true
                                                                        ? (
                                                                            <Grid.Column width={5}>
                                                                                <Grid>
                                                                                    <Grid.Row>
                                                                                        <Grid.Column>
                                                                                            <a className="link secondary-link float-right" onClick={() => this.props.openModalSecond('ReactionIframeModal', {reactionLink: sourceTag.reactionLink})}>{'</> HTML'}</a>
                                                                                        </Grid.Column>
                                                                                    </Grid.Row>
                                                                                    <Grid.Row>
                                                                                        <Grid.Column>
                                                                                            <Divider hidden className="size_4"/>
                                                                                            <a className="link secondary-link float-right" onClick={() => sourceTag.isGlobal === true ? this.openRemoveGlobalSourceTagDialog(sourceTag.sourceTagId) : this.handleSourceTagDelete(sourceTag.sourceTagId)}>{Translations.getStatic('deleteCandidate')}</a>
                                                                                        </Grid.Column>
                                                                                    </Grid.Row>
                                                                                </Grid>
                                                                            </Grid.Column>
                                                                        )
                                                                        : (
                                                                            <>
                                                                                <Grid.Column width={3} textAlign="right">
                                                                                    <a className="link secondary-link" onClick={() => this.props.openModalSecond('ReactionIframeModal', {integratedReactionLink: sourceTag.integratedReactionLink})}>{'</> HTML'}</a>
                                                                                </Grid.Column>
                                                                                <Grid.Column width={2} textAlign="right">
                                                                                    <a className="link secondary-link" onClick={() => sourceTag.isGlobal === true ? this.openRemoveGlobalSourceTagDialog(sourceTag.sourceTagId) : this.handleSourceTagDelete(sourceTag.sourceTagId)}>{Translations.getStatic('deleteCandidate')}</a>
                                                                                </Grid.Column>
                                                                            </>
                                                                        )
                                                                }
                                                            </Grid.Row>
                                                        </Grid>
                                                    </Item>
                                                ))
                                            }
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                            {
                                this.state.removeGlobalSourceTagDialogOpen === true
                                && (
                                    <RemoveGlobalSourceTagDialog
                                        onRemoveGlobalSourceTag={() => this.handleSourceTagDelete(this.state.removeSourceTagId)}
                                        onCancel={() => this.closeRemoveGlobalSourceTagDialog()}
                                    />
                                )
                            }
                        </>
                }
            />
        );
    }
}

const mapStateToProps = state => ({
    isOpen: state.modalLogic.isOpen,
    settings: state.jobModel.settings,
    jobId: state.jobModel.job.id,
    isFetchingSourceTag: state.isFetchingSourceTag,
    displaySize: state.displaySize,
    loginManagerModel: state.loginManagerModel,
});

const mapDispatchToProps = {
    closeModal,
    openModalSecond,
    createSourceTag,
    deleteSourceTag,
};

JobCandidatesSourcesReactionLinksModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    isFetchingSourceTag: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    createSourceTag: PropTypes.func.isRequired,
    deleteSourceTag: PropTypes.func.isRequired,
    settings: PropTypes.shape({
        sourceTags: PropTypes.array.isRequired,
    }),
    jobId: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(JobCandidatesSourcesReactionLinksModal);



