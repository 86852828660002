import React from 'react';
import JobsListFilterSkeleton from './JobsListFilterSkeleton';
import JobsListTableSkeleton from './JobsListTableSkeleton';

const JobsListPageSkeleton = () => (
    <>
        <JobsListFilterSkeleton/>
        <JobsListTableSkeleton/>
    </>
);

export default JobsListPageSkeleton;
