import DateFormatted from './DateFormatted';
import {CommonObjectType} from '../../interfaces/CommonTypes';

class Form
{
    static check(formField: FormFieldType): boolean {
        const type: FormFieldTypeEnum = formField.fieldType;
        const value: any              = formField.fieldValue;
        const against: any            = formField.against;

        switch (type) {
            case 'date':
            case 'email':
            case 'ico':
            case 'phone':
            case 'text':
            case 'url':
            case 'wysiwyg':
            case 'zip':
                return this.checkStringFormField(value, type);

            case 'maxLength':
                return this.checkMaxLength(value, formField?.maxLength ?? 0);

            case 'unique':
                if (formField.hasOwnProperty('against')) {
                    return typeof value === 'number'
                        ? this.isUniqueNumber(value, against)
                        : this.isUniqueString(value, against);
                }
                break;

            case 'filesTotalSize':
                if (formField.hasOwnProperty('against')) {
                    return this.checkFileSize(value, against);
                }
                break;

            case 'numeric':
                return this.isNumeric(value);

            case 'boolean':
                return typeof value === 'boolean' ? value : false;

            case 'notEmpty':
                return !this.isEmpty(value);

            case 'emptyOrCheck':
                return this.isEmpty(value)
                    || this.check({
                        ...formField,

                        fieldType: formField.hasOwnProperty('secondaryFieldType') && formField.secondaryFieldType
                            ? formField.secondaryFieldType
                            : FormFieldTypeEnum.text,
                    });
        }

        return false;
    }

    private static isEmpty(value: string | number): boolean {
        return (value?.toString().length ?? 0) === 0;
    }

    private static isNumeric(value: string | number): boolean {
        const numericValue: number = typeof value === 'number' ? value : parseInt(value);

        return numericValue !== 0 && value.toString().match(/^[0-9]+$/gi) !== null;
    }

    private static checkMaxLength(value: string | number, maxLength: number = 0): boolean {
        return value.toString().length <= maxLength;
    }

    private static isUniqueNumber(value: number, against: number[]): boolean {
        return !against.includes(value);
    }

    private static isUniqueString(value: string, against: string[]): boolean {
        return !against.includes(value);
    }

    private static checkFileSize(attachments: CommonObjectType[], maxFileSize: number): boolean {
        const totalFileSize: number = attachments.reduce((accumulator, attachment) => accumulator + attachment.file.size, 0);

        return totalFileSize <= maxFileSize;
    }

    private static checkStringFormField(value: string, type: FormFieldTypeEnum = FormFieldTypeEnum.text): boolean {
        let regExp: RegExp = /^.+$/gi;

        switch (type) {
            case 'text':
                regExp = /^.+$/gim;
                break;

            case 'wysiwyg':
                return value.trim() !== '<p></p>';

            case 'email':
                regExp = /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()\[\].,;:\s@"]+\.)+[^<>()\[\].,;:\s@"]{2,})$/gi;
                break;

            case 'phone':
                regExp = /^\+?[0-9-\s]+$/gi;
                break;

            case 'zip':
                value  = value.replace(/\s+/gi, '');
                regExp = /^[0-9]{5}$/gi;
                break;

            case 'ico':
                value  = value.replace(/\s+/gi, '');
                regExp = /^[0-9]{8}$/gi;
                break;

            case 'date':
                return DateFormatted.isValidFormat(value);

            case 'url':
                regExp = /^(https?:\/\/(?:www\.)?[-\w@:%.+~#=]{2,256}\.[a-z]{2,6}\b[-\w@:%+.~#?&\/=]*|)$/gi;
                break;

            default:
                regExp = /^.+$/gim;
        }

        return value.match(regExp) !== null;
    }

    static multiCheck(formFields: FormFieldType[]): string[] {
        let inputFieldsWithError: string[] = [];

        formFields.forEach(formField => {
            if (this.check(formField)) {
                if (formField.hasOwnProperty('conditionalChecks')) {
                    formField.conditionalChecks?.forEach(conditionalCheck => {
                        if (formField.fieldValue === conditionalCheck.fieldValue) {
                            inputFieldsWithError = inputFieldsWithError.concat(this.multiCheck(conditionalCheck.fieldsToCheck));
                        }
                    });
                }
            } else {
                inputFieldsWithError.push(formField.fieldName ?? '');
            }
        });

        return inputFieldsWithError;
    }
}

export enum FormFieldTypeEnum
{
    date           = 'date',
    email          = 'email',
    ico            = 'ico',
    phone          = 'phone',
    text           = 'text',
    url            = 'url',
    wysiwyg        = 'wysiwyg',
    zip            = 'zip',
    maxLength      = 'maxLength',
    unique         = 'unique',
    filesTotalSize = 'filesTotalSize',
    numeric        = 'numeric',
    boolean        = 'boolean',
    notEmpty       = 'notEmpty',
    emptyOrCheck   = 'emptyOrCheck',
}

interface FormFieldType
{
    fieldType: FormFieldTypeEnum;
    fieldValue: any;
    fieldName?: string;
    against?: string[] | number[];
    maxLength?: number;
    conditionalChecks?: ConditionalCheckType[];
    secondaryFieldType?: FormFieldTypeEnum;
}

interface ConditionalCheckType
{
    fieldValue: any;
    fieldsToCheck: FormFieldType[];
}

export default Form;
