import React from 'react';
import {connect} from 'react-redux';
import {Routes, Route} from 'react-router-dom';
import markVersion from './../version';
import Url from './../helpers/Url';
import MenuDefault from './Menu/MenuDefault';
import Header4MenuDetail from './Header4MenuDetail';
import Header4MenuCreateForm from './Header4MenuCreateForm';
import Translations from '../helpers/Translations';
import Language from '../helpers/Language';
import {requestHeader} from '../actions/HeaderActions';
import {openCancelCloseJobWizardDialog} from '../actions/DialogActions';
import Version from '../helpers/Version';
import headerModelMenuSkeleton from '../skeletons/models/headerModelMenuSkeleton.json';
import loginManagerModelSkeleton from '../skeletons/models/loginManagerModelSkeleton.json';
import Skeletons from '../helpers/Skeletons';
import WithRouterProp from '../helpers/WithRouterProp';
import CookieConsentManager from '../helpers/CookieConsentManager';

class Header extends React.Component
{
    state = {
        action: Url.getUrlParameter('action'),
    };

    static getDerivedStateFromProps(props, state) {
        const action = Url.getUrlParameter('action');

        if (action !== state.action) {
            if (Version.get() > markVersion) {
                Url.reload('New mark available - reloading page');
            }

            return {
                action,
            };
        }

        return null;
    }

    componentDidMount() {
        this.setLanguage();

        const action = Url.getUrlParameter('action');

        if (['login', 'logout'].includes(action) === false && this.isCandidateFromEmailCheckPage() === false) {
            this.props.requestHeader();
        }

        CookieConsentManager.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const action = Url.getUrlParameter('action');

        if (action !== 'login'
            && this.isCandidateFromEmailCheckPage() === false
            && this.props.isFetchingHeader === false
            && Skeletons.isSkeleton(this.props.headerModelMenu) === true
        ) {
            this.props.requestHeader();
        }
    }

    setLanguage = () => {
        Language.set(Language.get());
        document.documentElement.setAttribute('lang', Language.get());
    };

    isCandidateFromEmailCheckPage = () => {
        const urlParameters = Url.getUrlParameters();

        return urlParameters.action === 'dashboard' && urlParameters.hasOwnProperty('candidate') === true && Object.keys(urlParameters).length === 4;
    };

    render() {
        if (this.state.action === undefined) {
            return null;
        }

        if (['contact', 'features', 'faq', 'articles', 'pricing', 'packages', 'reaction', 'logout', 'calendar_sync', 'create-questionnaire', 'create-auto-answer', 'create-pipeline-template', 'maintenance', 'company-jobs', 'candidate-questionnaire', 'recommendation', 'integrated-reaction', 'candidate-card', 'create-job-ad'].indexOf(this.state.action) !== -1) {
            return null;
        }

        if (this.state.action === 'login' && Url.getUrlParameter('external-user') !== '') {
            return null;
        }

        if (this.isCandidateFromEmailCheckPage()) {
            return null;
        }

        if (this.props.isPageError === true) {
            return null;
        }

        const oldMenu = ['main'].includes(this.state.action) === true;

        if (
            Skeletons.isSkeleton(this.props.headerModelMenu)
            || this.props.headerModelMenu === undefined
            || this.props.headerModelMenu.length === 0
            || oldMenu === true
        ) {
            return null;
        }

        const jobDetailRoutes = [
            {path: Url.MARK_SERVER_PREFIX + '/pipeline/job/:jobId', name: 'pipeline'},
            {path: Url.MARK_SERVER_PREFIX + '/pipeline/job/:jobId/candidate/:candidateId', name: 'pipelineWithCandidateDetail'},
            {path: Url.MARK_SERVER_PREFIX + '/pipeline/job/:jobId/candidate/:candidateId/tab/:tabAlias', name: 'pipelineWithCandidateDetailAndTabSpecified'},
            {path: Url.MARK_SERVER_PREFIX + '/candidates/job/:jobId', name: 'candidates'},
            {path: Url.MARK_SERVER_PREFIX + '/candidates/job/:jobId/candidate/:candidateId', name: 'candidatesWithCandidateDetail'},
            {path: Url.MARK_SERVER_PREFIX + '/candidates/job/:jobId/candidate/:candidateId/tab/:tabAlias', name: 'candidatesWithCandidateDetailAndTabSpecified'},
            {path: Url.MARK_SERVER_PREFIX + '/detail/job/:jobId', name: 'detail'},
            {path: Url.MARK_SERVER_PREFIX + '/candidate-sources/job/:jobId', name: 'sources'},
        ];

        return (
            <Routes>
                {
                    jobDetailRoutes.map(({path}) => (
                        <Route
                            key={path}
                            path={path}
                            element={
                                <Header4MenuDetail
                                    action={this.state.action}
                                />
                            }

                        />
                    ))
                }

                <Route
                    path={Url.MARK_SERVER_PREFIX + '/create-job'}
                    element={
                        <Header4MenuCreateForm
                            backAction={{action: Url.build('jobs'), icon: 'cancel', text: Translations.getStatic('backActionText')}}
                            headerText={Translations.getStatic('createJob')}
                        />
                    }
                />

                <Route
                    path={Url.MARK_SERVER_PREFIX + '/close-job'}
                    element={
                        <Header4MenuCreateForm
                            backAction={{
                                action: '',
                                function: this.props.openCancelCloseJobWizardDialog,
                                icon: 'cancel',
                                text: Translations.getStatic('backActionText'),
                            }}
                            headerText={Translations.getStatic('closeJob')}
                        />
                    }
                />

                <Route
                    path={'/*'}
                    element={
                        <MenuDefault
                            action={this.state.action}
                            menu={this.props.headerModelMenu}
                        />
                    }
                />
            </Routes>
        );
    }
}

const mapStateToProps = state => ({
    loginManagerModel: Skeletons.getData(state.loginManagerModel, loginManagerModelSkeleton),
    headerModelMenu: Skeletons.getData(state.headerModel.menu, headerModelMenuSkeleton),
    isFetchingHeader: state.isFetchingHeader,
    isPageError: state.isPageError,
});

const mapDispatchToProps = {
    requestHeader,
    openCancelCloseJobWizardDialog,
};

Header.defaultProps = {
    pathNamesOfTabs: ['pipeline', 'candidates', 'detail', 'candidate-sources', 'create-job'],
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(WithRouterProp(Header));
