import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import MarkModal from '../Modal/MarkModal';
import {closeModal} from '../../actions/ModalActions';
import {processAutoAnswer} from '../../actions/AutoAnswersSettingsActions';
import SettingsAutoAnswerEditor from '../Settings/SettingsAutoAnswerEditor';
import SuccessMessage from '../../helpers/SuccessMessage';
import AutoAnswerValidatorHelper from '../../helpers/components/AutoAnswerValidatorHelper';
import TemplateAssistantHelper from '../../helpers/components/TemplateAssistantHelper';
import Translations from '../../helpers/Translations';
import TemplateAttachmentsHelper from '../../helpers/components/TemplateAttachmentsHelper';
import AttachmentAssistantHelper from '../../helpers/components/AttachmentAssistantHelper';

class SettingsPageAutoAnswerEditorModal extends Component
{
    state = {
        errors: [],
    };

    componentDidUpdate(prevProps) {
        if ((SuccessMessage.canShowSuccessMessage(prevProps, this.props, 'isProcessingAutoAnswer'))
            && this.props.autoAnswersSettingsModel.hasOwnProperty('isSuccess') === true
            && this.props.autoAnswersSettingsModel.isSuccess === true
        ) {
            this.props.closeModal();
        }
    };

    processAutoAnswer = () => {
        const {autoAnswerId, autoAnswerEditorModel, autoAnswersSettingsModel, templateAssistant, attachmentAssistantModel} = this.props;
        const {autoAnswerData}                                                                                             = autoAnswerEditorModel;
        const {autoAnswers}                                                                                                = autoAnswersSettingsModel;

        const templateSavingMode = TemplateAssistantHelper.getTemplateSavingMode(
            templateAssistant.autoAnswer,
        );

        let checkAgainst = templateSavingMode === TemplateAssistantHelper.TEMPLATE_SAVING_MODE_UPDATE
            ? autoAnswers.filter(autoAnswer => parseInt(autoAnswer.id) !== parseInt(autoAnswerId))
            : autoAnswers;
        checkAgainst     = checkAgainst.map(autoAnswer => autoAnswer.name);

        const validator = new AutoAnswerValidatorHelper(autoAnswerData);
        const errors    = validator.checkAutoAnswer(true, true, checkAgainst);

        this.setState({errors});

        if (errors.length === 0) {
            let formData = new FormData();

            formData.append('autoAnswerData', JSON.stringify(autoAnswerData));
            formData.append('autoAnswerSavingMode', templateSavingMode);

            formData = TemplateAttachmentsHelper.prepareFormData(formData, attachmentAssistantModel);

            this.props.processAutoAnswer(formData, parseInt(autoAnswerId), templateSavingMode);
        }
    };

    render() {
        const {isOpen, closeModal, isFetchingAutoAnswerEditorModel, isProcessingAutoAnswer} = this.props;

        const isPerformingAction = isFetchingAutoAnswerEditorModel || isProcessingAutoAnswer;

        return (
            <MarkModal
                isOpen={isOpen}
                closeModal={closeModal}
                isLoadingData={isFetchingAutoAnswerEditorModel}

                actionButton={{
                    text: Translations.getStatic('save'),
                    disabled: isPerformingAction,
                    loading: isPerformingAction,
                    onClick: this.processAutoAnswer,
                }}

                title={Translations.getStatic('automaticReplySettings')}

                content={(
                    <SettingsAutoAnswerEditor
                        errors={this.state.errors}
                        autoAnswerId={this.props.autoAnswerId}
                        isCopy={this.props.isCopy}
                    />
                )}
            />
        );
    }
}

SettingsPageAutoAnswerEditorModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    isCopy: PropTypes.bool.isRequired,
    autoAnswerId: PropTypes.number.isRequired,
};

SettingsPageAutoAnswerEditorModal.defaultProps = {
    autoAnswerId: 0,
    isCopy: false,
};

const mapStateToProps = state => ({
    isOpen: state.modalLogic.isOpen,
    autoAnswerEditorModel: state.autoAnswerEditorModel,
    autoAnswersSettingsModel: state.autoAnswersSettingsModel,
    templateAssistant: state.templateAssistant,
    isFetchingAutoAnswerEditorModel: state.isFetchingAutoAnswerEditorModel,
    isProcessingAutoAnswer: state.isProcessingAutoAnswer,

    attachmentAssistantModel: AttachmentAssistantHelper.getActiveModelFromAssistantAttachmentModel(
        state.attachmentAssistantModel,
        AttachmentAssistantHelper.createAttachmentAssistantHashIdForEmail(),
    ),
});

const mapDispatchToProps = {
    closeModal,
    processAutoAnswer,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SettingsPageAutoAnswerEditorModal);
