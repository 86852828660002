import React, {Component} from 'react';
import PropTypes from 'prop-types';
import MarkModal from '../Modal/MarkModal';
import {closeModal} from '../../actions/ModalActions';
import {connect} from 'react-redux';
import {requestSendEmailModel} from '../../actions/SendEmailActions';
import EmailTemplatePreview from '../Email/EmailTemplatePreview';
import Translations from '../../helpers/Translations';
import FormSkeleton from '../../skeletons/components/FormSkeleton';

class EmailTemplatePreviewModal extends Component
{
    componentDidMount = () => {
        this.props.requestSendEmailModel(this.props.emailTemplateId);
    };

    render() {
        const {isOpen, sendEmailModel, isFetchingSendEmailModel} = this.props;

        return (
            <MarkModal
                isOpen={isOpen}
                closeModal={this.props.closeModal}
                title={Translations.getStatic('emailTemplate')}
                actionButton={{visible: false}}
                isLoadingData={isFetchingSendEmailModel}

                content={
                    isFetchingSendEmailModel === true || sendEmailModel.hasOwnProperty('emailTemplateData') === false
                        ? <FormSkeleton/>
                        : <EmailTemplatePreview emailTemplateData={sendEmailModel.emailTemplateData}/>
                }
            />
        );
    }
}

EmailTemplatePreviewModal.propTypes = {
    emailTemplateId: PropTypes.number.isRequired,
    isOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    isOpen: state.modalLogic.isOpen,
    sendEmailModel: state.sendEmailModel,
    isFetchingSendEmailModel: state.isFetchingSendEmailModel,
});

const mapDispatchToProps = {
    closeModal,
    requestSendEmailModel,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplatePreviewModal);




