import React from 'react';
import PropTypes from 'prop-types';

const widthList = {
    1: 'one',
    2: 'two',
    3: 'three',
    4: 'four',
    5: 'five',
    6: 'six',
    7: 'seven',
    8: 'eight',
    9: 'nine',
    10: 'ten',
    11: 'eleven',
    12: 'twelve',
    13: 'thirteen',
    14: 'fourteen',
    15: 'fifteen',
    16: 'sixteen',
};

const Grid = props => {
    let {
            verticalAlign,
            textAlign,
            container,
            className,
            ...validProps
        } = props;

    container     = container === true ? 'container ' : '';
    verticalAlign = `${verticalAlign ? verticalAlign + ' aligned ' : ''}`;
    textAlign     = `${textAlign ? textAlign + ' aligned ' : ''}`;
    className     = `${className ? className + ' ' : ''}`;

    return <div {...validProps} className={`${container}${verticalAlign}${textAlign}${className}ui grid`}>{props.children}</div>;
};

const Row = props => {
    let {
            verticalAlign,
            textAlign,
            columns,
            isVisible = true,
            className,
            ...validProps
        } = props;

    verticalAlign = `${verticalAlign ? verticalAlign + ' aligned ' : ''}`;
    textAlign     = `${textAlign ? textAlign + ' aligned ' : ''}`;
    columns       = `${columns ? widthList[columns] + ' column ' : ''}`;
    className     = `${className ? className + ' ' : ''}`;

    return isVisible ? <div {...validProps} className={`${verticalAlign}${textAlign}${columns}${className}row`}>{props.children}</div> : null;
};

const Column = props => {
    let {
            verticalAlign,
            textAlign,
            width,
            mobile,
            tablet,
            computer,
            isVisible = true,
            className,
            ...validProps
        } = props;

    verticalAlign = `${verticalAlign ? verticalAlign + ' aligned ' : ''}`;
    textAlign     = `${textAlign ? textAlign + ' aligned ' : ''}`;
    width         = `${width ? widthList[width] + ' wide ' : ''}`;
    mobile        = `${mobile ? widthList[mobile] + ' wide mobile ' : ''}`;
    tablet        = `${tablet ? widthList[tablet] + ' wide tablet ' : ''}`;
    computer      = `${computer ? widthList[computer] + ' wide computer ' : ''}`;
    className     = `${className ? className + ' ' : ''}`;

    return isVisible ? <div {...validProps} className={`${verticalAlign}${textAlign}${computer}${mobile}${tablet}${width}${className}column`}>{props.children}</div> : null;
};


Grid.Row    = Row;
Grid.Column = Column;

Grid.propTypes = {
    verticalAlign: PropTypes.string,
    textAlign: PropTypes.string,
    container: PropTypes.bool,
    className: PropTypes.string,
};

Grid.propTypes = {
    verticalAlign: PropTypes.string,
    textAlign: PropTypes.string,
    columns: PropTypes.number,
    className: PropTypes.string,
};

Column.propTypes = {
    verticalAlign: PropTypes.string,
    textAlign: PropTypes.string,
    className: PropTypes.string,
    width: PropTypes.number,
    mobile: PropTypes.number,
    tablet: PropTypes.number,
    computer: PropTypes.number,
};

export default Grid;
