import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {Location, NavigateFunction, Params} from 'react-router-dom';

const WithRouterProp = (WrappedComponent: any) => (props: any) => {
    const location: Location         = useLocation();
    const navigate: NavigateFunction = useNavigate();
    const params: Params             = useParams();

    return (
        <WrappedComponent
            {...props}
            {...{location, navigate, params}}
        />
    );
};

export default WithRouterProp;
