import React from 'react';
import JobCandidatesListSkeleton from './JobCandidatesListSkeleton';
import ResponsiveJobCandidatesListFilterSkeleton from './ResponsiveJobCandidatesListFilterSkeleton';

const JobCandidatesListPageSkeleton = () => (
    <>
        <ResponsiveJobCandidatesListFilterSkeleton/>
        <JobCandidatesListSkeleton/>
    </>
);

export default JobCandidatesListPageSkeleton;
