import React from 'react';
import MarkForm from '../Form';
import {InputField} from '@profesia/adamui/components/common/input';
import Select from '@profesia/adamui/components/common/dropdown/SelectDefault';
import Grid from '../Grid';
import Divider from '../Divider';
import Icon from '../Icon';
import PropTypes from 'prop-types';
import Constants from '../../helpers/Constants';
import Validation from '../../helpers/Validation';
import SendEmailHelper from '../../helpers/components/SendEmailHelper';
import Translations from '../../helpers/Translations';
import Form from '../../helpers/Form';
import DateFormatted from '../../helpers/DateFormatted';
import DatetimePicker from '../DatetimePicker';
import SenderSelect from './SenderSelect';
import RecipientsSelect from './RecipientsSelect';
import BccSelect from './BccSelect';
import EmailMessageVariablesContainerEditor from './EmailMessageVariablesContainerEditor';

class EmailEditor extends React.Component
{
    constructor(props) {
        super(props);

        const emails = SendEmailHelper.filterNotEmptyEmailsFromRecipients(props.recipients);

        this.state = {
            emails,
            isRecipientsChanged: false,

            replaceRecipientField: props.visibleFields.includes(Constants.EMAIL_EDITOR_FIELD_RECIPIENTS_INFO) === true
                && props.recipients.length === 1
                && (emails.length === 0 || (emails.length === 1 && emails[0].hasOwnProperty('isEditable') === true && emails[0].isEditable === true)),
        };
    }

    componentDidUpdate(prevProps) {
        const nextPropsEmails = SendEmailHelper.filterNotEmptyEmailsFromRecipients(this.props.recipients);
        const emails          = SendEmailHelper.filterNotEmptyEmailsFromRecipients(prevProps.recipients);

        if (emails.length !== nextPropsEmails.length || (nextPropsEmails.length === 1 && nextPropsEmails[0].hasOwnProperty('isEditable') === true && nextPropsEmails[0].isEditable === true && nextPropsEmails[0].value !== emails[0].value)) {
            this.setState({
                emails: nextPropsEmails,
                isRecipientsChanged: nextPropsEmails.length > 0,
            });
        }
    }

    onChangeSendAt = type => {
        if (type === Constants.SEND_EMAIL_LATER) {
            const hour     = (parseInt(DateFormatted.getDateTime('now', 'H')) + 1) % 24;
            const datetime = DateFormatted.getDateTime(DateFormatted.getDate('now', 'Y-m-d') + ' ' + hour.toString() + ':00:00', 'Y-m-d H:i:s');

            this.props.onSetSendEmailAt({
                type: type,
                datetime,
            });
        } else {
            this.props.onSetSendEmailAt({
                type: type,
                datetime: null,
            });
        }
    };

    onChangeDate = date => {
        if (date === null) {
            this.props.onSetSendEmailAt({
                datetime: null,
            });
        }

        const time = this.props.sendEmailAt.datetime === null
            ? '10:00:00'
            : DateFormatted.getTime(this.props.sendEmailAt.datetime);

        this.props.onSetSendEmailAt({
            datetime: date + ' ' + time,
        });
    };

    onChangeTime = time => {
        const date = this.props.sendEmailAt.datetime === null
            ? DateFormatted.getDate('now', 'Y-m-d')
            : DateFormatted.getDate(this.props.sendEmailAt.datetime, 'Y-m-d');

        this.props.onSetSendEmailAt({
            datetime: date + ' ' + time + ':00',
        });
    };

    onHandleRecipientEmailChange = event => {
        this.props.onSetEmailRecipient(event.target.value);
    };

    render = () => {
        const {loginManagerModel, emailData, settings, bcc, errors, sendEmailAt, sender, cursorOnStart, languageId, languages} = this.props;

        let visibleFields = this.props.visibleFields;

        if (this.state.replaceRecipientField === true) {
            visibleFields = visibleFields.filter(field => field !== Constants.EMAIL_EDITOR_FIELD_RECIPIENTS_INFO);
            visibleFields.push(Constants.EMAIL_EDITOR_FIELD_RECIPIENT);
        }

        const {isRecipientsChanged, emails} = this.state;

        const colleaguesEmails = settings.colleaguesEmails.map(colleagueEmail => ({
            key: colleagueEmail,
            text: colleagueEmail,
            value: colleagueEmail,
            content: (
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={15}>{colleagueEmail}</Grid.Column>
                        <Grid.Column width={1} textAlign={'right'}>
                            {
                                this.props.isRemovingColleagueContact === true
                                    ? (
                                        <Icon
                                            inverted
                                            loading
                                            disabled
                                            name="spinner"
                                            color="grey"
                                        />
                                    )
                                    : (
                                        <Icon
                                            inverted
                                            name="delete"
                                            color="grey"
                                        />
                                    )
                            }
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            ),
        }));

        const recipientEmails = emails.map(item => item.value);
        const bccEmails       = bcc.map(item => item.value);

        const senders = SendEmailHelper.getSenders(settings.companyUsers, loginManagerModel);

        return (
            <>
                {
                    visibleFields.includes(Constants.EMAIL_EDITOR_FIELD_SEND_AT) === true
                    && (
                        <>
                            <Grid.Row className="horizontal-row">
                                <Grid.Column computer={4} tablet={16} verticalAlign="middle">
                                    <label>{Translations.getStatic('send')}</label>
                                </Grid.Column>
                                <Grid.Column computer={5} tablet={16} verticalAlign="middle">
                                    <Select
                                        error={sendEmailAt.type === Constants.SEND_EMAIL_LATER && errors.includes('sendAt') === true}
                                        variant="dark"
                                        value={sendEmailAt.type}
                                        options={[
                                            {key: Constants.SEND_EMAIL_IMMEDIATELY, label: Translations.getStatic(Constants.SEND_EMAIL_IMMEDIATELY), id: Constants.SEND_EMAIL_IMMEDIATELY},
                                            {key: Constants.SEND_EMAIL_LATER, label: Translations.getStatic(Constants.SEND_EMAIL_LATER), id: Constants.SEND_EMAIL_LATER},
                                        ]}
                                        onChange={(value) => this.onChangeSendAt(value)}
                                    />
                                </Grid.Column>
                                {
                                    sendEmailAt.type === Constants.SEND_EMAIL_LATER
                                    && (
                                        <>
                                            <Grid.Column computer={4} tablet={16} verticalAlign="middle">
                                                <MarkForm.Field className={'padding-bottom_none'}>
                                                    <DatetimePicker
                                                        className="darker"
                                                        error={sendEmailAt.type === Constants.SEND_EMAIL_LATER && errors.includes('sendAt') === true}
                                                        placeholder={''}
                                                        defaultValue={
                                                            sendEmailAt.datetime === null
                                                                ? DateFormatted.getDate('now', 'Y-m-d')
                                                                : DateFormatted.getDate(sendEmailAt.datetime, 'Y-m-d')
                                                        }
                                                        name={'sendAtDate'}
                                                        dateInputNameSuffix={''}
                                                        dateEnabled={true}
                                                        timeEnabled={false}
                                                        onChange={date => this.onChangeDate(date)}
                                                    />
                                                </MarkForm.Field>
                                            </Grid.Column>
                                            <Grid.Column computer={3} tablet={16} verticalAlign="middle">
                                                <MarkForm.Field className={'padding-bottom_none'}>
                                                    <DatetimePicker
                                                        className="darker"
                                                        error={sendEmailAt.type === Constants.SEND_EMAIL_LATER && errors.includes('sendAt') === true}
                                                        align={'center'}
                                                        defaultValue={
                                                            sendEmailAt.datetime === null
                                                                ? DateFormatted.getDateTime(DateFormatted.getDate('now', 'Y-m-d') + ' 10:00:00', 'Y-m-d H:i:s')
                                                                : DateFormatted.getDateTime(sendEmailAt.datetime, 'Y-m-d H:i:s')
                                                        }
                                                        name={'sendAtTime'}
                                                        dateInputNameSuffix={''}
                                                        dateEnabled={false}
                                                        timeEnabled={true}
                                                        onTimeChange={time => this.onChangeTime(time)}
                                                    />
                                                </MarkForm.Field>
                                            </Grid.Column>
                                        </>
                                    )
                                }
                            </Grid.Row>

                            {
                                errors.includes('sendAt') === true
                                && (
                                    <Grid.Row>
                                        <Grid.Column computer={4} tablet={16} verticalAlign="top"/>
                                        <Grid.Column computer={12} tablet={16}>
                                            <div className="prompt label">
                                                {Validation.getMessage('date')}
                                            </div>
                                            <Divider hidden/>
                                        </Grid.Column>
                                    </Grid.Row>
                                )
                            }

                        </>
                    )
                }
                {
                    visibleFields.includes(Constants.EMAIL_EDITOR_FIELD_SENDER_INFO) === true
                        ? (
                            <Grid.Row className="horizontal-row">
                                <Grid.Column computer={4} tablet={16} verticalAlign="middle">
                                    <label>{Translations.getStatic('sender')}</label>
                                </Grid.Column>
                                <Grid.Column computer={12} tablet={16} verticalAlign="middle">
                                    {settings.userData.email}
                                </Grid.Column>
                            </Grid.Row>
                        )
                        : null
                }
                {
                    visibleFields.includes(Constants.EMAIL_EDITOR_FIELD_SENDER) === true
                        ? (
                            <Grid.Row className="horizontal-row">
                                <Grid.Column computer={4} tablet={16} verticalAlign="middle">
                                    <label>{Translations.getStatic('sender')}</label>
                                </Grid.Column>
                                <Grid.Column computer={12} tablet={16} verticalAlign="middle">
                                    <SenderSelect senders={senders} senderId={sender} onSetEmailSender={this.props.onSetEmailSender}/>
                                </Grid.Column>
                            </Grid.Row>
                        )
                        : null
                }
                {
                    visibleFields.includes(Constants.EMAIL_EDITOR_FIELD_RECIPIENTS_INFO) === true
                        ? (
                            <Grid.Row className="horizontal-row multiline">
                                <Grid.Column computer={4} tablet={16} verticalAlign="top">
                                    <label>{Translations.getStatic('recipients')}</label>
                                </Grid.Column>
                                <Grid.Column computer={12} tablet={16} verticalAlign="top">
                                    <p>
                                        {
                                            this.props.recipients.length > 1
                                                ? Translations.getStatic('recipientBulk')
                                                : recipientEmails.join(', ')
                                        }
                                    </p>
                                </Grid.Column>
                            </Grid.Row>
                        )
                        : null
                }
                {
                    visibleFields.includes(Constants.EMAIL_EDITOR_FIELD_RECIPIENTS_INFO) === true
                    && Array.isArray(errors) === true
                    && errors.length > 0 && errors.includes('recipients') === true
                    && (
                        <Grid.Row>
                            <Grid.Column computer={4} tablet={16} verticalAlign="top"/>
                            <Grid.Column computer={12} tablet={16}>
                                <div className="prompt label">
                                    {Translations.getStatic('emailValidation') + ' ' + Translations.getStatic('setValidEmailInCandidateData')}
                                </div>
                                <Divider hidden/>
                            </Grid.Column>
                        </Grid.Row>
                    )
                }
                {
                    visibleFields.includes(Constants.EMAIL_EDITOR_FIELD_RECIPIENTS) === true
                        ? (
                            <Grid.Row className="horizontal-row multiline">
                                <Grid.Column computer={4} tablet={16} verticalAlign="top">
                                    <label>{Translations.getStatic('recipient')}</label>
                                </Grid.Column>
                                <Grid.Column computer={12} tablet={16}>
                                    <RecipientsSelect
                                        value={recipientEmails}
                                        options={colleaguesEmails}
                                        isRecipientsChanged={isRecipientsChanged}
                                        errors={errors}
                                        onSetEmailRecipients={this.props.onSetEmailRecipients}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        )
                        : null
                }
                {
                    visibleFields.includes(Constants.EMAIL_EDITOR_FIELD_RECIPIENT) === true
                        ? (
                            <Grid.Row className="horizontal-row multiline">
                                <Grid.Column computer={4} tablet={16} verticalAlign="top">
                                    <label>{Translations.getStatic('recipient')}</label>
                                </Grid.Column>
                                <Grid.Column computer={12} tablet={16}>
                                    <MarkForm.Field
                                        error={
                                            Array.isArray(errors) === true && errors.includes('recipients') && (recipientEmails.length === 0 || (Form.check({fieldType: 'email', fieldValue: recipientEmails[0]}) === false && isRecipientsChanged === true))
                                                ? Validation.getMessage('empty', 'recipient')
                                                : false
                                        }
                                    >
                                        <InputField
                                            className=" darker_small padding-bottom_none"
                                            value={recipientEmails.join('')}
                                            onChange={(event) => this.onHandleRecipientEmailChange(event)}
                                        />
                                    </MarkForm.Field>
                                </Grid.Column>
                            </Grid.Row>
                        )
                        : null
                }
                {
                    visibleFields.includes(Constants.EMAIL_EDITOR_FIELD_BCC) === true
                        ? (
                            <Grid.Row className="horizontal-row multiline">
                                <Grid.Column computer={4} tablet={16} verticalAlign="top">
                                    <label>{Translations.getStatic('bcc')}</label>
                                </Grid.Column>
                                <Grid.Column computer={12} tablet={16}>
                                    <BccSelect
                                        value={bccEmails}
                                        options={colleaguesEmails}
                                        onSetEmailBcc={this.props.onSetEmailBcc}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        )
                        : null
                }

                <EmailMessageVariablesContainerEditor
                    languageId={languageId}
                    emailData={emailData}
                    settings={settings}
                    errors={errors}
                    onUpdateEmailTemplate={this.props.onUpdateEmailTemplate}
                    cursorOnStart={cursorOnStart}
                />
            </>
        );
    };
}

EmailEditor.propTypes = {
    languageId: PropTypes.number.isRequired,
    errors: PropTypes.array,
    visibleFields: PropTypes.array,

    recipients: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
    })),

    bcc: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
    })),

    sender: PropTypes.number.isRequired,
    emailData: PropTypes.object.isRequired,
    settings: PropTypes.object.isRequired,
    cursorOnStart: PropTypes.bool,
    onUpdateEmailTemplate: PropTypes.func.isRequired,
    onSetEmailRecipients: PropTypes.func.isRequired,
    onSetEmailRecipient: PropTypes.func.isRequired,
    onSetEmailBcc: PropTypes.func.isRequired,
    onSetEmailSender: PropTypes.func.isRequired,
    isRemovingColleagueContact: PropTypes.bool,
};

EmailEditor.defaultProps = {
    visibleFields: [
        Constants.EMAIL_EDITOR_FIELD_SENDER_INFO,
        Constants.EMAIL_EDITOR_FIELD_SEND_AT,
    ],
    errors: [],
    recipients: [],
    bcc: [],
    isRemovingColleagueContact: false,
    cursorOnStart: false,
};

export default EmailEditor;
