import React from 'react';
import {connect} from 'react-redux';
import {closeNoModalSecond} from '../../actions/ModalActions';
import PropTypes from 'prop-types';
import DetailCandidateReadOnlyContent from '../Candidate/DetailCandidateReadOnlyContent';

const DetailCandidateDuplicateNoModal = props => {
    const visibility = props.noModalIsOpen === true ? 'show' : 'hide';

    return (
        <div className='modal-wrapper modal-wrapper--open'>
            <div className={('noModal-duplicate noModal-wrapper ' + visibility)}>
                <DetailCandidateReadOnlyContent {...props}/>
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    noModalIsOpen: state.noModalSecondLogic.noModalSecondIsOpen,
    candidateModel: state.candidateModel.duplicatedDetail,
});

const mapDispatchToProps = {
    closeNoModal: closeNoModalSecond,
};

DetailCandidateDuplicateNoModal.propTypes = {
    candidateId: PropTypes.number.isRequired,
    candidateName: PropTypes.string.isRequired,
    attachmentsCount: PropTypes.number.isRequired,
    questionnaireResult: PropTypes.number,
};

DetailCandidateDuplicateNoModal.defaultProps = {
    questionnaireResult: null,
    attachmentsCount: 0,
    candidateName: '',
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DetailCandidateDuplicateNoModal);
