export const REQUEST_JOB_QUESTIONNAIRE_RESULTS_MODEL = 'REQUEST_JOB_QUESTIONNAIRE_RESULTS_MODEL';
export const RECEIVE_JOB_QUESTIONNAIRE_RESULTS_MODEL = 'RECEIVE_JOB_QUESTIONNAIRE_RESULTS_MODEL';

export const requestJobQuestionnaireResultsModel = jobId => ({
    type: REQUEST_JOB_QUESTIONNAIRE_RESULTS_MODEL,

    meta: {
        requestRoute: 'v1/admin/model/job-questionnaire-results/' + jobId,
        onRequestSuccess: RECEIVE_JOB_QUESTIONNAIRE_RESULTS_MODEL,
    },
});
