export const REQUEST_COMPANY_EXPORT_REQUESTS_MODEL     = 'REQUEST_COMPANY_EXPORT_REQUESTS_MODEL';
export const RECEIVE_COMPANY_EXPORT_REQUESTS_MODEL     = 'RECEIVE_COMPANY_EXPORT_REQUESTS_MODEL';
export const COMPANY_EXPORT_REQUEST_ACTIVATE           = 'COMPANY_EXPORT_REQUEST_ACTIVATE';
export const COMPANY_EXPORT_REQUEST_ACTIVATE_SUCCESS   = 'COMPANY_EXPORT_REQUEST_ACTIVATE_SUCCESS';
export const COMPANY_EXPORT_REQUEST_ACTIVATE_FAILURE   = 'COMPANY_EXPORT_REQUEST_ACTIVATE_FAILURE';
export const COMPANY_EXPORT_REQUEST_DEACTIVATE         = 'COMPANY_EXPORT_REQUEST_DEACTIVATE';
export const COMPANY_EXPORT_REQUEST_DEACTIVATE_SUCCESS = 'COMPANY_EXPORT_REQUEST_DEACTIVATE_SUCCESS';
export const COMPANY_EXPORT_REQUEST_DEACTIVATE_FAILURE = 'COMPANY_EXPORT_REQUEST_DEACTIVATE_FAILURE';
export const COMPANY_EXPORT_REQUEST_UPDATE             = 'COMPANY_EXPORT_REQUEST_UPDATE';
export const COMPANY_EXPORT_REQUEST_UPDATE_SUCCESS     = 'COMPANY_EXPORT_REQUEST_UPDATE_SUCCESS';
export const COMPANY_EXPORT_REQUEST_UPDATE_FAILURE     = 'COMPANY_EXPORT_REQUEST_UPDATE_FAILURE';
export const COMPANY_EXPORT_REQUEST_CREATE             = 'COMPANY_EXPORT_REQUEST_CREATE';
export const COMPANY_EXPORT_REQUEST_CREATE_SUCCESS     = 'COMPANY_EXPORT_REQUEST_CREATE_SUCCESS';
export const COMPANY_EXPORT_REQUEST_CREATE_FAILURE     = 'COMPANY_EXPORT_REQUEST_CREATE_FAILURE';
export const COMPANY_EXPORT_REQUEST_ADD                = 'COMPANY_EXPORT_REQUEST_ADD';
export const COMPANY_EXPORT_REQUEST_ADD_CANCEL         = 'COMPANY_EXPORT_REQUEST_ADD_CANCEL';

export const requestCompanyExportRequestsModel = () => ({
    type: REQUEST_COMPANY_EXPORT_REQUESTS_MODEL,

    meta: {
        requestRoute: 'v1/admin/model/company-export-requests',
        onRequestSuccess: RECEIVE_COMPANY_EXPORT_REQUESTS_MODEL,
    },
});

export const activateCompanyExportRequest = companyExportRequestId => ({
    type: COMPANY_EXPORT_REQUEST_ACTIVATE,

    payload: {
        companyExportRequestId,
    },

    meta: {
        requestMethod: 'POST',
        requestRoute: 'v1/admin/company-export-requests/action/activate',

        requestBody: JSON.stringify({
            companyExportRequestId,
        }),

        onRequestSuccess: COMPANY_EXPORT_REQUEST_ACTIVATE_SUCCESS,
        onRequestFailure: COMPANY_EXPORT_REQUEST_ACTIVATE_FAILURE,
    },
});

export const deactivateCompanyExportRequest = companyExportRequestId => ({
    type: COMPANY_EXPORT_REQUEST_DEACTIVATE,

    payload: {
        companyExportRequestId,
    },

    meta: {
        requestMethod: 'POST',
        requestRoute: 'v1/admin/company-export-requests/action/deactivate',

        requestBody: JSON.stringify({
            companyExportRequestId,
        }),

        onRequestSuccess: COMPANY_EXPORT_REQUEST_DEACTIVATE_SUCCESS,
        onRequestFailure: COMPANY_EXPORT_REQUEST_DEACTIVATE_FAILURE,
    },
});

export const updateCompanyExportRequest = (companyExportRequestId, companyId, jobsCount, stateId) => ({
    type: COMPANY_EXPORT_REQUEST_UPDATE,

    payload: {
        companyExportRequestId,
        companyId,
        jobsCount,
        stateId,
    },

    meta: {
        requestMethod: 'POST',
        requestRoute: 'v1/admin/company-export-requests/action/update',

        requestBody: JSON.stringify({
            companyExportRequestId,
            companyId,
            jobsCount,
            stateId,
        }),

        onRequestSuccess: COMPANY_EXPORT_REQUEST_UPDATE_SUCCESS,
        onRequestFailure: COMPANY_EXPORT_REQUEST_UPDATE_FAILURE,
    },
});

export const createCompanyExportRequest = (companyId, jobsCount, stateId) => ({
    type: COMPANY_EXPORT_REQUEST_CREATE,

    payload: {
        companyExportRequestId: 'new',
    },

    meta: {
        requestMethod: 'POST',
        requestRoute: 'v1/admin/company-export-requests/action/create',

        requestBody: JSON.stringify({
            companyId,
            jobsCount,
            stateId,
        }),

        onRequestSuccess: COMPANY_EXPORT_REQUEST_CREATE_SUCCESS,
        onRequestFailure: COMPANY_EXPORT_REQUEST_CREATE_FAILURE,
    },
});

export const addCompanyExportRequest = () => ({
    type: COMPANY_EXPORT_REQUEST_ADD,
});

export const cancelAddCompanyExportRequest = () => ({
    type: COMPANY_EXPORT_REQUEST_ADD_CANCEL,
});
