import Form from '../Form';
import SendEmailHelper from './SendEmailHelper';
import Translations from '../Translations';
import Constants from '../Constants';
import DateFormatted from '../DateFormatted';
import Variables from '../Variables';

export default class OutOfOfficeValidatorHelper
{
    constructor(message, subject, startAt, endAt) {
        this.message = message.trim();
        this.subject = subject.trim();
        this.startAt = startAt;
        this.endAt   = endAt;

    }

    checkOutOfOffice(variableCheckParams = {
        variables: [],
        filledVariables: {},
        requiredVariables: {},
        forbiddenVariables: {},
    }) {
        const fieldsToCheck = [
            {
                fieldName: 'message',
                fieldType: 'wysiwyg',
                fieldValue: this.message,
            },
            {
                fieldName: 'message',
                fieldType: 'notEmpty',
                fieldValue: this.message,
            },
            {
                fieldName: 'subject',
                fieldType: 'wysiwyg',
                fieldValue: this.subject,
            },
            {
                fieldName: 'subject',
                fieldType: 'notEmpty',
                fieldValue: this.subject,
            },
            {
                fieldName: 'startAtForCheck',
                fieldType: 'date',
                fieldValue: this.startAt,
            },
            {
                fieldName: 'endAtForCheck',
                fieldType: 'date',
                fieldValue: this.endAt,
            },
            {
                fieldName: 'incorrectDates',
                fieldType: 'boolean',
                fieldValue: DateFormatted.getTimestamp(this.startAt) <= DateFormatted.getTimestamp(this.endAt),
            },
        ];

        let errors = Form.multiCheck(fieldsToCheck);

        if (Object.keys(variableCheckParams.requiredVariables).length > 0) {
            const variableErrors = Variables.checkRequiredVariables(variableCheckParams.variables, [this.subject, this.message], variableCheckParams.requiredVariables.actionTypesToCheck);

            if (variableErrors.length > 0) {
                variableErrors.forEach(variableError => {
                    errors.push(variableError);
                });
            }
        }

        if (errors.length > 0) {
            return errors;
        }

        if (Object.keys(variableCheckParams.forbiddenVariables).length > 0) {
            const variableErrors = Variables.checkForbiddenVariables(variableCheckParams.variables, [this.subject, this.message], variableCheckParams.forbiddenVariables.actionTypesToCheck);

            if (variableErrors.length > 0) {
                variableErrors.forEach(variableError => {
                    errors.push(variableError);
                });
            }
        }

        if (errors.length > 0) {
            return errors;
        }

        if (Object.keys(variableCheckParams.filledVariables).length > 0) {
            const variableErrors = Variables.checkFilledVariables(variableCheckParams.variables, [this.subject, this.message], variableCheckParams.filledVariables.actionTypesToCheck, variableCheckParams.filledVariables.dataToCheck);

            if (variableErrors.length > 0) {
                variableErrors.forEach(variableError => {
                    errors.push(variableError);
                });
            }
        }

        return errors;
    }
}
