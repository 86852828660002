import React from 'react';
import Grid from '../../components/Grid';
import Translations from '../../helpers/Translations';
import DashboardJobsListSkeleton from './DashboardJobsListSkeleton';
import DashboardNewCandidatesSkeleton from './DashboardNewCandidatesSkeleton';

const B2bDashboardSkeleton = () => (
    <div className="page">
        <div className="template-header header-basic">
            <div className="width_full">
                <Grid>
                    <Grid.Row verticalAlign="middle" columns={4}>
                        <Grid.Column computer={10} tablet={10} mobile={16}>
                            <div className="header__title">
                                <h1>{Translations.getStatic('home')}</h1>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        </div>
        <div className="page-content" id="scrollableDiv">
            <DashboardJobsListSkeleton/>

            <div className="page-content_right skeleton">
                <DashboardNewCandidatesSkeleton/>
            </div>
        </div>
    </div>
);

export default B2bDashboardSkeleton;
