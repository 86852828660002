import {
    REQUEST_CONTENT_PAGES_MENU_MODEL,
    RECEIVE_CONTENT_PAGES_MENU_MODEL,
    REQUEST_CONTENT_PAGE_MODEL,
    RECEIVE_CONTENT_PAGE_MODEL,
} from '../actions/ContentPagesActions';

export const isFetchingContentPagesMenuModel = (state = false, action) => {
    switch (action.type) {
        case REQUEST_CONTENT_PAGES_MENU_MODEL:
            return true;
        case RECEIVE_CONTENT_PAGES_MENU_MODEL:
            return false;
        default:
            return state;
    }
};

export const isFetchingContentPageModel = (state = false, action) => {
    switch (action.type) {
        case REQUEST_CONTENT_PAGE_MODEL:
            return true;
        case RECEIVE_CONTENT_PAGE_MODEL:
            return false;
        default:
            return state;
    }
};

const defaultState = {
    menu: [],
    page: {},
};

export const contentPageModel = (state = defaultState, action) => {
    switch (action.type) {
        case REQUEST_CONTENT_PAGES_MENU_MODEL:
            return {
                ...state,
                menu: [],
            };
        case RECEIVE_CONTENT_PAGES_MENU_MODEL:
            return {
                ...state,
                menu: action.payload.menu,
            };
        case REQUEST_CONTENT_PAGE_MODEL:
            return {
                ...state,
                page: {},
            };
        case RECEIVE_CONTENT_PAGE_MODEL:
            return {
                ...state,
                page: action.payload.page,
            };
        default:
            return state;
    }
};
