import React, {Component} from 'react';
import PropTypes from 'prop-types';
import MarkModal from '../Modal/MarkModal';
import {closeModal} from '../../actions/ModalActions';
import {connect} from 'react-redux';
import {requestQuestionnaireEditorModel} from '../../actions/QuestionnaireEditorActions';
import QuestionnairePreview from '../Questionnaire/QuestionnairePreview';
import LoadingComponent from '../LoadingComponent';
import Translations from '../../helpers/Translations';

class QuestionnaireTemplatePreviewModal extends Component
{
    componentDidMount = () => {
        this.props.requestQuestionnaireEditorModel(this.props.questionnaireId);
    };

    render() {
        const {isOpen, questionnaireEditorModel, isFetchingQuestionnaireEditorModel} = this.props;

        return (
            <MarkModal
                isOpen={isOpen}
                closeModal={this.props.closeModal}
                title={Translations.getStatic('questionnaireTemplate')}
                actionButton={{visible: false}}
                isLoadingData={isFetchingQuestionnaireEditorModel}

                content={
                    isFetchingQuestionnaireEditorModel === true || questionnaireEditorModel.hasOwnProperty('questionnaireData') === false
                        ? <LoadingComponent/>
                        : <QuestionnairePreview questionnaireData={questionnaireEditorModel.questionnaireData}/>
                }
            />
        );
    }
}

QuestionnaireTemplatePreviewModal.propTypes = {
    questionnaireId: PropTypes.number.isRequired,
    isOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    isOpen: state.modalLogic.isOpen,
    questionnaireEditorModel: state.questionnaireEditorModel,
    isFetchingQuestionnaireEditorModel: state.isFetchingQuestionnaireEditorModel,
});

const mapDispatchToProps = {
    closeModal,
    requestQuestionnaireEditorModel,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionnaireTemplatePreviewModal);




