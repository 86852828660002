import {
    RECEIVE_PIPELINE_TEMPLATES_SETTINGS_MODEL, REQUEST_PIPELINE_TEMPLATES_SETTINGS_MODEL,
    PROCESS_PIPELINE_TEMPLATE, PROCESS_PIPELINE_TEMPLATE_FAILURE, PROCESS_PIPELINE_TEMPLATE_SUCCESS,
    REMOVE_PIPELINE_TEMPLATE, REMOVE_PIPELINE_TEMPLATE_FAILURE, REMOVE_PIPELINE_TEMPLATE_SUCCESS,
    SET_PIPELINE_TEMPLATE_AS_DEFAULT,
    UNSET_PIPELINE_TEMPLATE_AS_DEFAULT, SET_PIPELINE_TO_OPEN_JOBS, SET_PIPELINE_TO_OPEN_JOBS_SUCCESS, SET_PIPELINE_TO_OPEN_JOBS_FAILURE,
} from '../actions/PipelineTemplatesSettingsActions';
import TemplateAssistantHelper from '../helpers/components/TemplateAssistantHelper';

export const pipelineTemplatesSettingsModel = (state = {}, action) => {
    switch (action.type) {
        case REQUEST_PIPELINE_TEMPLATES_SETTINGS_MODEL:
            return {
                ...state,
                isSuccess: false,
            };
        case RECEIVE_PIPELINE_TEMPLATES_SETTINGS_MODEL:
            return {
                ...state,
                ...action.payload,
            };
        case PROCESS_PIPELINE_TEMPLATE_SUCCESS:
            let pipelineTemplates = action.meta.requestPayload.savingMode === TemplateAssistantHelper.TEMPLATE_SAVING_MODE_UPDATE
                ? state.pipelineTemplates.filter(pipelineTemplate => pipelineTemplate.id !== action.meta.requestPayload.id)
                : state.pipelineTemplates;

            if (action.payload.pipelineTemplate.isDefault === true) {
                pipelineTemplates = pipelineTemplates.map(pipelineTemplate => ({
                    ...pipelineTemplate,
                    isDefault: false,
                }));
            }

            pipelineTemplates.push(action.payload.pipelineTemplate);

            return {
                ...state,
                pipelineTemplates,

                originalPipelineId: action.payload.originalPipelineId,
                openJobsCount: action.payload.openJobsCount,
                pipelineStagesToBeChanged: action.payload.pipelineStagesToBeChanged,
                lastEditedPipelineTemplateId: action.payload.id,

                isSuccess: true,
            };
        case REMOVE_PIPELINE_TEMPLATE:
            return {
                ...state,
                removedPipelineTemplate: state.pipelineTemplates.find(pipelineTemplate => parseInt(action.payload.id) === pipelineTemplate.id),
                pipelineTemplates: state.pipelineTemplates.filter(pipelineTemplate => parseInt(action.payload.id) !== pipelineTemplate.id),
            };
        case REMOVE_PIPELINE_TEMPLATE_FAILURE:
            return {
                ...state,
                pipelineTemplates: state.pipelineTemplates.concat([state.removedPipelineTemplate]),
                isSuccess: true,
            };
        case REMOVE_PIPELINE_TEMPLATE_SUCCESS:
            return {
                ...state,
                isSuccess: true,
            };
        case SET_PIPELINE_TEMPLATE_AS_DEFAULT:
            return {
                ...state,

                pipelineTemplates: state.pipelineTemplates.map(pipelineTemplate => ({
                    ...pipelineTemplate,
                    isDefault: pipelineTemplate.id === action.payload.pipelineTemplateId,
                })),
            };
        case UNSET_PIPELINE_TEMPLATE_AS_DEFAULT:
            return {
                ...state,

                pipelineTemplates: state.pipelineTemplates.map(pipelineTemplate => ({
                    ...pipelineTemplate,
                    isDefault: false,
                })),
            };
        default:
            return state;
    }
};

export const isFetchingPipelineTemplatesSettings = (state = false, action) => {
    switch (action.type) {
        case REQUEST_PIPELINE_TEMPLATES_SETTINGS_MODEL:
            return true;
        case RECEIVE_PIPELINE_TEMPLATES_SETTINGS_MODEL:
            return false;
        default:
            return state;
    }
};

export const isProcessingPipelineTemplate = (state = false, action) => {
    switch (action.type) {
        case PROCESS_PIPELINE_TEMPLATE:
        case REMOVE_PIPELINE_TEMPLATE:
            return true;
        case PROCESS_PIPELINE_TEMPLATE_SUCCESS:
        case PROCESS_PIPELINE_TEMPLATE_FAILURE:
        case REMOVE_PIPELINE_TEMPLATE_SUCCESS:
        case REMOVE_PIPELINE_TEMPLATE_FAILURE:
            return false;
        default:
            return state;
    }
};

export const isSettingPipelineToOpenJobs = (state = false, action) => {
    switch (action.type) {
        case SET_PIPELINE_TO_OPEN_JOBS:
            return true;

        case SET_PIPELINE_TO_OPEN_JOBS_SUCCESS:
        case SET_PIPELINE_TO_OPEN_JOBS_FAILURE:
            return false;

        default:
            return state;
    }
};
