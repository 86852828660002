import React, {Component} from 'react';
import {connect} from 'react-redux';
import CandidateSidebarHistoryItem from './CandidateSidebarHistoryItem';
import Item from '../../Item';
import Icon from '../../Icon';
import {openSidebarEditPanel} from '../../../actions/SidebarActions';
import PropTypes from 'prop-types';
import Translations from '../../../helpers/Translations';
import CandidateHistoryHelper from '../../../helpers/components/CandidateHistoryHelper';
import CandidateHelper from '../../../helpers/components/CandidateHelper';

class CandidateSidebarHistory extends Component
{
    state = {
        slideItem: false,
    };

    componentDidUpdate(prevProps) {
        if (this.props.history.length > prevProps.history.length) {
            this.setState({slideItem: true});

            setTimeout(() => {
                this.setState({slideItem: false});
            }, this.props.animationSlideTime);
        }
    }

    render() {
        const {history, candidateId, displaySize} = this.props;
        const {slideItem}                         = this.state;

        if (displaySize.isMobile === true) {
            return (
                <div className="ui items items-history unstackable">
                    <Item>
                        <Icon className="icon-history_small" size="mini"/>
                        <Item.Content>
                            <Item.Header>
                                <a className="secondary-link" onClick={() => this.props.openSidebarEditPanel('CandidateSidebarHistoryAll', {candidateId, history})}>{Translations.getStatic('candidateHistory')}</a>
                            </Item.Header>
                        </Item.Content>
                    </Item>
                </div>
            );
        }

        return (
            <div className="ui items items-history unstackable">
                <Item>
                    <Icon className="icon-history_small" size="mini"/>
                    <Item.Content>
                        <Item.Header>
                            {
                                history.length > 4
                                    ? <a className="secondary-link" onClick={() => this.props.openSidebarEditPanel('CandidateSidebarHistoryAll', {candidateId, history})}>{Translations.getStatic('history')}</a>
                                    : <p>{Translations.getStatic('history')}</p>
                            }
                        </Item.Header>
                    </Item.Content>
                </Item>
                {
                    history.slice(0, 4).map((item, i) => {
                        return (
                            <Item key={i} className={i === 0 && slideItem === true ? 'slideItem' : ''}>
                                <Icon className="icon-list-item" size="mini"/>
                                <Item.Content>
                                    <h6>
                                        {CandidateHistoryHelper.getHistoryItemHeader(item, this.props.candidateModel.candidate)}
                                    </h6>

                                    <CandidateSidebarHistoryItem
                                        candidateId={candidateId}
                                        item={item}
                                    />
                                </Item.Content>
                            </Item>
                        );
                    })
                }
                {
                    history.length > 4
                    && (
                        <Item>
                            <Icon className="hidden mini"/>
                            <Item.Content>
                                <a className="secondary-link" onClick={() => this.props.openSidebarEditPanel('CandidateSidebarHistoryAll', {candidateId, history})}>{Translations.getStatic('showHistory')}</a>
                            </Item.Content>
                        </Item>
                    )
                }
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        candidateModel: CandidateHelper.getActiveModelFromCandidateModel(state.candidateModel, ownProps.candidateId),
        displaySize: state.displaySize,
    };
};

const mapDispatchToProps = {
    openSidebarEditPanel,
};

CandidateSidebarHistory.propTypes = {
    history: PropTypes.array.isRequired,
    candidateId: PropTypes.number.isRequired,
};

CandidateSidebarHistory.defaultProps = {
    history: [],
    animationSlideTime: 300,
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateSidebarHistory);
