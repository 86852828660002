import React from 'react';
import {Radio} from 'semantic-ui-react';
import {InputField} from '@profesia/adamui/components/common/input';
import Form from '../Form';
import Checkbox from '@profesia/adamui/components/common/input/checkbox/Checkbox';
import {Textarea} from '@profesia/adamui/components/common/input';
import Grid from '../Grid';
import PropTypes from 'prop-types';
import Constants from '../../helpers/Constants';
import Translations from '../../helpers/Translations';
import DatetimePicker from '../DatetimePicker';

const QuestionnairePreview = props => {
    const rowItems = props.questionnaireData.questions.map(question => {
        let answersWrapper = null;

        switch (question.type) {
            case Constants.QUESTIONNAIRE_QUESTION_TYPE_ONE:
            case Constants.QUESTIONNAIRE_QUESTION_TYPE_SELECTBOX:
                answersWrapper = (
                    <Form.Field>
                        <label>{question.question}</label>
                        <Form.Group className='grouped'>
                            {
                                question.answers.map(answer => (
                                    <Form.Field key={answer.id}>
                                        <Radio
                                            readOnly={true}
                                            label={answer.answer}
                                            value={answer.id}
                                        />
                                    </Form.Field>
                                ))
                            }
                        </Form.Group>
                    </Form.Field>
                );
                break;

            case Constants.QUESTIONNAIRE_QUESTION_TYPE_MORE:
                answersWrapper = (
                    <Form.Field>
                        <label>{question.question}</label>
                        <Form.Group className='grouped'>
                            {
                                question.answers.map(answer => (
                                    <Form.Field key={answer.id}>
                                        <Checkbox
                                            disabled={true}
                                            label={answer.answer}
                                            value={answer.id}
                                        />
                                    </Form.Field>
                                ))
                            }
                        </Form.Group>
                    </Form.Field>
                );
                break;

            case Constants.QUESTIONNAIRE_QUESTION_TYPE_TEXT:
            case Constants.QUESTIONNAIRE_QUESTION_TYPE_LONGTEXT:
                answersWrapper = (
                    <Form.Field>
                        <label>{question.question}</label>
                        {
                            question.answers.map(answer => (
                                <Textarea
                                    key={answer.id}
                                />
                            ))
                        }
                    </Form.Field>
                );
                break;

            case Constants.QUESTIONNAIRE_QUESTION_TYPE_SALARY:
                answersWrapper = (
                    <Form.Field>
                        <label>{question.question}</label>
                        {
                            question.answers.map(answer => (
                                <InputField
                                    readOnly={true}
                                    key={answer.id}
                                />
                            ))
                        }
                    </Form.Field>
                );
                break;

            case Constants.QUESTIONNAIRE_QUESTION_TYPE_DISPOSABLE_DATE:
                answersWrapper = (
                    <Form.Field>
                        <label>{question.question}</label>
                        {
                            question.answers.map(answer => (
                                <DatetimePicker
                                    readOnly={true}
                                    key={answer.id}
                                    placeholder={Translations.getStatic('date')}
                                    direction={'left'}
                                    timeEnabled={false}
                                />
                            ))
                        }
                    </Form.Field>
                );
                break;
        }

        return (
            <Grid.Row columns={1} key={question.id}>
                <Grid.Column>
                    {answersWrapper}
                </Grid.Column>
            </Grid.Row>
        );
    });

    return (
        <Form>
            <Grid>
                {rowItems}
            </Grid>
        </Form>
    );
};

QuestionnairePreview.propTypes = {
    questionnaireData: PropTypes.object.isRequired,
};

QuestionnairePreview.defaultProps = {};

export default QuestionnairePreview;
