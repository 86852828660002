import React from 'react';
import PropTypes from 'prop-types';

const Icon = props => {
    const disabled  = props.disabled === true ? 'disabled ' : '';
    const inverted  = props.inverted === true ? 'inverted ' : '';
    const loading   = props.loading === true ? 'loading ' : '';
    const color     = `${props.color ? props.color + ' ' : ''}`;
    const name      = `${props.name ? props.name + ' ' : ''}`;
    const size      = `${props.size ? props.size + ' ' : ''}`;
    const className = `${props.className ? props.className + ' ' : ''}`;

       return (
        <i onClick={props.onClick} className={`${disabled}${inverted}${loading} ${color}${name}${size}${className}icon`}/>
    )
};

Icon.defaultProps = {
    disabled: false,
    inverted: false,
    loading: false,
};

Icon.propTypes = {
    name: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.string,
    disabled: PropTypes.bool,
    inverted: PropTypes.bool,
    loading: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func,
};

export default Icon;
