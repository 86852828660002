import React from 'react';
import {Stack} from '@mui/material';
import {SkeletonCircular, SkeletonRectangular} from '@profesia/adamui/components/common/skeleton';

const DashboardEventsListSkeleton = ({itemsCount = 3}) => (
    <div className="ui items items-calendar unstackable skeleton">
        <Stack flex={1} spacing={3} className="dashboard-events-list-skeleton">
            {
                [...Array(itemsCount)].map((e, i) => (
                    <Stack flex={1} spacing={2.5} direction="row" alignItems="center" key={i}>
                        <SkeletonCircular size={18}/>

                        <Stack flex={1}>
                            <SkeletonRectangular height={10} borderRadius={26}/>
                        </Stack>
                    </Stack>
                ))
            }
        </Stack>
    </div>
);

export default DashboardEventsListSkeleton;
