import {
    RECEIVE_QUESTIONNAIRE_EDITOR_MODEL, REQUEST_QUESTIONNAIRE_EDITOR_MODEL,
    ADD_QUESTION_ITEM, DELETE_QUESTION_ITEM, UPDATE_QUESTION_ITEM, REORDER_QUESTION_ITEM,
    ADD_ANSWER_ITEM, DELETE_ANSWER_ITEM, UPDATE_ANSWER_ITEM,
    UPDATE_QUESTIONNAIRE,
} from '../actions/QuestionnaireEditorActions';
import QuestionnaireHelper from '../helpers/components/QuestionnaireHelper';
import {CLEAR_CREATE_JOB_DATA} from '../actions/CreateJobActions';

export const isFetchingQuestionnaireEditorModel = (state = false, action) => {
    switch (action.type) {
        case REQUEST_QUESTIONNAIRE_EDITOR_MODEL:
            return true;
        case RECEIVE_QUESTIONNAIRE_EDITOR_MODEL:
            return false;
        default:
            return state;
    }
};

export const questionnaireEditorModel = (state = {}, action) => {
    let questions = [];

    switch (action.type) {
        case REQUEST_QUESTIONNAIRE_EDITOR_MODEL:
        case CLEAR_CREATE_JOB_DATA:
            return {};

        case RECEIVE_QUESTIONNAIRE_EDITOR_MODEL:
            return {
                ...state,
                ...action.payload,
            };

        case ADD_QUESTION_ITEM:
            questions = state.questionnaireData.questions;
            questions.push(QuestionnaireHelper.createNewQuestion(action.payload.questionType));

            return {
                ...state,

                questionnaireData: {
                    ...state.questionnaireData,
                    questions,
                    currentChangeHash: QuestionnaireHelper.createChangeHash(questions),
                },
            };

        case UPDATE_QUESTIONNAIRE:
            return Object.assign({}, state, {
                questionnaireData: Object.assign({}, state.questionnaireData, action.payload.questionnaireData),
            });

        case DELETE_QUESTION_ITEM:
            questions = state.questionnaireData.questions;
            questions.splice(action.payload.index, 1).filter(removedQuestion => removedQuestion.id !== null);

            return Object.assign({}, state, {
                questionnaireData: Object.assign({}, state.questionnaireData, {
                    questions: questions,
                    currentChangeHash: QuestionnaireHelper.createChangeHash(questions),
                }),
            });
        case UPDATE_QUESTION_ITEM:
            questions = state.questionnaireData.questions.map((question, i) => {
                return (i === action.payload.questionIndex)
                    ? action.payload.question
                    : question;
            });

            return Object.assign({}, state, {
                questionnaireData: Object.assign({}, state.questionnaireData, {
                    questions: questions,
                    currentChangeHash: QuestionnaireHelper.createChangeHash(questions),
                }),
            });

        case ADD_ANSWER_ITEM:
            questions = state.questionnaireData.questions.map((question, i) => {
                return (i === action.payload.questionIndex)
                    ? QuestionnaireHelper.addAnswerIntoQuestion(question, QuestionnaireHelper.createNewAnswer())
                    : question;
            });

            return {
                ...state,

                questionnaireData: {
                    ...state.questionnaireData,
                    questions,
                    currentChangeHash: QuestionnaireHelper.createChangeHash(questions),
                },
            };

        case DELETE_ANSWER_ITEM:
            questions = state.questionnaireData.questions.map((question, i) => {
                if (i === action.payload.questionIndex) {
                    question.answers.splice(action.payload.answerIndex, 1);
                }
                return question;
            });

            return Object.assign({}, state, {
                questionnaireData: Object.assign({}, state.questionnaireData, {
                    questions: questions,
                    currentChangeHash: QuestionnaireHelper.createChangeHash(questions),
                }),
            });
        case UPDATE_ANSWER_ITEM:
            questions = state.questionnaireData.questions.map((question, i) => {
                return (i === action.payload.questionIndex)
                    ? QuestionnaireHelper.replaceAnswerInQuestion(question, action.payload.answerIndex, action.payload.answer)
                    : question;
            });

            return Object.assign({}, state, {
                questionnaireData: Object.assign({}, state.questionnaireData, {
                    questions: questions,
                    currentChangeHash: QuestionnaireHelper.createChangeHash(questions),
                }),
            });
        case REORDER_QUESTION_ITEM:
            questions = QuestionnaireHelper.moveElementInArray(state.questionnaireData.questions, action.payload.questionIndex, action.payload.newQuestionIndex);

            return Object.assign({}, state, {
                questionnaireData: Object.assign({}, state.questionnaireData, {
                    questions: questions,
                    currentChangeHash: QuestionnaireHelper.createChangeHash(questions),
                }),
            });

        default:
            return state;
    }
};
