import React, {Component} from 'react';
import MarkModal from '../Modal/MarkModal';
import {closeModal} from '../../actions/ModalActions';
import {connect} from 'react-redux';
import {requestContentPageModel} from '../../actions/ContentPagesActions';
import Translations from '../../helpers/Translations';
import Grid from '../Grid';
import Language from '../../helpers/Language';
import FormSkeleton from '../../skeletons/components/FormSkeleton';

class ContentPageModal extends Component
{
    componentDidMount = () => {
        this.props.requestContentPageModel(this.props.alias, this.props?.companyId ?? null, this.props?.language ?? Language.get());
    };

    render = () => {
        const {isOpen, contentPageModel, isFetchingContentPageModel, language} = this.props;

        let content = <FormSkeleton/>;
        let title   = '';
        let action  = {
            visible: false,
        };

        if (isFetchingContentPageModel === false && contentPageModel.page.hasOwnProperty('content')) {
            title = contentPageModel.page.title;

            content = (
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <div className="content-page" dangerouslySetInnerHTML={{__html: contentPageModel.page.content}}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            );

            if (contentPageModel.page.action !== null) {
                action = {
                    visible: true,
                    text: Translations.getStatic('downloadAsPdf', language),
                    onClick: () => window.open(contentPageModel.page.action, '_blank'),
                };
            }
        }

        return (
            <MarkModal
                isOpen={isOpen}
                closeModal={this.props.closeModal}
                title={title}
                content={content}

                actionButton={{
                    visible: false,
                }}

                entityOption={action}
                language={language}
                isLoadingData={isFetchingContentPageModel}
            />
        );
    };
}

const mapStateToProps = state => ({
    isOpen: state.modalLogic.isOpen,
    contentPageModel: state.contentPageModel,
    isFetchingContentPageModel: state.isFetchingContentPageModel,
});

const mapDispatchToProps = {
    closeModal,
    requestContentPageModel,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentPageModal);




