import React from 'react';
import PropTypes from 'prop-types';
import MarkForm from '../Form';
import {InputField} from '@profesia/adamui/components/common/input';
import Select from '@profesia/adamui/components/common/dropdown/SelectDefault';
import Checkbox from '@profesia/adamui/components/common/input/checkbox/Checkbox';
import Grid from '../Grid';
import Divider from '../Divider';
import Heading from '../Heading';
import NotificationMessage from '../NotificationMessage';
import TextareaAutosize from 'react-autosize-textarea';
import ErrorFocus from '../ErrorFocus';
import Translations from '../../helpers/Translations';
import Validation from '../../helpers/Validation';
import Form from '../../helpers/Form';

const JobPositionEditor = props => {
    const {settings, jobData, errors, languages, visibleNotificationMessage} = props;

    const langOptions = languages.map(lang => ({
        key: lang.languageCode, label: lang.language, id: lang.languageCode,
    }));

    return (
        <ErrorFocus>
            <MarkForm>
                {
                    visibleNotificationMessage === true
                    && (
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <NotificationMessage
                                        title={Translations.getStatic('createNewJob')}
                                        description={Translations.getStatic('createNewJobDescription')}
                                    />
                                    <Divider hidden className="size_8" />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    )
                }
                <Heading as='h3'>{Translations.getStatic('positionDescription')}</Heading>
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <MarkForm.Field
                                error={
                                    errors.includes('jobTitle') &&
                                    Form.check({fieldType: 'text', fieldValue: jobData.jobTitle}) === false
                                        ? Validation.getMessage('empty', 'positionName')
                                        : false
                                }
                            >
                                <InputField
                                    maxLength={128}
                                    value={jobData.jobTitle}
                                    label={Translations.getStatic('positionName') + '*'}
                                    onChange={e => props.onUpdateJob({
                                        jobTitle: e.target.value,
                                    })}
                                />
                            </MarkForm.Field>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <MarkForm.Field
                                error={
                                    errors.includes('jobLanguage') &&
                                    Form.check({fieldType: 'text', fieldValue: jobData.jobLanguage}) === false
                                        ? Validation.getMessage('empty', 'jobLanguage')
                                        : false
                                }
                            >
                                <Select
                                    value={jobData.jobLanguage}
                                    label={Translations.getStatic('jobLanguage') + '*'}
                                    options={langOptions}
                                    onChange={(value) => props.onUpdateJob({
                                        jobLanguage: value,
                                    })}
                                />
                            </MarkForm.Field>
                        </Grid.Column>
                    </Grid.Row>
                    {
                        jobData.id !== null
                        && (
                            <Grid.Row>
                                <Grid.Column>
                                    <MarkForm.Field>
                                        <Select
                                            value={jobData.user.id}
                                            label={Translations.getStatic('contactPerson') + ' ' + Translations.getStatic('contactName')}
                                            options={settings.position.users.map(user => ({
                                                key: user.id, label: user.name, id: user.id,
                                            }))}
                                            onChange={(value) => props.onUpdateJob({
                                                user: {
                                                    ...jobData.user,
                                                    id: parseInt(data.value),
                                                    name: settings.position.users.find(u => u.id === parseInt(value)).name
                                                },
                                            })}
                                        />
                                    </MarkForm.Field>
                                </Grid.Column>
                            </Grid.Row>
                        )
                    }
                    <Grid.Row>
                        <Grid.Column>
                            <MarkForm.Field>
                                <Checkbox
                                    checked={jobData.hasRequiredCv === false}
                                    label={Translations.getStatic('offerWithoutCv') + '*'}
                                    onChange={() => props.onUpdateJob({
                                        hasRequiredCv: !jobData.hasRequiredCv,
                                    })}
                                />
                            </MarkForm.Field>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <MarkForm.Field
                                error={
                                    errors.includes('jobPlace') &&
                                    Form.check({fieldType: 'text', fieldValue: jobData.jobPlace}) === false
                                        ? Validation.getMessage('empty', 'placeWork')
                                        : false
                                }
                            >
                                <InputField
                                    maxLength={128}
                                    value={jobData.jobPlace}
                                    label={Translations.getStatic('placeWork') + '*'}
                                    onChange={e => props.onUpdateJob({
                                        jobPlace: e.target.value,
                                    })}
                                />
                            </MarkForm.Field>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <MarkForm.Field
                                error={
                                    errors.includes('jobSalary') &&
                                    Form.check({fieldType: 'text', fieldValue: jobData.jobSalary}) === false
                                        ? Validation.getMessage('empty', 'jobSalary')
                                        : false
                                }
                            >
                                <InputField
                                    maxLength={128}
                                    value={jobData.jobSalary}
                                    label={Translations.getStatic('jobSalary') + '*'}
                                    onChange={e => props.onUpdateJob({
                                        jobSalary: e.target.value,
                                    })}
                                />
                            </MarkForm.Field>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                                <MarkForm.Field
                                    error={
                                        errors.includes('jobDescription') &&
                                        Form.check({fieldType: 'text', fieldValue: jobData.jobDescription}) === false
                                            ? Validation.getMessage('empty', 'jobDescription')
                                            : false
                                    }
                                >
                                <label>{Translations.getStatic('jobDescription') + '*'}</label>
                                <TextareaAutosize
                                    value={jobData.jobDescription}
                                    rows={6}
                                    onChange={e => props.onUpdateJob({
                                        jobDescription: e.target.value,
                                    })}
                                />
                                {
                                    errors.includes('jobDescription') &&
                                    Form.check({fieldType: 'text', fieldValue: jobData.jobDescription}) === false
                                        && (
                                            <div className="ui prompt label">{Validation.getMessage('empty', 'jobDescription')}</div>
                                        )
                                }
                            </MarkForm.Field>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <MarkForm.Field>
                                <label>{Translations.getStatic('personalSkills')}</label>
                                <TextareaAutosize
                                    value={jobData.jobPersonalityRequirements}
                                    rows={4}
                                    onChange={e => props.onUpdateJob({
                                        jobPersonalityRequirements: e.target.value,
                                    })}
                                />
                            </MarkForm.Field>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <MarkForm.Field>
                                <label>{Translations.getStatic('additionalInformation')}</label>
                                <TextareaAutosize
                                    value={jobData.benefits}
                                    rows={4}
                                    onChange={e => props.onUpdateJob({
                                        benefits: e.target.value,
                                    })}
                                />
                            </MarkForm.Field>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <MarkForm.Field disabled>
                                <label>{Translations.getStatic('companyShortDescriptionProfesia') + '*'}</label>
                                <TextareaAutosize
                                    disabled
                                    value={settings.position.companyCharacteristics}
                                    rows={4}
                                    onChange={e => props.onUpdateJob({
                                        jobDescription: e.target.value,
                                    })}
                                />
                            </MarkForm.Field>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </MarkForm>
        </ErrorFocus>
    );
}

JobPositionEditor.propTypes = {
    settings: PropTypes.object.isRequired,
    jobData: PropTypes.object.isRequired,
    errors: PropTypes.array.isRequired,
    onUpdateJob: PropTypes.func.isRequired,
    languages: PropTypes.array.isRequired,
    visibleNotificationMessage: PropTypes.bool,
};

export default JobPositionEditor;
