import React, {Component} from 'react';
import MarkModal from '../Modal/MarkModal';
import {closeModal, openModalSecond} from '../../actions/ModalActions';
import {connect} from 'react-redux';
import Translations from '../../helpers/Translations';
import {getTalentPoolCandidates} from '../../actions/TalentPoolActions';
import Checkbox from '@profesia/adamui/components/common/input/checkbox/Checkbox';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import CandidateAvatar from '../CandidateAvatar';
import {copyCandidates} from '../../actions/ActionWithCandidateActions';
import CandidatesReducer from '../../helpers/generalReducers/CandidatesReducer';
import SuccessActionStatus from '../../helpers/SuccessActionStatus';
import FormSkeleton from '../../skeletons/components/FormSkeleton';

class AddCandidateFromTalentPoolModal extends Component
{
    state = {
        selectedCandidateIds: [],
    };

    componentDidUpdate = prevProps => {
        if (SuccessActionStatus.isActionSuccessful(prevProps, this.props)) {
            this.props.closeModal();
        }
    };

    componentDidMount = () => {
        this.props.getTalentPoolCandidates();
    };

    onRowClick = (e, candidate) => {
        if (e.target.parentElement.classList.contains('checkbox') !== true) {
            this.props.openModalSecond('DetailCandidateFromTalentPoolModal', {
                candidateId: candidate.id,
                candidateName: candidate.candidateName,
                jobId: this.props.jobId,
                isCandidateAlreadyAdded: this.isCandidateAlreadyAdded(this.props.jobId, candidate),
            });
        }
    };

    onCheckCandidate = candidateId => {
        if (this.state.selectedCandidateIds.indexOf(candidateId) > -1) {
            this.setState({
                selectedCandidateIds: this.state.selectedCandidateIds.filter(selectedCandidateId => selectedCandidateId !== candidateId),
            });
        } else {
            this.setState({
                selectedCandidateIds: this.state.selectedCandidateIds.concat([candidateId]),
            });
        }
    };

    onCheckAll = () => {
        const availableCandidates = this.props.talentPoolModel.candidates.filter(candidate => this.isCandidateAlreadyAdded(this.props.jobId, candidate) === false);

        if (this.state.selectedCandidateIds.length < availableCandidates.length) {
            this.setState({
                selectedCandidateIds: availableCandidates.map(candidate => candidate.id),
            });
        } else {
            this.setState({
                selectedCandidateIds: [],
            });
        }
    };

    addIntoJob = () => {
        const actionData = new FormData();

        const copyParams = {
            jobId: this.props.jobId,
            copyHistory: true,
            removeCandidate: false,
            sendEmail: false,
        };

        actionData.append('copyParams', JSON.stringify(copyParams));
        actionData.append('candidateIds', JSON.stringify(this.state.selectedCandidateIds));

        this.props.copyCandidates(actionData, this.state.selectedCandidateIds, copyParams, CandidatesReducer.getReducerType());
    };

    isCandidateAlreadyAdded = (jobId, candidate) => candidate.hasDuplicities === true && candidate.duplicities.find(duplicity => duplicity.jobId === jobId) !== undefined;

    render = () => {
        const {isOpen, jobId, isFetchingTalentPoolCandidates, isCopyingCandidates, talentPoolModel} = this.props;
        const {selectedCandidateIds}                                                                = this.state;

        let content       = null;
        let primaryAction = {
            visible: false,
        };

        let isLoadingData = false;

        if (isFetchingTalentPoolCandidates === true || talentPoolModel.hasOwnProperty('candidates') === false) {
            isLoadingData = true;
            content       = <FormSkeleton/>;
        } else {
            const availableCandidates = talentPoolModel.candidates.filter(candidate => this.isCandidateAlreadyAdded(jobId, candidate) === false);

            content = (
                <div className="list">
                    <div className="list__table">
                        <Table className="no-border ui table sortable">
                            <TableHead>
                                <TableRow>
                                    <TableCell className="no-clickable" style={{width: 33, maxWidth: 33}}>
                                        <Checkbox
                                            disabled={availableCandidates.length === 0}
                                            checked={selectedCandidateIds.length === availableCandidates.length}
                                            onChange={this.onCheckAll}
                                        />
                                    </TableCell>
                                    <TableCell className="no-clickable">
                                        {Translations.getStatic('nameAndSurname')}
                                    </TableCell>
                                    <TableCell name="activeCandidate" style={{maxWidth: 120}}/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    talentPoolModel.candidates.map(candidate => (
                                        <TableRow className={'clickable'} key={candidate.id} onClick={event => this.onRowClick(event, candidate)}>
                                            <TableCell style={{width: 33, maxWidth: 33}}>
                                                <Checkbox
                                                    disabled={this.isCandidateAlreadyAdded(jobId, candidate) === true}
                                                    checked={selectedCandidateIds.indexOf(candidate.id) > -1}
                                                    onChange={() => this.onCheckCandidate(candidate.id)}
                                                />
                                            </TableCell>
                                            <TableCell name="name" className="single line" style={{minWidth: 150, maxWidth: 250}}>
                                                <CandidateAvatar
                                                    surname={candidate.surname}
                                                    firstname={candidate.firstname}
                                                    profilePhoto={candidate.profilePhoto}
                                                    isContacted={false}
                                                />
                                                <span className="h5">{candidate.candidateName}</span>
                                            </TableCell>
                                            <TableCell align="right" name="activeCandidate" className="single line" style={{maxWidth: 120}}>
                                                {
                                                    this.isCandidateAlreadyAdded(jobId, candidate) === true
                                                    && <span className="txt-disabled">{Translations.getStatic('activeCandidate')}</span>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </div>
                </div>
            );

            primaryAction = {
                visible: true,
                text: Translations.getStatic('addCandidate'),
                disabled: selectedCandidateIds.length === 0 || isCopyingCandidates === true || availableCandidates.length === 0,
                loading: isCopyingCandidates === true,
                onClick: this.addIntoJob,
            };
        }

        return (
            <MarkModal
                isOpen={isOpen}
                closeModal={this.props.closeModal}
                title={Translations.getStatic('addCandidate')}
                content={content}
                actionButton={primaryAction}
                isLoadingData={this.props.isFetchingCandidateTag}
            />
        );
    };
}

const mapStateToProps = state => ({
    isOpen: state.modalLogic.isOpen,
    talentPoolModel: state.talentPoolModel,
    isFetchingTalentPoolCandidates: state.isFetchingTalentPoolCandidates,
    isCopyingCandidates: state.isCopyingCandidates,
    actionSuccessStatus: state.actionSuccessStatus,
});

const mapDispatchToProps = {
    openModalSecond,
    closeModal,
    getTalentPoolCandidates,
    copyCandidates,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCandidateFromTalentPoolModal);




