import React from 'react';
import PropTypes from 'prop-types';
import Item from '../Item';
import Icon from '../Icon';
import DateFormatted from '../../helpers/DateFormatted';
import Sort from '../../helpers/Sort';
import Translations from '../../helpers/Translations';
import CandidateDetailOpenHandler from '../CandidateDetailOpenHandler';
import DashboardEventsListSkeleton from '../../skeletons/components/DashboardEventsListSkeleton';
import Skeletons from '../../helpers/Skeletons';

const DashboardEventsList = props => {
    const currentDate = new Date();

    const {date, events, toggleSlide, displaySize, visibleCalendar, dashboardModel, isFetchingMoreEvents} = props;

    const RenderEventList = () => {
        if (events.length === 0) {
            const isCurrentDateSelected = currentDate.getDate() === date.getDate()
                && currentDate.getMonth() === date.getMonth()
                && currentDate.getFullYear() === date.getFullYear();

            const noEventsMessage = isCurrentDateSelected === true
                ? Translations.getStatic('noEventToday')
                : Translations.getStatic('noEventDate').replace('%date%', DateFormatted.getDate(date));

            return (
                <div className={'ui items items-calendar unstackable'}>
                    <Item
                        onClick={
                            displaySize.isMobile === true
                                ? props.toggleVisibleCalendar
                                : e => e.preventDefault()
                        }
                    >
                        <Icon className="icon-coffe" size="large"/>
                        <Item.Content verticalAlign="middle">
                            <h5>{noEventsMessage}</h5>
                        </Item.Content>
                    </Item>
                </div>
            );
        }

        return (
            <div className={'ui items items-calendar unstackable'}>
                {
                    Sort.byColumn(events, 'startAt').map(event => {
                        const candidate          = event.candidate;
                        const candidateNameParts = [candidate.title, candidate.firstname, candidate.surname];
                        const candidateName      = candidateNameParts.filter(part => part !== '').join(' ');

                        return (
                            <CandidateDetailOpenHandler as={'Item'} candidate={candidate} key={event.id} className="clickable unstackable">
                                <h5>{DateFormatted.getTime(event.startAt, 'H:i')}</h5>

                                <Item.Content>
                                    <h5>{event.eventName}</h5>
                                    <p>{candidateName}</p>
                                    <p>{candidate.jobTitle}</p>
                                </Item.Content>
                            </CandidateDetailOpenHandler>
                        );
                    })
                }

                {
                    displaySize.isMobile === true
                    && (
                        <Item
                            className="pointer"
                            onClick={props.toggleVisibleCalendar}
                        >
                            <Item.Content className="flex align justify center">
                                <h5>{Translations.getStatic(visibleCalendar === true ? 'hideCalendar' : 'showCalendar')}</h5>
                                <Icon
                                    className={visibleCalendar === true ? 'icon-arrow_left-blue' : 'icon-arrow_right-blue'}
                                    size="small"
                                />
                            </Item.Content>
                        </Item>
                    )
                }
            </div>
        );
    };

    return (
        <div className='container-items'>
            {
                Skeletons.isSkeleton(dashboardModel) === true || isFetchingMoreEvents === true
                    ? <DashboardEventsListSkeleton/>
                    : <RenderEventList/>
            }
        </div>
    );
};

DashboardEventsList.propTypes = {
    events: PropTypes.array.isRequired,
    date: PropTypes.object.isRequired,
    toggleSlide: PropTypes.bool.isRequired,
    displaySize: PropTypes.object.isRequired,
    visibleCalendar: PropTypes.bool.isRequired,
};

export default DashboardEventsList;



