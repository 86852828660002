import Translations from '../Translations';
import Constants from '../Constants';

export default class CandidateHelper
{
    static getActiveModelFromCandidateModel(state, candidateId) {
        if (candidateId === state.fullDetailId) {
            return state.fullDetail;
        }

        if (candidateId === state.anonymizedDetailId) {
            return state.anonymizedDetail;
        }

        if (candidateId === state.duplicatedDetailId) {
            return state.duplicatedDetail;
        }

        return state;
    }

    static refreshHistory(state, action) {
        if (state.hasOwnProperty('candidate') === false) {
            return state;
        }

        if (state.hasOwnProperty('history') === false) {
            return state;
        }

        if (action.hasOwnProperty('meta') === false) {
            return state;
        }

        if (action.hasOwnProperty('payload') === false) {
            return state;
        }

        if (action.meta.hasOwnProperty('requestPayload') === false) {
            return state;
        }

        if (action.meta.requestPayload.hasOwnProperty('candidateIds') === true && action.payload.hasOwnProperty('candidatesHistory') === false) {
            return state;
        }

        if (action.meta.requestPayload.hasOwnProperty('candidateId') === true && action.payload.hasOwnProperty('candidateHistory') === false) {
            return state;
        }

        if (action.payload.hasOwnProperty('candidatesHistory') === true) {
            let history = state.history;

            if (action.meta.requestPayload.candidateIds.includes(state.candidate.id) === true) {
                if (action.payload.candidatesHistory.find(candidate => candidate.candidateId === state.candidate.id) !== undefined) {
                    history = action.payload.candidatesHistory.find(candidate => candidate.candidateId === state.candidate.id).history;
                }
            }

            return {
                ...state,
                history,
            };
        }

        if (action.payload.hasOwnProperty('candidateHistory') === true) {
            return {
                ...state,
                history: action.meta.requestPayload.candidateId === state.candidate.id
                    ? action.payload.candidateHistory.history
                    : state.history,
            };
        }

        return state;
    }

    static refreshInbox(state, action) {
        if (state.hasOwnProperty('candidate') === false) {
            return state;
        }

        if (state.hasOwnProperty('inbox') === false) {
            return state;
        }

        if (action.hasOwnProperty('meta') === false) {
            return state;
        }

        if (action.meta.hasOwnProperty('requestPayload') === false) {
            return state;
        }

        if (action.meta.requestPayload.hasOwnProperty('candidateIds') === true && action.payload.hasOwnProperty('candidatesInbox') === false) {
            return state;
        }

        if (action.payload.hasOwnProperty('candidatesInbox') === true) {
            return {
                ...state,
                inbox: {
                    ...state.inbox,
                    messages: action.meta.requestPayload.candidateIds.includes(state.candidate.id) === true && action.payload.candidatesInbox.find(candidate => candidate.candidateId === state.candidate.id) !== undefined
                        ? action.payload.candidatesInbox.find(candidate => candidate.candidateId === state.candidate.id).messages
                        : state.inbox.messages,
                },
            };
        }

        return state;
    }

    static getRefusalReason(refusalReason) {
        if (refusalReason === null) {
            return Translations.getStatic('withoutRefusalReason');
        }

        if (parseInt(refusalReason.id) === Constants.REFUSAL_REASON_WITHOUT_REASON_ID) {
            return Translations.getStatic('withoutRefusalReason');
        }

        if (parseInt(refusalReason.id) === Constants.REFUSAL_REASON_OTHER_ID) {
            return refusalReason.customRefusalReason;
        }

        return Translations.getStatic('refusalReasonUnsuitable');
    }

    static getCandidateDataAfterRefuse(candidateId, requestPayload, payload) {
        return {
            isContacted: payload.result.find(refused => refused.candidateId === candidateId).isContacted,
            stateId: payload.result.find(refused => refused.candidateId === candidateId).stateId,
            isRefusedAndNotContacted: payload.result.find(refused => refused.candidateId === candidateId).isRefusedAndNotContacted,
            refusalReason: payload.result.find(refused => refused.candidateId === candidateId).refusalReason,
        };
    }
}
