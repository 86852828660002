import Constants from '../../Constants';
import CandidatesHelper from '../../components/CandidatesHelper';
import Url from '../../Url';

export default class RemoveCandidateFromTalentPoolReducer
{
    static process(state, action) {
        if (state.hasOwnProperty('candidates') === false) {
            return state;
        }

        if (action.meta.requestPayload.reducerType === Constants.CANDIDATES_REDUCER_TYPE_JOB_DETAIL) {
            return this.processRemoveActionOnJobDetail(state, action);
        }

        if (action.meta.requestPayload.reducerType === Constants.CANDIDATES_REDUCER_TYPE_GLOBAL_SEARCH) {
            return this.processRemoveActionOnGlobalSearch(state, action);
        }

        return this.processRemoveActionOnDashboard(state, action);
    }

    static processRemoveActionOnDashboard(state, action) {
        let result = {};

        if (action.meta.requestPayload.candidateId > 0) {
            result = {
                ...state,
                candidates: state.candidates.map(candidate => candidate.id === action.meta.requestPayload.candidateId
                    ? {
                        ...candidate,
                        stateId: Constants.CANDIDATE_STATE_DELETED,
                    }
                    : candidate,
                ),
            };

            if (state.hasOwnProperty('jobs') === true) {
                result = {
                    ...result,
                    jobs: state.jobs.map(job => {
                        if (action.payload.candidate.jobId === job.id) {
                            return {
                                ...job,
                                countActiveApplicants: job.countActiveApplicants - 1,
                                countAllApplicants: job.countAllApplicants - 1,
                                countNewApplicants: job.countNewApplicants - 1,
                            };
                        }

                        return job;
                    }),
                    jobsBasicData: state.jobsBasicData.map(job => {
                        if (action.payload.candidate.jobId === job.id) {
                            return {
                                ...job,
                                countActiveApplicants: job.countActiveApplicants - 1,
                                countAllApplicants: job.countAllApplicants - 1,
                                countNewApplicants: job.countNewApplicants - 1,
                            };
                        }

                        return job;
                    }),
                };
            }
        }

        return result;
    };

    static processRemoveActionOnJobDetail(state, action) {
        if (state.hasOwnProperty('fullCandidates') === false) {
            return state;
        }

        let result = {...state};

        if (action.meta.requestPayload.candidateId > 0) {
            if (parseInt(Url.getUrlParameter('job')) === parseInt(action.payload.candidate.jobId)) {
                result = {
                    ...result,
                    candidates: result.candidates.map(candidate => candidate.id === action.meta.requestPayload.candidateId
                        ? {
                            ...candidate,
                            stateId: Constants.CANDIDATE_STATE_DELETED,
                        }
                        : candidate,
                    ),
                    fullCandidates: result.fullCandidates === undefined
                        ? undefined
                        : result.fullCandidates.map(candidate => candidate.id === action.meta.requestPayload.candidateId
                            ? {
                                ...candidate,
                                stateId: Constants.CANDIDATE_STATE_DELETED,
                            }
                            : candidate,
                        ),
                };
            }
        }

        return result;
    };

    static processRemoveActionOnGlobalSearch(state, action) {
        if (state.hasOwnProperty('basicCandidatesData') === false) {
            return state;
        }

        let result = {};

        if (action.meta.requestPayload.candidateId > 0) {
            result = {
                ...state,
                basicCandidatesData: state.basicCandidatesData.map(candidate => candidate.id === action.meta.requestPayload.candidateId
                    ? {
                        ...candidate,
                        stateId: Constants.CANDIDATE_STATE_DELETED,
                    }
                    : candidate,
                ),
                candidates: state.candidates.map(candidate => candidate.id === action.meta.requestPayload.candidateId
                    ? {
                        ...candidate,
                        stateId: Constants.CANDIDATE_STATE_DELETED,
                    }
                    : candidate,
                ),
            };
        }

        return result;
    };
}
