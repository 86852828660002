import React from 'react';
import DateFormatted from './../helpers/DateFormatted';
import {Dropdown} from 'semantic-ui-react';
import Icon from './Icon';
import Browser from '../helpers/Browser';
import PropTypes from 'prop-types';

export class MarkTimePicker extends React.Component
{
    state = {
        hours: this.props.hours,
        minutes: this.props.minutes,
        name: this.props.name,
        className: this.props.className,
        error: false,
        visibleStep: 30,
        timeOptions: [],
        maxVisibleHour: 18,
    };

    componentDidMount = () => {
        this.timer = null;

        let {hours, minutes}                = this.state;
        const {maxVisibleHour, visibleStep} = this.state;

        let timeOptions = [];

        for (let h = hours.from; h <= hours.to; h += hours.step) {
            for (let m = minutes.from; m <= minutes.to; m += minutes.step) {
                const time      = [('00' + h).slice(-2), ('00' + m).slice(-2)].join(':');
                const isVisible = h <= maxVisibleHour && (m % visibleStep) === 0;

                timeOptions.push({
                    key: time,
                    text: time,
                    value: time,
                    className: isVisible ? 'visible' : '',
                });
            }
        }

        this.setState({
            timeOptions,
        });
    };

    handleTimeChange = time => {
        time = time.trim();

        const error = DateFormatted.isValidFormat(time, 'H:i') === false;

        this.setState({
            error,
        });

        if (error === false) {
            if (this.props.onChange !== null) {
                this.props.onChange(time);
            }
        }
    };

    render = () => (
        <Dropdown
            value={this.props.defaultValue}
            icon={<Icon className="icon-time" size="mini"/>}
            className={this.props.align + (this.state.error === true ? ' error mark-time-picker' : ' mark-time-picker')}
            options={this.state.timeOptions}
            onChange={(_event, {value}) => this.handleTimeChange(value)}
            onSearchChange={event => this.handleTimeChange(event.target.value)}
            fluid
            selection
            search
        />
    );
}

MarkTimePicker.propTypes = {
    name: PropTypes.string,

    hours: PropTypes.shape({
        from: PropTypes.number,
        to: PropTypes.number,
        step: PropTypes.number,
    }),

    minutes: PropTypes.shape({
        from: PropTypes.number,
        to: PropTypes.number,
        step: PropTypes.number,
    }),

    defaultValue: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func,
};

MarkTimePicker.defaultProps = {
    name: 'contactMe',

    hours: {
        from: 6,
        to: 19,
        step: 1,
    },

    minutes: {
        from: 0,
        to: 59,
        step: Browser.isInternetExplorer() ? 5 : 1,
    },

    defaultValue: '',
    className: 'span16 no-margin-bottom',
    onChange: null,
};

export default MarkTimePicker;
