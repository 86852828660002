import Screen from '../helpers/Screen';
import {UPDATE_DIMENSIONS} from '../actions/DisplaySizeActions';
import Constants from '../helpers/Constants';

export const displaySize = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_DIMENSIONS:
            return {
                width: Screen.getWidth(),
                isMobile: Screen.getWidth() <= Constants.RESOLUTION_DESKTOP,
                isDesktop: Screen.getWidth() > Constants.RESOLUTION_DESKTOP,
                isMobileProfesia: Screen.getWidth() <= Constants.RESOLUTION_MOBILE_PROFESIA,
                isTabletProfesia: Screen.getWidth() <= Constants.RESOLUTION_TABLET_PROFESIA && Screen.getWidth() > Constants.RESOLUTION_MOBILE_PROFESIA,
                isBigTabletProfesia: Screen.getWidth() > Constants.RESOLUTION_TABLET_PROFESIA && Screen.getWidth() <= Constants.RESOLUTION_DESKTOP_PROFESIA,
                isDesktopProfesia: Screen.getWidth() > Constants.RESOLUTION_DESKTOP_PROFESIA,
            };

        default:
            return state;
    }
};
