import React, {Component} from 'react';
import {connect} from 'react-redux';
import Form from '../Form';
import Select from '@profesia/adamui/components/common/dropdown/SelectDefault'
import Grid from '../Grid';
import Heading from '../Heading';
import PropTypes from 'prop-types';
import MarkModal from '../Modal/MarkModal';
import {closeModal} from '../../actions/ModalActions';
import {setUserDefaultJobView} from '../../actions/SettingsActions';
import SuccessActionStatus from '../../helpers/SuccessActionStatus';
import Translations from '../../helpers/Translations';

class SettingsPageJobViewSettingsModal extends Component
{
    state = {
        defaultJobViewId: this.props.user.defaultJobViewId,
    };

    componentDidUpdate(prevProps) {
        if (this.props.user.defaultJobViewId !== prevProps.user.defaultJobViewId) {
            this.setState({
                defaultJobViewId: this.props.user.defaultJobViewId,
            });
        }

        if (SuccessActionStatus.isActionSuccessful(prevProps, this.props)) {
            prevProps.closeModal();
        }
    }

    selectDefaultJobView = defaultJobViewId => {
        this.setState({
            defaultJobViewId,
        });
    };

    setUserDefaultJobView = defaultJobViewId => {
        this.props.setUserDefaultJobView(defaultJobViewId, this.prepareUserDefaultJobViewDataToSave(defaultJobViewId));
    };

    prepareUserDefaultJobViewDataToSave = defaultJobViewId => {
        const formData = new FormData();

        formData.append('defaultJobViewId', defaultJobViewId);

        return formData;
    };

    render() {
        const {isOpen, closeModal, isSettingDefaultJobView} = this.props;
        const {defaultJobViewId}                            = this.state;

        const jobViews = this.props.jobViews.map(jobView => ({
            label: Translations.getStatic(jobView.jobViewTitle),
            id: jobView.jobViewId,
        }));

        return (
            <MarkModal
                isOpen={isOpen}
                closeModal={closeModal}

                actionButton={{
                    text: Translations.getStatic('save'),
                    disabled: isSettingDefaultJobView === true,
                    onClick: () => this.setUserDefaultJobView(defaultJobViewId),
                }}

                title={Translations.getStatic('jobSettingsChange')}

                content={
                    <>
                        <Heading as="h3">
                            {Translations.getStatic('jobSettings')}
                        </Heading>
                        <Form>
                            <Grid className={this.props.noSpacing === true ? 'padding-top_none' : ''}>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Form.Field>
                                            <Select 
                                                value={defaultJobViewId}
                                                label={Translations.getStatic('defaultJobView')}
                                                options={jobViews}
                                                onChange={(value) => this.selectDefaultJobView(value)}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Form>
                    </>
                }
            />
        );
    }
}

SettingsPageJobViewSettingsModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,

    jobViews: PropTypes.arrayOf(
        PropTypes.shape({
            jobViewId: PropTypes.number.isRequired,
            jobViewTitle: PropTypes.string.isRequired,
        }),
    ),
};

const mapStateToProps = state => ({
    isOpen: state.modalLogic.isOpen,
    jobViews: state.loginManagerModel.jobViews,
    defaultJobViewId: state.settingsModel.user.defaultJobViewId,
    user: state.settingsModel.user,
    isSettingDefaultJobView: state.isSettingDefaultJobView || false,
    actionSuccessStatus: state.actionSuccessStatus,
});

const mapDispatchToProps = {
    closeModal,
    setUserDefaultJobView,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SettingsPageJobViewSettingsModal);
