import React from 'react';
import Translations from '../../../helpers/Translations';
import Url from '../../../helpers/Url';
import Link from '../../../helpers/overridden/Link';

const SidebarMenuFooter = () => (
    <div>
        <ul className="auto-bottom-links">
            <li>
                <a href="https://podpora.profesia.sk/822404-Firmy">{Translations.getStatic('helpAndSupport')}</a>
            </li>
            <li>
                <a href={Url.buildProfesiaLink('/writeus.php')}>{Translations.getStatic('secondaryNavContact')}</a>
            </li>
            <li>
                <Link to={Url.build('logout')} gtmTag={'sidebar-menu-logout'}>{Translations.getStatic('logoutTitle')}</Link>
            </li>
        </ul>
    </div>
);

export default SidebarMenuFooter;
