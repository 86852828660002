import Form from '../Form';
import Translations from '../Translations';

export default class AutoAnswerValidatorHelper
{
    constructor(autoAnswer) {
        this.autoAnswer = autoAnswer;
    }

    checkAutoAnswerTitle() {
        const fieldsToCheck = [
            {
                fieldName: 'templateTitle',
                fieldType: 'text',
                fieldValue: this.autoAnswer.name,
            },
        ];

        return Form.multiCheck(fieldsToCheck);
    }

    checkUniqueAutoAnswerTitle(against) {
        const fieldsToCheck = [
            {
                fieldName: 'uniqueAutoAnswerTitle',
                fieldType: 'unique',
                fieldValue: this.autoAnswer.name,
                against,
            },
        ];

        return Form.multiCheck(fieldsToCheck);
    }

    checkAutoAnswerMessage() {
        const fieldsToCheck = [
            {
                fieldName: 'subject',
                fieldType: 'wysiwyg',
                fieldValue: this.autoAnswer.subject,
            },
            {
                fieldName: 'message',
                fieldType: 'wysiwyg',
                fieldValue: this.autoAnswer.text,
            },
        ];

        return Form.multiCheck(fieldsToCheck);
    }

    checkAutoAnswer(withAlerts = true, checkTitle = true, against = []) {
        let errors = [];

        if (checkTitle === true) {
            errors = this.checkAutoAnswerTitle();

            if (errors.length > 0) {
                return errors;
            }

            if (against.length > 0) {
                errors = this.checkUniqueAutoAnswerTitle(against);

                if (errors.length > 0) {
                    if (errors.indexOf('uniqueAutoAnswerTitle') > -1 && withAlerts === true) {
                        alert(Translations.getStatic('uniqueAutoAnswerNameError'));
                    }

                    return errors;
                }
            }
        }

        errors = this.checkAutoAnswerMessage();

        if (errors.length > 0) {
            return errors;
        }

        return [];
    }
}
