import React from 'react';
import Grid from './Grid';
import Item from './Item';
import Image from './Image';
import PropTypes from 'prop-types';
import messageInfoImage from '../../img/icons/message-info.svg';

const NotificationMessage = ({title, description, type, imageSrc, ellipsis, isDashboard}) => 
{
    if (isDashboard === true) {
        return (
            <div className={'message ' + type}>
                <Image size="large" src={imageSrc}/>
                <div className='content'>
                    <h4>{title}</h4>
                    <p className='text-small' dangerouslySetInnerHTML={{__html: description}}/>
                </div>
            </div>
        );
    }

    return (
        <div className="ui items unstackable">
            <Item className={'message ' + type}>
                <Image size="large" src={imageSrc}/>
                <Grid verticalAlign="middle" container>
                    <Grid.Row>
                        <Grid.Column>
                            <Item.Header as="h4">{title}</Item.Header>
                            <Item.Meta className={ellipsis === true ? 'ellipsis' : ''} dangerouslySetInnerHTML={{__html: description}}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Item>
        </div>
    );
};

NotificationMessage.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    ellipsis: PropTypes.bool,
    isDashboard: PropTypes.bool.isRequired
};

NotificationMessage.defaultProps = {
    title: '',
    description: '',
    isDashboard: false,
    type: 'message-info',
    imageSrc: messageInfoImage,
};

export default NotificationMessage;
