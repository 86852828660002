import React, {Component} from 'react';
import List from '../../List';
import Item from '../../Item';
import Popup from '../../Popup';
import Icon from '../../Icon';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import DateFormatted from '../../../helpers/DateFormatted';
import {openModal} from '../../../actions/ModalActions';
import Translations from '../../../helpers/Translations';
import CandidatesHelper from '../../../helpers/components/CandidatesHelper';
import Constants from '../../../helpers/Constants';
import Truncate from 'react-truncate';

class CandidateSidebarEvents extends Component
{
    state = {
        showCandidateEventTypesDropdown: false,
    };

    toggleScheduleCandidateEventModalTypesDropdown = () => {
        this.setState({
            showCandidateEventTypesDropdown: !this.state.showCandidateEventTypesDropdown,
        });
    };

    openCandidateEventModal = (candidateId, eventType, eventId = 0, handleCandidateEventTypesDropdown = false, eventData = {}) => {
        if (handleCandidateEventTypesDropdown === true) {
            this.setState({
                showCandidateEventTypesDropdown: false,
            });
        }

        this.props.openModal('ScheduleCandidateEventModal', {candidateIds: [candidateId], eventId, eventType, eventData});
    };

    render = () => {
        const {events, candidateId, isReadOnly, isDuplicate} = this.props;

        const eventsSortedAndFilteredByStartAt = CandidatesHelper.filterAndSortEvents(events);

        let candidateEventTypesDropdown = null;

        if (this.state.showCandidateEventTypesDropdown === true) {
            candidateEventTypesDropdown = (
                <div role="listbox" aria-expanded="true" className="ui active visible fluid dropdown" tabIndex="0">
                    <div className="visible menu transition">
                        {
                            Constants.CANDIDATE_EVENT_TYPES_DROPDOWN_OPTIONS.map(candidateEventType => (
                                <div key={candidateEventType.id} role="option" className="item" onClick={() => this.openCandidateEventModal(candidateId, candidateEventType.id, 0, true)}>
                                    <span className="text">
                                        {candidateEventType.label}
                                    </span>
                                </div>
                            ))
                        }
                    </div>
                </div>
            );
        }

        if (eventsSortedAndFilteredByStartAt.length > 0) {
            return (
                <div className="ui items unstackable">
                    <Item>
                        <Icon className="icon-calendar_small" size="mini"/>
                        <Item.Content>
                            <Item.Header>{Translations.getStatic('upcomingEvents')}</Item.Header>
                            <List>
                                {
                                    eventsSortedAndFilteredByStartAt.map(event => (
                                        isReadOnly === true
                                            ? (
                                                <List.Item as="p" key={event.id}>
                                                    {DateFormatted.getDateTime(event.startAt, 'j.n.Y o H:i').replace('o', Translations.getStatic('at')) + ' - ' + event.eventName}
                                                </List.Item>
                                            )
                                            : (
                                                <List.Item as="a" key={event.id} className="primary-link txt-medium" onClick={() => this.openCandidateEventModal(candidateId, event.eventType, event.id, false, event.eventData)}>
                                                    <Truncate width={260} ellipsis={<Popup content={event.eventName} trigger={<span className="pointer">...</span>}/>}>
                                                        {DateFormatted.getDateTime(event.startAt, 'j.n.Y o H:i').replace('o', Translations.getStatic('at')) + ' - ' + event.eventName}
                                                    </Truncate>
                                                </List.Item>
                                            )
                                    ))
                                }
                            </List>

                            {
                                isDuplicate === false
                                && (
                                    <div>
                                        <div className={'secondary-link'} role="link" onClick={this.toggleScheduleCandidateEventModalTypesDropdown}>
                                            {Translations.getStatic('createEvent')}
                                        </div>

                                        {candidateEventTypesDropdown}
                                    </div>
                                )
                            }
                        </Item.Content>
                    </Item>
                </div>
            );
        }

        return (
            <div className="ui items unstackable">
                <Item>
                    <Icon className="icon-calendar_small" size="mini"/>
                    <Item.Content>
                        <Item.Header>
                            {
                                isReadOnly === true
                                    ? <p>{Translations.getStatic('createEvent')}</p>
                                    : (
                                        <>
                                            <div className="secondary-link" role="link" onClick={this.toggleScheduleCandidateEventModalTypesDropdown}>
                                                {Translations.getStatic('createEvent')}
                                            </div>

                                            {candidateEventTypesDropdown}
                                        </>
                                    )
                            }
                        </Item.Header>
                    </Item.Content>
                </Item>
            </div>
        );
    };
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
    openModal,
};

CandidateSidebarEvents.propTypes = {
    candidateId: PropTypes.number.isRequired,
    events: PropTypes.array.isRequired,
    isReadOnly: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateSidebarEvents);
