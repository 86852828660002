import React from 'react';
import DashboardJobItemSkeleton from './DashboardJobItemSkeleton';

const DashboardJobsListItemSkeleton = () => (
    <div className="item">
        <div className="content">
            <DashboardJobItemSkeleton/>
        </div>
    </div>
);

export default DashboardJobsListItemSkeleton;
