export const REQUEST_QUESTIONNAIRES_SETTINGS_MODEL = 'REQUEST_QUESTIONNAIRES_SETTINGS_MODEL';
export const RECEIVE_QUESTIONNAIRES_SETTINGS_MODEL = 'RECEIVE_QUESTIONNAIRES_SETTINGS_MODEL';
export const PROCESS_QUESTIONNAIRE                 = 'PROCESS_QUESTIONNAIRE';
export const PROCESS_QUESTIONNAIRE_SUCCESS         = 'PROCESS_QUESTIONNAIRE_SUCCESS';
export const PROCESS_QUESTIONNAIRE_FAILURE         = 'PROCESS_QUESTIONNAIRE_FAILURE';
export const REMOVE_QUESTIONNAIRE                  = 'REMOVE_QUESTIONNAIRE';
export const REMOVE_QUESTIONNAIRE_SUCCESS          = 'REMOVE_QUESTIONNAIRE_SUCCESS';
export const REMOVE_QUESTIONNAIRE_FAILURE          = 'REMOVE_QUESTIONNAIRE_FAILURE';

export const requestQuestionnairesSettingsModel = () => ({
    type: REQUEST_QUESTIONNAIRES_SETTINGS_MODEL,
    meta: {
        requestRoute: 'v1/questionnaire/model/list',
        onRequestSuccess: RECEIVE_QUESTIONNAIRES_SETTINGS_MODEL,
    },
});

export const processQuestionnaire = (formData, id, savingMode) => ({
    type: PROCESS_QUESTIONNAIRE,
    payload: {
        id: id,
        savingMode: savingMode,
    },
    meta: {
        requestRoute: 'v1/questionnaire/action/process',
        requestMethod: 'POST',
        requestBody: formData,

        onRequestSuccess: PROCESS_QUESTIONNAIRE_SUCCESS,
        onRequestFailure: PROCESS_QUESTIONNAIRE_FAILURE,
    },
});

export const removeQuestionnaire = (id) => ({
    type: REMOVE_QUESTIONNAIRE,
    payload: {
        id: id,
    },
    meta: {
        requestRoute: 'v1/questionnaire/action/archive/' + id,
        requestMethod: 'POST',

        onRequestSuccess: REMOVE_QUESTIONNAIRE_SUCCESS,
        onRequestFailure: REMOVE_QUESTIONNAIRE_FAILURE,
    },
});
