import Translations from './Translations';

class Validation
{
    static getMessage(type: string, translationFieldName: string = ''): string {
        switch (type) {
            case 'empty':
                if (translationFieldName == '') {
                    return Translations.getStatic('item') + ' ' + Translations.getStatic('emptyValidation');
                }

                return Translations.getStatic('item') + ' "' + Translations.getStatic(translationFieldName) + '" ' + Translations.getStatic('emptyValidation');

            case 'phone':
                return Translations.getStatic('phoneValidation');

            case 'email':
                return Translations.getStatic('emailValidation');

            case 'fileCV':
                return Translations.getStatic('fileCvValidation');

            case 'agreement':
                return Translations.getStatic('agreementValidation');

            case 'questionnaireQuestion':
                return Translations.getStatic('questionValidation');

            case 'questionnaireAnswer':
                return Translations.getStatic('answerValidation');

            case 'variable':
                return Translations.getStatic(translationFieldName);

            case 'date':
                return Translations.getStatic('invalidDateFormat');

            case 'loginFailed':
                return Translations.getStatic('loginFailed');

            case 'greaterThan0':
                return Translations.getStatic('greaterThanZeroValidation');

            case 'maxLengthValidation':
                return Translations.getStatic('maxLengthValidation');

            case 'url':
                return Translations.getStatic('invalidUrlFormat');

            case 'candidateTags':
                return Translations.getStatic('noSelectedLabels');

            default:
                return Translations.getStatic('emptyValidation');
        }
    }
}

export default Validation;
