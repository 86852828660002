import { FormT } from '@profesia/mark-offer-form/components/content/form/types';
import { SuitableFor, LanguageRequirements } from '@profesia/mark-offer-form/codebooks';

export const transformData = (data: FormT): any => {
    const { form } = data;
    const salaryValue =
        form.salary?.type === 'fixed'
            ? form.salary?.value?.toString()
            : form.salary?.from?.toString();
    const hasAddress = (form: FormT['form']) => !!form.positionPlace?.address;

    return {
        title: form.jobTitle,
        language: form.language,
        positions: form.jobPositions.map((position: any) => ({
            positionId: Number(position.id),
            categoryId: Number(position.categoryId),
        })),
        jobTypes: form.employmentTypes,
        description: form.jobDescription,
        educationLevel: Number(form.educationLevel),
        workplaceTypeId: form.positionPlace?.type,
        place: hasAddress(form) ? form.positionPlace?.address[0]?.value : null,
        startDate: form.positionPlace?.startDate,
        skills: form.skills?.others.map((item: any) => ({
            skillId: Number(item.id),
            levelId: Number(item.level),
            skillName: item.value,
        })),
        regions: form.positionPlace?.regions?.map((region: any) => ({
            regionId: Number(region.id),
        })),
        personalityRequirements: form.skills?.personal,
        benefits: form.benefits,
        educationSpecialization: form.educationSpecialization,
        refugeeFriendly: form.offerSuitableFor?.includes(SuitableFor.Refugees) ?? false,
        suitableForHighSchoolStudent:
            form.offerSuitableFor?.includes(SuitableFor.HighSchoolStudents) ?? false,
        suitableForUniversityStudent:
            form.offerSuitableFor?.includes(SuitableFor.UniversityStudents) ?? false,
        validForGraduate: form.offerSuitableFor?.includes(SuitableFor.Graduates) ?? false,
        drivingLicences: form.skills?.drivingLicence,
        experienceInField: form.skills?.experienceAtPosition,
        experienceYears: form.skills?.yearsOfExperience,
        googlePlaceId: hasAddress(form) ? form.positionPlace?.address[0]?.id : null,
        allLanguagesRequired: form.languages?.required === LanguageRequirements.All,
        languageSkills: form.languages?.items.map((item: any) => ({
            languageId: Number(item.id),
            levelId: Number(item.level),
        })),
        salary: {
            currencyId: Number(form.salary?.currency),
            salaryPeriodId: Number(form.salary?.period),
            minimumValue: salaryValue,
            maximumValue: form.salary?.type === 'fixed' ? null : form.salary?.to?.toString(),
            publishMaximumValue: form.salary?.type !== 'fixed' && form.salary?.showTo,
            additionalInfo: form.salary?.otherInformation ?? null,
        },
    };
};