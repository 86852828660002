import Constants from '../Constants';
import moment from 'moment';

export default class JobHelper
{
    /**
     * @param stateId
     * @returns {boolean}
     */
    static isOpen(stateId) {
        return parseInt(stateId) === Constants.JOB_STATE_OPEN;
    }

    /**
     * @param stateId
     * @returns {boolean}
     */
    static isClosed(stateId) {
        return parseInt(stateId) === Constants.JOB_STATE_CLOSED;
    }

    /**
     * @param stateId
     * @returns {boolean}
     */
    static isPaid(stateId) {
        return this.isOpen(stateId) || this.isClosed(stateId);
    }

    /**
     * @param stateId
     * @returns {boolean}
     */
    static isUnpaid(stateId) {
        return parseInt(stateId) === Constants.JOB_STATE_UNPAID;
    }

    /**
     * @returns {boolean}
     * @param job
     */
    static hasJobEnabledReactionForm(job) {
        const {hasEnabledReactionForm, stateId} = job;

        return hasEnabledReactionForm === true && JobHelper.isOpen(stateId) === true;
    }

    /**
     * @returns {boolean}
     * @param job
     * @param tag
     * @param allowedTags
     */
    static hasJobEnabledRecommendationForm(job, tag, allowedTags) {
        const {stateId} = job;

        return JobHelper.isOpen(stateId) === true
            && allowedTags.includes(tag) === true;
    }

    static handlePipelineChangeForJob(pipelineStagesToBeChangedForJob, newPipelineStages, onCloseDialog, onOpenTransformModal, onTransformStages, onApplyWithoutTransform) {
        if (pipelineStagesToBeChangedForJob.length > 0) {
            onCloseDialog();

            onOpenTransformModal('SettingsPageTransformStagesModal', {
                newPipelineStages,
                pipelineStagesToBeChanged: pipelineStagesToBeChangedForJob,
                onTransformStages: (changedStages) => onTransformStages(changedStages),
            });
        } else {
            onApplyWithoutTransform();
        }
    }
}
