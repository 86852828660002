import Storages from './Storages';
import Language from './Language';

const B2B_SESSION_STORAGE_PREFIX: string = 'b2b';

class Authentication
{
    static getPublicActions(): string[] {
        return [
            'main',
            'features',
            'pricing',
            'packages',
            'faq',
            'articles',
            'contact',
            'order_mark',
            'reaction',
            'page-not-found',
            'show_file',
            'login',
            'logout',
            'maintenance',
            'company-jobs',
            'candidate-questionnaire',
            'recommendation',
            'integrated-reaction',
            'export-job',
        ];
    }

    static isAccessibleForUnlogged(action: string): boolean {
        return this.getPublicActions().includes(action);
    }

    static getGdprPublicActions(): string[] {
        return [
            'main',
            'features',
            'pricing',
            'packages',
            'gdpr',
            'page-not-found',
            'order_mark',
            'login',
            'logout',
        ];
    }

    static isGdprPublicAction(action: string): boolean {
        return this.getGdprPublicActions().includes(action);
    }

    static removeAuthenticationTokens(): void {
        Storages.removeLocal(`${B2B_SESSION_STORAGE_PREFIX}.accessToken`);
        Storages.removeLocal(`${B2B_SESSION_STORAGE_PREFIX}.refreshToken`);
        Storages.removeLocal(`${B2B_SESSION_STORAGE_PREFIX}.applicationId`);

        //only a temporary solution, it can be removed later
        Storages.removeSession(`${B2B_SESSION_STORAGE_PREFIX}.accessToken`);
        Storages.removeSession(`${B2B_SESSION_STORAGE_PREFIX}.refreshToken`);
        Storages.removeSession(`${B2B_SESSION_STORAGE_PREFIX}.applicationId`);
        Storages.removeLocal('accessToken');
        Storages.removeLocal('refreshToken');
        Storages.removeLocal('applicationId');
    }

    static setAuthenticationData(accessToken: string, refreshToken: string, applicationId: string, language: string): void {
        Storages.setLocal(`${B2B_SESSION_STORAGE_PREFIX}.accessToken`, accessToken);
        Storages.setLocal(`${B2B_SESSION_STORAGE_PREFIX}.refreshToken`, refreshToken);
        Storages.setLocal(`${B2B_SESSION_STORAGE_PREFIX}.applicationId`, applicationId);

        Language.set(language);
    }

    static getAccessToken(): string {
        return Storages.getLocal(`${B2B_SESSION_STORAGE_PREFIX}.accessToken`, Storages.getSession(`${B2B_SESSION_STORAGE_PREFIX}.accessToken`, Storages.getLocal('accessToken', '')));
    }

    static getRefreshToken(): string {
        return Storages.getLocal(`${B2B_SESSION_STORAGE_PREFIX}.refreshToken`, Storages.getSession(`${B2B_SESSION_STORAGE_PREFIX}.refreshToken`, Storages.getLocal('refreshToken', '')));
    }

    static getApplicationId(): string {
        return Storages.getLocal(`${B2B_SESSION_STORAGE_PREFIX}.applicationId`, Storages.getSession(`${B2B_SESSION_STORAGE_PREFIX}.applicationId`, Storages.getLocal('applicationId', '')));
    }

    static getLanguage(): string {
        return Language.get();
    }
}

export default Authentication;
