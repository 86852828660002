import React from 'react';
import Grid from '../../Grid';
import Divider from '../../Divider';

class QuestionnaireEditorProfesiaReplysheetQuestion extends React.Component
{
    render = () => {
        const {question, index} = this.props;

        return (
            <Grid>
                <Grid.Column width={14} className="questionnaire-box">
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16} verticalAlign="middle">{question.question}</Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Divider className="size_12 multiple"/>
                    <Grid>
                        {
                            question.hasOwnProperty('answers') === true
                            && question.answers.map((answer, answerIndex) => (
                                <Grid.Row key={index + '.' + answerIndex} className="questionnaire-box__row">
                                    <Grid.Column width={16} verticalAlign="middle">› {answer.name}</Grid.Column>
                                </Grid.Row>
                            ))
                        }
                    </Grid>
                </Grid.Column>
            </Grid>
        );
    };
}

QuestionnaireEditorProfesiaReplysheetQuestion.propTypes = {};

export default QuestionnaireEditorProfesiaReplysheetQuestion;
