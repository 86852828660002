import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import EmailMessageEditor from './EmailMessageEditor';
import {setErrorFocus} from '../../actions/ErrorActions';
import Variables from '../../helpers/Variables';
import Url from '../../helpers/Url';
import Constants from '../../helpers/Constants';
import SendEmailHelper from '../../helpers/components/SendEmailHelper';
import Storages from '../../helpers/Storages';

class EmailMessageVariablesEditor extends React.Component
{
    render = () => {
        const {emailData, errors, languageId, languages, settings, cursorOnStart, modalLogic: {modalType, modalProps}, modalSecondLogic, candidateModel, jobModel, createJobModel, loginManagerModel: {user, company}, candidateActionModel: {scheduleEventParams, sendEmailParams}} = this.props;

        const candidateIds           = modalProps !== undefined ? modalProps.candidateIds : undefined;
        const predefinedLanguageCode = languages.find(language => parseInt(language.languageId) === parseInt(languageId)).languageCode;

        const isTemplatePage                  = Url.getUrl().indexOf('settings/email-templates') > -1 || Url.getUrl().indexOf('settings/auto-answers') > -1;
        const isEvent                         = modalType === 'ScheduleCandidateEventModal';
        const isVideoInterview                = isEvent && scheduleEventParams !== undefined && scheduleEventParams.hasOwnProperty('eventType') === true && scheduleEventParams.eventType === Constants.CANDIDATE_EVENT_TYPE_VIDEO_INTERVIEW && settings.hasOwnProperty('videoInterview') === true;
        const isAutoAnswer                    = modalType === 'JobDetailPositionAutoAnswerModal' || modalType === 'SettingsPageAutoAnswerEditorModal' || (Url.getUrl().indexOf('create-job') > -1 && createJobModel.hasOwnProperty('settings') === true && createJobModel.settings.isUsingAutoAnswer === true);
        const isOutOfOffice                   = modalSecondLogic.hasOwnProperty('modalType') === true && modalSecondLogic.modalType === 'EditOutOfOfficeEmailModal';
        const multipleForwardingAsSingleEmail = Storages.getLocal('multipleForwardingAsSingleEmail', sendEmailParams !== undefined && sendEmailParams.multipleForwardingAsSingleEmail === true) === true;
        const isMassForward                   = SendEmailHelper.isMassForwarding(candidateIds, modalType, modalProps) && multipleForwardingAsSingleEmail === true;

        let hasJobQuestionnaire = false;

        if (candidateIds !== undefined && candidateIds.length > 1 && (Url.getUrl().indexOf('candidates/job/') > -1 || Url.getUrl().indexOf('pipeline/job/') > -1) && jobModel.hasOwnProperty('job') === true && (jobModel.job.questionnaireId !== null || jobModel.job.profesiaReplysheet === true)) {
            hasJobQuestionnaire = true;
        } else if (candidateIds !== undefined && candidateIds.length === 1 && candidateModel.hasOwnProperty('candidate') === true && candidateModel.candidate !== undefined && candidateModel.candidate.hasJobQuestionnaire === true) {
            hasJobQuestionnaire = true;
        }

        const actionTypes = Variables.getActionTypes(isTemplatePage, isEvent, isVideoInterview, hasJobQuestionnaire, isAutoAnswer, isOutOfOffice, isMassForward);
        const variables   = Variables.getVariablesForAction(settings.variables, predefinedLanguageCode, actionTypes, Variables.getUserFilledInData(user, company));

        return (
            <EmailMessageEditor
                errors={errors}
                setErrorFocus={this.props.setErrorFocus}
                emailData={emailData}
                multipleForwardingAsSingleEmail={multipleForwardingAsSingleEmail}
                variables={variables}
                languageId={languageId}
                cursorOnStart={cursorOnStart}
                predefinedLanguageCode={predefinedLanguageCode}
                onUpdateEmailTemplate={this.props.onUpdateEmailTemplate}
            />
        );
    };
}

const mapStateToProps = state => ({
    languages: state.loginManagerModel.languages,
    modalLogic: state.modalLogic,
    modalSecondLogic: state.modalSecondLogic,
    candidateModel: state.candidateModel.fullDetail,
    jobModel: state.jobModel,
    createJobModel: state.createJobModel,
    loginManagerModel: state.loginManagerModel,
    candidateActionModel: state.candidateActionModel,
});

const mapDispatchToProps = {
    setErrorFocus,
};

const EmailMessageVariablesContainerEditor = connect(mapStateToProps, mapDispatchToProps)(EmailMessageVariablesEditor);

EmailMessageVariablesContainerEditor.propTypes = {
    languageId: PropTypes.number.isRequired,
    emailData: PropTypes.object.isRequired,
    settings: PropTypes.object.isRequired,
    onUpdateEmailTemplate: PropTypes.func.isRequired,
};

EmailMessageVariablesContainerEditor.defaultProps = {
    errors: [],
    cursorOnStart: false,
};

export default EmailMessageVariablesContainerEditor;
