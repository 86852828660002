import JobValidatorHelper from './JobValidatorHelper';
import QuestionnaireValidatorHelper from './QuestionnaireValidatorHelper';
import AutoAnswerValidatorHelper from './AutoAnswerValidatorHelper';
import PipelineTemplateValidatorHelper from './PipelineTemplateValidatorHelper';

export default class CreateJobHelper
{
    static checkPosition(jobData) {
        const validator = new JobValidatorHelper(jobData);

        return validator.checkJob(true);
    }

    static checkQuestionnaire(isUsingQuestionnaire, questionnaireData, isNewTemplate, templatesForDropdown) {
        if (isUsingQuestionnaire === true) {
            const validator = new QuestionnaireValidatorHelper(questionnaireData);

            return validator.checkQuestionnaire(
                true,
                isNewTemplate,
                templatesForDropdown.map(template => template.text),
            );
        }

        return [];
    }

    static checkAutoAnswer(isUsingAutoAnswer, autoAnswerData, isNewTemplate, templatesForDropdown) {
        if (isUsingAutoAnswer === true) {
            const validator = new AutoAnswerValidatorHelper(autoAnswerData);

            return validator.checkAutoAnswer(
                true,
                isNewTemplate,
                templatesForDropdown.map(template => template.text),
            );
        }

        return [];
    }

    static checkPipeline(pipelineTemplateData, pipelineStages, isNewTemplate, templatesForDropdown) {
        const validator = new PipelineTemplateValidatorHelper(pipelineTemplateData, pipelineStages);

        return validator.checkPipelineTemplate(
            true,
            isNewTemplate,
            templatesForDropdown.map(template => template.text),
        );
    }
}
