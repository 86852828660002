import React from 'react';
import PropTypes from 'prop-types';

const Divider = ({hidden, className}) =>
{
    return (
        <div className={`mark-divider ${className} ${hidden ? 'hidden' : ''}`}/>
    );  
}

Divider.propTypes = {
    hidden: PropTypes.bool,
    className: PropTypes.string,
};

Divider.defaultProps = {
    hidden: false,
    className: ''
};

export default Divider;
