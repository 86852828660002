import React from 'react';
import Grid from '../Grid';
import Item from '../Item';
import PropTypes from 'prop-types';
import Translations from '../../helpers/Translations';
import File from '../../helpers/File';

const TemplateAttachmentColumn = ({attachment, onRemoveTemplateAttachment}) => {
    const size = attachment.size === 0 ? '' : <span className="file-size">&middot; {File.getReadableFileSize(attachment.size)}</span>;

    return (
        <Item className="data-block__content attachment basic">
            <Grid verticalAlign="middle" container>
                <Grid.Row>
                    <Grid.Column width={12}>
                        <Item.Header as="h5" className="ellipsis">{attachment.filename}{size}</Item.Header>
                    </Grid.Column>
                    <Grid.Column width={4} textAlign="right">
                        <a onClick={onRemoveTemplateAttachment} className="link secondary-link">{Translations.getStatic('delete')}</a>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Item>
    );
};

TemplateAttachmentColumn.propTypes = {
    attachment: PropTypes.object.isRequired,
    onRemoveTemplateAttachment: PropTypes.func.isRequired,
};

export default TemplateAttachmentColumn;
