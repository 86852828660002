import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {requestMoveCandidateModel, setMoveParams, getCandidatesForAction} from '../../actions/ActionWithCandidateActions';
import SendEmail from '../Email/SendEmail';
import ErrorFocus from '../ErrorFocus';
import Constants from '../../helpers/Constants';
import Select from '@profesia/adamui/components/common/dropdown/SelectDefault'
import Form from '../Form';
import Checkbox from '@profesia/adamui/components/common/input/checkbox/Checkbox';
import Grid from '../Grid';
import Heading from '../Heading';
import Translations from '../../helpers/Translations';
import Validation from '../../helpers/Validation';
import SendEmailHelper from '../../helpers/components/SendEmailHelper';
import FormSkeleton from '../../skeletons/components/FormSkeleton';

class MoveCandidate extends React.Component
{
    componentDidMount = () => {
        this.props.requestMoveCandidateModel();
        this.props.getCandidatesForAction(this.props.candidateIds, [Constants.CANDIDATE_OUTPUT_FLAG_HAS_EVENT, Constants.CANDIDATE_OUTPUT_FLAG_JOB_HAS_QUESTIONNAIRE]);
    };

    onSelectJob = jobId => {
        this.props.setMoveParams({
            jobId,
        });
    };

    render = () => {
        if (this.props.isFetchingCandidatesForAction === true || this.props.isFetchingMoveCandidateModel === true || this.props.candidateActionModel.hasOwnProperty('jobs') === false) {
            return <FormSkeleton/>;
        }

        const {jobs, moveParams} = this.props.candidateActionModel;

        let jobItems = jobs.map(job => ({
            id: job.id,
            label: job.title + (this.props.isAdmin === true ? ' (' + job.id + ')' : ''),
        }));

        jobItems = [{
            id: 0,
            label: Translations.getStatic('templatesDropdownEmptyItem'),
        }].concat(jobItems);

        return (
            <ErrorFocus>
                <Form>
                    <Heading className="highlighted" as="h3">{Translations.getStatic('detailsOfOperation')}</Heading>
                    <Grid className="highlighted">
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Form.Field className='fluid'
                                    error={
                                        Array.isArray(this.props.errors) === true && this.props.errors.includes('jobId') && moveParams.jobId === 0
                                            ? Validation.getMessage('empty')
                                            : false
                                    }
                                >
                                    <Select
                                        value={moveParams.jobId}
                                        label={Translations.getStatic('moveIntoJob')}
                                        options={jobItems}
                                        onChange={(value) => this.onSelectJob(parseInt(value))}
                                    />
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>

                <Form>
                    <Heading as="h3">{Translations.getStatic('emailToColleague')}</Heading>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Form.Field>
                                    <Checkbox
                                        checked={moveParams.sendEmail}
                                        label={Translations.getStatic('sendEmailToColleague')}
                                        onChange={(e) => this.props.setMoveParams({
                                            sendEmail: e.target.checked,
                                        })}
                                    />
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>

                {
                    moveParams.sendEmail === true
                    && (
                        <SendEmail
                            errors={this.props.errors}
                            visibleFields={SendEmailHelper.getDefaultEmailVisibleFields(Constants.EMAIL_TYPE_TO_COLLEAGUE)}

                            recipients={
                                this.props.sendEmailModel.hasOwnProperty('recipients') === true && this.props.sendEmailModel.recipients.length > 0
                                    ? this.props.sendEmailModel.recipients
                                    : []
                            }

                            bcc={
                                this.props.sendEmailModel.hasOwnProperty('bcc') === true && this.props.sendEmailModel.bcc.length > 0
                                    ? this.props.sendEmailModel.bcc
                                    : []
                            }

                            candidateIds={this.props.candidateIds}
                            noSpacing={true}
                            emailTemplateType={Constants.EMAIL_TEMPLATE_TYPE_COLLEAGUE_FORWARDING}
                        />
                    )
                }
            </ErrorFocus>
        );
    };
}

MoveCandidate.propTypes = {
    candidateIds: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
    sendEmailModel: state.sendEmailModel,
    candidateActionModel: state.candidateActionModel,
    isFetchingMoveCandidateModel: state.isFetchingMoveCandidateModel,
    isFetchingCandidatesForAction: state.isFetchingCandidatesForAction,
});

const mapDispatchToProps = {
    requestMoveCandidateModel,
    setMoveParams,
    getCandidatesForAction,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MoveCandidate);
