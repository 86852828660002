import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Dropdown} from 'semantic-ui-react';
import DateFormatted from '../helpers/DateFormatted';
import {openJob, closeJob, activateJob} from '../actions/JobDetailActions';
import PropTypes from 'prop-types';
import Translations from '../helpers/Translations';
import jobCloseImage from '../../img/icons/job-close.svg';
import jobImage from '../../img/icons/job.svg';
import Image from './Image';
import JobHelper from '../helpers/components/JobHelper';
import {openCloseJobUncontactedCandidateDialog} from '../actions/DialogActions';

class Header4MenuExtendedButton extends Component
{
    state = {
        dropdownActivfalse: false,
    };

    componentDidUpdate = () => {
        if (JobHelper.isClosed(this.props.stateId) === true && this.state.clickedOnJobClosed === true) {
            this.props.openCloseJobUncontactedCandidateDialog(this.props.jobId, this.props.candidates);
            this.setState({clickedOnJobClosed: false}); 
        }
    };

    handleCloseJob = () => {
        this.props.closeJob(this.props.jobId);
        this.setState({clickedOnJobClosed: true}); 
    }

    handleOpenJob = () => {
        this.props.openJob(this.props.jobId);
    };

    toggleActiveClass = () => {
        this.setState({
            dropdownActive: !this.state.dropdownActive,
        });
    };

    render() {
        const {addDate, validTill, stateId, jobId, endDate, displaySize, isExternalVisitor} = this.props;

        const dropdownActive = this.state.dropdownActive === true && isExternalVisitor === false;

        const ExtendedButton = ({toggleActiveClass, open, stateId, status, description = '', options}) => (
            <div onClick={toggleActiveClass} className={'extended-action menu ' + (dropdownActive === true ? 'active' : 'inactive')}>
                <div className="extended-action__icon-status">
                    <Image size="tiny" src={JobHelper.isOpen(stateId) === true ? jobImage : jobCloseImage}/>
                </div>

                <div className={'extended-action__dropdown' + (description === '' ? ' no-description' : '')}>
                    <Dropdown
                        icon={{className: isExternalVisitor === true ? 'hidden' : ''}}
                        trigger={displaySize.isMobile === false && <span className="text-button text-button__white">{status}</span>}
                        open={open}
                        onClick={toggleActiveClass}
                        onClose={toggleActiveClass}
                        direction={displaySize.isMobile === true ? 'left' : 'right'}
                    >
                        <Dropdown.Menu className="extended-list">
                            {options.map((option, i) => <Dropdown.Item key={i} {...option} className="txt-bold"/>)}
                        </Dropdown.Menu>
                    </Dropdown>

                    {
                        displaySize.isMobile === false
                        && <span className="text-small">{description}</span>
                    }
                </div>
            </div>
        );

        if (JobHelper.isPaid(stateId) === false) {
            return (
                <ExtendedButton
                    stateId={stateId}
                    status={Translations.getStatic('notActivatedJob')}
                    description={Translations.getStatic('dateCreated') + ' ' + DateFormatted.getDate(addDate)}
                    open={dropdownActive}
                    toggleActiveClass={this.toggleActiveClass}

                    options={[
                        {
                            text: Translations.getStatic('activateJob'),
                            onClick: () => this.props.activateJob(jobId),
                        },
                    ]}
                />
            );
        }

        if (JobHelper.isOpen(stateId) === true) {
            return (
                <>
                    <ExtendedButton
                        stateId={stateId}
                        status={Translations.getStatic('openJob')}
                        description={DateFormatted.isValidDate(new Date(validTill)) ? (Translations.getStatic('advertisingEndDate') + ' ' + DateFormatted.getDate(validTill)) : ''}
                        open={dropdownActive}
                        toggleActiveClass={this.toggleActiveClass}
                        options={[
                            {
                                text: Translations.getStatic('deleteJob'),
                                onClick: this.handleCloseJob,
                            },
                        ]}
                    />
                </>
            );
        }

        if (JobHelper.isClosed(stateId) === true) {
            return (
                <ExtendedButton
                    stateId={stateId}
                    status={Translations.getStatic('jobIsClosed')}
                    description={Translations.getStatic('endDateOfJob') + ' ' + DateFormatted.getDate(endDate)}
                    open={dropdownActive}
                    toggleActiveClass={this.toggleActiveClass}
                    options={[
                        {
                            text: Translations.getStatic('activateJobs'),
                            onClick: this.handleOpenJob,
                        },
                    ]}
                />
            );
        }

        return null;
    }
}

const mapStateToProps = state => ({
    displaySize: state.displaySize,
});

const mapDispatchToProps = {
    openJob,
    closeJob,
    activateJob,
    openCloseJobUncontactedCandidateDialog,
};

Header4MenuExtendedButton.propTypes = {
    addDate: PropTypes.string.isRequired,
    validTill: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    stateId: PropTypes.number.isRequired,
    isExternalVisitor: PropTypes.bool.isRequired,
    candidates: PropTypes.array.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header4MenuExtendedButton);
