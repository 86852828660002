import {
    MODAL_CLOSE,
    MODAL_OPEN,
    MODAL_SECOND_OPEN,
    MODAL_SECOND_CLOSE,
    NO_MODAL_OPEN,
    NO_MODAL_CLOSE,
    NO_MODAL_SECOND_OPEN,
    NO_MODAL_SECOND_CLOSE,
    NO_MODAL_DASHBOARD_OPEN,
    NO_MODAL_DASHBOARD_CLOSE,
  } from "../actions/ModalActions";

export const modalLogic = (state = {isOpen: false}, action) => {
    switch (action.type) {
        case MODAL_OPEN:
            return {
                modalType: action.payload.modalType,
                modalProps: action.payload.modalProps,
                isOpen: true,
                onClose: undefined,
            };
        case MODAL_CLOSE:
            return {
                isOpen: false,
                onClose: action.payload.onClose,
            };
        default:
            return state;
    }
};

export const modalSecondLogic = (state = {isOpen: false}, action) => {
    switch (action.type) {
        case MODAL_SECOND_OPEN:
            return {
                modalType: action.payload.modalType,
                modalProps: action.payload.modalProps,
                isOpen: true,
            };
        case MODAL_SECOND_CLOSE:
            return {
                isOpen: false,
            };
        default:
            return state;
    }
};

export const noModalLogic = (state = {noModalIsOpen: false}, action) => {
    switch (action.type) {
        case NO_MODAL_OPEN:
            return {
                noModalType: action.payload.modalType,
                noModalProps: action.payload.modalProps,
                noModalIsOpen: true,
            };
        case NO_MODAL_CLOSE:
            return {
                noModalIsOpen: false,
            };
        default:
            return state;
    }
};

export const noModalSecondLogic = (state = {noModalSecondIsOpen: false}, action) => {
    switch (action.type) {
        case NO_MODAL_SECOND_OPEN:
            return {
                noModalSecondType: action.payload.modalType,
                noModalSecondProps: action.payload.modalProps,
                noModalSecondIsOpen: true,
            };
        case NO_MODAL_SECOND_CLOSE:
            return {
                noModalSecondIsOpen: false,
            };
        default:
            return state;
    }
};

export const noModalDashboardLogic = (state = {noModalDashboardIsOpen: false}, action) => {
    switch (action.type) {
        case NO_MODAL_DASHBOARD_OPEN:
            return {
                noModalDashboardType: action.payload.modalType,
                noModalDashboardProps: action.payload.modalProps,
                noModalDashboardIsOpen: true,
            };
        case NO_MODAL_DASHBOARD_CLOSE:
            return {
                noModalDashboardIsOpen: false,
            };
        default:
            return state;
    }
};