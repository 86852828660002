import {CANCEL_CHUNKS_LOAD, CLEAR_CANCELLATION} from '../actions/ChunkCancellationActions';

const defaultState = {
    isCancelled: false,
};

export const chunkCancellation = (state = defaultState, action) => {
    switch (action.type) {
        case CANCEL_CHUNKS_LOAD:
            return {
                ...state,
                isCancelled: true,
            };

        case CLEAR_CANCELLATION:
            return {
                ...state,
                isCancelled: false,
            };

        default:
            return state;
    }
};
