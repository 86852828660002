import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Progress} from 'semantic-ui-react';
import Button from '../Button';
import Grid from '../Grid';
import Item from '../Item';
import Image from '../Image';
import Divider from '../Divider';
import Heading from '../Heading';
import Icon from '../Icon';
import {requestQuestionnaireResults} from '../../actions/QuestionnaireResultsActions';
import CircularProgressbar from '../CircularProgressbar';
import Translations from '../../helpers/Translations';
import PropTypes from 'prop-types';
import {openModal} from '../../actions/ModalActions';
import questionnaireImage from '../../../img/icons/questionnaire-340x340.svg';
import User from '../../helpers/User';
import DateFormatted from '../../helpers/DateFormatted';
import Constants from '../../helpers/Constants';
import md5 from 'md5';
import QuestionnaireResultsSkeleton from '../../skeletons/components/QuestionnaireResultsSkeleton';

class QuestionnaireResults extends Component
{
    state = {
        showPrintIframe: false,
    };

    componentDidMount() {
        this.props.requestQuestionnaireResults(this.props.candidateId);
    }

    printIframe = url => {
        const pdfIframe = document.getElementById(this.getIframeId(url));

        try {
            pdfIframe.contentWindow.focus();
            pdfIframe.contentWindow.print();
        } catch (e) {
            window.open(window.location.origin + url);
        }
    };

    printTrigger = url => {
        const pdfIframe = document.getElementById(this.getIframeId(url));

        if (pdfIframe !== null) {
            this.printIframe(url);
        }

        this.setState({
            showPrintIframe: true,
        });
    };

    getIframeId = url => {
        return md5('iFrameQRPreview_' + url);
    };

    render() {
        const {isFetchingQuestionnaireResults, questionnaire, replysheet, displaySize, candidateQuestionnaireResultExport, loginManagerModel, jobLanguageCode} = this.props;

        if (isFetchingQuestionnaireResults === true || questionnaire === undefined) {
            return <QuestionnaireResultsSkeleton/>;
        }

        if (questionnaire === null && (replysheet === undefined || replysheet === null)) {
            return (
                <div className="attachment-assistant list content-vertical">
                    <Divider hidden/>
                    <Image className="auto" src={questionnaireImage}/>
                    <Heading className="mb-2">{Translations.getStatic('candidateWithoutQuestionnaire')}</Heading>

                    {
                        User.isExternalUser(loginManagerModel) === true
                        || (
                            <Button className="secondary-button" onClick={() => this.props.openModal('RequestCandidateQuestionnaireModal', {candidateIds: [this.props.candidateId]})}>
                                {Translations.getStatic('sendQuestionnaireRequest')}
                            </Button>
                        )
                    }
                </div>
            );
        }

        return (
            <div className="questionnaire list">
                <Grid className="data-block">
                    <Grid.Row className="padding-bottom_none">
                        <Grid.Column>
                            <div className="ui items unstackable">
                                <Item className="data-block__content questionnaire-result">
                                    <div className="circular-progressbar">
                                        {
                                            questionnaire === null || questionnaire.totalScorePercent === null
                                                ? (
                                                    <CircularProgressbar
                                                        percentage={0}
                                                        text={'-'}
                                                    />
                                                )
                                                : (
                                                    <CircularProgressbar
                                                        percentage={questionnaire.totalScorePercent}
                                                        text={`${questionnaire.totalScorePercent}%`}
                                                    />
                                                )
                                        }

                                    </div>
                                    <Grid verticalAlign="middle">
                                        <Grid.Row>
                                            <Grid.Column width={10}>
                                                <Item.Header as="h4">{Translations.getStatic('questionnaireRank', jobLanguageCode)}</Item.Header>
                                                {
                                                    questionnaire === null || questionnaire.totalScorePercent === null
                                                        ? <p style={{visibility: 'hidden', width: '200px'}}>&nbsp;</p>
                                                        : <p>{Translations.getStatic('candidateReached', jobLanguageCode)} {questionnaire.totalScore} {Translations.getStatic('outOf', jobLanguageCode)} {questionnaire.maxScore} {Translations.getStatic('pointsGenitive', jobLanguageCode)}</p>
                                                }
                                            </Grid.Column>
                                            {
                                                displaySize.isMobile === false
                                                && (
                                                    <Grid.Column width={6} textAlign="right">
                                                        <a
                                                            onClick={() => this.printTrigger(candidateQuestionnaireResultExport.downloadUrl)}
                                                            className="link secondary-link"
                                                        >
                                                            {Translations.getStatic('print', jobLanguageCode)}
                                                        </a>
                                                        <a
                                                            onClick={() => window.open(candidateQuestionnaireResultExport.downloadUrl)}
                                                            className="link secondary-link"
                                                        >
                                                            {'PDF'}
                                                        </a>
                                                        <p style={{visibility: 'hidden', width: '200px'}}>&nbsp;</p>
                                                    </Grid.Column>
                                                )
                                            }
                                        </Grid.Row>
                                    </Grid>
                                </Item>
                            </div>
                        </Grid.Column>
                    </Grid.Row>

                    <>
                        {
                            questionnaire === null ||
                            (
                                <>
                                    {
                                        questionnaire.questions.scored !== undefined
                                        && questionnaire.questions.scored.length > 0
                                        && (
                                            <>
                                                <Grid.Row className="padding-bottom_none">
                                                    <Grid.Column>
                                                        <Heading as="h4">
                                                            <Icon size="large" className="icon-pointsQuestion"/>
                                                            <Heading.Content>{Translations.getStatic('rankedQuestions', jobLanguageCode)}</Heading.Content>
                                                        </Heading>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row className="padding-top_none padding-bottom_none">
                                                    <Grid.Column>
                                                        <div className='ui items'>
                                                            {
                                                                questionnaire.questions.scored.map((question, i) => {
                                                                    return (
                                                                        <div key={i} className="questionnaire__question">
                                                                            <Item>
                                                                                <Item.Content verticalAlign="middle">
                                                                                    <Item.Header as="h5">{question.question}</Item.Header>
                                                                                    <Grid>
                                                                                        <Grid.Column width={12}>
                                                                                            <Item.Meta>{question.answers.map(answer => answer.answer).join('; ')}</Item.Meta>
                                                                                        </Grid.Column>
                                                                                        <Grid.Column width={4} textAlign="right">
                                                                                            <Item.Meta className="points">
                                                                                                {Translations.countDependantStatic(question.totalAnswersScore === null ? 0 : question.totalAnswersScore, ['point', 'points', 'pointsGenitive'])}
                                                                                            </Item.Meta>
                                                                                        </Grid.Column>
                                                                                    </Grid>
                                                                                    <Progress className="full" percent={question.totalAnswersScorePercent === null ? 0 : question.totalAnswersScorePercent}/>
                                                                                </Item.Content>
                                                                            </Item>
                                                                        </div>
                                                                    );
                                                                })
                                                            }
                                                        </div>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </>
                                        )
                                    }

                                    {
                                        questionnaire.questions.unscored !== undefined
                                        && questionnaire.questions.unscored.length > 0
                                        && (
                                            <>
                                                <Grid.Row className="padding-bottom_none">
                                                    <Grid.Column>
                                                        <Heading as="h4">
                                                            <Icon size="large" className="icon-pointsQuestion"/>
                                                            <Heading.Content>{Translations.getStatic('notRankedQuestions', jobLanguageCode)}</Heading.Content>
                                                        </Heading>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row className="padding-top_none padding-bottom_none">
                                                    <Grid.Column>
                                                        <div className='ui items'>
                                                            {
                                                                questionnaire.questions.unscored.map((question, i) => {
                                                                    return (
                                                                        <div key={i} className="questionnaire__question">
                                                                            <Item>
                                                                                <Item.Content verticalAlign="middle">
                                                                                    <Item.Header as="h5">{question.question}</Item.Header>
                                                                                    <Grid>
                                                                                        <Grid.Column width={12}>
                                                                                            <Item.Meta>
                                                                                                {
                                                                                                    question.answers.map(answer => {
                                                                                                        if (question.type === Constants.QUESTIONNAIRE_QUESTION_TYPE_DISPOSABLE_DATE) {
                                                                                                            return DateFormatted.getDate(answer.answer);
                                                                                                        }

                                                                                                        return answer.answer;
                                                                                                    }).join('; ')
                                                                                                }
                                                                                            </Item.Meta>
                                                                                        </Grid.Column>
                                                                                    </Grid>
                                                                                </Item.Content>
                                                                            </Item>
                                                                        </div>
                                                                    );
                                                                })
                                                            }
                                                        </div>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </>
                                        )
                                    }
                                </>
                            )
                        }

                        {
                            replysheet !== undefined && replysheet !== null
                            && (
                                <>
                                    <Grid.Row className="padding-bottom_none">
                                        <Grid.Column>
                                            <Heading as="h4">
                                                <Icon size="large" className="icon-pointsQuestion"/>
                                                <Heading.Content>{Translations.getStatic('criteriaFromOffer', jobLanguageCode)}</Heading.Content>
                                            </Heading>
                                        </Grid.Column>
                                    </Grid.Row>

                                    {
                                        replysheet.EDU.length > 0
                                        && (
                                            <>
                                                <Grid.Row className="padding-bottom_none">
                                                    <Grid.Column>
                                                        <Heading as="h5">
                                                            <Heading.Content className="padding-left">{Translations.getStatic('education', jobLanguageCode)}</Heading.Content>
                                                        </Heading>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row className="padding-top_none padding-bottom_none">
                                                    <Grid.Column>
                                                        <div className='ui items'>
                                                            {
                                                                replysheet.EDU.map((question, i) => {
                                                                    return (
                                                                        <div key={i} className="questionnaire__question">
                                                                            <Item>
                                                                                <Item.Content verticalAlign="middle">
                                                                                    <Item.Header as="h5">{question.required.join(', ')}</Item.Header>
                                                                                    <Grid>
                                                                                        <Grid.Column width={12}>
                                                                                            <Item.Meta className={question.score > 0 ? 'success' : 'failure'}>{question.declared}</Item.Meta>
                                                                                        </Grid.Column>
                                                                                    </Grid>
                                                                                </Item.Content>
                                                                            </Item>
                                                                        </div>
                                                                    );
                                                                })
                                                            }
                                                        </div>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </>
                                        )
                                    }

                                    {
                                        replysheet.SKILL.length > 0
                                        && (
                                            <>
                                                <Grid.Row className="padding-bottom_none">
                                                    <Grid.Column>
                                                        <Heading as="h5">
                                                            <Heading.Content className="padding-left">{Translations.getStatic('personalSkills', jobLanguageCode)}</Heading.Content>
                                                        </Heading>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row className="padding-top_none padding-bottom_none">
                                                    <Grid.Column>
                                                        <div className='ui items'>
                                                            {
                                                                replysheet.SKILL.map((question, i) => {
                                                                    return (
                                                                        <div key={i} className="questionnaire__question">
                                                                            <Item>
                                                                                <Item.Content verticalAlign="middle">
                                                                                    <Item.Header as="h5">{question.skillName} - {question.required}</Item.Header>
                                                                                    <Grid>
                                                                                        <Grid.Column width={12}>
                                                                                            <Item.Meta className={question.score > 0 ? 'success' : 'failure'}>{question.declared}</Item.Meta>
                                                                                        </Grid.Column>
                                                                                    </Grid>
                                                                                </Item.Content>
                                                                            </Item>
                                                                        </div>
                                                                    );
                                                                })
                                                            }
                                                        </div>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </>
                                        )
                                    }

                                    {
                                        replysheet.DRIVE.length > 0
                                        && (
                                            <>
                                                <Grid.Row className="padding-bottom_none">
                                                    <Grid.Column>
                                                        <Heading as="h5">
                                                            <Heading.Content className="padding-left">{Translations.getStatic('drivingLicence', jobLanguageCode)}</Heading.Content>
                                                        </Heading>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row className="padding-top_none padding-bottom_none">
                                                    <Grid.Column>
                                                        <div className='ui items'>
                                                            {
                                                                replysheet.DRIVE.map((question, i) => {
                                                                    return (
                                                                        <div key={i} className="questionnaire__question">
                                                                            <Item>
                                                                                <Item.Content verticalAlign="middle">
                                                                                    <Item.Header as="h5">{question.required}</Item.Header>
                                                                                    <Grid>
                                                                                        <Grid.Column width={12}>
                                                                                            <Item.Meta className={question.score > 0 ? 'success' : 'failure'}>{question.declared}</Item.Meta>
                                                                                        </Grid.Column>
                                                                                    </Grid>
                                                                                </Item.Content>
                                                                            </Item>
                                                                        </div>
                                                                    );
                                                                })
                                                            }
                                                        </div>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </>
                                        )
                                    }
                                </>
                            )
                        }
                    </>
                </Grid>

                {
                    this.state.showPrintIframe === true
                    && <iframe onLoad={() => this.printIframe(candidateQuestionnaireResultExport.url)} className="iFramePdfPreview" id={this.getIframeId(candidateQuestionnaireResultExport.url)} src={window.location.origin + candidateQuestionnaireResultExport.url}/>
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    isFetchingQuestionnaireResults: state.isFetchingQuestionnaireResults,
    questionnaire: state.questionnaireResultsModel.questionnaire,
    replysheet: state.questionnaireResultsModel.replysheet,
    candidateQuestionnaireResultExport: state.questionnaireResultsModel.candidateQuestionnaireResultExport,
    displaySize: state.displaySize,
    loginManagerModel: state.loginManagerModel,
});

const mapDispatchToProps = {
    requestQuestionnaireResults,
    openModal,
};

QuestionnaireResults.propTypes = {
    isFetchingQuestionnaireResults: PropTypes.bool.isRequired,

    questionnaire: PropTypes.shape({
        maxScore: PropTypes.number,
        totalScore: PropTypes.number,
        totalScorePercent: PropTypes.number,
        questions: PropTypes.object.isRequired,
    }),

    replysheet: PropTypes.shape({
        maxScore: PropTypes.number,
        totalScore: PropTypes.number,
        totalScorePercent: PropTypes.number,
        EDU: PropTypes.array,
        SKILL: PropTypes.array,
        DRIVE: PropTypes.array,
    }),
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionnaireResults);
