import React from 'react';
import {connect} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import MarkDialog from '../Modal/MarkDialog';
import {closeJobCloseUncontactedCandidateDialog} from '../../actions/DialogActions';
import Translations from '../../helpers/Translations';
import Constants from '../../helpers/Constants';
import Url from '../../helpers/Url';
import Track from '../../helpers/Track';
import {TRACK_LOCATION_MARK, TRACK_NAME_EXCLUDE_FROM_PROCEDURE, TRACK_NAME_KEEP_IN_PROCEDURE} from '../../helpers/ConstantsTrack';

const ConfirmCloseJobUncontactedDialog = ({confirmCloseJobUncontactedDialog, closeJobCloseUncontactedCandidateDialog}) => {
    const navigate = useNavigate();

    const redirectToCloseJobWizard = () => {
        closeJobCloseUncontactedCandidateDialog();

        Track.uxClick(TRACK_LOCATION_MARK, TRACK_NAME_EXCLUDE_FROM_PROCEDURE);

        navigate(
            Url.build('close-job', {job: confirmCloseJobUncontactedDialog.jobId}),
        );
    };

    const closeDialog = () => {
        closeJobCloseUncontactedCandidateDialog();

        Track.uxClick(TRACK_LOCATION_MARK, TRACK_NAME_KEEP_IN_PROCEDURE);
    };

    const {candidates, open} = confirmCloseJobUncontactedDialog;

    const unprocessedCandidatesInJob =
              candidates !== undefined
              && candidates.filter(
                  (candidate) =>
                      candidate.isAnonymized === false
                      && candidate.stateId === Constants.CANDIDATE_STATE_ACTIVE
                      && candidate.stage.id !== Constants.CANDIDATE_STAGE_ACCEPTED_ID,
              );

    const hasUncontactedCandidate =
              candidates !== undefined
              && candidates.find((candidate) => candidate.isAnonymized === false && candidate.isContacted === false) !== undefined;

    if (open === false || unprocessedCandidatesInJob === 0 || hasUncontactedCandidate === false) {
        return null;
    }

    const dialogString = Translations.countDependantStatic(unprocessedCandidatesInJob.length, ['unprocessedCandidateInJob', 'unprocessedCandidatesInJob', 'unprocessedCandidatesInJob'], false);

    return (
        <MarkDialog
            closeOnDimmerClick={false}
            message={{
                iconName: 'icon-closeJob',
                title: Translations.getStatic('unprocessedCandidates'),
                infoLines: hasUncontactedCandidate === true
                    ? [dialogString.replace('%applicants%', Translations.countDependantStatic(unprocessedCandidatesInJob.length, ['candidateGenitive', 'candidatesGenitive', 'candidatesGenitive']).toLowerCase())]
                    : [Translations.getStatic('closeJobWithUnprocessedCandidates')],
            }}
            actions={[
                {
                    title: Translations.getStatic('understandAndRefuse'),
                    action: redirectToCloseJobWizard,
                    type: 'positive',
                    color: 'orange',
                },
                {
                    title: Translations.getStatic('keepInAction'),
                    action: closeDialog,
                },
            ]}
        />
    );
};

const mapStateToProps = (state) => ({
    confirmCloseJobUncontactedDialog: state.confirmCloseJobUncontactedDialog,
});

const mapDispatchToProps = {
    closeJobCloseUncontactedCandidateDialog,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ConfirmCloseJobUncontactedDialog);
