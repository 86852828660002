import React from 'react';
import Grid from '../Grid';
import PropTypes from 'prop-types';
import Translations from '../../helpers/Translations';
import EmailMessageVariablesContainerEditor from './EmailMessageVariablesContainerEditor';

class AutoAnswerEditor extends React.Component
{
    constructor(props) {
        super(props);
    }

    render = () => {
        const {emailData, settings, errors, languageId} = this.props;

        return (
            <Grid>
                <Grid.Row className='horizontal-row'>
                    <Grid.Column computer={4} tablet={16}>
                        <label>{Translations.getStatic('sender')}</label>
                    </Grid.Column>
                    <Grid.Column computer={12} tablet={16}>
                        {settings.userData.email}
                    </Grid.Column>
                </Grid.Row>
                <EmailMessageVariablesContainerEditor
                    languageId={languageId}
                    emailData={emailData}
                    settings={settings}
                    errors={errors}
                    onUpdateEmailTemplate={this.props.onUpdateEmailTemplate}
                />
            </Grid>
        );
    };
}

AutoAnswerEditor.propTypes = {
    languageId: PropTypes.number.isRequired,
    emailData: PropTypes.object.isRequired,
    settings: PropTypes.object.isRequired,
    onUpdateEmailTemplate: PropTypes.func.isRequired,
};

export default AutoAnswerEditor;
