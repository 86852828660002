import React from 'react';
import PropTypes from 'prop-types';

const Image = props => {
    if (props.wrapped === true) {
        return (
            <div className={`image ${props.size} ${props.className}`}>
                <img src={props.src} alt={props.alt} style={props.style}/>
            </div>
        );
    }

    return <img src={props.src} alt={props.alt} className={`image ${props.size} ${props.className}`} style={props.style} onClick={props.onClick}/>;
};

Image.defaultProps = {
    alt: '',
    style: null,
    size: 'small',
    className: '',
};

Image.propTypes = {
    alt: PropTypes.string,
    style: PropTypes.object,
    size: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    src: PropTypes.any,
};

export default Image;
