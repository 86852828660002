import {CANCEL_SCHEDULED_EMAIL, CANCEL_SCHEDULED_EMAIL_FAILURE, CANCEL_SCHEDULED_EMAIL_SUCCESS, CANCEL_SCHEDULED_MASS_FORWARD_EMAIL_SUCCESS, CANCEL_SCHEDULED_MASS_FORWARD_EMAIL_FAILURE, CANCEL_SCHEDULED_MASS_FORWARD_EMAIL} from '../actions/ScheduledEmailActions';

export const isCancellingScheduledEmail = (state = false, action) => {
    switch (action.type) {
        case CANCEL_SCHEDULED_EMAIL:
            return true;
        case CANCEL_SCHEDULED_EMAIL_SUCCESS:
        case CANCEL_SCHEDULED_EMAIL_FAILURE:
            return false;
        default:
            return state;
    }
};

const defaultState = {
    isSuccess: false,
};

export const scheduledEmailModel = (state = defaultState, action) => {
    switch (action.type) {
        case CANCEL_SCHEDULED_EMAIL:
            return defaultState;
        case CANCEL_SCHEDULED_EMAIL_SUCCESS:
            return {
                ...state,
                isSuccess: true,
            };
        case CANCEL_SCHEDULED_EMAIL_FAILURE:
            return {
                ...state,
                isSuccess: false,
            };
        default:
            return state;
    }
};

export const isCancellingScheduledMassForwardEmail = (state = false, action) => {
    switch (action.type) {
        case CANCEL_SCHEDULED_MASS_FORWARD_EMAIL:
            return true;
        case CANCEL_SCHEDULED_MASS_FORWARD_EMAIL_SUCCESS:
        case CANCEL_SCHEDULED_MASS_FORWARD_EMAIL_FAILURE:
            return false;
        default:
            return state;
    }
};

export const scheduledMassForwardEmailModel = (state = defaultState, action) => {
    switch (action.type) {
        case CANCEL_SCHEDULED_MASS_FORWARD_EMAIL:
            return defaultState;
        case CANCEL_SCHEDULED_MASS_FORWARD_EMAIL_SUCCESS:
            return {
                ...state,
                isSuccess: true,
            };
        case CANCEL_SCHEDULED_MASS_FORWARD_EMAIL_FAILURE:
            return {
                ...state,
                isSuccess: false,
            };
        default:
            return state;
    }
};
