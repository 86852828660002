import React, {Component} from 'react';
import PropTypes from 'prop-types';
import MarkModal from '../Modal/MarkModal';
import {closeModal, openModalSecond} from '../../actions/ModalActions';
import {connect} from 'react-redux';
import {refuseCandidateAndSendEmail, sendEmail} from '../../actions/ActionWithCandidateActions';
import {jobUncheckAllCandidates} from '../../actions/CandidatesListActions';
import LoadingComponent from '../LoadingComponent';
import Translations from '../../helpers/Translations';
import AttachmentAssistantHelper from '../../helpers/components/AttachmentAssistantHelper';
import SuccessMessage from '../../helpers/SuccessMessage';
import SendEmailHelper from '../../helpers/components/SendEmailHelper';
import Constants from '../../helpers/Constants';
import RefuseCandidate from '../Candidate/RefuseCandidate';
import RefuseCandidateValidatorHelper from '../../helpers/components/RefuseCandidateValidatorHelper';
import {openAllRecipientsWithoutEmailsDialog, openSomeRecipientsWithoutEmailsDialog} from '../../actions/DialogActions';
import Arrays from '../../helpers/Arrays';
import Variables from '../../helpers/Variables';
import CandidatesReducer from '../../helpers/generalReducers/CandidatesReducer';
import Track from '../../helpers/Track';
import {TRACK_FUNNEL_NAME_CLOSE, TRACK_FUNNEL_NAME_EXCLUDE, TRACK_FUNNEL_TYPE_EXCLUDE_CANDIDATE} from '../../helpers/ConstantsTrack';

class RefuseCandidateWithEmailModal extends Component
{
    state = {
        errors: [],
    };

    componentDidUpdate = prevProps => {
        if (SuccessMessage.canShowSuccessMessage(prevProps, this.props, 'isSendingEmail')
            && this.props.candidateActionModel.hasOwnProperty('isSuccess') === true
            && this.props.candidateActionModel.isSuccess === true
        ) {
            this.props.jobUncheckAllCandidates();
            this.props.closeModal();
        }
    };

    refuseAndSendEmail = (excludedCandidateIdsFromSendEmail = []) => {
        const {candidateActionModel, sendEmailModel, attachmentAssistantModel, templateAssistant, templatesForDropdownSelector, loginManagerModel} = this.props;
        const candidateIds                                                                                                                         = Arrays.getUniqueArray(this.props.candidateIds);

        try {
            const validator = new RefuseCandidateValidatorHelper(candidateActionModel.refuseCandidateParams);
            validator.checkRefusalReason();

            let actionData = new FormData();

            if (candidateActionModel.refuseCandidateParams.sendEmail === true) {
                actionData = SendEmailHelper.prepareEmail(
                    sendEmailModel,
                    attachmentAssistantModel,
                    templateAssistant,
                    templatesForDropdownSelector,
                    candidateIds,
                    Constants.EMAIL_TYPE_TO_CANDIDATE,
                    {
                        variables: sendEmailModel.settings.variables,
                        filledVariables: Variables.getFilledVariablesCheckParams(
                            [Constants.VARIABLE_ACTION_TYPE_USER, Constants.VARIABLE_ACTION_TYPE_EVENT, Constants.VARIABLE_ACTION_TYPE_VIDEO_INTERVIEW, Constants.VARIABLE_ACTION_TYPE_QUESTIONNAIRE],
                            candidateActionModel.candidates,
                            loginManagerModel,
                            sendEmailModel.settings.videoInterview,
                        ),
                        forbiddenVariables: Variables.getForbiddenVariablesCheckParams(
                            [Constants.VARIABLE_ACTION_TYPE_MULTIPLE_FORWARD],
                        ),
                        requiredVariables: {},
                    },
                    excludedCandidateIdsFromSendEmail,
                );
            }

            actionData.append('refuseCandidateParams', JSON.stringify(candidateActionModel.refuseCandidateParams));
            actionData.append('candidateIds', JSON.stringify(candidateIds));

            this.props.refuseCandidateAndSendEmail(
                actionData,
                candidateIds,
                candidateActionModel.refuseCandidateParams.sendEmail === true,
                CandidatesReducer.getReducerType(),
            );

            Track.funnel(4, TRACK_FUNNEL_NAME_EXCLUDE, TRACK_FUNNEL_TYPE_EXCLUDE_CANDIDATE);

            this.setState({
                errors: [],
            });
        } catch (error) {
            if (Array.isArray(error) === true) {
                SendEmailHelper.processRecipientsErrors(error, this.props.openAllRecipientsWithoutEmailsDialog, () => this.props.openSomeRecipientsWithoutEmailsDialog({
                    candidateIds: candidateIds,
                    onSkip: excludedCandidateIdsFromSendEmail => this.refuseAndSendEmail(excludedCandidateIdsFromSendEmail),
                }));

                this.setState({
                    errors: error,
                });
            }
        }
    };

    closeModal = () => {
        if (this.props.fromPipeline === true && this.props.fromPipelineCallback !== null) {
            this.props.fromPipelineCallback();
        }

        Track.funnel(3, TRACK_FUNNEL_NAME_CLOSE, TRACK_FUNNEL_TYPE_EXCLUDE_CANDIDATE);

        this.props.closeModal();
    };

    render() {
        const {
                  isOpen, sendEmailModel, candidateIds, candidateActionModel, attachmentAssistantModel, refusalReasonId, isSendingEmail,
                  isFetchingRefuseCandidatesModel, isFetchingCandidatesForAction,
                  isGettingVideoInterviewData,
                  isFetchingAttachmentAssistantModel,
                  isFetchingSendEmailModel,
              } = this.props;

        const {errors} = this.state;

        const refuseTitle = this.props.candidateIds.length > 1 ? Translations.getStatic('candidatesRefuse') : Translations.getStatic('refuseTitle');

        const children = isSendingEmail === true
            ? (
                <LoadingComponent
                    text={Translations.getStatic('discarding') + '...'}
                    showProgress={candidateIds.length > Constants.POST_CHUNK_SIZE}
                />
            )
            : (
                <RefuseCandidate
                    errors={errors}
                    candidateIds={candidateIds}
                    refusalReasonId={refusalReasonId}
                />
            );

        const emailData = {
            from: sendEmailModel.settings && sendEmailModel.settings.companyUsers ? sendEmailModel.settings.companyUsers.find(user => user.id === sendEmailModel.sender).email : '',
            to: sendEmailModel.recipients.map(recipient => recipient.value),
            bcc: sendEmailModel.bcc.map(bccEmail => bccEmail.value),
            subject: sendEmailModel.emailTemplateData ? sendEmailModel.emailTemplateData.subject : '',
            body: sendEmailModel.emailTemplateData ? sendEmailModel.emailTemplateData.text : '',
            attachments: AttachmentAssistantHelper.getAttachmentsForEmailPreview(attachmentAssistantModel),
            attachmentSettings: attachmentAssistantModel.settings,
            languageId: sendEmailModel.emailTemplateData ? sendEmailModel.emailTemplateData.languageId : 1,
            candidateId: candidateIds.length > 0 ? candidateIds[0] : 0,
            senderId: sendEmailModel.sender,
        };

        return (
            <MarkModal
                isOpen={isOpen}
                closeModal={this.closeModal}
                isLoadingData={
                    isFetchingRefuseCandidatesModel === true
                    || isFetchingCandidatesForAction === true
                    || isGettingVideoInterviewData === true
                    || isFetchingAttachmentAssistantModel === true
                    || isFetchingSendEmailModel === true

                }

                actionButton={{
                    text: candidateActionModel.refuseCandidateParams.sendEmail === true
                        ? Translations.getStatic('refuseAndSend')
                        : Translations.getStatic('refuse'),

                    disabled: this.props.isSendingEmail,
                    loading: this.props.isSendingEmail,
                    onClick: () => this.refuseAndSendEmail(),
                }}

                entityOption={{
                    text: Translations.getStatic('emailPreview'),
                    visible: candidateActionModel.refuseCandidateParams.sendEmail,
                    disabled: this.props.isSendingEmail,
                    loading: this.props.isSendingEmail,
                    onClick: () => this.props.openModalSecond('DetailEmailModal', {title: Translations.getStatic('emailPreview'), emailData}),
                }}

                title={
                    candidateActionModel.refuseCandidateParams.sendEmail === true
                        ? Translations.getStatic('refuseAndSendTitle')
                        : refuseTitle
                }

                content={children}
            />
        );
    }
}

RefuseCandidateWithEmailModal.propTypes = {
    candidateIds: PropTypes.array.isRequired,
    isOpen: PropTypes.bool.isRequired,
    fromPipeline: PropTypes.bool,
    fromPipelineCallback: PropTypes.func,
    refusalReasonId: PropTypes.number,
};

RefuseCandidateWithEmailModal.defaultProps = {
    fromPipeline: false,
    fromPipelineCallback: null,
    refusalReasonId: null,
};

const mapStateToProps = state => ({
    isOpen: state.modalLogic.isOpen,
    candidateActionModel: state.candidateActionModel,
    isSendingEmail: state.isSendingEmail,
    sendEmailModel: state.sendEmailModel,

    attachmentAssistantModel: AttachmentAssistantHelper.getActiveModelFromAssistantAttachmentModel(
        state.attachmentAssistantModel,
        AttachmentAssistantHelper.createAttachmentAssistantHashIdForEmail(),
    ),

    templateAssistant: state.templateAssistant,
    templatesForDropdownSelector: state.templatesForDropdownSelector.templates,
    loginManagerModel: state.loginManagerModel,
    isFetchingRefuseCandidatesModel: state.isFetchingRefuseCandidatesModel,
    isFetchingCandidatesForAction: state.isFetchingCandidatesForAction,
    isGettingVideoInterviewData: state.isGettingVideoInterviewData,
    isFetchingAttachmentAssistantModel: state.isFetchingAttachmentAssistantModel,
    isFetchingSendEmailModel: state.isFetchingSendEmailModel,
});

const mapDispatchToProps = {
    closeModal,
    openModalSecond,
    sendEmail,
    refuseCandidateAndSendEmail,
    jobUncheckAllCandidates,
    openAllRecipientsWithoutEmailsDialog,
    openSomeRecipientsWithoutEmailsDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(RefuseCandidateWithEmailModal);




