import React from 'react';
import {SkeletonRectangular} from '@profesia/adamui/components/common/skeleton';
import {Stack} from '@mui/material';

const JobItemSkeleton = () => (
    <Stack flex={1} my={1} py={3.5} direction="row" justifyContent="space-between">
        <Stack spacing={1}>
            <SkeletonRectangular width={244} height={18} borderRadius={26}/>
            <SkeletonRectangular width={116} height={10} borderRadius={26}/>
            <SkeletonRectangular width={188} height={10} borderRadius={26}/>
        </Stack>
        <Stack spacing={1} alignItems={'center'} justifyContent={'center'}>
            <SkeletonRectangular width={84} height={18} borderRadius={26}/>
            <SkeletonRectangular width={202} height={18} borderRadius={26}/>
        </Stack>
    </Stack>
);

export default JobItemSkeleton;
