import React, {Component} from 'react';
import {connect} from 'react-redux';
import Translations from '../../helpers/Translations';
import Validation from '../../helpers/Validation';
import DatetimePicker from '../DatetimePicker';
import DateFormatted from '../../helpers/DateFormatted';
import ErrorFocus from '../ErrorFocus';
import MarkForm from '../Form';
import Grid from '../Grid';
import Divider from '../Divider';
import Heading from '../Heading';
import Icon from '../Icon';
import User from '../../helpers/User';
import SendEmailHelper from '../../helpers/components/SendEmailHelper';
import SenderSelect from '../Email/SenderSelect';
import BccSelect from '../Email/BccSelect';
import Language from '../../helpers/Language';
import {setOutOfOfficeEmailParam} from '../../actions/OutOfOfficeEmailActions';
import nl2br from 'nl2br';
import EmailMessageVariablesContainerEditor from '../Email/EmailMessageVariablesContainerEditor';

class OutOfOfficeEmail extends Component
{
    render() {
        const {outOfOfficeEmailModel: {outOfOfficeEmailData, settings}, loginManagerModel, isRemovingColleagueContact, errors} = this.props;

        const colleaguesEmails = settings.colleaguesEmails.map(colleagueEmail => ({
            key: colleagueEmail,
            text: colleagueEmail,
            value: colleagueEmail,
            content: (
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={15}>{colleagueEmail}</Grid.Column>
                        <Grid.Column width={1} textAlign={'right'}>
                            {
                                isRemovingColleagueContact === true
                                    ? (
                                        <Icon
                                            inverted
                                            loading
                                            disabled
                                            name="spinner"
                                            color="grey"
                                        />
                                    )
                                    : (
                                        <Icon
                                            inverted
                                            name="delete"
                                            color="grey"
                                        />
                                    )
                            }
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            ),
        }));

        const bccEmails = outOfOfficeEmailData.emailData.hasOwnProperty('bcc') === true
            ? outOfOfficeEmailData.emailData.bcc.map(item => item.value)
            : [];

        return (
            <ErrorFocus>
                <MarkForm onSubmit={e => e.preventDefault()}>
                    <Heading as="h3" className="highlighted">
                        {Translations.getStatic('outOfOfficeDetails')}
                    </Heading>
                    <Grid className="highlighted">
                        <Grid.Row>
                            <Grid.Column width={8}>
                                <MarkForm.Field>
                                    <label>{Translations.getStatic('start')}</label>
                                    <DatetimePicker
                                        defaultValue={DateFormatted.getDateTime(outOfOfficeEmailData.startAt, 'Y-m-d H:i:s')}
                                        error={errors.includes('startAtForCheck') ? Validation.getMessage('date') : false}
                                        placeholder={DateFormatted.getDate(outOfOfficeEmailData.startAt)}
                                        name={'startAt'}
                                        dateInputNameSuffix={''}
                                        dateEnabled={true}
                                        timeEnabled={false}
                                        onChange={date => this.props.setOutOfOfficeEmailParam({
                                            startAt: date,
                                            startAtForCheck: date,
                                        })}
                                    />
                                </MarkForm.Field>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <MarkForm.Field>
                                    <label>{Translations.getStatic('end')}</label>
                                    <DatetimePicker
                                        defaultValue={DateFormatted.getDateTime(outOfOfficeEmailData.endAt, 'Y-m-d H:i:s')}
                                        error={errors.includes('endAtForCheck') ? Validation.getMessage('date') : false}
                                        placeholder={DateFormatted.getDate(outOfOfficeEmailData.endAt)}
                                        name={'endAt'}
                                        dateInputNameSuffix={''}
                                        dateEnabled={true}
                                        timeEnabled={false}
                                        onChange={date => this.props.setOutOfOfficeEmailParam({
                                            endAt: date,
                                            endAtForCheck: date,
                                        })}
                                    />
                                </MarkForm.Field>
                            </Grid.Column>
                        </Grid.Row>
                        {
                            errors.length > 0
                            && errors.indexOf('incorrectDates') > -1
                            && (
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        <div className="prompt label">
                                            {Validation.getMessage('variable', 'outOfOfficeIncorrectDates')}
                                        </div>
                                        <Divider hidden/>
                                    </Grid.Column>
                                </Grid.Row>
                            )
                        }
                    </Grid>
                    <Grid/>
                </MarkForm>

                <MarkForm>
                    <Heading as="h3">{Translations.getStatic('message')}</Heading>
                    <Grid className="padding-top_none">
                        <Grid.Row className="horizontal-row">
                            <Grid.Column computer={4} tablet={16} verticalAlign="middle">
                                <label>{Translations.getStatic('sender')}</label>
                            </Grid.Column>
                            <Grid.Column computer={12} tablet={16} verticalAlign="middle">
                                <SenderSelect
                                    senders={SendEmailHelper.getSenders(settings.companyUsers, loginManagerModel)}
                                    onSetEmailSender={value => this.props.setOutOfOfficeEmailParam({
                                        emailData: {
                                            ...outOfOfficeEmailData.emailData,
                                            senderId: value,
                                        },
                                    })}
                                    senderId={outOfOfficeEmailData.emailData.hasOwnProperty('senderId') === true ? outOfOfficeEmailData.emailData.senderId : User.getUserId(loginManagerModel)}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row className="horizontal-row multiline">
                            <Grid.Column computer={4} tablet={16} verticalAlign="top">
                                <label>{Translations.getStatic('bcc')}</label>
                            </Grid.Column>
                            <Grid.Column computer={12} tablet={16}>
                                <BccSelect
                                    onSetEmailBcc={value => this.props.setOutOfOfficeEmailParam({
                                        emailData: {
                                            ...outOfOfficeEmailData.emailData,
                                            bcc: value,
                                        },
                                    })}
                                    options={colleaguesEmails}
                                    value={bccEmails}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <EmailMessageVariablesContainerEditor
                            errors={errors}
                            emailData={{
                                ...outOfOfficeEmailData.emailData,
                                text: outOfOfficeEmailData.emailData.text.trim() === '' || outOfOfficeEmailData.emailData.text.trim() === '<p></p>'
                                    ? nl2br(Translations.getStatic('outOfOfficeDefaultEmailText', Language.get()))
                                    : outOfOfficeEmailData.emailData.text,
                                subject: outOfOfficeEmailData.emailData.subject.trim() === '' || outOfOfficeEmailData.emailData.subject.trim() === '<p></p>'
                                    ? nl2br(Translations.getStatic('outOfOfficeDefaultEmailSubject', Language.get()))
                                    : outOfOfficeEmailData.emailData.subject,
                            }}
                            settings={settings}
                            onUpdateEmailTemplate={value => this.props.setOutOfOfficeEmailParam({
                                emailData: {
                                    ...outOfOfficeEmailData.emailData,
                                    ...value,
                                },
                            })}
                            languageId={loginManagerModel.languages.find(language => language.languageCode === Language.get()).languageId}
                        />
                    </Grid>
                </MarkForm>
            </ErrorFocus>
        );
    }
}

const mapStateToProps = state => ({
    outOfOfficeEmailModel: state.outOfOfficeEmailModel,
    loginManagerModel: state.loginManagerModel,
    isRemovingColleagueContact: state.isRemovingColleagueContact,
});

const mapDispatchToProps = {
    setOutOfOfficeEmailParam,
};

export default connect(mapStateToProps, mapDispatchToProps)(OutOfOfficeEmail);
