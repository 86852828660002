import Translations from '../Translations';
import Constants from '../Constants';

export default class CandidatesListColumnsOrderHelper
{
    static getDefaultColumnsOrder(listType = Constants.CANDIDATES_LIST_TYPE_JOB_DETAIL) {
        switch (listType) {
            case Constants.CANDIDATES_LIST_TYPE_GLOBAL_SEARCH:
                return [
                    'name',
                    'position',
                    'stage',
                    'hasDuplicities',
                    'addDate',
                    'source',
                    'lastEmail',
                    'score',
                    'tags',
                    'note',
                    'colleaguesNotes',
                    'gdprAgreement',
                    'attachments',
                    'expectedSalary',
                    'disposableDate',
                    'refusalReason',
                ];

            default:
                return [
                    'name',
                    'stage',
                    'hasDuplicities',
                    'addDate',
                    'source',
                    'lastEmail',
                    'score',
                    'tags',
                    'note',
                    'colleaguesNotes',
                    'gdprAgreement',
                    'attachments',
                    'expectedSalary',
                    'disposableDate',
                    'refusalReason',
                ];
        }
    }

    static getExternalVisitorColumns() {
        return [
            'name',
            'source',
            'addDate',
            'stage',
            'score',
            'externalUsersNotes',
        ];
    }

    static getColumns(userColumnsOrder, defaultColumnsOrder, isExternalVisitor = false, isEditMode = false) {
        if (isExternalVisitor === true) {
            return this.getExternalVisitorColumns();
        }

        const columns = isExternalVisitor === true || isEditMode ? [] : ['checkbox'];

        userColumnsOrder.forEach(userColumn => {
            const index = defaultColumnsOrder.indexOf(userColumn);

            if (index > -1) {
                columns.push(userColumn);
                defaultColumnsOrder.splice(index, 1);
            }
        });

        defaultColumnsOrder.forEach(defaultColumn => {
            columns.push(defaultColumn);
        });

        return columns;
    }

    static getColumnName(key) {
        const names = {
            name: Translations.getStatic('nameAndSurname'),
            position: Translations.getStatic('positionName'),
            stage: Translations.getStatic('selectionProcessStep'),
            hasDuplicities: Translations.getStatic('duplicity'),
            addDate: Translations.getStatic('dateOfReaction'),
            source: Translations.getStatic('source'),
            lastEmail: Translations.getStatic('candidateCommunication'),
            score: Translations.getStatic('questionnaire'),
            tags: Translations.getStatic('tags'),
            note: Translations.getStatic('notes'),
            colleaguesNotes: Translations.getStatic('colleaguesNotes'),
            attachments: Translations.getStatic('attachmentsCount'),
            gdprAgreement: Translations.getStatic('gdprAgreement'),
            expectedSalary: Translations.getStatic('expectedSalary'),
            disposableDate: Translations.getStatic('disposableDate'),
            refusalReason: Translations.getStatic('refusalReason'),
        };

        return names.hasOwnProperty(key) === true
            ? names[key]
            : key;
    }

}
