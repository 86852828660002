import {useEffect, useRef} from 'react';

class Hooks {
    static useSkipFirstRender(callback: () => void, dependencies: any[]) {
        const firstRenderRef = useRef<boolean>(true);

        useEffect(() => {
            if (firstRenderRef.current) {
                firstRenderRef.current = false;
            } else {
                callback();
            }
        }, dependencies);
    }
}

export default Hooks;
