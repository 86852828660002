import React from 'react';
import PropTypes from 'prop-types';
import {Radio} from 'semantic-ui-react';
import {InputField} from '@profesia/adamui/components/common/input';
import Select from '@profesia/adamui/components/common/dropdown/SelectDefault';
import Button from '../../Button';
import Grid from '../../Grid';
import Translations from '../../../helpers/Translations';
import QuestionnaireEditorAnswersQuestion from './QuestionnaireEditorAnswersQuestion';
import QuestionnaireHelper from '../../../helpers/components/QuestionnaireHelper';
import Constants from '../../../helpers/Constants';

const QuestionnaireEditorOneAnswerQuestion = props => {
    const answers = props.question.hasOwnProperty('answers') === true
        && props.question.answers.map((answer, answerIndex) => {
                const scoreDefaultValue = QuestionnaireHelper.getDefaultScoreValue(answer);

                return (
                    <Grid.Row key={props.index + '.' + answerIndex} className='questionnaire-box__row'>
                        {
                            props.displaySize.isMobile === true
                            || <Grid.Column width={1}/>
                        }
                        <Grid.Column computer={1} tablet={2} textAlign='right' verticalAlign='middle'>
                            <Radio disabled/>
                        </Grid.Column>
                        <Grid.Column computer={9} tablet={8} verticalAlign='middle'>
                            <InputField
                                maxLength={5000}
                                value={answer.answer}
                                placeholder={Translations.getStatic('questionTypeOneInputPlaceholder')}
                                onChange={(e) => props.onUpdateAnswerItem(props.index, answerIndex, {
                                    answer: e.target.value,
                                })}
                            />
                        </Grid.Column>
                        <Grid.Column width={4} verticalAlign='middle'>
                            <Select
                                className="form__select form__select_size_m fluid selection MuiSelect-light"
                                options={QuestionnaireHelper.getAnswerScorePoints().map(scoreOption => scoreOption.id === Constants.QUESTIONNAIRE_ANSWER_VALUE_REQUIRED && scoreDefaultValue !== Constants.QUESTIONNAIRE_ANSWER_VALUE_REQUIRED && props.question.answers.find(answer => answer.hasOwnProperty('isRequired') === true && answer.isRequired === true) !== undefined
                                    ? {
                                        ...scoreOption,
                                        disabled: true,
                                    }
                                    : scoreOption,
                                )}
                                value={scoreDefaultValue}
                                onChange={(value) => props.onUpdateAnswerItem(props.index, answerIndex, {
                                    score: value,
                                })}
                            />
                        </Grid.Column>
                        <Grid.Column width={1} verticalAlign='middle' textAlign='right'>
                            <Button className='mini' icon={{className: 'button icon-close-small'}} onClick={() => props.onDeleteAnswerItem(props.index, answerIndex)}/>
                        </Grid.Column>
                    </Grid.Row>
                );
            },
        );

    return (
        <QuestionnaireEditorAnswersQuestion
            answers={answers}
            question={props.question}
            index={props.index}
            first={props.first}
            last={props.last}
            onDeleteQuestionItem={props.onDeleteQuestionItem}
            onUpdateQuestionItem={props.onUpdateQuestionItem}
            onAddAnswerItem={props.onAddAnswerItem}
            onReorderQuestionItem={props.onReorderQuestionItem}
        />
    );
};

QuestionnaireEditorOneAnswerQuestion.propTypes = {
    question: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    first: PropTypes.bool.isRequired,
    last: PropTypes.bool.isRequired,
    onDeleteQuestionItem: PropTypes.func.isRequired,
    onUpdateQuestionItem: PropTypes.func.isRequired,
    onAddAnswerItem: PropTypes.func.isRequired,
    onDeleteAnswerItem: PropTypes.func.isRequired,
    onUpdateAnswerItem: PropTypes.func.isRequired,
    onReorderQuestionItem: PropTypes.func.isRequired,
};

export default QuestionnaireEditorOneAnswerQuestion;
