import React from 'react';
import Translations from '../../../helpers/Translations';
import MarkDialog from '../../Modal/MarkDialog';
import {connect} from 'react-redux';
import {closeDashboardNewsTestingNewsletterDialog} from '../../../actions/DialogActions';
import {updateUserTesting} from '../../../actions/UserTestingActions';
import WithRouterProp from '../../../helpers/WithRouterProp';
import SuccessMessage from '../../../helpers/SuccessMessage';

class DashboardNewsTestingNewsletterDialog extends React.Component
{
    state = {
        hasActiveNewsletter: false,
    };

    componentDidUpdate = prevProps => {
        if ((SuccessMessage.canShowSuccessMessage(prevProps, this.props, 'isUpdatingUserTesting'))
            && this.props.userTestingModel.hasOwnProperty('isSuccess') === true
            && this.props.userTestingModel.isSuccess === true
        ) {
            this.props.closeDashboardNewsTestingNewsletterDialog();
        }
    };

    updateUserTesting = () => {
        let formData = new FormData();

        formData.append('id', this.props.userTestingModel.userTesting.id);
        formData.append('hasActiveNewsletter', this.state.hasActiveNewsletter === true ? '1' : '0');
        formData.append('testingData', JSON.stringify(this.props.userTestingModel.userTesting.testingData));

        this.props.updateUserTesting(formData);
        this.props.closeDashboardNewsTestingNewsletterDialog();
    };

    render() {
        const {dashboardNewsTestingNewsletterDialog: {open}, isUpdatingUserTesting} = this.props;

        if (open === false) {
            return null;
        }

        return (
            <MarkDialog
                closeOnDimmerClick={false}
                message={{
                    iconName: 'icon-dialog-question',
                    title: Translations.getStatic('dashboardNewsTestingNewsletterDialogTitle'),
                    infoLines: [Translations.getStatic('dashboardNewsTestingNewsletterDialogText')],
                    checkbox: {
                        show: true,
                        label: Translations.getStatic('userTestingInformMe'),
                        checked: this.state.hasActiveNewsletter,
                        onChange: () => {
                            this.setState({
                                hasActiveNewsletter: !this.state.hasActiveNewsletter,
                            });
                        },
                    },
                }}

                actions={[
                    {
                        title: Translations.getStatic('userTestingContinue'),
                        action: () => this.updateUserTesting(),
                        loading: isUpdatingUserTesting === true,
                        disabled: isUpdatingUserTesting === true,
                    },
                ]}
            />
        );
    }
}

const mapStateToProps = state => ({
    dashboardNewsTestingNewsletterDialog: state.dashboardNewsTestingNewsletterDialog,
    userTestingModel: state.userTestingModel,
    isUpdatingUserTesting: state.isUpdatingUserTesting,
});

const mapDispatchToProps = {
    closeDashboardNewsTestingNewsletterDialog,
    updateUserTesting,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(WithRouterProp(DashboardNewsTestingNewsletterDialog));


