import React, {Component} from 'react';
import {connect} from 'react-redux';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {toast} from 'react-semantic-toasts';
import MarkForm from '../Form'
import {InputField} from '@profesia/adamui/components/common/input';
import {Textarea} from '@profesia/adamui/components/common/input';
import Button from '../Button';
import Message from '../Message';
import Grid from '../Grid';
import Divider from '../Divider';
import Icon from '../Icon';
import MarkModal from '../Modal/MarkModal';
import RadioToggle from '../RadioToggle';
import {closeModal} from '../../actions/ModalActions';
import {activateCompanyExport, deactivateCompanyExport, setCompanyPushExportUrl, sendPushExportTestReaction} from '../../actions/CompanySettingsActions';
import Translations from '../../helpers/Translations';
import Form from '../../helpers/Form';
import NotificationMessage from '../NotificationMessage';
import Validation from '../../helpers/Validation';
import Constants from '../../helpers/Constants';

class CompanyExportJobsSettingsModal extends Component
{
    state = {
        errors: {},
        companyPushExportUrl: this.props.companySettingsModel.companyPushExportUrl || '',
        isActivateCompanyExport: this.props.companySettingsModel.companyExportJobsListLink !== null,
    };

    showToastMessage = () => {
        toast({
            type: 'info',
            color: 'green',
            title: '',
            description: (
                <div className="toast-message-wrapper">
                    <div className="toast-message">{Translations.getStatic("linkWasCopiedSuccessMessage")}</div>
                </div>
            ),
            animation: 'fade left',
            time: Constants.WEBSOCKET_TIME_CLOSE_AUTOMATICALLY,
            size: 'small',
        });
    };

    onCopyToClipboard = () => {
        if (this.props.companySettingsModel.companyExportJobsListLink === null) {
            return;
        }

        this.showToastMessage();
    }

    checkCompanyPushExportUrl = (companyPushExportUrl = null) => {
        if (companyPushExportUrl === null) {
            companyPushExportUrl = this.state.companyPushExportUrl;
        }

        const error = companyPushExportUrl.length !== 0
            && Form.check({
                fieldType: 'url',
                fieldValue: companyPushExportUrl,
            }) === false;

        this.setState({
            errors: {
                ...this.state.errors,
                companyPushExportUrl: error,
            },
        });

        return error;
    };

    handleToggleChange = checked => {
        if (checked === true) {
            this.props.activateCompanyExport();
        } else {
            this.props.deactivateCompanyExport();
        }

        this.setState({isActivateCompanyExport: checked});
    }

    handleOnClick = () => {
        if (this.state.companyPushExportUrl !== this.props.companySettingsModel.companyPushExportUrl) {
            const error = this.checkCompanyPushExportUrl();

            if (error === false) {
                const formData = new FormData();

                formData.append('companyPushExportUrl', this.state.companyPushExportUrl);

                this.props.setCompanyPushExportUrl(formData);
            }
        }
    };

    sendPushExportTestReaction = () => {
        const error = this.checkCompanyPushExportUrl();

        if (error === false) {
            const formData = new FormData();

            formData.append('companyPushExportUrl', this.state.companyPushExportUrl);

            this.props.sendPushExportTestReaction(formData);
        }
    };

    handleCompanyPushExportUrlOnChange = e => {
        this.setState({
            companyPushExportUrl: e.target.value || '',
        });

        this.checkCompanyPushExportUrl(e.target.value);
    };

    render() {
        const {isOpen, companySettingsModel, isModifyingServices, isFetchingCompanyExportModel} = this.props;

        const pushExportUrlError = this.state.errors.hasOwnProperty('companyPushExportUrl') === true && this.state.errors.companyPushExportUrl === true;

        return (
            
            <MarkModal
                title={Translations.getStatic('settingsForExportingJobsList')}
                isOpen={isOpen}
                closeModal={this.props.closeModal}
                isLoadingData={false}

                actionButton={{
                    text: Translations.getStatic('save'),
                    visible: this.state.companyPushExportUrl !== companySettingsModel.companyPushExportUrl,
                    loading: isModifyingServices,
                    onClick: this.handleOnClick,
                }}

                content={
                    <MarkForm>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <NotificationMessage
                                        title={Translations.getStatic('exportYourEvents')}
                                        description={Translations.getStatic('apiHelp')}
                                    />
                                    <Divider hidden className="size_12"/>
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Column>
                                    <div className="flex justify between align center">
                                        <h3 className="txt-blue txt-regular">{Translations.getStatic('linkForJobsList')}</h3>
                                        <RadioToggle
                                            loading={isFetchingCompanyExportModel}
                                            label={this.state.isActivateCompanyExport === true ? Translations.getStatic('turnedOn') : Translations.getStatic('turnedOff')}
                                            className={this.props.displaySize.isMobile === true ? '' : 'float-right'}
                                            checked={this.state.isActivateCompanyExport === true}
                                            onChange={(e, {checked}) => this.handleToggleChange(checked)}
                                        />
                                    </div>
                                    <Divider hidden className="size_12"/>
                                    <Grid>
                                        <Grid.Column width="13">
                                            <InputField
                                                className="full field"
                                                disabled={true}
                                                value={companySettingsModel.companyExportJobsListLink === null ? '' : companySettingsModel.companyExportJobsListLink}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width="3" textAlign="right">
                                            <CopyToClipboard
                                                text={companySettingsModel.companyExportJobsListLink} 
                                                onCopy={this.onCopyToClipboard}
                                            >
                                                <Button disabled={companySettingsModel.companyExportJobsListLink === null} className="secondary-button mini">
                                                    {Translations.getStatic('copy')}
                                                </Button>
                                            </CopyToClipboard>
                                        </Grid.Column>
                                    </Grid>
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Column>
                                    <Divider className="size_24 multiple"/>
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Column>
                                    <div className="flex justify between align center">
                                        <h3 className="txt-blue txt-regular">{Translations.getStatic('linkForPushExportUrl')}</h3>
                                    </div>
                                    <Divider hidden className="size_12"/>
                                    <MarkForm.Field className={'no-space-bottom'}>
                                        <InputField
                                            className="full field no-space-bottom"
                                            value={this.state.companyPushExportUrl}
                                            onChange={this.handleCompanyPushExportUrlOnChange}
                                            disabled={isModifyingServices}
                                            error={pushExportUrlError}
                                        />

                                        {
                                            pushExportUrlError === true
                                            && (
                                                <Message icon negative>
                                                    <Icon className="icon-exclamation" size="mini"/>
                                                    <Message.Content>
                                                        {Validation.getMessage('url')}
                                                    </Message.Content>
                                                </Message>
                                            )
                                        }
                                    </MarkForm.Field>

                                    <Button
                                        className="primary-button"
                                        onClick={this.sendPushExportTestReaction}
                                        disabled={isModifyingServices || pushExportUrlError}
                                        loading={isModifyingServices}
                                    >
                                        {Translations.getStatic('sendTestReaction')}
                                    </Button>

                                    {
                                        companySettingsModel.hasOwnProperty('testReactionResponse') === true
                                        && companySettingsModel.testReactionResponse !== null
                                        && (
                                            <>
                                                <Divider hidden className="size_16"/>
                                                <div className="flex justify between align center">
                                                    <h3 className="txt-blue txt-regular">{Translations.getStatic('response')}</h3>
                                                </div>
                                                <Divider hidden className="size_12"/>
                                                <Textarea
                                                    value={JSON.stringify(companySettingsModel.testReactionResponse, undefined, 4)}
                                                    disabled
                                                    minRows={7}
                                                />
                                            </>
                                        )
                                    }
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </MarkForm>
                }
            />
        );
    }
}

const mapStateToProps = state => ({
    isOpen: state.modalLogic.isOpen,
    displaySize: state.displaySize,
    companySettingsModel: state.companySettingsModel,
    isModifyingServices: state.isModifyingServices,
    isFetchingCompanyExportModel: state.isFetchingCompanyExportModel,
});

const mapDispatchToProps = {
    closeModal,
    setCompanyPushExportUrl,
    activateCompanyExport,
    deactivateCompanyExport,
    sendPushExportTestReaction,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CompanyExportJobsSettingsModal);
