import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Dropdown} from 'semantic-ui-react';
import Item from '../../Item';
import Icon from '../../Icon';
import {candidateAddTag, candidateRemoveTag, requestCandidateHistory} from '../../../actions/CandidateActions';
import PropTypes from 'prop-types';
import Translations from '../../../helpers/Translations';
import Arrays from '../../../helpers/Arrays';
import {openNotAllowedFeatureDialog} from '../../../actions/DialogActions';
import Validation from '../../../helpers/Validation';
import Track from '../../../helpers/Track';
import {TRACK_LOCATION_DETAIL, TRACK_NAME_TAG} from '../../../helpers/ConstantsTrack';

class CandidateSidebarTags extends Component
{
    state = {
        customTag: '',
        disabled: false,
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.isFetchingCandidateTag === true && this.props.isFetchingCandidateTag === false) {
            this.setState({
                customTag: '',
            });
        }
    }

    handleCustomTag = data => {
        if (this.props.isBulk === true) {
            const customTag = data.searchQuery.substring(0, 128);

            this.setState({
                customTag,
            });
        } else {
            const customTag = data.searchQuery.substring(0, 128);

            this.setState({
                customTag,
            });
        }
    };

    handleChangeCandidateTag = (e, data) => {
        if (this.props.isBulk === true) {
            if (e.target.classList.contains('delete') === true) {
                if (this.props.hasOwnProperty('onRemoveTag') === true) {
                    const tagIdValue = e.target.parentElement.getAttribute('value');

                    const tagId = isNaN(parseInt(tagIdValue)) === true ? tagIdValue : parseInt(tagIdValue);

                    this.props.onRemoveTag(tagId);
                }
            } else if (e.code === 'Backspace') {
                if (this.props.hasOwnProperty('onRemoveTag') === true) {
                    this.props.onRemoveTag(this.props.tags[this.props.tags.length - 1].tagId);
                }
            } else {
                if (this.props.hasOwnProperty('onAddTag') === true) {
                    let tag = data.options.find(tag => tag.value === data.value[data.value.length - 1]);

                    if (tag === undefined) {
                        if (this.state.customTag === '') {
                            tag = null;
                        } else {
                            tag = {
                                value: this.state.customTag,
                                text: this.state.customTag,
                            };
                        }
                    }

                    if (tag !== null) {
                        this.props.onAddTag({
                            tagId: tag.value,
                            tagName: tag.text,
                        });

                        this.setState({
                            customTag: '',
                        });
                    }
                }
            }
        } else {
            const formData = new FormData();

            if (e.target.classList.contains('delete') === true) {
                formData.append('candidateId', this.props.candidateId);
                formData.append('tagId', parseInt(e.target.parentElement.getAttribute('value')));

                this.props.candidateRemoveTag(formData, this.props.candidateId);
            } else if (e.code === 'Backspace') {
                formData.append('candidateId', this.props.candidateId);
                formData.append('tagId', this.props.tags[this.props.tags.length - 1].tagId);

                this.props.candidateRemoveTag(formData, this.props.candidateId);
            } else {
                formData.append('candidateIds', JSON.stringify([this.props.candidateId]));
                formData.append('tags', JSON.stringify([data.options.find(tag => tag.value === data.value[data.value.length - 1]).text]));

                this.props.candidateAddTag([this.props.candidateId], formData);
            }
        }
    };

    render() {
        const {tags, isFetchingCandidateTag, isReadOnly, errors, isBulk} = this.props;

        let {companyTags} = this.props;

        let customTagExists = true;

        if (this.state.customTag !== '') {
            customTagExists = companyTags.find(tag => tag.tagName === this.state.customTag) !== undefined;

            if (customTagExists === false) {
                companyTags = Arrays.prepend({
                    tagId: this.state.customTag,
                    tagName: this.state.customTag,
                }, companyTags);
            }
        }

        const errorMessage = errors !== undefined && errors.includes('candidateTags') === true ? Validation.getMessage('candidateTags') : '';

        return (
            <div className={'ui items unstackable' + (isBulk === true ? ' add-tags-bulk' : '')}>
                <Item>
                    <Icon className="icon-tags_small" size="mini"/>
                    <Item.Content>
                        {tags.length > 0 && <Item.Header>{Translations.getStatic('tags')}</Item.Header>}

                        <Dropdown
                            className={isReadOnly ? 'hideDeleteIcon' : ''}
                            search fluid multiple icon=""
                            loading={isFetchingCandidateTag}
                            disabled={isFetchingCandidateTag || isReadOnly || this.state.disabled}

                            options={
                                companyTags.map(option => ({
                                    key: option.tagId,
                                    value: option.tagId,
                                    text: option.tagName,
                                }))
                            }

                            searchQuery={this.state.customTag}
                            placeholder={Translations.getStatic('addTag')}
                            value={tags.filter(tag => tag.tagId !== null).map(tag => tag.tagId)}
                            noResultsMessage={Translations.getStatic('tagNotFound')}
                            onChange={(e, data) => this.handleChangeCandidateTag(e, data)}
                            onSearchChange={(_e, data) => this.handleCustomTag(data)}
                            onClose={() => this.setState({customTag: ''})}
                            onOpen={() => tags.length === 0 ? Track.event(TRACK_LOCATION_DETAIL, TRACK_NAME_TAG) : null}
                        />
                    </Item.Content>
                </Item>

                {
                    isBulk === true
                    && errorMessage !== ''
                    && (
                        <Item className="prompt label">{errorMessage}</Item>
                    )
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    isFetchingCandidateTag: state.isFetchingCandidateTag,
    loginManagerModel: state.loginManagerModel,
});

const mapDispatchToProps = {
    candidateAddTag,
    candidateRemoveTag,
    requestCandidateHistory,
    openNotAllowedFeatureDialog,
};

CandidateSidebarTags.propTypes = {
    isFetchingCandidateTag: PropTypes.bool.isRequired,
    tags: PropTypes.array.isRequired,
    candidateId: PropTypes.number.isRequired,
    isReadOnly: PropTypes.bool.isRequired,
};

CandidateSidebarTags.default = {
    isBulk: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateSidebarTags);
