import React from 'react';
import {connect} from 'react-redux';
import MarkForm from './Form';
import Link from '../helpers/overridden/Link';
import NavBar from './WebSite/nav/NavBar/NavBar';
import {InputField} from '@profesia/adamui/components/common/input';
import FooterComponent from './WebSite/nav/Footer/Footer';
import Translations from '../helpers/Translations';
import Authentication from '../helpers/Authentication';
import Url from '../helpers/Url';
import Validation from '../helpers/Validation';
import Form, {FormFieldTypeEnum} from '../helpers/Form';
import './../../less/markWeb.less';
import {requestHeader} from '../actions/HeaderActions';
import LoadingComponent from './LoadingComponent';
import WithRouterProp from '../helpers/WithRouterProp';

class ExternalUserLoginPage extends React.Component
{
    state = {
        email: this.props.params.hasOwnProperty('email') === false || this.props.params.email === undefined ? '' : this.props.params.email,
        checkEmailValidation: this.props.params.hasOwnProperty('email') === true && this.props.params.email !== undefined ? 'loginFailed' : '',
        externalUserAuthLogin: this.props.params.externalUserAuthLogin,
    };

    componentDidMount = () => {
        Authentication.removeAuthenticationTokens();

        if (this.props.headerModel.hasOwnProperty('menu') === false) {
            this.props.requestHeader();
        }
    };

    updateEmail = email => {
        this.setState({
            email,
        });
    };

    login = () => {
        const {externalUserAuthLogin, email} = this.state;

        const check = Form.check({
            fieldValue: email,
            fieldType: FormFieldTypeEnum.email,
        });

        this.setState({
            checkEmailValidation: check === true ? '' : 'email',
        });

        if (check === true) {
            Url.redirectTo(Url.build('login') + '?externalUserAuthLogin=' + externalUserAuthLogin + '&email=' + email);
        }
    };

    render = () => {
        if (this.props.isFetchingHeader === true) {
            return <LoadingComponent/>;
        }

        const {email, checkEmailValidation} = this.state;

        return (
            <div className="sub-page">
                <div className="header-section">
                    <NavBar history={this.props.history} isSubPage={true}/>
                </div>
                <div className="main-section">
                    <div className="row align center">
                        <div className="header sub-header small header-full-width">
                            <h1 className="header__text">{Translations.getStatic('welcomeFormal')}</h1>
                        </div>
                    </div>
                    <div className="row align center">
                        <div className="col col-8">
                            <p className="txt-center">{Translations.getStatic('externalUserInvitationMessage')}</p>
                            <div className="gap medium"/>
                            <MarkForm>
                                <MarkForm.Field error={checkEmailValidation === '' ? false : Validation.getMessage(checkEmailValidation)}>
                                    <InputField
                                        id={'email'}
                                        maxLength={128}
                                        label={Translations.getStatic('email')}
                                        value={email}
                                        onChange={e => this.updateEmail(e.target.value)}
                                    />
                                </MarkForm.Field>
                                <MarkForm.Field className="txt-center">
                                    <Link className="btn btn-primary" onClick={this.login}>{Translations.getStatic('enter')}</Link>
                                </MarkForm.Field>
                            </MarkForm>
                        </div>
                    </div>
                    <div className="gap large"/>
                    <div className="gap large"/>
                </div>

                <FooterComponent/>
            </div>
        );
    };
}

const mapStateToProps = state => ({
    isFetchingHeader: state.isFetchingHeader,
    headerModel: state.headerModel,
});

const mapDispatchToProps = {
    requestHeader,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(WithRouterProp(ExternalUserLoginPage));
