import React from 'react';
import {connect} from 'react-redux';
import Authentication from '../helpers/Authentication';
import Url from '../helpers/Url';
import {checkLogin} from '../actions/LoginActions';
import User from '../helpers/User';
import Skeletons from '../helpers/Skeletons';
import loginManagerModelSkeleton from '../skeletons/models/loginManagerModelSkeleton.json';
import {Navigate} from 'react-router-dom';
import SuspenseFallbackSkeleton from '../skeletons/components/SuspenseFallbackSkeleton';

class LoginPage extends React.Component
{
    componentDidMount = () => {
        Authentication.removeAuthenticationTokens();

        const userId                = Url.getQueryStringParameter('userId');
        const authLogin             = Url.getQueryStringParameter('authLogin');
        const adminId               = Url.getQueryStringParameter('adminId');
        const email                 = Url.getQueryStringParameter('email');
        const externalUserAuthLogin = Url.getQueryStringParameter('externalUserAuthLogin');

        this.props.checkLogin(userId, authLogin, adminId, email, externalUserAuthLogin);
    };

    render = () => {
        const {loginState, loginManagerModel} = this.props;

        if (loginState === false) {
            return <SuspenseFallbackSkeleton/>;
        } else if (loginState === true) {
            let redirectTo = decodeURIComponent(Url.getQueryStringParameter('redirectTo'));

            if (redirectTo.indexOf('mark/trial') > -1) {
                redirectTo = Url.build('?utm_source=candidates_list&utm_medium=banner&utm_campaign=banner_trial');
            } else if (redirectTo === '') {
                if (Url.getQueryStringParameter('email') !== '') {
                    return null;
                }

                redirectTo = Url.build('dashboard', {}, 'auto', false, true);
            } else {
                if (Url.isLocal() === true && window.location.host.startsWith('local.') === true) {
                    redirectTo = window.location.href.replace(/(.+\/\/).+(.profesia)/, '$1mark.local$2');
                } else if (Url.isLocal() === false && Url.getQueryStringParameter('local') === '1') {
                    if (redirectTo.startsWith('/') === true) {
                        redirectTo = window.location.href.replace(/(.+\/\/).+(.profesia)/, '$1mark.local$2');
                    }
                } else if (Url.isJobLink(redirectTo) === true) {
                    redirectTo = Url.buildJobLink(Url.getUrlParameter('job', redirectTo), User.getDefaultJobViewId(loginManagerModel), Url.getUrlParameter('action', redirectTo));
                }
            }

            if ((
                    redirectTo.startsWith('/mark') === true
                    && redirectTo.startsWith('/mark/help') === false
                )
                || redirectTo.startsWith('/dashboard') === true
            ) {
                return <Navigate to={redirectTo} replace={true}/>;
            } else {
                Url.redirectTo(redirectTo, true);

                return null;
            }
        }

        Url.redirectTo(Url.build('logout'), true);

        return null;
    };
}

const mapStateToProps = state => ({
    loginState: state.loginState,
    loginManagerModel: Skeletons.getData(state.loginManagerModel, loginManagerModelSkeleton),
});

const mapDispatchToProps = {
    checkLogin,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LoginPage);
