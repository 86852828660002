export const UPDATE_CHUNK_STATUS = 'UPDATE_CHUNK_STATUS';
export const RESET_CHUNK_STATUS  = 'RESET_CHUNK_STATUS';

export const updateChunkStatus = (processedChunksCount, totalChunksCount) => ({
    type: UPDATE_CHUNK_STATUS,

    payload: {
        processedChunksCount,
        totalChunksCount,
        done: processedChunksCount === totalChunksCount,
    },
});

export const resetChunkStatus = () => ({
    type: RESET_CHUNK_STATUS,

    payload: {
        processedChunksCount: 0,
        totalChunksCount: 1,
        done: false,
    },
});
