import React from 'react';
import {connect} from 'react-redux';
import Button from '../Button';
import Image from '../Image';
import CandidateInboxItem from './CandidateInboxItem';
import LoadingComponent from '../LoadingComponent';
import {openModal} from '../../actions/ModalActions';
import Constants from '../../helpers/Constants';
import Translations from '../../helpers/Translations';
import sendEmailImage from '../../../img/icons/send-email-340x340.svg';

const CandidateInbox = props =>
{
    const {inbox, candidateId, isFetchingCandidateInbox, openModal} = props;

    if (inbox === undefined || isFetchingCandidateInbox === true) {
        return <LoadingComponent/>;
    }

    return (
        <div className="inbox-list">
            {
                inbox.messages.length > 0
                    ? inbox.messages.map((message, i) => <CandidateInboxItem key={i} message={message}/>)
                    : (
                        <div className="flex column align justify center height_full">
                            <Image size='massive' src={sendEmailImage}/>
                            <h3 className="txt-medium mb-2">{Translations.getStatic('noCandidateCommunication')}</h3>
                            <Button
                                className="secondary-button"
                                onClick={() =>
                                    openModal("SendEmailModal", {
                                        candidateIds: [candidateId],
                                        emailType: Constants.EMAIL_TYPE_TO_CANDIDATE,
                                    })
                                }
                            >
                                {Translations.getStatic('sendEmailToCandidate')}
                            </Button>
                        </div>
                    )

            }
        </div>
    );
}

const mapStateToProps = state => ({
    inbox: state.candidateModel.fullDetail.inbox,
    isFetchingCandidateInbox: state.isFetchingCandidateInbox,
});

const mapDispatchToProps = {
    openModal,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CandidateInbox);
