import Constants from '../Constants';

export default class CandidateFilterHelper
{
    static convertScoreRangeIntoValue(scoreRange) {
        return scoreRange === 0
            ? scoreRange
            : scoreRange / 100;
    }

    static isFilterSameAsPrevious(candidatesFilter, payload) {
        if (candidatesFilter === undefined) {
            return false;
        }

        if (candidatesFilter.previousFilter === undefined) {
            return false;
        }

        const previousFilter = {...candidatesFilter.previousFilter};
        const newFilter      = {...candidatesFilter, ...payload};

        let result = true;

        Object.keys(newFilter).forEach(key => {
            switch (key) {
                case 'searchText':
                    if (newFilter.searchText !== previousFilter.searchText) {
                        result = false;
                    }
                    break;

                case 'scoreRange':
                    if (newFilter.scoreRange !== previousFilter.scoreRange) {
                        result = false;
                    }
                    break;

                case 'tagList':
                    const activeNewTags      = newFilter.tagList.filter(tag => tag.active === true);
                    const activePreviousTags = previousFilter.tagList.filter(tag => tag.active === true);

                    if (newFilter.tagList.length !== previousFilter.tagList.length || activeNewTags.length !== activePreviousTags.length || activeNewTags.filter(tag => activePreviousTags.find(previousTag => tag.tagId === previousTag.tagId) === undefined).length !== 0) {
                        result = false;
                    }
                    break;

                case 'sourceTypeCollection':
                    if (newFilter.sourceTypeCollection.length !== previousFilter.sourceTypeCollection.length || newFilter.sourceTypeCollection.filter(source => previousFilter.sourceTypeCollection.find(previousSource => previousSource === source) === undefined).length !== 0) {
                        result = false;
                    }
                    break;

                case 'addDate':
                    if (newFilter.addDate.timespan !== previousFilter.addDate.timespan || newFilter.addDate.dateFrom !== previousFilter.addDate.dateFrom || newFilter.addDate.dateTo !== previousFilter.addDate.dateTo) {
                        result = false;
                    }
                    break;

                case 'isNotContacted':
                    if (newFilter.isNotContacted !== previousFilter.isNotContacted) {
                        result = false;
                    }
                    break;

                case 'noGdprAgreement':
                    if (newFilter.noGdprAgreement !== previousFilter.noGdprAgreement) {
                        result = false;
                    }
                    break;

                case 'note':
                    if (newFilter.note !== previousFilter.note) {
                        result = false;
                    }
                    break;
            }
        });

        return result;
    }

    static getDefaultFilter(sourceTypeCollection = [], stageCollectionIds = []) {
        return {
            stageCollectionIds,
            sourceTypeCollection,
            phaseId: Constants.CANDIDATES_LIST_FILTER_BY_PHASE_ID_ALL,
            isRefusedAndNotContacted: false,
            isNotContacted: false,
            isStateActive: true,
            isStatePassive: false,
            isAnonymized: false,
            noGdprAgreement: false,
            addDate: {
                dateFrom: null,
                dateTo: null,
                timespan: Constants.JOBS_LIST_FILTER_BY_ADD_DATE_DEFAULT_VALUE,
            },
            tagList: [],
            candidateIds: [],
            scoreRange: 0,
            note: '',
            searchText: '',
        };
    }
}
