import React from 'react';
import PropTypes from 'prop-types';
import {Editor} from 'react-draft-wysiwyg';
import {EditorState} from 'draft-js';
import Divider from '../Divider';
import CustomToolbar from './CustomToolbar';
import WysiwygHelper from '../../helpers/components/WysiwygHelper';
import Validation from '../../helpers/Validation';
import {WysiwygHandlePaste} from '../../helpers/WysiwygHandlePaste';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import iconItalic from '../../../img/icons/italic-small.svg';
import iconBold from '../../../img/icons/bold-small.svg';
import iconLink from '../../../img/icons/link-wysiwyg-small.svg';
import iconUnlink from '../../../img/icons/unLink-wysiwyg-small.svg';
import Variables from '../../helpers/Variables';

class WysiwygEditor extends React.Component
{
    state = {
        editorState: EditorState.createEmpty(),
        firstLoad: true,
    };

    componentDidMount = () => {
        this.prepareEditorState(this.props);
    };

    componentDidUpdate = prevProps => {
        if (this.state.firstLoad === true || this.props.id !== prevProps.id || this.props.lang !== prevProps.lang || this.props.multipleForwardingAsSingleEmail !== prevProps.multipleForwardingAsSingleEmail) {
            this.prepareEditorState(this.props);
        }
    };

    prepareEditorState = props => {
        this.setState({
            firstLoad: false,

            editorState: props.value === ''
                ? EditorState.createEmpty()
                : WysiwygHelper.createEditorStateFromHtml(props.value, Variables.getEditorVariables(props.variables, false), props.cursorOnStart),
        });
    };

    onEditorStateChange = editorState => {
        this.setState({
            editorState,
        });

        const text = WysiwygHelper.getHtmlFromEditor(editorState);

        if (text !== this.props.value) {
            this.props.onChange(text);
        }
    };

    render() {
        const toolbarConfig = {
            options: ['inline', 'link'],

            inline: {
                options: ['bold', 'italic'],
                bold: {icon: iconBold, className: 'button'},
                italic: {icon: iconItalic, className: 'button'},
            },

            link: {
                inDropdown: false,
                showOpenOptionOnHover: false,
                defaultTargetOption: '_self',
                options: ['link', 'unlink'],
                link: {icon: iconLink, className: 'button'},
                unlink: {icon: iconUnlink, className: 'button'},
            },
        };

        const {editorState} = this.state;

        return (
            <>
                <Editor
                    editorState={editorState}
                    wrapperClassName={'mark-wysiwyg-wrapper ' + (
                        (this.props.error === true && this.props.highlighted === true)
                            ? 'error'
                            : ''
                    )}
                    editorClassName="mark-wysiwyg-editor"
                    toolbarClassName="mark-wysiwyg-toolbar"
                    toolbar={toolbarConfig}
                    toolbarCustomButtons={[
                        <CustomToolbar
                            variables={Variables.getEditorVariables(this.props.variables)}
                        />,
                    ]}
                    customDecorators={WysiwygHelper.getDecorators()}
                    onEditorStateChange={this.onEditorStateChange}
                    handlePastedText={(text, html, editorState, onChange) => {
                        const newEditorState = WysiwygHelper.createEditorStateFromHtml(text, Variables.getEditorVariables(this.props.variables, false));

                        return WysiwygHandlePaste('', '', newEditorState, onChange);
                    }}
                />
                {
                    this.props.error === true
                    && this.props.highlighted === true
                    && (
                        <>
                            <div className="prompt label">
                                {Validation.getMessage('empty', 'emailContent')}
                            </div>
                            <Divider className="hidden size_4"/>
                        </>
                    )
                }
            </>
        );
    }
}

WysiwygEditor.propTypes = {
    id: PropTypes.number.isRequired,
    lang: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    variables: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.bool,
    cursorOnStart: PropTypes.bool,
};

WysiwygEditor.defaultProps = {
    error: false,
    highlighted: false,
    cursorOnStart: false,
};

export default WysiwygEditor;
