import Form from '../Form';

export default class RecommendationTagValidatorHelper
{
    constructor(recommendationTag) {
        this.recommendationTag = recommendationTag;
    }

    checkRecommendationTag(recommendationTags) {
        const fieldsToCheck = [
            {
                fieldName: 'recommendationTag',
                fieldType: 'text',
                fieldValue: this.recommendationTag,
            },
            {
                fieldName: 'recommendationTagUnique',
                fieldType: 'unique',
                fieldValue: this.recommendationTag,
                against: recommendationTags,
            },
        ];

        const errors = Form.multiCheck(fieldsToCheck);

        if (errors.length > 0) {
            throw errors;
        }
    }
}
