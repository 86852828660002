export const REQUEST_QUESTIONNAIRE_EDITOR_MODEL = 'REQUEST_QUESTIONNAIRE_EDITOR_MODEL';
export const RECEIVE_QUESTIONNAIRE_EDITOR_MODEL = 'RECEIVE_QUESTIONNAIRE_EDITOR_MODEL';
export const UPDATE_QUESTIONNAIRE               = 'UPDATE_QUESTIONNAIRE';
export const ADD_QUESTION_ITEM                  = 'ADD_QUESTION_ITEM';
export const DELETE_QUESTION_ITEM               = 'DELETE_QUESTION_ITEM';
export const UPDATE_QUESTION_ITEM               = 'UPDATE_QUESTION_ITEM';
export const ADD_ANSWER_ITEM                    = 'ADD_ANSWER_ITEM';
export const DELETE_ANSWER_ITEM                 = 'DELETE_ANSWER_ITEM';
export const UPDATE_ANSWER_ITEM                 = 'UPDATE_ANSWER_ITEM';
export const REORDER_QUESTION_ITEM              = 'REORDER_QUESTION_ITEM';

export const requestQuestionnaireEditorModel = (questionnaireId = null, copy = false) => ({
    type: REQUEST_QUESTIONNAIRE_EDITOR_MODEL,
    meta: {
        requestRoute: 'v1/questionnaire/model/editor/' + (questionnaireId === null ? 0 : questionnaireId),
        requestData: {
            copy: copy ? 1 : 0,
        },

        onRequestSuccess: RECEIVE_QUESTIONNAIRE_EDITOR_MODEL,
    },
});

export const updateQuestionnaire = (questionnaireData) => ({
    type: UPDATE_QUESTIONNAIRE,
    payload: {
        questionnaireData,
    },
});

export const addQuestionItem = (type) => ({
    type: ADD_QUESTION_ITEM,
    payload: {
        questionType: type,
    },
});

export const deleteQuestionItem = (index) => ({
    type: DELETE_QUESTION_ITEM,
    payload: {
        index,
    },
});

export const addAnswerItem = (questionIndex) => ({
    type: ADD_ANSWER_ITEM,
    payload: {
        questionIndex,
    },
});

export const deleteAnswerItem = (questionIndex, answerIndex) => ({
    type: DELETE_ANSWER_ITEM,
    payload: {
        questionIndex,
        answerIndex,
    },
});

export const updateQuestionItem = (questionIndex, question) => ({
    type: UPDATE_QUESTION_ITEM,
    payload: {
        questionIndex,
        question,
    },
});

export const updateAnswerItem = (questionIndex, answerIndex, answer) => ({
    type: UPDATE_ANSWER_ITEM,
    payload: {
        questionIndex,
        answerIndex,
        answer,
    },
});

export const reorderQuestionItem = (questionIndex, newQuestionIndex) => ({
    type: REORDER_QUESTION_ITEM,
    payload: {
        questionIndex,
        newQuestionIndex,
    },
});
