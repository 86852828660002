import {
    REQUEST_INACTIVE_USERS_MODEL, RECEIVE_INACTIVE_USERS_MODEL,
} from '../actions/InactiveUsersActions';

export const inactiveUsersModel = (state = {}, action) => {
    switch (action.type) {
        case RECEIVE_INACTIVE_USERS_MODEL:
            return {
                ...state,
                ...action.payload,
            };

        default:
            return state;
    }
};

export const isFetchingInactiveUsersModel = (state = false, action) => {
    switch (action.type) {
        case REQUEST_INACTIVE_USERS_MODEL:
            return true;

        case RECEIVE_INACTIVE_USERS_MODEL:
            return false;

        default:
            return state;
    }
};
