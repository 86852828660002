import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Translations from '../../helpers/Translations';
import Validation from '../../helpers/Validation';
import ColleagueEmailsSelect from './ColleagueEmailsSelect';
import CheckForm from '../../helpers/Form';

class RecipientsSelect extends Component
{
    state = {
        incorrectRecipientEmail: '',
    };

    setIncorrectRecipientEmail = email => {
        this.setState({
            incorrectRecipientEmail: email,
        });
    };

    onSetEmailRecipients = data => {
        let selectedEmail;
        let emailsArray = [];

        for (let i = 0; i < data.value.length; i++) {
            selectedEmail = data.options.find(email => email.value === data.value[i]);

            if (selectedEmail !== undefined) {
                emailsArray.push({value: selectedEmail.value, text: selectedEmail.text});
            } else if (CheckForm.check({
                fieldType: 'email',
                fieldValue: data.value[i],
            }) === true) {
                emailsArray.push({value: data.value[i], text: data.value[i]});
            }
        }

        this.props.onSetEmailRecipients(emailsArray);
    };

    render() {
        const {options, value, className, errors, isRecipientsChanged} = this.props;
        const {incorrectRecipientEmail}                                = this.state;

        const getRecipientsError = () => {
            if (Array.isArray(errors) === true && errors.includes('recipients') && value.length === 0 && isRecipientsChanged === false) {
                return Validation.getMessage('empty', 'recipient');
            }

            if (incorrectRecipientEmail !== '') {
                return Translations.getStatic('invalidRecipientEmail').replace(/%email%/g, incorrectRecipientEmail);
            }

            return '';
        };

        return (
            <ColleagueEmailsSelect
                value={value}
                className={className}
                error={getRecipientsError()}
                options={options}
                onChange={data => this.onSetEmailRecipients(data)}
                onSearchChange={() => this.setIncorrectRecipientEmail('')}
                onSetIncorrectEmail={(email) => this.setIncorrectRecipientEmail(email)}
            />
        );
    }
}

RecipientsSelect.propTypes = {
    value: PropTypes.array.isRequired,
    options: PropTypes.array.isRequired,
    isRecipientsChanged: PropTypes.bool.isRequired,
    onSetEmailRecipients: PropTypes.func.isRequired,
    className: PropTypes.string,
    errors: PropTypes.array,
};

RecipientsSelect.defaultProps = {
    className: 'darker darker_small multiple-basic',
    errors: [],
    isRecipientsChanged: false,
};

export default RecipientsSelect;
