import React, {Component} from 'react';
import {connect} from 'react-redux';
import {updateDimensions} from '../actions/DisplaySizeActions';

class UpdateDimensions extends Component 
{
    componentDidMount() {
        this.props.updateDimensions();
        window.addEventListener('resize', this.props.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.props.updateDimensions);
    }

    render() {
        return (
                <>
                    {this.props.children}
                </>
            );
    }
}

const mapDispatchToProps = {
    updateDimensions,
};

export default connect(null, mapDispatchToProps)(UpdateDimensions);
