import {
    WEB_PAGE_SELECT_PACKAGE,
} from '../actions/HomepageActions';

export const selectedPackage = (state = {}, action) => {
    switch (action.type) {
        case WEB_PAGE_SELECT_PACKAGE:
            return {
                ...state,
                ...action.payload.props,
            };
        default:
            return state;
    }
};
