class ToastMessages
{
    static getColorByMessageType(messageType: string): string {
        switch (messageType) {
            case 'success':
                return 'green';

            case 'warning':
                return 'yellow';

            case 'error':
                return 'red';

            case 'info':
                return 'blue';

            default:
                return 'green';
        }
    }

    static getTypeByMessageType(messageType: string): string {
        switch (messageType) {
            case 'success':
            case 'warning':
            case 'error':
            case 'info':
                return messageType;

            default:
                return 'info';
        }
    }
}

export default ToastMessages;
