import React from 'react';

const Item = props => {
    const Tag       = props.as || 'div';
    const className = `${props.className ? props.className + ' ' : ''}`;

    const validProps = {
        ...props,
        as: typeof props.as === 'string' ? props.as : 'div',
    };

    return (
        <Tag {...validProps} className={`item ${className}`}>
            {props.children}
        </Tag>
    );
};

const Description = props => <div {...props} className={`description ${props.className}`}>{props.children}</div>;
const Meta        = props => <div {...props} className={`meta ${props.className}`}>{props.children}</div>;
const Extra       = props => <div {...props} className={`extra ${props.className}`}>{props.children}</div>;

const Content = props => {
    const Tag = props.as || 'div';

    const className = props.className || '';

    return <Tag className={`content ${className} ${props.verticalAlign ? 'aligned ' + props.verticalAlign : ''}`}>{props.children}</Tag>;
};

const Header = props => {
    const Tag = props.as || 'div';

    const validProps = {
        ...props,
        as: typeof props.as === 'string' ? props.as : 'div',
    };

    return <Tag {...validProps} className={`header ${props.className}`}>{props.children}</Tag>;
};

Item.Content     = Content;
Item.Description = Description;
Item.Meta        = Meta;
Item.Extra       = Extra;
Item.Header      = Header;

export default Item;
