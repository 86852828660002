export const REQUEST_PIPELINE_TEMPLATES_SETTINGS_MODEL  = 'REQUEST_PIPELINE_TEMPLATES_SETTINGS_MODEL';
export const RECEIVE_PIPELINE_TEMPLATES_SETTINGS_MODEL  = 'RECEIVE_PIPELINE_TEMPLATES_SETTINGS_MODEL';
export const PROCESS_PIPELINE_TEMPLATE                  = 'PROCESS_PIPELINE_TEMPLATE';
export const PROCESS_PIPELINE_TEMPLATE_SUCCESS          = 'PROCESS_PIPELINE_TEMPLATE_SUCCESS';
export const PROCESS_PIPELINE_TEMPLATE_FAILURE          = 'PROCESS_PIPELINE_TEMPLATE_FAILURE';
export const REMOVE_PIPELINE_TEMPLATE                   = 'REMOVE_PIPELINE_TEMPLATE';
export const REMOVE_PIPELINE_TEMPLATE_SUCCESS           = 'REMOVE_PIPELINE_TEMPLATE_SUCCESS';
export const REMOVE_PIPELINE_TEMPLATE_FAILURE           = 'REMOVE_PIPELINE_TEMPLATE_FAILURE';
export const SET_PIPELINE_TEMPLATE_AS_DEFAULT           = 'SET_PIPELINE_TEMPLATE_AS_DEFAULT';
export const SET_PIPELINE_TEMPLATE_AS_DEFAULT_SUCCESS   = 'SET_PIPELINE_TEMPLATE_AS_DEFAULT_SUCCESS';
export const SET_PIPELINE_TEMPLATE_AS_DEFAULT_FAILURE   = 'SET_PIPELINE_TEMPLATE_AS_DEFAULT_FAILURE';
export const UNSET_PIPELINE_TEMPLATE_AS_DEFAULT         = 'UNSET_PIPELINE_TEMPLATE_AS_DEFAULT';
export const UNSET_PIPELINE_TEMPLATE_AS_DEFAULT_SUCCESS = 'UNSET_PIPELINE_TEMPLATE_AS_DEFAULT_SUCCESS';
export const UNSET_PIPELINE_TEMPLATE_AS_DEFAULT_FAILURE = 'UNSET_PIPELINE_TEMPLATE_AS_DEFAULT_FAILURE';
export const SET_PIPELINE_TO_OPEN_JOBS                  = 'SET_PIPELINE_TO_OPEN_JOBS';
export const SET_PIPELINE_TO_OPEN_JOBS_SUCCESS          = 'SET_PIPELINE_TO_OPEN_JOBS_SUCCESS';
export const SET_PIPELINE_TO_OPEN_JOBS_FAILURE          = 'SET_PIPELINE_TO_OPEN_JOBS_FAILURE';

export const requestPipelineTemplatesSettingsModel = () => ({
    type: REQUEST_PIPELINE_TEMPLATES_SETTINGS_MODEL,

    meta: {
        requestRoute: 'v1/pipeline-template/model/list',
        onRequestSuccess: RECEIVE_PIPELINE_TEMPLATES_SETTINGS_MODEL,
    },
});

export const processPipelineTemplate = (formData, id, savingMode) => ({
    type: PROCESS_PIPELINE_TEMPLATE,

    payload: {
        id,
        savingMode,
    },

    meta: {
        requestRoute: 'v1/pipeline-template/action/process',
        requestMethod: 'POST',
        requestBody: formData,

        onRequestSuccess: PROCESS_PIPELINE_TEMPLATE_SUCCESS,
        onRequestFailure: PROCESS_PIPELINE_TEMPLATE_FAILURE,
    },
});

export const removePipelineTemplate = id => ({
    type: REMOVE_PIPELINE_TEMPLATE,

    payload: {
        id,
    },

    meta: {
        requestRoute: 'v1/pipeline-template/action/archive/' + id,
        requestMethod: 'POST',

        onRequestSuccess: REMOVE_PIPELINE_TEMPLATE_SUCCESS,
        onRequestFailure: REMOVE_PIPELINE_TEMPLATE_FAILURE,
    },
});

export const setPipelineTemplateAsDefault = pipelineTemplateId => ({
    type: SET_PIPELINE_TEMPLATE_AS_DEFAULT,

    payload: {
        pipelineTemplateId,
    },

    meta: {
        requestRoute: 'v1/pipeline-template/action/set-as-default/' + pipelineTemplateId,
        requestMethod: 'POST',

        onRequestSuccess: SET_PIPELINE_TEMPLATE_AS_DEFAULT_SUCCESS,
        onRequestFailure: SET_PIPELINE_TEMPLATE_AS_DEFAULT_FAILURE,
    },
});

export const unsetPipelineTemplateAsDefault = pipelineTemplateId => ({
    type: UNSET_PIPELINE_TEMPLATE_AS_DEFAULT,

    payload: {
        pipelineTemplateId,
    },

    meta: {
        requestRoute: 'v1/pipeline-template/action/unset-as-default/' + pipelineTemplateId,
        requestMethod: 'POST',

        onRequestSuccess: UNSET_PIPELINE_TEMPLATE_AS_DEFAULT_SUCCESS,
        onRequestFailure: UNSET_PIPELINE_TEMPLATE_AS_DEFAULT_FAILURE,
    },
});

export const setPipelineToOpenJobs = formData => ({
    type: SET_PIPELINE_TO_OPEN_JOBS,

    meta: {
        requestRoute: 'v1/pipeline-template/action/set-pipeline-to-open-jobs',
        requestMethod: 'POST',
        requestBody: formData,
        onRequestSuccess: SET_PIPELINE_TO_OPEN_JOBS_SUCCESS,
        onRequestFailure: SET_PIPELINE_TO_OPEN_JOBS_FAILURE,
    },
});


