export const ENABLE_PAGE_TRANSITION    = 'ENABLE_PAGE_TRANSITION';
export const DISABLE_PAGE_TRANSITION   = 'DISABLE_PAGE_TRANSITION';

export const enableTransition = () => ({
    type: ENABLE_PAGE_TRANSITION,
});

export const disableTransition = () => ({
    type: DISABLE_PAGE_TRANSITION,
});
