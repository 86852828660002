import React, {Component} from 'react';
import {connect} from 'react-redux';
import GlobalSearchInput from '../GlobalSearch/GlobalSearchInput';
import PropTypes from 'prop-types';
import {openSidebarSearch, closeSidebarSearch} from '../../actions/SidebarHeaderActions';
import Icon from '../Icon';
import Divider from '../Divider';
import Translations from '../../helpers/Translations';
import WithRouterProp from '../../helpers/WithRouterProp';

class SidebarSearch extends Component
{
    componentDidUpdate(prevProps, prevState, snapShot) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.props.closeSidebarSearch();
        }

        setTimeout(() => {
            if (this.props.isOpenSearch === true) {
                window.addEventListener('click', this.props.closeSidebarSearch);
            } else {
                window.removeEventListener('click', this.props.closeSidebarSearch);
            }
        }, 0);
    }

    render() {
        const {isOpenSearch, className = ''} = this.props;

        return (
            <>
                <div className={['sidebar-search', isOpenSearch === true ? 'sidebar-search-open' : '', className].join(' ')}>
                    <div className="search-wrapper">
                        <div className={'left menu transition extended-list' + (isOpenSearch === true ? ' visible' : '')} onClick={e => e.stopPropagation()}>
                            <ul className="sidebar-search-nav">
                                <h3>{Translations.getStatic('search')}</h3>
                                <Icon className="icon-nav-close pointer" size="small" onClick={this.props.closeSidebarSearch}/>
                            </ul>

                            <Divider/>

                            {isOpenSearch === true && <GlobalSearchInput/>}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => ({
    isOpenSearch: state.sidebarHeaderLogic.isOpenSearch,
});

const mapDispatchToProps = {
    openSidebarSearch,
    closeSidebarSearch,
};

SidebarSearch.propTypes = {
    isOpenSearch: PropTypes.bool,
    openSidebarSearch: PropTypes.func.isRequired,
    closeSidebarSearch: PropTypes.func.isRequired,
    className: PropTypes.string,
    isMobile: PropTypes.bool,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(WithRouterProp(SidebarSearch));
