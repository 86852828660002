import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow';
import Item from './Item';
import CandidateDetailOpenHelper from '../helpers/components/CandidateDetailOpenHelper';
import {useNavigate} from 'react-router-dom';
import Url from '../helpers/Url';

const CandidateDetailOpenHandler = props => {
    const navigate = useNavigate();

    const openDetail = event => {
        if (event.target.type !== 'checkbox'
            && event.target.parentElement.classList.contains('checkbox') !== true
            && event.target.hasAttribute('href') === false
        ) {
            const {candidate, onClick} = props;

            if (CandidateDetailOpenHelper.openCandidateDetail(candidate, {}) === true) {
                if (Url.getUrlParameter('action') !== 'search') {
                    const url = Url.build(Url.getUrlParameter('action'), {
                        job: Url.getUrlParameter('job'),
                        candidate: candidate.id,
                    });

                    navigate(url, {replace: true});
                }

                if (onClick !== undefined) {
                    onClick(event);

                    return true;
                }
            }
        }

        return false;
    };

    const {
              forwardedRef,
              as,
              candidate,
              onClick,
              dispatch,
              ...rest
          } = props;

    switch (as) {
        case 'Item':
            return (
                <Item
                    onClick={e => openDetail(e)}
                    {...rest}
                >
                    {props.children}
                </Item>
            );

        case 'TableRow':
            return (
                <TableRow
                    onClick={e => openDetail(e)}
                    {...rest}
                >
                    {props.children}
                </TableRow>
            );

        case 'span':
            return (
                <span
                    onClick={e => openDetail(e)}
                    {...rest}
                >
                    {props.children}
                </span>
            );

        case 'div':
        default:
            return (
                <div
                    onClick={e => openDetail(e)}
                    ref={forwardedRef}
                    {...rest}
                >
                    {props.children}
                </div>
            );
    }
};

CandidateDetailOpenHandler.defaultProps = {
    as: 'div',
};

CandidateDetailOpenHandler.propTypes = {
    as: PropTypes.string.isRequired,
};

export default connect(null, null, null, {forwardRef: true})(React.forwardRef((props, ref) => {
    return <CandidateDetailOpenHandler {...props} forwardedRef={ref}/>;
}));
