import React from 'react';
import Form from '../Form';
import Divider from '../Divider';
import Heading from '../Heading';
import PropTypes from 'prop-types';
import TemplateDropdownSelector from '../TemplateAssistant/TemplateDropdownSelector';
import PipelineTemplateEditor from '../PipelineTemplate/Editor/PipelineTemplateEditor';
import TemplateAssistantSettings from '../TemplateAssistant/TemplateAssistantSettings';
import {connect} from 'react-redux';
import {
    requestPipelineTemplateEditorModel,
    disableSwitchableFixedStage,
    enableSwitchableFixedStage,
    setPipelineStages,
    updatePipelineTemplate,
} from '../../actions/PipelineTemplateEditorActions';
import Translations from '../../helpers/Translations';
import Constants from '../../helpers/Constants';
import UserRights from '../../helpers/UserRights';
import {openNotAllowedFeatureDialog} from '../../actions/DialogActions';
import FormSkeleton from '../../skeletons/components/FormSkeleton';

class JobPipelineEditor extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {
            selectedPipelineTemplate: props.pipelineTemplateEditorModel.hasOwnProperty('pipelineTemplateData') === true && props.pipelineTemplateEditorModel.pipelineTemplateData.id !== null
                ? props.pipelineTemplateEditorModel.pipelineTemplateData.id
                : props.pipelineTemplateId,
        };
    }

    componentDidMount() {
        let requestPipelineTemplateEditorModel = false;

        if (this.props.pipelineTemplateEditorModel.hasOwnProperty('pipelineTemplateData') === false) {
            requestPipelineTemplateEditorModel = true;
        } else {
            const id = this.props.pipelineTemplateEditorModel.pipelineTemplateData.id === null ? 0 : parseInt(this.props.pipelineTemplateEditorModel.pipelineTemplateData.id);
            if (id !== parseInt(this.state.selectedPipelineTemplate)) {
                requestPipelineTemplateEditorModel = true;
            }
        }

        if (requestPipelineTemplateEditorModel === true) {
            this.props.requestPipelineTemplateEditorModel(this.state.selectedPipelineTemplate);
        }
    }

    selectPipelineTemplate = (pipelineTemplateId) => {
        if (parseInt(pipelineTemplateId) !== parseInt(this.state.selectedPipelineTemplate)) {
            this.setState({
                selectedPipelineTemplate: pipelineTemplateId,
            });

            this.props.requestPipelineTemplateEditorModel(pipelineTemplateId);
            this.props.onSaveAsChangedTemplate(false);
            this.props.onSaveAsNewTemplate(false);
        }
    };

    onSaveAsNewTemplate = (isNew) => {
        if (this.props.editFromJob === true && UserRights.hasAllowedMultiPipelines(this.props.loginManagerModel.activePackage) === false) {
            this.props.openNotAllowedFeatureDialog({title: Translations.getStatic('pipelineTemplates'), package: UserRights.getPackageWithAllowedMultiPipelines()});
        } else {
            this.props.onSaveAsNewTemplate(isNew);
        }
    };

    render() {
        if (this.props.isFetchingPipelineTemplateEditorModel === true) {
            return <FormSkeleton/>;
        }

        let pipelineTemplateEditorContent = null;
        let hasChanges                    = false;

        if (this.props.pipelineTemplateEditorModel.hasOwnProperty('pipelineTemplateData') === true) {
            const settings = (
                <TemplateAssistantSettings
                    isModal={this.props.isModal}
                    templateData={this.props.pipelineTemplateEditorModel.pipelineTemplateData}
                    isChangedTemplate={this.props.isChangedTemplate}
                    isNewTemplate={this.props.isNewTemplate}
                    onUpdateTemplate={this.props.updatePipelineTemplate}
                    onSaveAsChangedTemplate={this.props.onSaveAsChangedTemplate}
                    onSaveAsNewTemplate={this.onSaveAsNewTemplate}
                    errors={this.props.errors}
                    strings={{
                        saveChangesInTemplate: Translations.getStatic('saveChangesInPipelineTemplate'),
                        saveAsNewTemplate: Translations.getStatic('saveAsNewPipelineTemplate'),
                        doNotSaveChangesInTemplate: Translations.getStatic('doNotSaveChangesInPipelineTemplate'),
                    }}
                    templateType={Constants.TEMPLATE_TYPE_PIPELINE}
                    allowedTemplateSavingActions={this.props.allowedTemplateSavingActions}
                />
            );

            hasChanges = this.props.pipelineTemplateEditorModel.pipelineTemplateData.hasOwnProperty('currentChangeHash') === true && this.props.pipelineTemplateEditorModel.pipelineTemplateData.currentChangeHash !== this.props.pipelineTemplateEditorModel.pipelineTemplateData.changeHash;

            const actionsWrapper = hasChanges === true
                ? (
                    <div className={'template-assistant-settings'}>
                        {settings}
                    </div>
                )
                : null;

            pipelineTemplateEditorContent = (
                <>
                    {actionsWrapper}

                    <Divider className="column"/>

                    <PipelineTemplateEditor
                        pipelineTemplateEditorModel={this.props.pipelineTemplateEditorModel}
                        disableSwitchableFixedStage={this.props.disableSwitchableFixedStage}
                        enableSwitchableFixedStage={this.props.enableSwitchableFixedStage}
                        setPipelineStages={this.props.setPipelineStages}
                        loginManagerModel={this.props.loginManagerModel}
                        displaySize={this.props.displaySize}
                        errors={this.props.errors}
                        editFromJob={this.props.editFromJob}
                    />
                </>
            );
        }

        const disableTemplateDropdownSelector = this.props.editFromJob === true && UserRights.hasAllowedMultiPipelines(this.props.loginManagerModel.activePackage) === false;

        return (
            <>
                <Heading as="h3">
                    {Translations.getStatic('pipelineTemplate')}
                </Heading>

                <TemplateDropdownSelector
                    onStartFetchingData={this.props.onStartFetchingTemplates}
                    onSelectedValue={this.selectPipelineTemplate}

                    emptyItem={
                        disableTemplateDropdownSelector === true
                            ? undefined
                            : null
                    }

                    changeSelectedItem={hasChanges}
                    defaultValue={this.state.selectedPipelineTemplate}
                    templatesDropdownLabel={Translations.getStatic('useTemplate')}
                    disableTemplateDropdownSelector={disableTemplateDropdownSelector}
                    templateType={Constants.TEMPLATE_TYPE_PIPELINE}
                />

                <Form className={this.props.isFetchingPipelineTemplateEditorModel ? 'loading' : ''}>
                    {pipelineTemplateEditorContent}
                </Form>

                <Divider hidden className="small"/>
            </>
        );
    }
}

const mapStateToProps = state => ({
    loginManagerModel: state.loginManagerModel,
    isFetchingPipelineTemplateEditorModel: state.isFetchingPipelineTemplateEditorModel,
    pipelineTemplateEditorModel: state.pipelineTemplateEditorModel,
    displaySize: state.displaySize,
});

const mapDispatchToProps = {
    requestPipelineTemplateEditorModel,
    disableSwitchableFixedStage,
    enableSwitchableFixedStage,
    setPipelineStages,
    updatePipelineTemplate,
    openNotAllowedFeatureDialog,
};

const JobPipelineEditorContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(JobPipelineEditor);

JobPipelineEditorContainer.propTypes = {
    pipelineTemplateId: PropTypes.number.isRequired,
    isNewTemplate: PropTypes.bool.isRequired,
    isChangedTemplate: PropTypes.bool.isRequired,
    allowedTemplateSavingActions: PropTypes.array,
    editFromJob: PropTypes.bool,
    errors: PropTypes.array,
    onSaveAsNewTemplate: PropTypes.func.isRequired,
    onSaveAsChangedTemplate: PropTypes.func.isRequired,
    onStartFetchingTemplates: PropTypes.func.isRequired,
};

JobPipelineEditorContainer.defaultProps = {
    allowedTemplateSavingActions: Constants.TEMPLATE_ASSISTANT_ALL_SAVING_OPTIONS,
    errors: [],
    editFromJob: false,
};

export default JobPipelineEditorContainer;
