import Form from '../Form';
import Constants from '../Constants';

export default class RefuseCandidateValidatorHelper
{
    constructor(refusalReason) {
        this.refusalReason = refusalReason;
    }

    checkRefusalReason() {
        const fieldsToCheck = [
            {
                fieldName: 'refusalReason',
                fieldType: 'numeric',
                fieldValue: parseInt(this.refusalReason.refusalReasonId),

                conditionalChecks: [{
                    fieldValue: Constants.REFUSAL_REASON_OTHER_ID,

                    fieldsToCheck: [{
                        fieldName: 'customRefusalReason',
                        fieldType: 'notEmpty',
                        fieldValue: this.refusalReason.customRefusalReason,
                    }],
                }],
            },
        ];

        const errors = Form.multiCheck(fieldsToCheck);

        if (errors.length > 0) {
            throw errors;
        }

        return true;
    }
}
