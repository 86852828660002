import React from 'react';
import {connect} from 'react-redux';
import Form from '../Form';
import {Textarea} from '@profesia/adamui/components/common/input';
import Grid from '../Grid';
import MarkModal from '../Modal/MarkModal';
import {closeModal, closeModalSecond} from '../../actions/ModalActions';
import Translations from '../../helpers/Translations';

const ReactionIframeModal = props => {
    const {isModalSecond, isOpenModal, isOpenModalSecond, closeModal, closeModalSecond, integratedReactionLink} = props;

    const iframeHtml = '<iframe title="' + Translations.getStatic('reactionForm') + '" src="' + integratedReactionLink + '"></iframe>';

    return (
        <MarkModal
            isModalSecond={isModalSecond}
            isOpen={isModalSecond ? isOpenModalSecond : isOpenModal}
            closeModal={isModalSecond ? closeModalSecond : closeModal}
            title={Translations.getStatic('htmlIntegration')}
            actionButton={{visible: false}}

            content={(
                <Form>
                    <Grid>
                        <Grid.Row className="horizontal-row">
                            <Grid.Column computer={16} tablet={16}>
                                <Form.Field>
                                    <Textarea
                                        label={Translations.getStatic('htmlCode')}
                                        value={iframeHtml}
                                        onChange={() => e.preventDefault()}
                                    />
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                </Form>
            )}
        />
    );
};

const mapStateToProps = state => ({
    isOpenModal: state.modalLogic.isOpen,
    isOpenModalSecond: state.modalSecondLogic.isOpen,
});

const mapDispatchToProps = {
    closeModal,
    closeModalSecond,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReactionIframeModal);
