import React from 'react';
import ResponsiveJobCandidatesListFilterSkeleton from './ResponsiveJobCandidatesListFilterSkeleton';
import GlobalSearchCandidatesListSkeleton from './GlobalSearchCandidatesListSkeleton';

const GlobalSearchPageSkeleton = () => (
    <>
        <ResponsiveJobCandidatesListFilterSkeleton/>
        <GlobalSearchCandidatesListSkeleton/>
    </>
);

export default GlobalSearchPageSkeleton;
