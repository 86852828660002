import {
    RECEIVE_RECOMMENDATION_FORM_MODEL, REQUEST_RECOMMENDATION_FORM_MODEL,
    SET_RECOMMENDATION_FORM_ERRORS,
    SAVE_RECOMMENDATION, SAVE_RECOMMENDATION_FAILURE, SAVE_RECOMMENDATION_SUCCESS,
} from '../actions/RecommendationFormActions';

export const isFetchingRecommendationFormModel = (state = false, action) => {
    switch (action.type) {
        case REQUEST_RECOMMENDATION_FORM_MODEL:
            return true;
        case RECEIVE_RECOMMENDATION_FORM_MODEL:
            return false;
        default:
            return state;
    }
};

export const isSavingRecommendation  = (state = false, action) => {
    switch (action.type) {
        case SAVE_RECOMMENDATION:
            return true;
        case SAVE_RECOMMENDATION_FAILURE:
        case SAVE_RECOMMENDATION_SUCCESS:
            return false;
        default:
            return state;
    }
};
export const recommendationFormModel = (state = {}, action) => {
    switch (action.type) {
        case REQUEST_RECOMMENDATION_FORM_MODEL:
            return {};
        case RECEIVE_RECOMMENDATION_FORM_MODEL:
            return {
                ...state,
                ...action.payload,
                errors: [],
            };
        case SAVE_RECOMMENDATION:
            return {
                ...state,
                errors: [],
                isSuccess: false,
            };
        case SAVE_RECOMMENDATION_SUCCESS:
            return {
                ...state,
                isSuccess: true,
            };
        case SET_RECOMMENDATION_FORM_ERRORS:
            return {
                ...state,
                errors: action.payload.errors,
            };
        default:
            return state;
    }
};
