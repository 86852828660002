import React from 'react';
import Grid from '../Grid';
import Item from '../Item';
import PropTypes from 'prop-types';
import Translations from '../../helpers/Translations';
import Language from '../../helpers/Language';
import File from '../../helpers/File';

const BulkUploadEmailAttachmentColumn = ({attachment, language, onRemoveAttachmentFromBulk}) => {
    const size = attachment.file.size === 0 ? '' : <span className="file-size">&middot; {File.getReadableFileSize(attachment.file.size)}</span>;

    return (
        <Item className="data-block__content attachment basic">
            <Grid verticalAlign="middle" container>
                <Grid.Row>
                    <Grid.Column width={12}>
                        <Item.Header as="h5" className="ellipsis">{attachment.file.name}{size}</Item.Header>
                    </Grid.Column>
                    <Grid.Column width={4} textAlign="right">
                        <a onClick={onRemoveAttachmentFromBulk} className="link secondary-link">{Translations.getStatic('delete', language)}</a>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Item>
    );
};

BulkUploadEmailAttachmentColumn.propTypes = {
    language: PropTypes.string,
    attachment: PropTypes.object.isRequired,
    attachmentTypeName: PropTypes.string.isRequired,
    onRemoveAttachmentFromBulk: PropTypes.func.isRequired,
};

BulkUploadEmailAttachmentColumn.defaultProps = {
    language: Language.get(),
};

export default BulkUploadEmailAttachmentColumn;
