import React from 'react';
import {connect} from 'react-redux';
import {Image} from 'semantic-ui-react';
import companyInfoIcon from './../../img/icons/company-info.svg';
import Skeletons from '../helpers/Skeletons';
import {openSidebarMenu} from '../actions/SidebarHeaderActions';

const Header4MenuExtendedButtonCompanyInfo = props => {
    const {loginManagerModel, displaySize, openSidebarMenu} = props;

    return Skeletons.isSkeleton(loginManagerModel) === true
        ? null
        : (
            <div onClick={openSidebarMenu} className="extended-action">
                <div className="extended-action__icon">
                    <Image size="tiny" src={companyInfoIcon}/>
                </div>

                {
                    displaySize.isTabletProfesia === false
                    && (
                        <div className="extended-action__desc">
                            <div className="company-name">
                                {loginManagerModel.companyName}
                                <i className="icon icon-arrow_down_white"/>
                            </div>
                            <div className="contact-email">
                                {loginManagerModel.user?.email ?? ''}
                            </div>
                        </div>
                    )
                }
            </div>
        );
};

const mapStateToProps = state => ({
    loginManagerModel: Skeletons.getData(state.loginManagerModel, {}),
    displaySize: state.displaySize,
});

const mapDispatchToProps = {
    openSidebarMenu,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Header4MenuExtendedButtonCompanyInfo);
