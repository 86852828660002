import React from 'react';
import PropTypes from 'prop-types';
import {InputField} from '@profesia/adamui/components/common/input';
import Form from '../../Form';
import Textarea from '@profesia/adamui/components/common/input/Textarea';
import Button from '../../Button';
import Grid from '../../Grid';
import Divider from '../../Divider';
import Translations from '../../../helpers/Translations';

const QuestionnaireEditorTextQuestion = props =>
{
    const moveUp = props.first === true
        ? null
        : (
            <Grid.Column width={1}>
                <Button icon={{className: 'button icon-up_dark mini'}} onClick={() => props.onReorderQuestionItem(props.index, props.index - 1)}/>
            </Grid.Column>
        );

    const moveDown = props.last === true
        ? null
        : (
            <Grid.Column width={1}>
                <Button icon={{className: 'button icon-down_dark mini'}} onClick={() => props.onReorderQuestionItem(props.index, props.index + 1)}/>
            </Grid.Column>
        );

    return (
        <Grid key={props.index}>
            <Grid.Column tablet={16} computer={14} className='questionnaire-box'>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={1} verticalAlign='middle'><span className='text-form__label'>{props.index + 1}.</span></Grid.Column>
                        <Grid.Column width={14} verticalAlign='middle'>
                            <InputField maxLength={5000} value={props.question.question} onChange={(e) => props.onUpdateQuestionItem(props.index, e.target.value)}/>
                        </Grid.Column>
                        <Grid.Column width={1} verticalAlign='middle' textAlign='right'>
                            <Button icon={{className: 'button icon-cancel'}} onClick={() => props.onDeleteQuestionItem(props.index)}/>
                        </Grid.Column>
                    </Grid.Row>
                    <Divider hidden className='size_8'/>
                    <Grid.Row>
                        <Grid.Column width={1}/>
                        <Grid.Column width={14}>
                            <Form.Field className='padding-bottom_none'>
                                <Textarea 
                                    disabled 
                                    placeholder={Translations.getStatic('questionTypeTextTextareaPlaceholder')}
                                />
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={1}/>
                    </Grid.Row>
                </Grid>
            </Grid.Column>
            {moveUp}
            {moveDown}
        </Grid>
    );
}

QuestionnaireEditorTextQuestion.propTypes = {
    question: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    first: PropTypes.bool.isRequired,
    last: PropTypes.bool.isRequired,
    onDeleteQuestionItem: PropTypes.func.isRequired,
    onUpdateQuestionItem: PropTypes.func.isRequired,
    onReorderQuestionItem: PropTypes.func.isRequired,
};

export default QuestionnaireEditorTextQuestion;
