import React, {Component} from 'react';
import {connect} from 'react-redux';
import MarkModal from '../Modal/MarkModal';
import {closeModalSecond} from '../../actions/ModalActions';
import Translations from '../../helpers/Translations';
import {requestOutOfOfficeEmailModel, saveUserOutOfOfficeEmail} from '../../actions/OutOfOfficeEmailActions';
import LoadingComponent from '../LoadingComponent';
import OutOfOfficeEmail from '../OutOfOfficeEmail/OutOfOfficeEmail';
import OutOfOfficeValidatorHelper from '../../helpers/components/OutOfOfficeValidatorHelper';
import SuccessMessage from '../../helpers/SuccessMessage';
import Variables from '../../helpers/Variables';
import Constants from '../../helpers/Constants';

class EditOutOfOfficeEmailModal extends Component
{
    state = {
        errors: [],
    };

    componentDidMount = () => {
        if (this.props.outOfOfficeEmail !== null && this.props.outOfOfficeEmail !== undefined) {
            this.props.requestOutOfOfficeEmailModel(this.props.outOfOfficeEmail.id, this.props.outOfOfficeEmail.emailData.senderId, this.props.outOfOfficeEmail.emailData.bcc);
        } else {
            this.props.requestOutOfOfficeEmailModel();
        }
    };

    componentDidUpdate = prevProps => {
        if (SuccessMessage.canShowSuccessMessage(prevProps, this.props, 'isSavingUserOutOfOfficeEmail')
            && this.props.outOfOfficeEmailModel.hasOwnProperty('isSuccess') === true
            && this.props.outOfOfficeEmailModel.isSuccess === true
        ) {
            this.props.closeModalSecond();
        }
    };

    saveOutOfOfficeEmail = () => {
        const {outOfOfficeEmailModel, loginManagerModel} = this.props;

        const outOfOfficeValidator = new OutOfOfficeValidatorHelper(
            outOfOfficeEmailModel.outOfOfficeEmailData.emailData.text,
            outOfOfficeEmailModel.outOfOfficeEmailData.emailData.subject,
            outOfOfficeEmailModel.outOfOfficeEmailData.startAtForCheck,
            outOfOfficeEmailModel.outOfOfficeEmailData.endAtForCheck,
        );

        const errors = outOfOfficeValidator.checkOutOfOffice({
            variables: outOfOfficeEmailModel.settings.variables,
            filledVariables: Variables.getFilledVariablesCheckParams(
                [Constants.VARIABLE_ACTION_TYPE_USER, Constants.VARIABLE_ACTION_TYPE_OUT_OF_OFFICE],
                [],
                loginManagerModel,
                null,
                outOfOfficeEmailModel.outOfOfficeEmailData,
            ),
            requiredVariables: Variables.getRequiredVariablesCheckParams(
                [Constants.VARIABLE_ACTION_TYPE_OUT_OF_OFFICE],
            ),
            forbiddenVariables: Variables.getForbiddenVariablesCheckParams(
                [Constants.VARIABLE_ACTION_TYPE_VIDEO_INTERVIEW, Constants.VARIABLE_ACTION_TYPE_QUESTIONNAIRE, Constants.VARIABLE_ACTION_TYPE_MULTIPLE_FORWARD, Constants.VARIABLE_ACTION_TYPE_EVENT],
            ),
        });

        if (errors.length > 0) {
            this.setState({errors});
        } else {
            this.setState({errors: []});

            let formData = new FormData();

            formData.append('outOfOfficeEmailData', JSON.stringify(outOfOfficeEmailModel.outOfOfficeEmailData));

            this.props.saveUserOutOfOfficeEmail(outOfOfficeEmailModel.outOfOfficeEmailData.id, formData);
        }
    };

    render() {
        const {isOpenModalSecond, closeModalSecond, isFetchingOutOfOfficeEmailModel, outOfOfficeEmailModel, isSavingUserOutOfOfficeEmail} = this.props;
        const {errors}                                                                                                                    = this.state;

        return (
            <MarkModal
                title={Translations.getStatic('scheduleOutOfOffice')}
                isModalSecond={true}
                isOpen={isOpenModalSecond}
                closeModal={closeModalSecond}
                isLoadingData={isFetchingOutOfOfficeEmailModel}

                actionButton={{
                    text: Translations.getStatic('scheduleAndSave'),
                    loading: isSavingUserOutOfOfficeEmail,
                    disabled: isSavingUserOutOfOfficeEmail,
                    onClick: () => this.saveOutOfOfficeEmail(),
                }}

                content={isFetchingOutOfOfficeEmailModel === true || outOfOfficeEmailModel.hasOwnProperty('settings') === false
                    ? <LoadingComponent/>
                    : <OutOfOfficeEmail errors={errors}/>
                }
            />
        );
    }
}

const mapStateToProps = state => ({
    outOfOfficeEmailModel: state.outOfOfficeEmailModel,
    isFetchingOutOfOfficeEmailModel: state.isFetchingOutOfOfficeEmailModel,
    isSavingUserOutOfOfficeEmail: state.isSavingUserOutOfOfficeEmail,
    isOpenModalSecond: state.modalSecondLogic.isOpen,
    loginManagerModel: state.loginManagerModel,
});

const mapDispatchToProps = {
    closeModalSecond,
    requestOutOfOfficeEmailModel,
    saveUserOutOfOfficeEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditOutOfOfficeEmailModal);
