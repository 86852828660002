import Form from '../Form';
import Translations from '../Translations';

export default class PipelineValidatorHelper
{
    constructor(pipelineStages) {
        this.pipelineStages = pipelineStages;
    }

    prepareNotEmptyStagesRules() {
        return this.pipelineStages.map((stage, index) => ({
            fieldName: 'stage#' + index.toString(),
            fieldType: 'text',
            fieldValue: stage.stageName,
        }));
    }

    prepareUniqueStagesRules() { // kontrola unikatnosti otazok
        return this.pipelineStages.map((stage, index) => ({
            fieldName: 'stageUnique#' + index.toString(),
            fieldType: 'unique',
            fieldValue: stage.stageName,
            against: this.pipelineStages.filter((compareStage, compareIndex) => {
                return index !== compareIndex;
            }).map(stage => stage.stageName),
        }));
    }

    checkStages() {
        let fieldsToCheck = [];

        fieldsToCheck = fieldsToCheck.concat(this.prepareNotEmptyStagesRules());
        fieldsToCheck = fieldsToCheck.concat(this.prepareUniqueStagesRules());

        return Form.multiCheck(fieldsToCheck);
    }

    checkPipeline(withAlerts = true) {
        let errors = this.checkStages();

        if (errors.length > 0) {
            const emptyStageErrors  = errors.filter(error => error.search('stage#') > -1);
            const uniqueStageErrors = errors.filter(error => error.search('stageUnique#') > -1);

            if (emptyStageErrors.length > 0) {
                if (withAlerts === true) {
                    alert(Translations.getStatic('fillAllStageNames'));
                }

                return emptyStageErrors;
            } else if (uniqueStageErrors.length > 0) {
                if (withAlerts === true) {
                    alert(Translations.getStatic('uniqueStageNamesError'));
                }

                return uniqueStageErrors.map(error => error.replace('Unique', ''));
            }
        }

        return [];
    }
}
