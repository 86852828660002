import React from 'react';
import {connect} from 'react-redux';
import {Image} from 'semantic-ui-react';
import MenuItem from './../Menu/MenuItem';
import SidebarMenu from '../../components/Menu/Sidebar/SidebarMenu';
import SidebarSearch from '../../components/Menu/SidebarSearch';
import {openModal} from '../../actions/ModalActions';
import UserRights from '../../helpers/UserRights';
import logoImage from '../../../img/logo_profesia_color.svg';
import User from '../../helpers/User';
import {openNotAllowedFeatureDialog} from '../../actions/DialogActions';
import HeaderHelper from '../../helpers/components/HeaderHelper';
import {openSidebarMenu, openSidebarSearch} from '../../actions/SidebarHeaderActions';
import {setAllNotificationsAsRead, setNotificationAsRead} from '../../actions/NotificationsCenterActions';
import Header4MenuExtendedButtonAddJob from '../Header4MenuExtendedButtonAddJob';
import Header4MenuExtendedButtonCompanyInfo from '../Header4MenuExtendedButtonCompanyInfo';
import Header4MenuExtendedButtonHamburger from '../Header4MenuExtendedButtonHamburger';

class MenuDefault extends React.Component
{
    render() {
        const {loginManagerModel, action, displaySize, menu} = this.props;

        const links = menu
            .filter(item => UserRights.isMenuItemHidden(item) === false && item.hasOwnProperty('showInHeader') === true && item.showInHeader === true)
            .map(item => (
                <MenuItem
                    key={'menu-item-' + item.title}
                    action={action}
                    item={item}
                    menuItemsStrings={HeaderHelper.getMenuItemsStrings()}
                />
            ));

        const isMobile       = displaySize.isTabletProfesia || displaySize.isMobileProfesia;
        const isExternalUser = User.isExternalUser(loginManagerModel);

        return (
            <nav
                className={'navbar navbar_dark' + (User.isSuperAdmin(loginManagerModel) ? ' admin' : '')}
                id="navigation"
            >
                <div className="navbar__row">
                    <div className="col-left">
                        <a href="/" className="logo">
                            <Image src={logoImage} alt="logo"/>
                        </a>

                        {
                            (displaySize.isDesktopProfesia === true || displaySize.isBigTabletProfesia === true)
                            && isExternalUser === false
                            && (
                                <div className="nav-grid">
                                    <ul className="nav-grid__tabs">
                                        {links}
                                    </ul>
                                </div>
                            )
                        }
                    </div>
                    <div className="col-right">
                        {isExternalUser === false && <SidebarSearch isMobile={isMobile}/>}

                        <SidebarMenu/>

                        <Header4MenuExtendedButtonAddJob/>

                        {
                            isMobile
                                ? <Header4MenuExtendedButtonHamburger/>
                                : <Header4MenuExtendedButtonCompanyInfo/>
                        }
                    </div>
                </div>
            </nav>
        );
    }
}

const mapStateToProps = state => ({
    displaySize: state.displaySize,
    loginManagerModel: state.loginManagerModel,
});

const mapDispatchToProps = {
    openSidebarMenu,
    openSidebarSearch,
    openModal,
    openNotAllowedFeatureDialog,
    setAllNotificationsAsRead,
    setNotificationAsRead,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MenuDefault);
