import React from 'react';
import Translations from '../../helpers/Translations';
import MarkDialog from '../Modal/MarkDialog';
import {connect} from 'react-redux';
import {closeSomeRecipientsWithoutEmailsDialog} from '../../actions/DialogActions';
import Form from '../../helpers/Form';

class SomeRecipientsWithoutEmailsDialog extends React.Component
{
    skip = candidateIds => {
        const {dialogProps: {onSkip}} = this.props.someRecipientsWithoutEmailsDialog;

        this.props.closeSomeRecipientsWithoutEmailsDialog();

        onSkip(candidateIds);
    };

    render() {
        const {isFetchingCandidatesForSomeRecipientsWithoutEmailsDialog, someRecipientsWithoutEmailsDialog} = this.props;

        let infoLines = [Translations.getStatic('loading')];
        const actions = [{
            title: Translations.getStatic('cancelSend'),
            action: this.props.closeSomeRecipientsWithoutEmailsDialog,
            type: 'negative',
        }];

        if (isFetchingCandidatesForSomeRecipientsWithoutEmailsDialog === false && someRecipientsWithoutEmailsDialog.hasOwnProperty('candidates') === true) {
            const candidatesWithoutEmail = someRecipientsWithoutEmailsDialog.candidates.filter(candidate => Form.check({
                fieldType: 'email',
                fieldValue: candidate.email,
            }) === false);

            if (candidatesWithoutEmail.length === 1) {
                infoLines = [
                    Translations.getStatic('someRecipientsWithoutEmailsDialogTextSingle').replace(/%candidate%/g, candidatesWithoutEmail.map(candidate => candidate.candidateName).join()),
                ];
            } else {
                infoLines = [
                    Translations.getStatic('someRecipientsWithoutEmailsDialogTextMultiple').replace(/%candidates%/g, candidatesWithoutEmail.map(candidate => candidate.candidateName).join(', ')),
                ];
            }

            actions.push({
                title: candidatesWithoutEmail.length === 1
                    ? Translations.getStatic('skipCandidate')
                    : Translations.getStatic('skipCandidates'),
                action: () => this.skip(candidatesWithoutEmail.map(candidate => candidate.id)),
                type: 'positive',
                loading: false,
            });
        }

        return this.props.someRecipientsWithoutEmailsDialog.open === true && (
            <MarkDialog
                isLoading={isFetchingCandidatesForSomeRecipientsWithoutEmailsDialog === true}
                closeOnDimmerClick={false}
                message={{
                    iconName: 'icon-permission',
                    title: Translations.getStatic('someRecipientsWithoutEmailsDialogTitle'),
                    infoLines: infoLines,
                }}

                actions={actions}
            />
        );
    }
}

const mapStateToProps = state => ({
    someRecipientsWithoutEmailsDialog: state.someRecipientsWithoutEmailsDialog,
    isFetchingCandidatesForSomeRecipientsWithoutEmailsDialog: state.isFetchingCandidatesForSomeRecipientsWithoutEmailsDialog,
});

const mapDispatchToProps = {
    closeSomeRecipientsWithoutEmailsDialog,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SomeRecipientsWithoutEmailsDialog);


