import Url from './Url';
import Analytics from './Analytics';

class GoogleAnalytics {
    static isGoogleAnalyticsAvailable(): boolean {
        return typeof window.ga === 'function';
    }

    static getTrackerName(): string {
        if (this.isGoogleAnalyticsAvailable()) {
            if (typeof window.google_tag_manager !== 'undefined') {
                if (typeof window.ga.getAll === 'function' && window.ga.getAll().length > 0) {
                    return window.ga.getAll()[0].get('name') + '.';
                }
            }
        }

        return '';
    }

    static getCommand(command: string): string {
        return this.getTrackerName() + command;
    }

    static sendModalView(modalName: string): void {
        if (Url.isOnline() && this.isGoogleAnalyticsAvailable()) {
            this.sendEvent(modalName, 'view');
        }
    }

    static sendActionDuration(category: string, action: string, duration: number): void {
        if (Url.isOnline() && this.isGoogleAnalyticsAvailable()) {
            window.ga(this.getCommand('send'), 'timing', category, action, duration, action);
        }
    }

    static sendEvent(eventAction: string, eventLabel: string = 'click'): void {
        if (Url.isOnline() && this.isGoogleAnalyticsAvailable()) {
            const eventData: EventDataType = {
                hitType: 'event',
                eventCategory: Analytics.getEventCategory(),
                eventAction,
                eventValue: null,
                eventLabel,
                nonInteraction: false,
            };

            window.ga(this.getCommand('send'), eventData);
        }
    }
}

interface EventDataType {
    hitType: string;
    eventCategory: string;
    eventAction: string;
    eventValue: null
    eventLabel: string
    nonInteraction: boolean,
}

export default GoogleAnalytics;
