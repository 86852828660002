import {REQUEST_JOBS_GENERATOR_MODEL, RECEIVE_JOBS_GENERATOR_MODEL, SYNC_USER, GENERATE_JOBS, GENERATE_JOBS_SUCCESS, GENERATE_JOBS_FAILURE} from '../actions/JobsGeneratorActions';

export const jobsGeneratorModel = (state = {}, action) => {
    switch (action.type) {
        case RECEIVE_JOBS_GENERATOR_MODEL:
            return {
                ...state,
                ...action.payload,
            };

        default:
            return state;
    }
};

export const isFetchingJobsGeneratorModel = (state = false, action) => {
    switch (action.type) {
        case REQUEST_JOBS_GENERATOR_MODEL:
        case GENERATE_JOBS:
        case SYNC_USER:
            return true;

        case RECEIVE_JOBS_GENERATOR_MODEL:
        case GENERATE_JOBS_SUCCESS:
        case GENERATE_JOBS_FAILURE:
            return false;

        default:
            return state;
    }
};
