export const REQUEST_CANDIDATES_GDPR_SETTINGS_MODEL                   = 'REQUEST_CANDIDATES_GDPR_SETTINGS_MODEL';
export const RECEIVE_CANDIDATES_GDPR_SETTINGS_MODEL                   = 'RECEIVE_CANDIDATES_GDPR_SETTINGS_MODEL';
export const CANDIDATES_GDPR_SETTING_UPDATE                           = 'CANDIDATES_GDPR_SETTING_UPDATE';
export const CANDIDATES_GDPR_SETTING_UPDATE_SUCCESS                   = 'CANDIDATES_GDPR_SETTING_UPDATE_SUCCESS';
export const CANDIDATES_GDPR_SETTING_UPDATE_FAILURE                   = 'CANDIDATES_GDPR_SETTING_UPDATE_FAILURE';
export const CANDIDATES_GDPR_SETTING_CREATE                           = 'CANDIDATES_GDPR_SETTING_CREATE';
export const CANDIDATES_GDPR_SETTING_CREATE_SUCCESS                   = 'CANDIDATES_GDPR_SETTING_CREATE_SUCCESS';
export const CANDIDATES_GDPR_SETTING_CREATE_FAILURE                   = 'CANDIDATES_GDPR_SETTING_CREATE_FAILURE';
export const CANDIDATES_GDPR_SETTING_ADD                              = 'CANDIDATES_GDPR_SETTING_ADD';
export const CANDIDATES_GDPR_SETTING_ADD_CANCEL                       = 'CANDIDATES_GDPR_SETTING_ADD_CANCEL';
export const SAVE_COMPANY_CANDIDATES_GDPR_EXPIRATION_SETTINGS         = 'SAVE_COMPANY_CANDIDATES_GDPR_EXPIRATION_SETTINGS';
export const SAVE_COMPANY_CANDIDATES_GDPR_EXPIRATION_SETTINGS_SUCCESS = 'SAVE_COMPANY_CANDIDATES_GDPR_EXPIRATION_SETTINGS_SUCCESS';
export const SAVE_COMPANY_CANDIDATES_GDPR_EXPIRATION_SETTINGS_FAILURE = 'SAVE_COMPANY_CANDIDATES_GDPR_EXPIRATION_SETTINGS_FAILURE';

export const requestCandidatesGdprSettingsModel = () => ({
    type: REQUEST_CANDIDATES_GDPR_SETTINGS_MODEL,

    meta: {
        requestRoute: 'v1/admin/model/candidates-gdpr-settings',
        onRequestSuccess: RECEIVE_CANDIDATES_GDPR_SETTINGS_MODEL,
    },
});

export const updateCandidatesGdprSetting = (candidatesGdprSettingId, companyId, monthsToExpire) => ({
    type: CANDIDATES_GDPR_SETTING_UPDATE,

    payload: {
        candidatesGdprSettingId,
        companyId,
        monthsToExpire,
    },

    meta: {
        requestMethod: 'POST',
        requestRoute: 'v1/admin/candidates-gdpr-settings/action/update',

        requestBody: JSON.stringify({
            candidatesGdprSettingId,
            companyId,
            monthsToExpire,
        }),

        onRequestSuccess: CANDIDATES_GDPR_SETTING_UPDATE_SUCCESS,
        onRequestFailure: CANDIDATES_GDPR_SETTING_UPDATE_FAILURE,
    },
});

export const createCandidatesGdprSetting = (companyId, monthsToExpire) => ({
    type: CANDIDATES_GDPR_SETTING_CREATE,

    payload: {
        candidatesGdprSettingId: 'new',
    },

    meta: {
        requestMethod: 'POST',
        requestRoute: 'v1/admin/candidates-gdpr-settings/action/create',

        requestBody: JSON.stringify({
            companyId,
            monthsToExpire,
        }),

        onRequestSuccess: CANDIDATES_GDPR_SETTING_CREATE_SUCCESS,
        onRequestFailure: CANDIDATES_GDPR_SETTING_CREATE_FAILURE,
    },
});

export const addCandidatesGdprSetting = () => ({
    type: CANDIDATES_GDPR_SETTING_ADD,
});

export const cancelAddCandidatesGdprSetting = () => ({
    type: CANDIDATES_GDPR_SETTING_ADD_CANCEL,
});

export const saveCompanyCandidatesGdprExpirationSettings = (companyId, monthsToExpire) => ({
    type: SAVE_COMPANY_CANDIDATES_GDPR_EXPIRATION_SETTINGS,

    meta: {
        requestMethod: 'POST',
        requestRoute: 'v1/settings/action/save-company-candidates-gdpr-expiration-settings',

        requestBody: JSON.stringify({
            companyId,
            monthsToExpire,
        }),

        onRequestSuccess: SAVE_COMPANY_CANDIDATES_GDPR_EXPIRATION_SETTINGS_SUCCESS,
        onRequestFailure: SAVE_COMPANY_CANDIDATES_GDPR_EXPIRATION_SETTINGS_FAILURE,
    },
});

