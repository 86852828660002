import React from 'react';
import {SkeletonRectangular} from '@profesia/adamui/components/common/skeleton';
import {Stack} from '@mui/material';

const MarkModalActionButtonsSkeleton = () => (
    <Stack spacing={2} direction="row" justifyContent="end">
        {[...Array(2)].map((e, i) => <SkeletonRectangular width={160} height={48} borderRadius={4} key={i}/>)}
    </Stack>
);

export default MarkModalActionButtonsSkeleton;
