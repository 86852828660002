import React from 'react';
import {connect} from 'react-redux';
import {Dropdown} from 'semantic-ui-react';
import Grid from '../Grid';
import Item from '../Item';
import Image from '../Image';
import PropTypes from 'prop-types';
import Translations from '../../helpers/Translations';
import Constants from '../../helpers/Constants';
import fileImage from '../../../img/icons/file.svg';
import User from '../../helpers/User';

const AttachmentOptions = ({isDuplicate, language, displaySize, attachment, openAttachment, onRemoveCandidateAttachment, isAdmin, loginManagerModel, jobModel}) => {
    if (displaySize.width < Constants.WIDTH_NO_MODAL) {
        return (
            <Dropdown className="icon" direction="left" icon={{className: 'icon-more_small mini'}}>
                <Dropdown.Menu>
                    <Dropdown.Item
                        text={Translations.getStatic('show', language)}
                        value={Translations.getStatic('show', language)}
                        onClick={openAttachment}
                    />

                    {
                        isDuplicate === false
                        && attachment.attachmentTypeId !== Constants.ATTACHMENT_TYPE_QUESTIONNAIRE_RESULT
                        && User.isExternalVisitor(loginManagerModel, jobModel) === false
                        && (
                            <Dropdown.Item
                                text={Translations.getStatic('delete', language)}
                                value={Translations.getStatic('delete', language)}
                                onClick={onRemoveCandidateAttachment}
                            />
                        )
                    }
                </Dropdown.Menu>
            </Dropdown>
        );
    }

    return (
        <>
            <a onClick={openAttachment} className="link secondary-link">{Translations.getStatic('show', language)}</a>

            <a
                target="_blank"
                href={attachment.downloadUrl}
                className={'link secondary-link' + (attachment.exists === false && isAdmin === true ? ' red' : '')}
            >
                {Translations.getStatic('download', language)}
            </a>

            {
                isDuplicate === false
                && attachment.attachmentTypeId !== Constants.ATTACHMENT_TYPE_QUESTIONNAIRE_RESULT
                && User.isExternalVisitor(loginManagerModel, jobModel) === false
                && (
                    <Dropdown className="icon" direction="left" icon={{className: 'icon-more_small mini'}}>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                text={Translations.getStatic('delete', language)}
                                value={Translations.getStatic('delete', language)}
                                onClick={onRemoveCandidateAttachment}
                            />
                        </Dropdown.Menu>
                    </Dropdown>
                )
            }
        </>
    );
};

const CandidateAttachmentColumn = ({
    attachment,
    language,
    attachmentTypeName,
    onRemoveCandidateAttachment,
    isDuplicate,
    displaySize,
    loginManagerModel,
    jobModel,
}) => {
    const openAttachment = () => {
        window.open(attachment.url);
    };

    const image = attachment.attachmentTypeId === Constants.ATTACHMENT_TYPE_PHOTO && attachment.thumbUrl !== null
        ? (
            <span onClick={openAttachment} className={'photo no-contacted pointer'}>
                <Image src={attachment.thumbUrl}/>
            </span>
        )
        : <Image onClick={openAttachment} className="pointer" size="large" src={fileImage}/>;

    const isAdmin = User.isAdmin(loginManagerModel);

    return (
        <Item className="data-block__content attachment">
            {image}
            <Grid verticalAlign="middle" container>
                <Grid.Row>
                    <Grid.Column width={displaySize.isMobile === true ? 12 : 9}>
                        <Item.Header as="h4" className="ellipsis pointer" onClick={openAttachment}>{attachmentTypeName}</Item.Header>
                        <Item.Meta className="ellipsis">{attachment.filename}</Item.Meta>
                    </Grid.Column>
                    <Grid.Column width={displaySize.isMobile === true ? 4 : 7} textAlign="right">
                        <AttachmentOptions
                            language={language}
                            isDuplicate={isDuplicate}
                            displaySize={displaySize}
                            attachment={attachment}
                            openAttachment={openAttachment}
                            onRemoveCandidateAttachment={onRemoveCandidateAttachment}
                            isAdmin={isAdmin}
                            loginManagerModel={loginManagerModel}
                            jobModel={jobModel}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Item>
    );
};

const mapStateToProps = state => ({
    displaySize: state.displaySize,
    loginManagerModel: state.loginManagerModel,
    jobModel: state.jobModel,
});

CandidateAttachmentColumn.propTypes = {
    attachment: PropTypes.object.isRequired,
    attachmentTypeName: PropTypes.string.isRequired,
    onRemoveCandidateAttachment: PropTypes.func.isRequired,
    isDuplicate: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(CandidateAttachmentColumn);
