import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Translations from '../../../helpers/Translations';
import CandidateAvatar from '../../CandidateAvatar';
import {openNoModalDashboard} from '../../../actions/ModalActions';
import Icon from '../../Icon';
import {connect} from 'react-redux';
import WithRouterProp from '../../../helpers/WithRouterProp';

class DashboardNewCandidates extends Component
{
    state = {};

    componentDidMount() {
        this.setState({indexAvatarStopRender: this.findOverflowAvatarsIndex(document.querySelector('.card-new-candidates'))});
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.displaySize.width !== this.props.displaySize.width || prevProps.candidatesInNewStage.length !== this.props.candidatesInNewStage.length) {
            this.setState({indexAvatarStopRender: this.findOverflowAvatarsIndex(document.querySelector('.card-new-candidates'))});
        }
    }

    openNewCandidates = () => {
        this.props.openNoModalDashboard('DashboardCandidatesInOffersNoModal');
    };

    findOverflowAvatarsIndex = refusedGrid => {
        const candidates       = this.props.candidatesInNewStage;
        const avatarWidth      = 40;
        let reservedSpaceInBox = 136;

        if (candidates === undefined || candidates.length === 0) {
            return;
        }

        for (let i = 0; i < candidates.length; i++) {

            reservedSpaceInBox += avatarWidth;

            if (reservedSpaceInBox > refusedGrid.offsetWidth) {
                return i;
            }
        }
    };

    render = () => {
        const {newCandidatesTotalCount, candidatesInNewStage, isLocked} = this.props;
        const {indexAvatarStopRender}                                   = this.state;

        return (
            <div className="card card-new-candidates" onClick={newCandidatesTotalCount > 0 ? () => this.openNewCandidates() : null}>
                <div className="flex row">
                    <Icon className="icon-user icon-user-background large"/>

                    <div className="flex column">
                        <div className="h1 txt-medium">
                            {newCandidatesTotalCount}
                            {isLocked === true && <Icon className="icon-locked small"/>}
                        </div>
                        <div className="text-paragraph txt-medium">{Translations.getStatic('candidatesInNewStage')}</div>
                    </div>
                </div>
                <div className="avatars-row">
                    {
                        candidatesInNewStage
                            .slice(0, indexAvatarStopRender)
                            .map(candidate => (
                                <CandidateAvatar
                                    key={candidate.id}
                                    firstname={candidate.firstname}
                                    surname={candidate.surname}
                                    isContacted={false}
                                    profilePhoto={candidate.profilePhoto}
                                />
                            ))
                    }
                </div>
            </div>
        );
    };
}

DashboardNewCandidates.propTypes = {
    newCandidatesTotalCount: PropTypes.number.isRequired,
    candidatesInNewStage: PropTypes.array.isRequired,
    isLocked: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    displaySize: state.displaySize,
});

const mapDispatchToProps = {
    openNoModalDashboard,
};

export default connect(mapStateToProps, mapDispatchToProps)(WithRouterProp(DashboardNewCandidates));



