import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import md5 from 'md5';
import {
    requestMultiAttachmentAssistantModel,
    addAttachmentsInBulk,
    removeAttachmentFromBulk,
    addAttachmentToEmail,
    removeAttachmentFromEmail,
    addIntoSelectedAttachmentTypes,
    removeFromSelectedAttachmentTypes,
    removeTemplateAttachment,
} from '../../actions/AttachmentAssistantActions';
import AttachmentAssistantHelper from '../../helpers/components/AttachmentAssistantHelper';
import FileUploadAttachmentsList from './FileUploadAttachmentsList';
import AttachmentAssistantActionButton from './AttachmentAssistantActionButton';
import MultiAttachmentAssistantTypesSelector from './MultiAttachmentAssistantTypesSelector';
import Constants from '../../helpers/Constants';
import User from '../../helpers/User';
import TemplateAttachmentsList from './TemplateAttachmentsList';
import Grid from '../Grid';
import Item from '../Item';
import Divider from '../Divider';
import {getCandidatesForAction} from '../../actions/ActionWithCandidateActions';
import {jobUncheckCandidate} from '../../actions/CandidatesListActions';
import Translations from '../../helpers/Translations';
import FormSkeleton from '../../skeletons/components/FormSkeleton';

class MultiAttachmentAssistant extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {
            hashId: this.props.modelHashId,
            errors: [],
        };
    }

    componentDidMount() {
        this.props.requestMultiAttachmentAssistantModel(this.props.candidateIds, this.state.hashId, this.props.templateAttachments);
    }

    onHandleAttachmentTypeCheckboxChange = (typeId, isActive) => {
        if (isActive) {
            this.props.addIntoSelectedAttachmentTypes(typeId, this.state.hashId);
        } else {
            this.props.removeFromSelectedAttachmentTypes(typeId, this.state.hashId);
        }
    };

    onAddAttachments = (fileTypesAllowed, typeId) => {
        const {settings}                = this.props.attachmentAssistantModel;
        const attachmentAssistantHelper = new AttachmentAssistantHelper();
        const selector                  = '.' + Constants.ATTACHMENT_ASSISTANT_CLASS + ' input[type=file][id=attachmentType' + typeId + ']';

        let files = attachmentAssistantHelper.getFileListFromElement(selector);

        const filesTypeErrors       = attachmentAssistantHelper.checkFilesType(files, fileTypesAllowed);
        const singleFilesSizeErrors = attachmentAssistantHelper.checkSingleFilesSizes(files, settings.singleFileSizeUploadLimit);

        if (filesTypeErrors.length === 0 && singleFilesSizeErrors.length === 0) {
            const uploadHash = md5(Date.now() + JSON.stringify(files));

            files = files.map(file => ({
                typeId: typeId,
                file: file,
                uploadHash: uploadHash,
            }));

            this.props.addAttachmentsInBulk(files, this.state.hashId);

            this.setState({
                errors: [],
            });
        } else {
            this.setState({
                errors: filesTypeErrors.concat(singleFilesSizeErrors),
            });
        }
    };

    removeCandidateFromAction = candidateId => {
        const candidateIds = this.props.candidateActionModel.candidates
            .filter(candidate => candidate.id !== candidateId)
            .map(candidate => candidate.id);

        this.props.jobUncheckCandidate(candidateId);
        this.props.getCandidatesForAction(candidateIds);
    };

    render() {
        if (this.props.attachmentAssistantModel.hasOwnProperty('selectedAttachmentTypes') === false) {
            return <FormSkeleton itemsCount={1}/>;
        }

        const {settings, bulkUploadAttachments, selectedAttachmentTypes, selectedAttachmentTypesSizes, templateAttachments} = this.props.attachmentAssistantModel;

        const attachmentTypes = settings.attachmentTypes.filter(attachmentType => attachmentType.useType === Constants.ATTACHMENT_USE_TYPE_FILE_UPLOAD);

        const isExternalUser = User.isExternalUser(this.props.loginManagerModel);

        const oversizedCandidates = AttachmentAssistantHelper.getCandidatesWithExceededAttachmentsSize(this.props.attachmentAssistantModel, this.props.jobModel);

        return (
            <div className={Constants.ATTACHMENT_ASSISTANT_CLASS + ' list'}>
                <MultiAttachmentAssistantTypesSelector
                    selectedAttachmentTypes={selectedAttachmentTypes}
                    selectedAttachmentTypesSizes={selectedAttachmentTypesSizes}
                    settings={settings}
                    onHandleAttachmentTypeCheckboxChange={this.onHandleAttachmentTypeCheckboxChange}
                />

                {
                    <TemplateAttachmentsList
                        templateAttachments={templateAttachments}
                        onRemoveTemplateAttachment={attachmentId => this.props.removeTemplateAttachment(attachmentId, this.state.hashId)}
                    />
                }

                <FileUploadAttachmentsList
                    attachmentTypes={attachmentTypes.map(attachmentType => attachmentType.id)}
                    bulkUploadAttachments={bulkUploadAttachments}
                    candidateAttachments={[]}
                    liveUploadAttachments={[]}
                    settings={settings}
                    onRemoveAttachmentFromBulk={attachmentKey => this.props.removeAttachmentFromBulk(attachmentKey, this.state.hashId)}
                    onAddAttachmentToEmail={attachment => this.props.addAttachmentToEmail(attachment, this.state.hashId)}
                    removeCandidateAttachment={attachmentId => this.props.removeAttachmentFromEmail(attachmentId, this.state.hashId)}
                />

                {
                    isExternalUser === true
                    || (
                        <AttachmentAssistantActionButton
                            attachmentTypes={attachmentTypes}
                            settings={settings}
                            typeCounts={{}}
                            onAddAttachments={this.onAddAttachments}
                        />
                    )
                }

                {
                    oversizedCandidates.length === 0
                        ? null
                        : (
                            <>
                                <Divider hidden className="size_16"/>
                                <div>
                                    <div className="prompt label">{Translations.getStatic('emailAttachmentsMaxSizeExceededMulti')}</div>

                                    <Divider hidden className="size_16"/>

                                    <Grid className="data-block data-block_primary">
                                        <Grid.Row className="padding-top_none">
                                            <Grid.Column>
                                                <div className="ui items unstackable">
                                                    {
                                                        oversizedCandidates.map(candidate => (
                                                            <Item className="data-block__content attachment basic">
                                                                <Grid verticalAlign="middle" container>
                                                                    <Grid.Row>
                                                                        <Grid.Column computer={12} tablet={11}>
                                                                            <Item.Header as="h5" className="ellipsis">
                                                                                {[candidate.firstname, candidate.surname].join(' ').trim()}
                                                                            </Item.Header>
                                                                        </Grid.Column>
                                                                        <Grid.Column computer={4} textAlign="right">
                                                                            <a onClick={() => this.removeCandidateFromAction(candidate.id)} className="link secondary-link">{Translations.getStatic('remove')}</a>
                                                                        </Grid.Column>
                                                                    </Grid.Row>
                                                                </Grid>
                                                            </Item>
                                                        ))
                                                    }
                                                </div>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </div>
                            </>
                        )
                }

                {
                    this.state.errors.length > 0
                    && this.state.errors.map((error, i) => (
                        <React.Fragment key={i}>
                            <Divider hidden className="size_16"/>
                            <div className="prompt label">{error}</div>
                        </React.Fragment>
                    ))
                }
            </div>
        );
    }
}

MultiAttachmentAssistant.propTypes = {
    modelHashId: PropTypes.string.isRequired,
    candidateIds: PropTypes.array.isRequired,
    templateAttachments: PropTypes.array,
};

MultiAttachmentAssistant.defaultProps = {
    templateAttachments: [],
};

const mapStateToProps = state => ({
    attachmentAssistantModel: AttachmentAssistantHelper.getActiveModelFromAssistantAttachmentModel(
        state.attachmentAssistantModel,
        AttachmentAssistantHelper.createAttachmentAssistantHashIdForEmail(),
    ),

    candidateActionModel: state.candidateActionModel,
    loginManagerModel: state.loginManagerModel,
    jobModel: state.jobModel,
});

const mapDispatchToProps = {
    requestMultiAttachmentAssistantModel,
    addAttachmentsInBulk,
    removeAttachmentFromBulk,
    addAttachmentToEmail,
    removeAttachmentFromEmail,
    addIntoSelectedAttachmentTypes,
    removeFromSelectedAttachmentTypes,
    removeTemplateAttachment,
    getCandidatesForAction,
    jobUncheckCandidate,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MultiAttachmentAssistant);
