import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import MarkModal from '../Modal/MarkModal';
import {closeModal, openModalSecond} from '../../actions/ModalActions';
import {processPipelineTemplate} from '../../actions/PipelineTemplatesSettingsActions';
import SettingsPipelineTemplateEditor from '../Settings/SettingsPipelineTemplateEditor';
import PipelineTemplateValidatorHelper from '../../helpers/components/PipelineTemplateValidatorHelper';
import SuccessMessage from '../../helpers/SuccessMessage';
import TemplateAssistantHelper from '../../helpers/components/TemplateAssistantHelper';
import Translations from '../../helpers/Translations';

class SettingsPagePipelineTemplateEditorModal extends Component
{
    componentDidUpdate = prevProps => {
        if ((SuccessMessage.canShowSuccessMessage(prevProps, this.props, 'isProcessingPipelineTemplate'))
            && this.props.pipelineTemplatesSettingsModel.hasOwnProperty('isSuccess') === true
            && this.props.pipelineTemplatesSettingsModel.isSuccess === true
        ) {
            this.props.closeModal();
        }
    };

    processPipelineTemplate = () => {
        const {pipelineTemplateId, pipelineTemplateEditorModel, pipelineTemplatesSettingsModel, templateAssistant} = this.props;
        const {pipelineTemplateData, enabledSwitchableFixedStages, pipelineStagesForEditor}                        = pipelineTemplateEditorModel;
        const {pipelineTemplates}                                                                                  = pipelineTemplatesSettingsModel;

        const templateSavingMode = TemplateAssistantHelper.getTemplateSavingMode(
            templateAssistant.pipelineTemplate,
        );

        let checkAgainst = templateSavingMode === TemplateAssistantHelper.TEMPLATE_SAVING_MODE_UPDATE
            ? pipelineTemplates.filter(pipelineTemplate => parseInt(pipelineTemplate.id) !== parseInt(pipelineTemplateId))
            : pipelineTemplates;
        checkAgainst     = checkAgainst.map(pipelineTemplate => pipelineTemplate.name);

        const validator = new PipelineTemplateValidatorHelper(pipelineTemplateData, pipelineStagesForEditor);
        const errors    = validator.checkPipelineTemplate(true, true, checkAgainst);

        this.setState({errors});

        if (errors.length === 0) {
            let formData = new FormData();

            formData.append('pipelineTemplateSavingMode', templateSavingMode);
            formData.append('pipelineTemplateData', JSON.stringify({
                templateData: pipelineTemplateData,
                enabledSwitchableFixedStages: enabledSwitchableFixedStages,
                pipelineStagesForEditor: pipelineStagesForEditor,
            }));

            this.props.processPipelineTemplate(formData, parseInt(pipelineTemplateId), templateSavingMode);
        }
    };

    render() {
        const {
                  isOpen,
                  closeModal,
                  isFetchingPipelineTemplateEditorModel,
                  isProcessingPipelineTemplate,
                  pipelineTemplateId,
                  isCopy,
              }                  = this.props;
        const isPerformingAction = isFetchingPipelineTemplateEditorModel === true || isProcessingPipelineTemplate === true;

        return (
            <MarkModal
                isOpen={isOpen}
                closeModal={closeModal}
                isLoadingData={isFetchingPipelineTemplateEditorModel}

                actionButton={{
                    text: Translations.getStatic('savePipelineTemplate'),
                    disabled: isPerformingAction,
                    loading: isPerformingAction,
                    onClick: this.processPipelineTemplate,
                }}

                title={Translations.getStatic('pipelineTemplateSettings')}

                content={(
                    <SettingsPipelineTemplateEditor
                        pipelineTemplateId={pipelineTemplateId}
                        isCopy={isCopy}
                    />
                )}
            />
        );
    }
}

SettingsPagePipelineTemplateEditorModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    isCopy: PropTypes.bool.isRequired,
    pipelineTemplateId: PropTypes.number.isRequired,
};

SettingsPagePipelineTemplateEditorModal.defaultProps = {
    pipelineTemplateId: 0,
    isCopy: false,
};

const mapStateToProps = state => ({
    isOpen: state.modalLogic.isOpen,
    pipelineTemplateEditorModel: state.pipelineTemplateEditorModel,
    pipelineTemplatesSettingsModel: state.pipelineTemplatesSettingsModel,
    templateAssistant: state.templateAssistant,
    isFetchingPipelineTemplateEditorModel: state.isFetchingPipelineTemplateEditorModel,
    isProcessingPipelineTemplate: state.isProcessingPipelineTemplate,
});

const mapDispatchToProps = {
    closeModal,
    openModalSecond,
    processPipelineTemplate,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SettingsPagePipelineTemplateEditorModal);
