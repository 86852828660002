import React from 'react';
import Form from '../Form';
import Grid from '../Grid';
import Divider from '../Divider';
import Heading from '../Heading';
import PropTypes from 'prop-types';
import EmailEditor from '../Email/EmailEditor';
import TemplateAssistantSettings from '../TemplateAssistant/TemplateAssistantSettings';
import TemplateDropdownSelector from '../TemplateAssistant/TemplateDropdownSelector';
import {connect} from 'react-redux';
import {
    requestSendEmailModel,
    updateEmailTemplate,
    setEmailRecipients,
    setSendEmailAt, setEmailSender, setEmailBcc, getVideoInterviewData,
} from '../../actions/SendEmailActions';
import {fetchEmailTemplates} from '../../actions/TemplateDropdownSelectorActions';
import {saveAsChangedEmailTemplate, saveAsNewEmailTemplate} from '../../actions/TemplateAssistantActions';
import AttachmentAssistant from '../AttachmentsAssistant/AttachmentsAssistant';
import MultiAttachmentAssistant from '../AttachmentsAssistant/MultiAttachmentAssistant';
import NotificationMessage from '../NotificationMessage';
import AttachmentAssistantHelper from '../../helpers/components/AttachmentAssistantHelper';
import Translations from '../../helpers/Translations';
import Language from '../../helpers/Language';
import Constants from '../../helpers/Constants';
import UserRights from '../../helpers/UserRights';
import User from '../../helpers/User';
import FormSkeleton from '../../skeletons/components/FormSkeleton';

class SendEmail extends React.Component
{
    state = {
        selectedEmailTemplate: this.props.sendEmailModel.hasOwnProperty('emailTemplateData') === true && this.props.sendEmailModel.emailTemplateData.id !== null
            ? this.props.sendEmailModel.emailTemplateData.id
            : null,
        languageId: this.props.loginManagerModel.languages.find(language => language.languageCode === Language.get()).languageId,
    };

    componentDidMount() {
        const {selectedEmailTemplate, languageId}                                     = this.state;
        const {loginManagerModel, sendEmailModel, recipients, bcc, emailTemplateType} = this.props;

        if (sendEmailModel.hasOwnProperty('emailTemplateData') === false
            || JSON.stringify(recipients) !== JSON.stringify(sendEmailModel.recipients)
            || JSON.stringify(bcc) !== JSON.stringify(sendEmailModel.bcc)
        ) {
            this.props.requestSendEmailModel(selectedEmailTemplate, User.getUserId(loginManagerModel), recipients, bcc, languageId, false, emailTemplateType, this.props.messageHistory, this.props.isMassForwarding);
        }
    }

    componentDidUpdate(prevProps) {
        const requestSendEmailModel = (
            (prevProps.sendEmailModel.hasOwnProperty('emailTemplateData') === false && this.props.sendEmailModel.hasOwnProperty('emailTemplateData') === true && this.state.selectedEmailTemplate === null && prevProps.isFetchingSendEmailModel === false)
            || (this.props.sendEmailModel.hasOwnProperty('emailTemplateData') === true && prevProps.eventType !== this.props.eventType && this.props.eventType === Constants.CANDIDATE_EVENT_TYPE_VIDEO_INTERVIEW)
        );

        if (requestSendEmailModel === true) {
            const newLanguageCode = this.props.loginManagerModel.languages.find(language => language.languageId === this.props.sendEmailModel.emailTemplateData.languageId).languageCode;

            const selectedEmailTemplate = this.props.eventType === Constants.CANDIDATE_EVENT_TYPE_VIDEO_INTERVIEW
                ? Constants.CANDIDATE_VIDEO_INTERVIEW_DEFAULT_TEMPLATE_IDS.find(template => template.languageCode === newLanguageCode).templateId
                : this.props.sendEmailModel.emailTemplateData.id;

            if (selectedEmailTemplate !== this.state.selectedEmailTemplate) {
                this.setState({
                    selectedEmailTemplate,
                });

                this.props.requestSendEmailModel(selectedEmailTemplate, User.getUserId(this.props.loginManagerModel), this.props.recipients, this.props.bcc, this.state.languageId, false, this.props.emailTemplateType, this.props.messageHistory, this.props.isMassForwarding);
            }
        }

        if (this.props.sendEmailModel.hasOwnProperty('emailTemplateData') === true && this.props.sendEmailModel.emailTemplateData.languageId !== this.state.languageId) {
            this.setState({
                languageId: this.props.sendEmailModel.emailTemplateData.languageId,
            });
        }

        if (this.props.sendEmailModel.hasOwnProperty('settings') === true && this.props.sendEmailModel.settings.hasOwnProperty('videoInterview') === false && this.props.isGettingVideoInterviewData === false) {
            this.props.getVideoInterviewData();
        }
    }

    onSetEmailRecipient = email => {
        this.props.setEmailRecipients([{
            value: email,
            text: email,
            isEditable: true,
        }]);
    };

    selectEmailTemplate = emailTemplateId => {
        if (parseInt(emailTemplateId) !== parseInt(this.state.selectedEmailTemplate)) {
            this.setState({
                selectedEmailTemplate: emailTemplateId,
            });

            this.props.requestSendEmailModel(emailTemplateId, User.getUserId(this.props.loginManagerModel), this.props.recipients, this.props.bcc, this.state.languageId, false, this.props.emailTemplateType, this.props.messageHistory, this.props.isMassForwarding);
            this.props.saveAsChangedEmailTemplate(false);
            this.props.saveAsNewEmailTemplate(false);
        }
    };

    changeLanguage = languageId => {
        const languageCode = this.props.loginManagerModel.languages.find(language => language.languageId === languageId).languageCode;

        const selectedEmailTemplate = this.props.eventType === Constants.CANDIDATE_EVENT_TYPE_VIDEO_INTERVIEW
            ? Constants.CANDIDATE_VIDEO_INTERVIEW_DEFAULT_TEMPLATE_IDS.find(template => template.languageCode === languageCode).templateId
            : 0;

        this.setState({
            selectedEmailTemplate,
            languageId,
        });

        this.props.requestSendEmailModel(selectedEmailTemplate, User.getUserId(this.props.loginManagerModel), this.props.recipients, this.props.bcc, languageId, false, this.props.emailTemplateType, this.props.messageHistory, this.props.isMassForwarding);
        this.props.saveAsChangedEmailTemplate(false);
        this.props.saveAsNewEmailTemplate(false);
    };

    render() {
        let autoAnswerEditorContent = null;
        let hasChanges              = false;

        const {
                  sendEmailModel, loginManagerModel, templateAssistant, isRemovingColleagueContact, eventType,
                  emailTemplateType, isFetchingSendEmailModel, isFetchingTemplatesForDropdownSelector,
              } = this.props;

        if (isFetchingSendEmailModel === true || isFetchingTemplatesForDropdownSelector === true) {
            return <FormSkeleton/>;
        }

        if (sendEmailModel.hasOwnProperty('emailTemplateData') === true) {
            const withMessageHistory = this.props.messageHistory !== null;

            const settings = (
                <TemplateAssistantSettings
                    isModal={true}
                    templateData={sendEmailModel.emailTemplateData}
                    strings={{
                        saveChangesInTemplate: Translations.getStatic('saveChangesInEmailTemplate'),
                        saveAsNewTemplate: Translations.getStatic('saveAsNewEmailTemplate'),
                        doNotSaveChangesInTemplate: Translations.getStatic('doNotSaveChangesInEmailTemplate'),
                    }}
                    isChangedTemplate={templateAssistant.emailTemplate.isChangedTemplate}
                    isNewTemplate={templateAssistant.emailTemplate.isNewTemplate}
                    onUpdateTemplate={this.props.updateEmailTemplate}
                    onSaveAsChangedTemplate={this.props.saveAsChangedEmailTemplate}
                    onSaveAsNewTemplate={this.props.saveAsNewEmailTemplate}
                    customMessageForTemplateChangesWillSaveAfterAction={Translations.getStatic('emailTemplateChangesWillSaveAfterAction')}
                    templateType={Constants.TEMPLATE_TYPE_EMAIL}
                    settings={sendEmailModel.settings}
                />
            );

            hasChanges = sendEmailModel.emailTemplateData.hasOwnProperty('currentChangeHash') === true && sendEmailModel.emailTemplateData.currentChangeHash !== sendEmailModel.emailTemplateData.changeHash;

            const actionsWrapper = hasChanges === true && UserRights.hasAllowedEditableEmailTemplates(this.props.loginManagerModel.activePackage) === true
                ? (
                    <>
                        <div className={'template-assistant-settings'}>
                            {settings}
                        </div>
                    </>
                )
                : null;

            autoAnswerEditorContent = (
                <>
                    {actionsWrapper}

                    <Divider className="column"/>

                    <Heading as="h3">{Translations.getStatic('message')}</Heading>

                    <Grid className="padding-top_none">
                        <EmailEditor
                            cursorOnStart={withMessageHistory}
                            languageId={this.state.languageId}
                            errors={this.props.errors}
                            recipients={sendEmailModel.recipients}
                            bcc={sendEmailModel.bcc}
                            sender={sendEmailModel.sender}
                            visibleFields={this.props.visibleFields}
                            sendEmailAt={sendEmailModel.sendAt}
                            emailData={sendEmailModel.emailTemplateData}
                            settings={sendEmailModel.settings}
                            loginManagerModel={loginManagerModel}
                            isRemovingColleagueContact={isRemovingColleagueContact}
                            onUpdateEmailTemplate={this.props.updateEmailTemplate}
                            onSetSendEmailAt={this.props.setSendEmailAt}
                            onSetEmailRecipient={this.onSetEmailRecipient}
                            onSetEmailBcc={this.props.setEmailBcc}
                            onSetEmailRecipients={this.props.setEmailRecipients}
                            onSetEmailSender={this.props.setEmailSender}
                        />

                        <Grid.Row>
                            <Grid.Column>
                                {
                                    this.props.candidateIds.length > 1
                                        ? (
                                            <MultiAttachmentAssistant
                                                candidateIds={this.props.candidateIds}
                                                templateAttachments={
                                                    sendEmailModel.hasOwnProperty('emailTemplateData') === true
                                                        ? sendEmailModel.emailTemplateData.attachments
                                                        : []
                                                }
                                                modelHashId={AttachmentAssistantHelper.createAttachmentAssistantHashIdForEmail()}
                                            />
                                        )
                                        : (
                                            <AttachmentAssistant
                                                candidateId={this.props.candidateIds.length === 1 ? this.props.candidateIds[0] : 0}
                                                templateAttachments={
                                                    sendEmailModel.hasOwnProperty('emailTemplateData') === true
                                                        ? sendEmailModel.emailTemplateData.attachments
                                                        : []
                                                }
                                                modelHashId={AttachmentAssistantHelper.createAttachmentAssistantHashIdForEmail()}
                                                isFileUploadOnly={true}
                                                isEmailSending={true}
                                            />
                                        )
                                }
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </>
            );
        }

        return (
            <>
                {
                    eventType === Constants.CANDIDATE_EVENT_TYPE_VIDEO_INTERVIEW
                    && (
                        <>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NotificationMessage
                                            title={Translations.getStatic('videoInterviewByProfesia')}
                                            description={Translations.getStatic('videoInterviewByProfesiaInfo')}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            <Divider hidden/>
                        </>
                    )
                }

                {
                    emailTemplateType === Constants.EMAIL_TEMPLATE_TYPE_QUESTIONNAIRE_REQUEST
                    && (
                        <>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NotificationMessage
                                            title={Translations.getStatic('requestCandidateQuestionnaireInfoBoxTitle')}
                                            description={Translations.getStatic('requestCandidateQuestionnaireInfoBoxText')}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            <Divider hidden/>
                        </>
                    )
                }

                <Heading as="h3">{Translations.getStatic('emailTemplate')}</Heading>

                {
                    this.props.isFetchingSendEmailModel === false
                    && sendEmailModel.hasOwnProperty('emailTemplateData') === true
                    && (
                        <TemplateDropdownSelector
                            onStartFetchingData={() => this.props.fetchEmailTemplates(User.getUserId(this.props.loginManagerModel), this.state.languageId)}
                            onSelectedValue={this.selectEmailTemplate}
                            emptyItem={0}
                            changeSelectedItem={hasChanges}
                            defaultValue={this.state.selectedEmailTemplate}
                            noSpacing={this.props.noSpacing}
                            languageFilter={{
                                languages: this.props.loginManagerModel.languages,
                                languageId: this.state.languageId,
                                onChangeLanguage: this.changeLanguage,
                            }}
                            emailTemplateType={emailTemplateType}
                            templatesDropdownLabel={Translations.getStatic('emailTemplate')}
                            templateType={Constants.TEMPLATE_TYPE_EMAIL}
                        />
                    )
                }

                <Form>
                    {autoAnswerEditorContent}
                </Form>
                <Divider hidden className="small"/>
            </>
        );
    }
}

const mapStateToProps = state => ({
    isFetchingSendEmailModel: state.isFetchingSendEmailModel,
    sendEmailModel: state.sendEmailModel,
    loginManagerModel: state.loginManagerModel,
    templateAssistant: state.templateAssistant,
    isAddingNewColleagueContact: state.isAddingNewColleagueContact,
    isRemovingColleagueContact: state.isRemovingColleagueContact,
    isGettingVideoInterviewData: state.isGettingVideoInterviewData,
    isFetchingTemplatesForDropdownSelector: state.isFetchingTemplatesForDropdownSelector,
});

const mapDispatchToProps = {
    requestSendEmailModel,
    updateEmailTemplate,
    setEmailRecipients,
    setEmailBcc,
    setEmailSender,
    fetchEmailTemplates,
    saveAsNewEmailTemplate,
    saveAsChangedEmailTemplate,
    setSendEmailAt,
    getVideoInterviewData,
};

const SendEmailContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(SendEmail);

SendEmailContainer.propTypes = {
    recipients: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
    })).isRequired,

    bcc: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
    })),

    candidateIds: PropTypes.array,
    visibleFields: PropTypes.array,
    errors: PropTypes.array,
    emailTemplateType: PropTypes.number.isRequired,
    eventType: PropTypes.number,
    messageHistory: PropTypes.object,
    isMassForwarding: PropTypes.bool,
};

SendEmailContainer.defaultProps = {
    candidateIds: [],
    bcc: [],
    errors: [],
    messageHistory: null,
    isMassForwarding: false,
};

export default SendEmailContainer;
