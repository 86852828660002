export const REQUEST_CLOSE_JOB_MODEL             = 'REQUEST_CLOSE_JOB_MODEL';
export const RECEIVE_CLOSE_JOB_MODEL             = 'RECEIVE_CLOSE_JOB_MODEL';
export const ENABLE_CLOSE_JOB_STEP               = 'ENABLE_CLOSE_JOB_STEP';
export const ACTIVATE_CLOSE_JOB_STEP             = 'ACTIVATE_CLOSE_JOB_STEP';
export const ENABLE_SENDING_EMAIL                = 'ENABLE_SENDING_EMAIL';
export const DISABLE_SENDING_EMAIL               = 'DISABLE_SENDING_EMAIL';
export const ENABLE_REFUSING_CANDIDATES          = 'ENABLE_REFUSING_CANDIDATES';
export const DISABLE_REFUSING_CANDIDATES         = 'DISABLE_REFUSING_CANDIDATES';
export const SET_CANDIDATE_IDS_FOR_EMAIL_SENDING = 'SET_CANDIDATE_IDS_FOR_EMAIL_SENDING';
export const SET_CANDIDATES_EMAIL_SENDING_STATE  = 'SET_CANDIDATES_EMAIL_SENDING_STATE';

export const requestCloseJobModel = (jobId = null) => ({
    type: REQUEST_CLOSE_JOB_MODEL,
    meta: {
        requestRoute: 'v1/job/model/close/' + (jobId === null ? 0 : jobId),

        onRequestSuccess: RECEIVE_CLOSE_JOB_MODEL,
    },
});

export const activateCloseJobStep = (stepAction) => ({
    type: ACTIVATE_CLOSE_JOB_STEP,
    payload: {
        stepAction,
    },
});

export const enableCloseJobStep = (stepAction) => ({
    type: ENABLE_CLOSE_JOB_STEP,
    payload: {
        stepAction,
    },
});

export const enableSendingEmail = () => ({
    type: ENABLE_SENDING_EMAIL,
});

export const disableSendingEmail = () => ({
    type: DISABLE_SENDING_EMAIL,
});

export const enableRefusingCandidates = () => ({
    type: ENABLE_REFUSING_CANDIDATES,
});

export const disableRefusingCandidates = () => ({
    type: DISABLE_REFUSING_CANDIDATES,
});

export const setCandidateIdsForEmailSending = candidateIds => ({
    type: SET_CANDIDATE_IDS_FOR_EMAIL_SENDING,
    payload: {
        candidateIds,
    },
});

export const setCandidatesEmailSendingState = emailSendingState => ({
    type: SET_CANDIDATES_EMAIL_SENDING_STATE,
    payload: {
        emailSendingState,
    },
});


