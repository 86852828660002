import Url from './Url';

class Log {
    static error(message: string, data: string = ''): void {
        if (data.length !== 0) {
            console.error('%cERROR: ' + message, 'color: red;', data);
        } else {
            console.error('%cERROR: ' + message, 'color: red;');
        }
    }

    static warning(message: string, data: string = ''): void {
        if (data.length !== 0) {
            console.warn('%cWARNING: ' + message, 'color: orange;', data);
        } else {
            console.warn('%cWARNING: ' + message, 'color: orange;');
        }
    }

    static time(message: string): void {
        console.log('%cTIME: ' + message, 'color: lightblue;');
    }

    static api(message: string): void {
        console.log('%cAPI: ' + message, 'color: violet;');
    }

    static state(message: string, data: string = ''): void {
        if (data.length !== 0) {
            console.log('%c' + message, 'color: orange;', data);
        } else {
            console.log('%c' + message, 'color: orange;');
        }
    }

    static action(message: string, data: string = ''): void {
        if (data.length !== 0) {
            console.log('%cACTION: ' + message, 'color: lightgreen;', data);
        } else {
            console.log('%cACTION: ' + message, 'color: lightgreen;');
        }
    }

    static storage(message: string): void {
        console.info('%c' + message, 'color: pink;');
    }

    static message(message: string): void {
        if (!Url.isOnline()) {
            console.log(message);
        }
    }
}

export default Log;
