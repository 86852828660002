import {
    APPLY_DEFAULT_PIPELINE_TEMPLATE_TO_JOB_SUCCESS, APPLY_PIPELINE_TEMPLATE_TO_JOB_SUCCESS, PROCESS_PIPELINE_TEMPLATE_FOR_JOB_SUCCESS,
    RECEIVE_JOB_MODEL,
} from '../actions/JobDetailActions';

import {
    RECEIVE_GLOBAL_SEARCH_MODEL,
    GLOBAL_SEARCH,
    CONFIRM_FILTER,
} from '../actions/GlobalSearchActions';

import {
    FILTER_CANDIDATES_BY_NAME,
    FILTER_CANDIDATES_BY_SCORE_RANGE,
    FILTER_CANDIDATES_BY_SOURCE_TYPE_COLLECTION,
    FILTER_CANDIDATES_BY_STATE,
    FILTER_CANDIDATES_BY_STAGE_COLLECTION,
    FILTER_CANDIDATES_BY_STATE_RESET,
    FILTER_CANDIDATES_BY_TAG_LIST,
    FILTER_CANDIDATES_BY_PHASE,
    FILTER_CANDIDATES_BY_REFUSED_AND_NOT_CONTACTED,
    FILTER_CANDIDATES_BY_STATE_ACTIVE,
    FILTER_CANDIDATES_BY_STATE_PASSIVE,
    FILTER_CANDIDATES_BY_ANONYMIZED,
    FILTER_CANDIDATES_BY_NOT_CONTACTED,
    FILTER_CANDIDATES_BY_NO_GDPR_AGREEMENT,
    FILTER_CANDIDATES_BY_ADD_DATE,
    FILTER_CANDIDATES_BY_CANDIDATE_IDS,
    FILTER_CANDIDATES_BY_NOTE,
    CANCEL_FILTER_BY_CANDIDATE_IDS,
    COLLAPSING_FILTERS, RESET_FILTER, UPDATE_DEFAULT_FILTER,
} from '../actions/CandidatesFilterActions';

import Constants from '../helpers/Constants';
import {CANCEL_CHUNKS_LOAD} from '../actions/ChunkCancellationActions';
import CandidateFilterHelper from '../helpers/components/CandidateFilterHelper';

export const candidateFilter = (state = {}, action) => {
    let sourceTypeCollection = [];
    let stageCollectionIds   = [Constants.CANDIDATE_STAGE_UNKNOWN_ID];
    let defaultFilter        = null;

    switch (action.type) {
        case RECEIVE_JOB_MODEL:
            sourceTypeCollection = action.payload.settings.sources.map(source => parseInt(source.sourceId));
            stageCollectionIds   = action.payload.settings.pipeline !== null ? stageCollectionIds.concat(action.payload.settings.pipeline.stages.map(stage => parseInt(stage.stageId))) : [];
            defaultFilter        = {
                ...CandidateFilterHelper.getDefaultFilter(sourceTypeCollection, stageCollectionIds),
            };

            return {
                ...state,
                ...defaultFilter,
                collapsedFilters: [
                    Constants.FILTER_NAME_PERIOD,
                    Constants.FILTER_NAME_CANDIDATE_SOURCES,
                    Constants.FILTER_NAME_QUESTIONNAIRE_RESULTS,
                    Constants.FILTER_NAME_CANDIDATE_STAGE,
                    Constants.FILTER_NAME_PROCESS_STEP,
                ],
                defaultFilter,
            };

        case RECEIVE_GLOBAL_SEARCH_MODEL:
            sourceTypeCollection = action.payload.settings.sources.map(source => parseInt(source.sourceId));
            defaultFilter        = {
                ...CandidateFilterHelper.getDefaultFilter(sourceTypeCollection, []),
            };

            return {
                ...state,
                ...defaultFilter,
                searchText: action.meta.requestPayload.candidateName,
                collapsedFilters: [
                    Constants.FILTER_NAME_PERIOD,
                    Constants.FILTER_NAME_CANDIDATE_SOURCES,
                ],
                defaultFilter,
            };

        case GLOBAL_SEARCH:
            return Object.assign({}, state, {
                searchText: action.payload.candidateName,
            });

        case FILTER_CANDIDATES_BY_NAME:
            return {
                ...state,
                searchText: action.payload.searchText,
            };

        case FILTER_CANDIDATES_BY_PHASE:
            return {
                ...state,
                phaseId: action.payload.phaseId,
            };

        case FILTER_CANDIDATES_BY_STATE:
            return {
                ...state,
                stateId: action.payload.stateId,
                isRefusedAndNotContacted: action.payload.stateId === Constants.CANDIDATES_LIST_FILTER_BY_STATE_ID_ACTIVE
                    ? false
                    : state.isRefusedAndNotContacted,
            };

        case FILTER_CANDIDATES_BY_STAGE_COLLECTION:
            return {
                ...state,
                stageCollectionIds: action.payload.stageIds,
            };

        case FILTER_CANDIDATES_BY_STATE_RESET:
            return {
                ...state,
                stateId: action.payload.stateId,
                stageCollectionIds: action.payload.stageIds,
            };

        case FILTER_CANDIDATES_BY_TAG_LIST:
            return {
                ...state,
                tagList: action.payload.tagList,
            };

        case FILTER_CANDIDATES_BY_SCORE_RANGE:
            return {
                ...state,
                scoreRange: action.payload.scoreRange,
            };

        case FILTER_CANDIDATES_BY_SOURCE_TYPE_COLLECTION:
            return {
                ...state,
                sourceTypeCollection: action.payload.sourceTypeCollection,
            };

        case FILTER_CANDIDATES_BY_REFUSED_AND_NOT_CONTACTED:
            return {
                ...state,
                isRefusedAndNotContacted: action.payload.isRefusedAndNotContacted,
            };

        case FILTER_CANDIDATES_BY_STATE_ACTIVE:
            return {
                ...state,
                isStateActive: action.payload.isStateActive,
            };

        case FILTER_CANDIDATES_BY_STATE_PASSIVE:
            return {
                ...state,
                isStatePassive: action.payload.isStatePassive,
            };

        case FILTER_CANDIDATES_BY_ANONYMIZED:
            return {
                ...state,
                isAnonymized: action.payload.isAnonymized,
            };

        case FILTER_CANDIDATES_BY_NOT_CONTACTED:
            return {
                ...state,
                isNotContacted: action.payload.isNotContacted,
            };

        case FILTER_CANDIDATES_BY_NO_GDPR_AGREEMENT:
            return {
                ...state,
                noGdprAgreement: action.payload.noGdprAgreement,
            };

        case FILTER_CANDIDATES_BY_ADD_DATE:
            return {
                ...state,
                addDate: action.payload.addDate,
            };

        case FILTER_CANDIDATES_BY_CANDIDATE_IDS:
            return {
                ...state,
                candidateIds: action.payload.candidateIds,
            };

        case FILTER_CANDIDATES_BY_NOTE:
            return {
                ...state,
                note: action.payload.note,
            };

        case CANCEL_FILTER_BY_CANDIDATE_IDS:
            return {
                ...state,
                candidateIds: [],
            };

        case COLLAPSING_FILTERS:
            return {
                ...state,
                collapsedFilters: action.payload.collapsedFilters,
            };

        case CANCEL_CHUNKS_LOAD:
            return {
                ...state,
                ...state.previousFilter,
            };

        case CONFIRM_FILTER:
            return {
                ...state,
                previousFilter: {
                    ...state.previousFilter,
                    searchText: state.searchText,
                    phaseId: state.phaseId,
                    stateId: state.stateId,
                    isRefusedAndNotContacted: state.isRefusedAndNotContacted,
                    stageCollectionIds: state.stageCollectionIds,
                    tagList: state.tagList,
                    scoreRange: state.scoreRange,
                    sourceTypeCollection: state.sourceTypeCollection,
                    isStateActive: state.isStateActive,
                    isStatePassive: state.isStatePassive,
                    isAnonymized: state.isAnonymized,
                    isNotContacted: state.isNotContacted,
                    noGdprAgreement: state.noGdprAgreement,
                    addDate: state.addDate,
                    candidateIds: state.candidateIds,
                    note: state.note,
                },
            };

        case RESET_FILTER:
            return {
                ...state,
                ...state.defaultFilter,
            };

        case UPDATE_DEFAULT_FILTER:
            return {
                ...state,
                defaultFilter: {
                    ...state.defaultFilter,
                    ...action.payload.filterParams,
                },
            };

        case APPLY_DEFAULT_PIPELINE_TEMPLATE_TO_JOB_SUCCESS:
        case APPLY_PIPELINE_TEMPLATE_TO_JOB_SUCCESS:
        case PROCESS_PIPELINE_TEMPLATE_FOR_JOB_SUCCESS:
            if (action.payload.hasOwnProperty('changedStages') === true && action.payload.changedStages !== null && action.payload.changedStages.length > 0) {
                let {stageCollectionIds: stageIds} = state;

                action.payload.changedStages.forEach(changedStage => {
                    stageIds = stageIds.filter(stageId => parseInt(stageId) !== parseInt(changedStage.fromStageId));
                    stageIds = stageIds.concat([changedStage.toStageId]);
                });

                let result = {
                    ...state,
                    stageCollectionIds: stageIds,
                    defaultFilter: {
                        ...state.defaultFilter,
                        stageCollectionIds: stageIds,
                    },
                };

                if (state.hasOwnProperty('previousFilter') === true) {
                    let {stageCollectionIds: previousStageIds} = state.previousFilter;

                    action.payload.changedStages.forEach(changedStage => {
                        previousStageIds = previousStageIds.filter(stageId => parseInt(stageId) !== parseInt(changedStage.fromStageId));
                        previousStageIds = previousStageIds.concat([changedStage.toStageId]);
                    });

                    result = {
                        ...result,
                        previousFilter: {
                            ...result.previousFilter,
                            stageCollectionIds: previousStageIds,
                        },
                    };
                }

                return result;
            }

            return state;

        default:
            return state;
    }
};
