export const REQUEST_GDPR_MANAGER_MODEL          = 'REQUEST_GDPR_MANAGER_MODEL';
export const RECEIVE_GDPR_MANAGER_MODEL          = 'RECEIVE_GDPR_MANAGER_MODEL';
export const REQUEST_GDPR_MANAGER_EXTENDED_MODEL = 'REQUEST_GDPR_MANAGER_EXTENDED_MODEL';
export const RECEIVE_GDPR_MANAGER_EXTENDED_MODEL = 'RECEIVE_GDPR_MANAGER_EXTENDED_MODEL';
export const CONFIRM_GDPR_AGREEMENT              = 'CONFIRM_GDPR_AGREEMENT';
export const CONFIRM_GDPR_AGREEMENT_SUCCESS      = 'CONFIRM_GDPR_AGREEMENT_SUCCESS';
export const CONFIRM_GDPR_AGREEMENT_FAILURE      = 'CONFIRM_GDPR_AGREEMENT_FAILURE';

export const requestGdprManagerModel = () => ({
    type: REQUEST_GDPR_MANAGER_MODEL,

    meta: {
        requestRoute: 'v1/gdpr/model/manager',

        onRequestSuccess: RECEIVE_GDPR_MANAGER_MODEL,
    },
});

export const requestGdprManagerExtendedModel = () => ({
    type: REQUEST_GDPR_MANAGER_EXTENDED_MODEL,

    meta: {
        requestRoute: 'v1/gdpr/model/manager/extended',

        onRequestSuccess: RECEIVE_GDPR_MANAGER_EXTENDED_MODEL,
    },
});

export const confirmGdprAgreement = (withTrial = false) => ({
    type: CONFIRM_GDPR_AGREEMENT,

    meta: {
        requestRoute: 'v1/gdpr/action/confirm',
        requestMethod: 'POST',
        requestBody: JSON.stringify({
            withTrial,
        }),

        onRequestSuccess: CONFIRM_GDPR_AGREEMENT_SUCCESS,
        onRequestFailure: CONFIRM_GDPR_AGREEMENT_FAILURE,
    },
});
