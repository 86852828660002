import {CommonObjectType} from '../../interfaces/CommonTypes';

class SuccessMessage
{
    static canShowSuccessMessage(props: CommonObjectType, nextProps: CommonObjectType, successAction: string): boolean {
        return (
            props.hasOwnProperty(successAction)
            && props[successAction] === true
            && nextProps.hasOwnProperty(successAction)
            && nextProps[successAction] === false
        );
    }
}

export default SuccessMessage;
