import React from 'react';
import {connect} from 'react-redux';
import Select from '@profesia/adamui/components/common/dropdown/SelectDefault'
import Form from '../Form';
import Grid from '../Grid';
import Item from '../Item';
import Divider from '../Divider';
import Button from '../Button';
import LoadingComponent from '../LoadingComponent';
import CandidateAvatar from '../CandidateAvatar';
import {requestMicrosoftCalendarData} from '../../actions/CalendarActions';
import Translations from '../../helpers/Translations';

class SettingsPageCalendarSettingsMicrosoftCalendar extends React.Component
{
    componentDidMount = () => {
    };

    openMicrosoftLogin = () => {
        window.open(this.props.microsoft.authUrl, 'microsoftLogin', 'width=640,height=480');
    };

    microsoftLoginClosed = () => {
        this.props.requestMicrosoftCalendarData();
    };

    microsoftCalendarChanged = (value) => {
        this.props.microsoftCalendarChanged(value);
    };

    render = () => {
        const {microsoft, isSettingCalendar, isSynchronizingCalendar} = this.props;

        if (isSettingCalendar === true) {
            return <LoadingComponent/>;
        }

        if (isSynchronizingCalendar === true) {
            return <LoadingComponent/>;
        }

        let selectedCalendar = null;

        if (microsoft.isLoggedInMicrosoft === true) {
            selectedCalendar = this.props.calendarId === null ? microsoft.calendars.find(calendar => calendar.hasOwnProperty('selected') === true && calendar.selected === true) : this.props;

            if (selectedCalendar === undefined) {
                selectedCalendar = null;
            } else {
                selectedCalendar = selectedCalendar.calendarId;
            }
        }

        return (
            <Form>
                {
                    microsoft.isLoggedInMicrosoft === true
                        ? (
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <span className="text-form__label">{Translations.getStatic('connectedToMAccount')}</span>
                                        <Divider hidden className="size_4"/>
                                        <div className='ui items divided'>
                                            <Item>
                                                <Item.Content as="h5" verticalAlign="middle">
                                                    <CandidateAvatar
                                                        firstname={microsoft.user.name === null ? '' : microsoft.user.name.split(' ')[0]}
                                                        surname={microsoft.user.name === null || microsoft.user.name.split(' ').length < 2 ? '' : microsoft.user.name.split(' ')[1]}
                                                        profilePhoto={microsoft.user.picture}
                                                    />
                                                    {microsoft.user.name}
                                                </Item.Content>
                                            </Item>
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                                <Divider hidden className="size_24"/>
                                <Grid.Row>
                                    <Grid.Column computer={8} tablet={16}>
                                        {
                                            microsoft.isLoggedInMicrosoft === true &&
                                            (
                                                <Grid.Row>
                                                    <Grid.Column>
                                                        <Form.Field>
                                                            <Select
                                                                variant='dark'
                                                                value={selectedCalendar}
                                                                label={Translations.getStatic('targetMCal')}
                                                                options={
                                                                    microsoft.calendars.map(calendar => ({
                                                                        key: calendar.calendarId,
                                                                        label: calendar.calendarName,
                                                                        id: calendar.calendarId,
                                                                    }))
                                                                }
                                                                onChange={this.microsoftCalendarChanged}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            )
                                        }
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        )
                        : (
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Button className="primary-button" onClick={this.openMicrosoftLogin}>{Translations.getStatic('logInToMicrosoft')}</Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        )
                }
                <Button hidden basic id="refresh-microsoft-calendar-data" color="grey" icon="close" content="refresh-microsoft-calendar-data" onClick={this.microsoftLoginClosed}/>
            </Form>
        );
    };
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
    requestMicrosoftCalendarData,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SettingsPageCalendarSettingsMicrosoftCalendar);
