import Log from './Log';
import SkTranslation from './../translations/sk';
import CsTranslation from './../translations/cs';
import EnTranslation from './../translations/en';
import HuTranslation from './../translations/hu';
import DeTranslation from './../translations/de';
import Storages from './Storages';
import Language from './Language';
import Channel from './Channel';
import Strings from './Strings';
import * as Sentry from '@sentry/react';
import Url from './Url';
import Constants from './Constants';
import {CommonObjectType} from '../../interfaces/CommonTypes';

class Translations
{
    static translations: CommonObjectType | null = null;
    static language: string                      = 'sk';

    static translatedMark: string    = ''; // '·';
    static notTranslatedMark: string = '';

    static isTranslated(translation: string): string {
        return this.translatedMark + translation + this.translatedMark;
    }

    static notTranslated(translationName: string): string {
        return this.notTranslatedMark + translationName + this.notTranslatedMark;
    }

    static getCurrentTranslations(language: string): CommonObjectType {
        switch (language) {
            case Constants.LANGUAGE_SK:
                return SkTranslation;

            case Constants.LANGUAGE_CS:
                return CsTranslation;

            case Constants.LANGUAGE_HU:
                return HuTranslation;

            case Constants.LANGUAGE_DE:
                return DeTranslation;

            case Constants.LANGUAGE_EN:
            default:
                return EnTranslation;
        }
    }

    static loadTranslations(language: string): void {
        if (language !== this.language || this.translations === null) {
            this.language     = language;
            this.translations = this.getCurrentTranslations(language);
        }
    }

    static getWorkplaceType(workplaceTypeId: number, language: string = ''): string {
        if (language === '') {
            language = Language.get();
        }

        return this.getStatic('workplaceTypeId' + workplaceTypeId.toString(), language);
    }

    static getSource(source: CommonObjectType, sourceTags: CommonObjectType = {}, language: string = ''): string {
        if (language === '') {
            language = Language.get();
        }

        const sourceId = source.hasOwnProperty('sourceId')
            ? source.sourceId
            : source.id;

        const sourceName: string[] = [this.getStatic('sourceId' + sourceId.toString(), language)];

        if (sourceTags.hasOwnProperty('name')) {
            sourceName.push(sourceTags.name);
        }

        return sourceName.join(' - ');
    }

    static getStage(stage: CommonObjectType, language: string = '') {
        if (language === '') {
            language = Language.get();
        }

        const returnStageName = (stage: CommonObjectType) => {
            return stage.hasOwnProperty('stageName')
                ? stage.stageName
                : stage.name;
        };

        if (stage.isDefault === true) {
            const stageId = stage.hasOwnProperty('stageId')
                ? stage.stageId
                : stage.id;

            if (parseInt(stageId) === -1) {
                return returnStageName(stage);
            }

            return this.getStatic('stageId' + stageId.toString(), language);
        }

        return returnStageName(stage);
    }

    static getGdprExpirationPeriodForAgreementConfirmation(months: number, language: string = ''): string {
        if (months % 12 > 0) {
            return this.countDependantStatic(months, ['monthGenitive', 'monthsGenitive', 'monthsGenitive'], true, language);
        }

        return this.countDependantStatic(months / 12, ['yearGenitive', 'yearsGenitive', 'yearsGenitive'], true, language);
    }

    static getStatic(translationName: string, language: string = ''): string {
        if (language === '') {
            language = Language.get();
        }

        this.loadTranslations(language);

        translationName = Channel.replaceCompleteStringByChannel(translationName);

        if (Storages.getLocal(Constants.TRANSLATION_KEYS_INFO_LOCAL_STORAGE_KEY, false) === true) {
            return 'LS_MARK_' + translationName.charAt(0).toUpperCase() + translationName.slice(1);
        }

        if (this.translations?.hasOwnProperty(translationName) === true) {
            return this.isTranslated(Channel.replaceSubstringInStringByChannel(translationName, this.translations[translationName]));
        }

        const message: string = 'Translation with name "' + translationName + '" does NOT exist in GENERAL translation object';

        Log.warning(message);

        const notTranslatedStrings = Storages.getLocal('notTranslatedStrings', []);

        if (notTranslatedStrings.includes(translationName) === false) {
            notTranslatedStrings.push(translationName);

            Storages.setLocal('notTranslatedStrings', notTranslatedStrings);
        }

        if (Url.isOnlineOrMaster()) {
            Sentry.captureEvent({
                message,
            });
        }

        return this.notTranslated(translationName);
    }

    static countDependantStatic(count: number | string = 0, translationsWords: string[] = [], visibleCount: boolean = true, language: string = ''): string {
        if (translationsWords.length !== 3) {
            Log.error('TranslationError: translationsWords count does NOT match the required count');
        }

        if (language === '') {
            language = Language.get();
        }

        count = parseInt('' + count);

        let index: number = 2;

        if (count === 1) {
            index = 0;
        } else if (count >= 2 && count <= 4) {
            index = 1;
        }

        return [visibleCount ? count : '', this.getStatic(translationsWords[index] ?? translationsWords[0] ?? '', language)]
            .filter(val => val !== '')
            .join(' ');
    }

    static getJsTranslationKey(translationName: string): string {
        return Strings.firstLetterLowerCase(translationName.replace('LS_MARK_', ''));
    }
}

export default Translations;
