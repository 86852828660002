import {
    RECEIVE_AUTO_ANSWERS_SETTINGS_MODEL, REQUEST_AUTO_ANSWERS_SETTINGS_MODEL,
    PROCESS_AUTO_ANSWER, PROCESS_AUTO_ANSWER_FAILURE, PROCESS_AUTO_ANSWER_SUCCESS,
    REMOVE_AUTO_ANSWER, REMOVE_AUTO_ANSWER_FAILURE, REMOVE_AUTO_ANSWER_SUCCESS,
    SET_AUTO_ANSWER_AS_DEFAULT, SET_AUTO_ANSWER_AS_DEFAULT_FAILURE, SET_AUTO_ANSWER_AS_DEFAULT_SUCCESS,
    UNSET_AUTO_ANSWER_AS_DEFAULT, UNSET_AUTO_ANSWER_AS_DEFAULT_FAILURE, UNSET_AUTO_ANSWER_AS_DEFAULT_SUCCESS,
} from '../actions/AutoAnswersSettingsActions';
import TemplateAssistantHelper from '../helpers/components/TemplateAssistantHelper';

export const autoAnswersSettingsModel = (state = {}, action) => {
    switch (action.type) {
        case REQUEST_AUTO_ANSWERS_SETTINGS_MODEL:
            return {
                ...state,
                isSuccess: false,
            };
        case RECEIVE_AUTO_ANSWERS_SETTINGS_MODEL:
            return {
                ...state,
                ...action.payload,
            };
        case PROCESS_AUTO_ANSWER_SUCCESS:
            let autoAnswers = action.meta.requestPayload.savingMode === TemplateAssistantHelper.TEMPLATE_SAVING_MODE_UPDATE
                ? state.autoAnswers.filter(autoAnswer => autoAnswer.id !== action.meta.requestPayload.id)
                : state.autoAnswers;

            if (action.payload.autoAnswer.isDefault === true) {
                autoAnswers = autoAnswers.map(autoAnswer => ({
                    ...autoAnswer,
                    isDefault: false,
                }));
            }

            autoAnswers.push(action.payload.autoAnswer);

            return {
                ...state,
                autoAnswers,
                isSuccess: true,
            };
        case REMOVE_AUTO_ANSWER:
            return {
                ...state,
                removedAutoAnswer: state.autoAnswers.find(autoAnswer => parseInt(action.payload.id) === autoAnswer.id),
                autoAnswers: state.autoAnswers.filter(autoAnswer => parseInt(action.payload.id) !== autoAnswer.id),
            };
        case REMOVE_AUTO_ANSWER_FAILURE:
            return {
                ...state,
                autoAnswers: state.autoAnswers.concat([state.removedAutoAnswer]),
                isSuccess: true,
            };
        case REMOVE_AUTO_ANSWER_SUCCESS:
            return {
                ...state,
                isSuccess: true,
            };
        case SET_AUTO_ANSWER_AS_DEFAULT:
            return {
                ...state,

                autoAnswers: state.autoAnswers.map(autoAnswer => ({
                    ...autoAnswer,
                    isDefault: autoAnswer.id === action.payload.autoAnswerId,
                })),
            };
        case UNSET_AUTO_ANSWER_AS_DEFAULT:
            return {
                ...state,

                autoAnswers: state.autoAnswers.map(autoAnswer => ({
                    ...autoAnswer,
                    isDefault: false,
                })),
            };
        default:
            return state;
    }
};

export const isFetchingAutoAnswersSettings = (state = false, action) => {
    switch (action.type) {
        case REQUEST_AUTO_ANSWERS_SETTINGS_MODEL:
            return true;
        case RECEIVE_AUTO_ANSWERS_SETTINGS_MODEL:
            return false;
        default:
            return state;
    }
};

export const isProcessingAutoAnswer = (state = false, action) => {
    switch (action.type) {
        case PROCESS_AUTO_ANSWER:
        case REMOVE_AUTO_ANSWER:
            return true;
        case PROCESS_AUTO_ANSWER_SUCCESS:
        case PROCESS_AUTO_ANSWER_FAILURE:
        case REMOVE_AUTO_ANSWER_SUCCESS:
        case REMOVE_AUTO_ANSWER_FAILURE:
            return false;
        default:
            return state;
    }
};
