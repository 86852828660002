export default class TemplateAttachmentsHelper
{
    /**
     * @param formData
     * @param attachmentAssistantModel
     * @param withTemplateAttachments
     * @returns {*}
     */
    static prepareFormData(formData, attachmentAssistantModel, withTemplateAttachments = true) {
        if (withTemplateAttachments === true && attachmentAssistantModel.bulkUploadAttachments !== undefined) {
            attachmentAssistantModel.bulkUploadAttachments.forEach(attachment => {
                formData.append('templateAttachments[]', attachment.file);
            });
        }

        formData.append('templateAttachmentIdsToRemove', JSON.stringify(attachmentAssistantModel.templateAttachments.filter(
            attachment => attachment.hasOwnProperty('removed') === true && attachment.removed === true,
        ).map(attachment => attachment.id)));

        formData.append('templateAttachmentIdsToSend', JSON.stringify(attachmentAssistantModel.templateAttachments.filter(
            attachment => attachment.hasOwnProperty('removed') === false || attachment.removed === false,
        ).map(attachment => attachment.id)));

        return formData;
    }
}
