import {DRAG_START, DRAG_STOP} from '../actions/DnDActions';

const initialState = {
    isDraggingActive: false,
};

export const dragAndDropLogic = (state = initialState, action) => {
    switch (action.type) {
        case DRAG_START:
            return {
                isDraggingActive: true,
            };
        case DRAG_STOP:
            return {
                isDraggingActive: false,
            };
        default:
            return state;
    }
};