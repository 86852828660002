export const CLEAR_CANCELLATION = 'CLEAR_CANCELLATION';
export const CANCEL_CHUNKS_LOAD = 'CANCEL_CHUNKS_LOAD';

export const cancelChunksLoad = () => ({
    type: CANCEL_CHUNKS_LOAD,
});

export const clearCancellation = () => ({
    type: CLEAR_CANCELLATION,
});
