import React from 'react';
import Translations from '../../helpers/Translations';
import MarkDialog from '../Modal/MarkDialog';
import {connect} from 'react-redux';
import {closeJobCloseConfirmationDialog} from '../../actions/DialogActions';
import WithRouterProp from '../../helpers/WithRouterProp';
import Constants from '../../helpers/Constants';
import Url from '../../helpers/Url';
import Track from '../../helpers/Track';
import {TRACK_NAME_CLOSE_JOB, TRACK_LOCATION_CLOSE_JOB_DIALOG} from '../../helpers/ConstantsTrack';

class ConfirmCloseJobDialog extends React.Component
{
    redirectToCloseJobWizard = () => {
        this.props.closeJobCloseConfirmationDialog();
        this.props.navigate(Url.build('close-job', {job: this.props.confirmCloseJobDialog.jobId}));
    };

    closeJob = () => {
        Track.event(TRACK_LOCATION_CLOSE_JOB_DIALOG, TRACK_NAME_CLOSE_JOB);
        this.props.closeJobCloseConfirmationDialog();
        this.props.confirmCloseJobDialog.onCloseJobCallback(this.props.confirmCloseJobDialog.jobId);
    };

    render() {
        const {confirmCloseJobDialog, isFetchingCandidatesForConfirmCloseJobDialog} = this.props;
        const {candidates, open}                                                    = confirmCloseJobDialog;

        if (open === false) {
            return null;
        }

        let modalInfoLines;
        let primaryAction;
        let secondaryAction = null;

        const acceptedCandidatesInJob    = candidates !== undefined && candidates.filter(candidate => candidate.isAnonymized === false && candidate.stateId === Constants.CANDIDATE_STATE_ACTIVE && candidate.stage.id === Constants.CANDIDATE_STAGE_ACCEPTED_ID);
        const newCandidatesInJob         = candidates !== undefined && candidates.filter(candidate => candidate.isAnonymized === false && candidate.stateId === Constants.CANDIDATE_STATE_ACTIVE && (candidate.stage.id === Constants.CANDIDATE_STAGE_NEW_ID || candidate.stage.id === Constants.CANDIDATE_STAGE_COLLECTED_ID));
        const unprocessedCandidatesInJob = candidates !== undefined && candidates.filter(candidate => candidate.isAnonymized === false && candidate.stateId === Constants.CANDIDATE_STATE_ACTIVE && candidate.stage.id !== Constants.CANDIDATE_STAGE_ACCEPTED_ID);
        const hasUncontactedCandidate    = candidates !== undefined && candidates.find(candidate => candidate.isAnonymized === false && candidate.isContacted === false) !== undefined;

        const actions = [];

        if (acceptedCandidatesInJob.length === 0) {
            if (newCandidatesInJob.length === 0) {
                modalInfoLines = [Translations.getStatic('closeJobWithoutActiveCandidates')];
            } else {
                modalInfoLines = [Translations.getStatic('closeJobWithActiveCandidates')];
            }

            primaryAction = {
                title: Translations.getStatic('closeJobSimple'),
                action: this.closeJob,
                type: 'negative',
            };

            actions.push(primaryAction);
        } else {
            if (hasUncontactedCandidate === true || unprocessedCandidatesInJob.length > 0) {
                modalInfoLines = hasUncontactedCandidate === true
                    ? [Translations.getStatic('closeJobWithUncontactedCandidates')]
                    : [Translations.getStatic('closeJobWithUnprocessedCandidates')];

                primaryAction = {
                    title: Translations.getStatic('startGuide'),
                    action: this.redirectToCloseJobWizard,
                    type: 'negative',
                };

                secondaryAction = {
                    title: Translations.getStatic('closeJob'),
                    action: this.closeJob,
                };

                actions.push(primaryAction);
                actions.push(secondaryAction);
            } else {
                modalInfoLines = [Translations.getStatic('reallyCloseJob')];

                primaryAction = {
                    title: Translations.getStatic('closeJobSimple'),
                    action: this.closeJob,
                    type: 'negative',
                };

                actions.push(primaryAction);
            }
        }

        actions.push({
            title: Translations.getStatic('cancel'),
            action: () => this.props.closeJobCloseConfirmationDialog(),
        });

        return (
            <MarkDialog
                isLoading={isFetchingCandidatesForConfirmCloseJobDialog === true}
                closeOnDimmerClick={false}
                message={{
                    iconName: 'icon-cancelJob',
                    title: Translations.getStatic('closeJobAction'),
                    infoLines: modalInfoLines,
                }}

                actions={actions}
            />
        );
    }
}

const mapStateToProps = state => ({
    confirmCloseJobDialog: state.confirmCloseJobDialog,
    isFetchingCandidatesForConfirmCloseJobDialog: state.isFetchingCandidatesForConfirmCloseJobDialog,
});

const mapDispatchToProps = {
    closeJobCloseConfirmationDialog,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(WithRouterProp(ConfirmCloseJobDialog));


