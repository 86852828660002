import Constants from './../helpers/Constants';

export const REQUEST_JOBS_LIST_MODEL        = 'REQUEST_JOBS_LIST_MODEL';
export const RECEIVE_JOBS_LIST_MODEL        = 'RECEIVE_JOBS_LIST_MODEL';
export const RECEIVE_JOBS_LIST_DATA         = 'RECEIVE_JOBS_LIST_DATA';
export const LOAD_JOBS                      = 'LOAD_JOBS';
export const LOAD_JOBS_SUCCESS              = 'LOAD_JOBS_SUCCESS';
export const LOAD_JOBS_FAILURE              = 'LOAD_JOBS_FAILURE';
export const CLOSE_JOB                      = 'CLOSE_JOB';
export const CLOSE_JOB_SUCCESS              = 'CLOSE_JOB_SUCCESS';
export const CLOSE_JOB_FAILURE              = 'CLOSE_JOB_FAILURE';
export const OPEN_JOB                       = 'OPEN_JOB';
export const OPEN_JOB_SUCCESS               = 'OPEN_JOB_SUCCESS';
export const OPEN_JOB_FAILURE               = 'OPEN_JOB_FAILURE';
export const ACTIVATE_JOB                   = 'ACTIVATE_JOB';
export const ACTIVATE_JOB_SUCCESS           = 'ACTIVATE_JOB_SUCCESS';
export const ACTIVATE_JOB_FAILURE           = 'ACTIVATE_JOB_FAILURE';
export const FILTER_JOBS_BY_JOB_TITLE       = 'FILTER_JOBS_BY_JOB_TITLE';
export const FILTER_JOBS_BY_STATE_ID        = 'FILTER_JOBS_BY_STATE_ID';
export const FILTER_JOBS_BY_STATE_REASON_ID = 'FILTER_JOBS_BY_STATE_REASON_ID';
export const FILTER_JOBS_BY_ADD_DATE        = 'FILTER_JOBS_BY_ADD_DATE';
export const FILTER_JOBS_BY_USER_ID         = 'FILTER_JOBS_BY_USER_ID';
export const FILTER_JOBS_WITH_ACTIVE_OFFER  = 'FILTER_JOBS_WITH_ACTIVE_OFFER';
export const SORT_JOBS_BY                   = 'SORT_JOBS_BY';
export const JOBS_EXPORT_REQUEST            = 'JOBS_EXPORT_REQUEST';
export const JOBS_EXPORT_REQUEST_SUCCESS    = 'JOBS_EXPORT_REQUEST_SUCCESS';
export const JOBS_EXPORT_REQUEST_FAILURE    = 'JOBS_EXPORT_REQUEST_FAILURE';

export const requestJobsListModel = userId => ({
    type: REQUEST_JOBS_LIST_MODEL,

    meta: {
        requestRoute: 'v1/jobs/model/list',

        requestData: {
            userId,
        },

        onRequestSuccess: RECEIVE_JOBS_LIST_MODEL,
    },
});

export const loadJobs = (page, jobIds) => ({
    type: LOAD_JOBS,

    payload: {
        jobIds,
        page,
    },

    meta: {
        requestRoute: 'v1/jobs/data/more',
        requestMethod: 'GET',

        requestData: {
            jobIds: JSON.stringify(jobIds),
            page: page,
        },

        onRequestSuccess: LOAD_JOBS_SUCCESS,
        onRequestFailure: LOAD_JOBS_FAILURE,
    },
});

export const closeJob = jobId => ({
    type: CLOSE_JOB,

    payload: {
        jobId,
        stateId: Constants.JOB_STATE_CLOSED,
    },

    meta: {
        requestRoute: 'v1/job/action/close/' + jobId,
        requestMethod: 'POST',
        onRequestSuccess: CLOSE_JOB_SUCCESS,
        onRequestFailure: CLOSE_JOB_FAILURE,
    },
});

export const openJob = jobId => ({
    type: OPEN_JOB,

    payload: {
        jobId,
        stateId: Constants.JOB_STATE_OPEN,
    },

    meta: {
        requestRoute: 'v1/job/action/open/' + jobId,
        requestMethod: 'POST',
        onRequestSuccess: OPEN_JOB_SUCCESS,
        onRequestFailure: OPEN_JOB_FAILURE,
    },
});

export const activateJob = jobId => ({
    type: ACTIVATE_JOB,

    payload: {
        jobId,
        stateId: Constants.JOB_STATE_OPEN,
    },

    meta: {
        requestRoute: 'v1/job/action/pay/' + jobId,
        requestMethod: 'POST',
        onRequestSuccess: ACTIVATE_JOB_SUCCESS,
        onRequestFailure: ACTIVATE_JOB_FAILURE,
    },
});

export const filterJobsByStateId = stateId => ({
    type: FILTER_JOBS_BY_STATE_ID,

    payload: {
        stateId,
    },
});

export const filterJobsByStateReasonId = stateReasonId => ({
    type: FILTER_JOBS_BY_STATE_REASON_ID,

    payload: {
        stateReasonId,
    },
});

export const filterJobsByUserId = (userId, withActiveOffer) => ({
    type: FILTER_JOBS_BY_USER_ID,

    payload: {
        userId,
    },

    meta: {
        requestRoute: 'v1/jobs/data/list/' + userId,
        onRequestSuccess: RECEIVE_JOBS_LIST_DATA,

        requestData: {
            withOfferData: withActiveOffer === true ? 1 : 0,
        },
    },
});

export const filterJobsWithActiveOffer = (userId, withActiveOffer) => withActiveOffer === true
    ? ({
        type: FILTER_JOBS_WITH_ACTIVE_OFFER,

        payload: {
            withActiveOffer,
        },

        meta: {
            requestRoute: 'v1/jobs/data/list/' + userId,
            onRequestSuccess: RECEIVE_JOBS_LIST_DATA,

            requestData: {
                withOfferData: 1,
            },
        },
    })
    : ({
        type: FILTER_JOBS_WITH_ACTIVE_OFFER,

        payload: {
            withActiveOffer,
        },
    });

export const filterJobsByAddDate = (dateFrom, dateTo, timespan) => ({
    type: FILTER_JOBS_BY_ADD_DATE,

    payload: {
        dateFrom,
        dateTo,
        timespan,
    },
});

export const filterJobsByJobTitle = searchText => ({
    type: FILTER_JOBS_BY_JOB_TITLE,

    payload: {
        searchText,
    },
});

export const sortJobsBy = (sortBy, sortDirection, sortType, withReload = false) => ({
    type: SORT_JOBS_BY,

    payload: {
        sortBy,
        sortDirection,
        sortType,
        withReload,
    },
});

export const jobsExportRequest = exportJobIds => ({
    type: JOBS_EXPORT_REQUEST,

    payload: {
        exportJobIds,
    },

    meta: {
        requestRoute: 'v1/candidate/action/export-request',
        requestMethod: 'POST',
        requestBody: JSON.stringify({exportJobIds}),
        onRequestSuccess: JOBS_EXPORT_REQUEST_SUCCESS,
        onRequestFailure: JOBS_EXPORT_REQUEST_FAILURE,
    },
});

