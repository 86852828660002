import {REMOVE_CANDIDATE_ATTACHMENT, REMOVE_CANDIDATE_ATTACHMENT_FAILURE, UPLOAD_ATTACHMENTS_LIVE_SUCCESS} from '../../actions/AttachmentAssistantActions';
import {CHANGE_CANDIDATES_STAGE_SUCCESS, COPY_CANDIDATES_SUCCESS, DELETE_CANDIDATE_EVENT_SUCCESS, MOVE_CANDIDATES_SUCCESS, REFUSE_CANDIDATE_AND_SEND_EMAIL_SUCCESS, REMOVE_CANDIDATES_FROM_TALENT_POOL_SUCCESS, SCHEDULE_CANDIDATE_EVENT_SUCCESS, SEND_EMAIL_SUCCESS} from '../../actions/ActionWithCandidateActions';
import {CHANGE_CANDIDATE_STAGE_FAILURE, CHANGE_CANDIDATE_STAGE_SUCCESS} from '../../actions/JobDetailActions';
import Constants from '../Constants';
import CopyCandidatesReducer from './actionWithCandidatesReducer/CopyCandidatesReducer';
import CandidateHelper from '../components/CandidateHelper';
import CandidatesHelper from '../components/CandidatesHelper';
import {SET_CANDIDATE_AS_FAVORITE, SET_CANDIDATE_AS_FAVORITE_FAILURE, UNSET_CANDIDATE_AS_FAVORITE, UNSET_CANDIDATE_AS_FAVORITE_FAILURE} from '../../actions/CandidateActions';
import RemoveCandidateFromTalentPoolReducer from './actionWithCandidatesReducer/RemoveCandidateFromTalentPoolReducer';
import MoveCandidatesReducer from './actionWithCandidatesReducer/MoveCandidatesReducer';

export default class CandidatesReducer
{
    static process(state, action) {
        if (state.hasOwnProperty('candidates') === false) {
            return state;
        }

        let change = {};

        switch (action.type) {
            case UPLOAD_ATTACHMENTS_LIVE_SUCCESS:
                change = {
                    attachmentsCount: action.payload.candidateAttachments.length,
                };

                return {
                    ...state,
                    candidates: CandidatesHelper.updateCandidatesData(state.candidates, action.meta.requestPayload.candidateId, change),
                    ...CandidatesHelper.mergeFullCandidatesDataIntoState(state, candidateFromState => candidateFromState.id === action.meta.requestPayload.candidateId, change),
                };

            case REMOVE_CANDIDATE_ATTACHMENT:
                change = {
                    attachmentsCountDesc: 1,
                };

                return {
                    ...state,
                    candidates: CandidatesHelper.updateCandidatesData(state.candidates, action.payload.candidateId, change),
                    ...CandidatesHelper.mergeFullCandidatesDataIntoState(state, candidateFromState => candidateFromState.id === action.payload.candidateId, change),
                };

            case REMOVE_CANDIDATE_ATTACHMENT_FAILURE:
                change = {
                    attachmentsCountAsc: 1,
                };

                return {
                    ...state,
                    candidates: CandidatesHelper.updateCandidatesData(state.candidates, action.meta.requestPayload.candidateId, change),
                    ...CandidatesHelper.mergeFullCandidatesDataIntoState(state, candidateFromState => candidateFromState.id === action.meta.requestPayload.candidateId, change),
                };

            case DELETE_CANDIDATE_EVENT_SUCCESS:
                const getChangedEvents = candidate => {
                    return candidate.hasOwnProperty('events') === true
                        ? candidate.events.filter(event => event.id !== action.meta.requestPayload.eventId)
                        : [];
                };

                return {
                    ...state,
                    candidates: state.candidates.map(candidate => {
                        return {
                            ...candidate,
                            events: getChangedEvents(candidate),
                        };
                    }),
                    ...CandidatesHelper.mergeFullCandidatesDataIntoStateWithCallback(state, () => true, candidate => ({
                        ...candidate,
                        events: getChangedEvents(candidate),
                    })),
                };

            case SCHEDULE_CANDIDATE_EVENT_SUCCESS:
                const updateCandidateEvents = (candidateEvents, event) => {
                    const events = candidateEvents === undefined
                        ? []
                        : candidateEvents;

                    const isEditEvent = events.some(candidateEvent => parseInt(candidateEvent.id) === parseInt(event.id));

                    if (isEditEvent === true) {
                        return events.map(candidateEvent => {
                            if (parseInt(candidateEvent.id) === parseInt(event.id)) {
                                return action.payload.event;
                            }
                            return candidateEvent;
                        });
                    }

                    events.push(action.payload.event);

                    return events;
                };

                return {
                    ...state,
                    candidates: state.candidates.map(candidate => action.meta.requestPayload.candidateIds.includes(candidate.id) === true
                        ? CandidatesHelper.updateCandidateData(candidate, {
                            events: updateCandidateEvents(candidate.events, action.payload.event),
                            ...CandidatesHelper.prepareIsContactedCandidate(action.meta.requestPayload, candidate),
                        })
                        : candidate,
                    ),
                    ...CandidatesHelper.mergeFullCandidatesDataIntoStateWithCallback(state, candidate => action.meta.requestPayload.candidateIds.includes(candidate.id) === true, candidate => ({
                        ...candidate,
                        events: updateCandidateEvents(candidate.events, action.payload.event),
                        ...CandidatesHelper.prepareIsContactedCandidate(action.meta.requestPayload, candidate),
                    })),
                };

            case REFUSE_CANDIDATE_AND_SEND_EMAIL_SUCCESS:
                return {
                    ...state,
                    candidates: state.candidates.map(candidate => action.meta.requestPayload.candidateIds.includes(candidate.id) === true
                        ? CandidatesHelper.updateCandidateData(candidate, CandidateHelper.getCandidateDataAfterRefuse(
                            candidate.id, action.meta.requestPayload, action.payload,
                        ))
                        : candidate,
                    ),
                    ...CandidatesHelper.mergeFullCandidatesDataIntoStateWithCallback(state, candidate => action.meta.requestPayload.candidateIds.includes(candidate.id) === true, candidate => ({
                        ...candidate,
                        ...CandidateHelper.getCandidateDataAfterRefuse(candidate.id, action.meta.requestPayload, action.payload),
                    })),

                };
            case CHANGE_CANDIDATE_STAGE_SUCCESS:
            case CHANGE_CANDIDATE_STAGE_FAILURE:
                change = {
                    stage: action.payload.stage,
                    stateId: Constants.CANDIDATE_STATE_ACTIVE,
                };

                return {
                    ...state,
                    candidates: CandidatesHelper.updateCandidatesData(state.candidates, action.meta.requestPayload.candidateId, change),
                    ...CandidatesHelper.mergeFullCandidatesDataIntoState(state, candidateFromStore => candidateFromStore.id === action.meta.requestPayload.candidateId, change),
                };

            case COPY_CANDIDATES_SUCCESS:
                return CopyCandidatesReducer.process(state, action);

            case MOVE_CANDIDATES_SUCCESS:
                return MoveCandidatesReducer.process(state, action);

            case REMOVE_CANDIDATES_FROM_TALENT_POOL_SUCCESS:
                return RemoveCandidateFromTalentPoolReducer.process(state, action);

            case SEND_EMAIL_SUCCESS:
                const prepareCandidateAfterSendEmail = candidate => ({
                    ...candidate,
                    ...CandidatesHelper.prepareWasCandidateSentToColleague(action.meta.requestPayload, action.payload.candidatesSentToColleague, candidate),
                    ...CandidatesHelper.prepareIsContactedCandidate(action.meta.requestPayload, candidate),
                });

                return {
                    ...state,
                    candidates: state.candidates.map(candidate => prepareCandidateAfterSendEmail(candidate)),
                    ...CandidatesHelper.mergeFullCandidatesDataIntoStateWithCallback(state, candidate => action.meta.requestPayload.candidateIds.includes(candidate.id) === true, candidate => prepareCandidateAfterSendEmail(candidate)),
                };

            case CHANGE_CANDIDATES_STAGE_SUCCESS:
                const prepareCandidateAfterChangeStage = candidate => ({
                    ...candidate,
                    ...CandidatesHelper.prepareIsContactedCandidate(action.meta.requestPayload, candidate),
                });

                return {
                    ...state,
                    candidates: state.candidates.map(candidate => prepareCandidateAfterChangeStage(candidate)),
                    ...CandidatesHelper.mergeFullCandidatesDataIntoStateWithCallback(state, candidate => action.meta.requestPayload.candidateIds.includes(candidate.id) === true, candidate => prepareCandidateAfterChangeStage(candidate)),

                };

            case SET_CANDIDATE_AS_FAVORITE:
            case UNSET_CANDIDATE_AS_FAVORITE:
            case SET_CANDIDATE_AS_FAVORITE_FAILURE:
            case UNSET_CANDIDATE_AS_FAVORITE_FAILURE:
                change = {
                    isFavorite: action.type === SET_CANDIDATE_AS_FAVORITE || action.type === UNSET_CANDIDATE_AS_FAVORITE_FAILURE,
                };

                const candidateId = action.hasOwnProperty('payload') === true && action.payload.hasOwnProperty('candidateId') === true
                    ? action.payload.candidateId
                    : action.meta.requestPayload.candidateId;

                return {
                    ...state,
                    candidates: CandidatesHelper.updateCandidatesData(state.candidates, candidateId, change),
                    ...CandidatesHelper.mergeFullCandidatesDataIntoState(state, candidate => parseInt(candidateId) === parseInt(candidate.id), change),
                };

            default:
                return state;
        }
    }

    static getReducerType() {
        const url = window.location.href;

        if (url.indexOf('/mark/dashboard') > -1) {
            return Constants.CANDIDATES_REDUCER_TYPE_DASHBOARD;
        }

        if (url.indexOf('/dashboard') > -1) {
            return Constants.CANDIDATES_REDUCER_TYPE_B2B_DASHBOARD;
        }

        if (url.indexOf('/search') > -1) {
            return Constants.CANDIDATES_REDUCER_TYPE_GLOBAL_SEARCH;
        }

        return Constants.CANDIDATES_REDUCER_TYPE_JOB_DETAIL;
    }
}
