import React from 'react';
import PropTypes from 'prop-types';
import SendEmail from '../Email/SendEmail';
import ErrorFocus from '../ErrorFocus';
import Constants from '../../helpers/Constants';
import Form from '../Form';
import Select from '@profesia/adamui/components/common/dropdown/SelectDefault'
import Checkbox from '@profesia/adamui/components/common/input/checkbox/Checkbox';
import Grid from '../Grid';
import Heading from '../Heading';
import {connect} from 'react-redux';
import {requestChangeStageModel, setChangeCandidatesStageParams, getCandidatesForAction} from '../../actions/ActionWithCandidateActions';
import LoadingComponent from '../LoadingComponent';
import Translations from '../../helpers/Translations';
import Validation from '../../helpers/Validation';
import SendEmailHelper from '../../helpers/components/SendEmailHelper';
import FormSkeleton from '../../skeletons/components/FormSkeleton';

class ChangeStageOfCandidates extends React.Component
{
    state = {
        isStageIdChanged: false,
    };

    componentDidMount() {
        this.props.requestChangeStageModel(this.props.jobId);
        this.props.getCandidatesForAction(this.props.candidateIds);
    }

    componentDidUpdate(prevProps) {
        const isChangeRecipients = this.props.candidateActionModel.changeStageOfCandidatesParams.stageId !== prevProps.candidateActionModel.changeStageOfCandidatesParams.stageId;

        if (this.state.isChangeRecipients !== isChangeRecipients) {
            this.setState({
                isChangeRecipients,
            });
        }
    }

    render = () => {
        const {candidateIds} = this.props;

        if (this.props.isFetchingCandidatesForAction === true || this.props.isFetchingChangeStageModel === true || this.props.candidateActionModel.hasOwnProperty('stages') === false || this.props.candidateActionModel.stages.length === 0) {
            return candidateIds.length > Constants.CANDIDATES_CHUNK_SIZE
                ? <LoadingComponent showProgress={true}/>
                : <FormSkeleton/>;
        }

        const {candidateActionModel}                              = this.props;
        const {changeStageOfCandidatesParams, stages, candidates} = candidateActionModel;
        const {isStageIdChanged}                                  = this.state;

        let stageItems = stages.map(stage => ({
            id: stage.stageId,
            label: Translations.getStage(stage),
        }));

        stageItems = [{
            id: null,
            label: '',
        }].concat(stageItems);

        return (
            <ErrorFocus>
                <Form>
                    <Heading className="highlighted" as="h3">{Translations.getStatic('chosenCandidates')}: {candidateIds.length}</Heading>
                    <Grid className="highlighted">
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Form.Field className='fluid'
                                    error={
                                        (this.props.errors.includes('stageId') && changeStageOfCandidatesParams.stageId === null && isStageIdChanged === false)
                                            ? Validation.getMessage('empty')
                                            : false
                                    }
                                >
                                    <Select
                                        label={Translations.getStatic('chooseStageToMoveCandidates')}
                                        options={stageItems}
                                        onChange={(value) => this.props.setChangeCandidatesStageParams({
                                            stageId: value === null ? null : parseInt(value),
                                        })}
                                    />
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>

                <Form>
                    <Heading as="h3">{Translations.getStatic('emailToCandidates')}</Heading>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Form.Field>
                                    <Checkbox
                                        checked={changeStageOfCandidatesParams.sendEmail}
                                        label={Translations.getStatic('sendToCandidatesEmail')}
                                        onChange={(e) => this.props.setChangeCandidatesStageParams({
                                            sendEmail: e.target.checked,
                                        })}
                                    />
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>

                {
                    changeStageOfCandidatesParams.sendEmail === true
                    && (
                        <SendEmail
                            errors={this.props.errors}
                            visibleFields={SendEmailHelper.getDefaultEmailVisibleFields()}

                            recipients={
                                this.props.sendEmailModel.hasOwnProperty('recipients') === true && this.props.sendEmailModel.recipients.length > 0
                                    ? this.props.sendEmailModel.recipients
                                    : candidates
                                        .filter(candidate => candidateIds.includes(candidate.id) === true && candidate.isAnonymized === false)
                                        .map(candidate => ({
                                            value: candidate.email,
                                            text: candidate.candidateName,
                                            candidateId: candidate.id,
                                        }))
                            }

                            bcc={
                                this.props.sendEmailModel.hasOwnProperty('bcc') === true && this.props.sendEmailModel.bcc.length > 0
                                    ? this.props.sendEmailModel.bcc
                                    : []
                            }

                            noSpacing={true}
                            emailTemplateType={Constants.EMAIL_TEMPLATE_TYPE_OTHER}
                        />
                    )
                }
            </ErrorFocus>
        );
    };
}

ChangeStageOfCandidates.propTypes = {
    candidateIds: PropTypes.array.isRequired,
    jobId: PropTypes.number.isRequired,
};

const mapStateToProps = state => ({
    sendEmailModel: state.sendEmailModel,
    candidateActionModel: state.candidateActionModel,
    isFetchingChangeStageModel: state.isFetchingChangeStageModel,
    isFetchingCandidatesForAction: state.isFetchingCandidatesForAction,
});

const mapDispatchToProps = {
    setChangeCandidatesStageParams,
    requestChangeStageModel,
    getCandidatesForAction,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ChangeStageOfCandidates);
