import {
    REQUEST_CONNECTED_USERS_MODEL, RECEIVE_CONNECTED_USERS_MODEL,
    CONNECTED_USERS_ADD, CONNECTED_USERS_ADD_SUCCESS, CONNECTED_USERS_ADD_FAILURE,
    CONNECTED_USERS_DELETE, CONNECTED_USERS_DELETE_SUCCESS, CONNECTED_USERS_DELETE_FAILURE,
} from '../actions/ConnectedUsersActions';

export const connectedUsersModel = (state = {}, action) => {
    switch (action.type) {
        case RECEIVE_CONNECTED_USERS_MODEL:
            return {
                ...state,
                ...action.payload,
            };

        case CONNECTED_USERS_DELETE:
            return {
                ...state,
                connectedUsers: state.connectedUsers.filter(connectedUser => connectedUser.id !== action.payload.connectedUsersId),
            };

        case CONNECTED_USERS_ADD_SUCCESS:
        case CONNECTED_USERS_DELETE_SUCCESS:
            return {
                ...state,
                connectedUsers: action.payload.connectedUsers,
            };

        default:
            return state;
    }
};

export const isFetchingConnectedUsersModel = (state = false, action) => {
    switch (action.type) {
        case REQUEST_CONNECTED_USERS_MODEL:
            return true;

        case RECEIVE_CONNECTED_USERS_MODEL:
            return false;

        default:
            return state;
    }
};
