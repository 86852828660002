export const CANCEL_SCHEDULED_EMAIL                      = 'CANCEL_SCHEDULED_EMAIL';
export const CANCEL_SCHEDULED_EMAIL_SUCCESS              = 'CANCEL_SCHEDULED_EMAIL_SUCCESS';
export const CANCEL_SCHEDULED_EMAIL_FAILURE              = 'CANCEL_SCHEDULED_EMAIL_FAILURE';
export const CANCEL_SCHEDULED_MASS_FORWARD_EMAIL         = 'CANCEL_SCHEDULED_MASS_FORWARD_EMAIL';
export const CANCEL_SCHEDULED_MASS_FORWARD_EMAIL_SUCCESS = 'CANCEL_SCHEDULED_MASS_FORWARD_EMAIL_SUCCESS';
export const CANCEL_SCHEDULED_MASS_FORWARD_EMAIL_FAILURE = 'CANCEL_SCHEDULED_MASS_FORWARD_EMAIL_FAILURE';

export const cancelScheduledEmail = (candidateId, scheduledEmailId) => ({
    type: CANCEL_SCHEDULED_EMAIL,

    payload: {
        candidateId,
    },

    meta: {
        requestRoute: 'v1/scheduled-email/action/cancel/' + scheduledEmailId,
        requestMethod: 'POST',

        onRequestSuccess: CANCEL_SCHEDULED_EMAIL_SUCCESS,
        onRequestFailure: CANCEL_SCHEDULED_EMAIL_FAILURE,
    },
});

export const cancelScheduledMassForwardEmail = (candidateIds, scheduledEmailId) => ({
    type: CANCEL_SCHEDULED_MASS_FORWARD_EMAIL,

    payload: {
        candidateIds,
    },

    meta: {
        requestRoute: 'v1/scheduled-mass-forward-email/action/cancel/' + scheduledEmailId,
        requestMethod: 'POST',

        onRequestSuccess: CANCEL_SCHEDULED_MASS_FORWARD_EMAIL_SUCCESS,
        onRequestFailure: CANCEL_SCHEDULED_MASS_FORWARD_EMAIL_FAILURE,
    },
});
