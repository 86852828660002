import React, {Component} from 'react';
import {connect} from 'react-redux';
import MarkModal from '../Modal/MarkModal';
import {closeModalSecond} from '../../actions/ModalActions';
import DetailCandidateReadOnlyContent from '../Candidate/DetailCandidateReadOnlyContent';
import Translations from '../../helpers/Translations';
import CandidatesReducer from '../../helpers/generalReducers/CandidatesReducer';
import {copyCandidates} from '../../actions/ActionWithCandidateActions';
import SuccessActionStatus from '../../helpers/SuccessActionStatus';

class DetailCandidateFromTalentPoolModal extends Component
{
    componentDidUpdate = prevProps => {
        if (SuccessActionStatus.isActionSuccessful(prevProps, this.props)) {
            this.props.closeModalSecond();
        }
    };

    addIntoJob = () => {
        const actionData = new FormData();

        const copyParams = {
            jobId: this.props.jobId,
            copyHistory: true,
            removeCandidate: false,
            sendEmail: false,
        };

        actionData.append('copyParams', JSON.stringify(copyParams));
        actionData.append('candidateIds', JSON.stringify([this.props.candidateId]));

        this.props.copyCandidates(actionData, [this.props.candidateId], copyParams, CandidatesReducer.getReducerType());
    };

    render() {
        const {
                  isCopyingCandidates,
                  isOpenModalSecond,
                  isCandidateAlreadyAdded,
                  closeModalSecond,
                  candidateModel,
                  candidateId,
                  candidateName,
                  candidateModel: {candidate, events},
              } = this.props;

        return (
            <MarkModal
                wide={true}
                isFooterVisible={false}
                isModalSecond={true}
                isOpen={isOpenModalSecond}
                closeModal={closeModalSecond}
                isLoadingData={false}

                content={(
                    <DetailCandidateReadOnlyContent
                        candidateId={candidateId}
                        candidateName={candidateName}
                        candidateModel={candidateModel}
                        closeNoModal={closeModalSecond}
                        actionButton={
                            candidate !== undefined
                            && events !== undefined
                                ? {
                                    visible: true,
                                    text: Translations.getStatic('add'),
                                    disabled: isCopyingCandidates === true || isCandidateAlreadyAdded === true,
                                    loading: isCopyingCandidates === true,
                                    onClick: this.addIntoJob,
                                }
                                : {
                                    visible: false,
                                }
                        }
                    />
                )}
            />
        );
    }
}

const mapStateToProps = state => ({
    isOpenModal: state.modalLogic.isOpen,
    isOpenModalSecond: state.modalSecondLogic.isOpen,
    candidateModel: state.candidateModel.duplicatedDetail,
    isCopyingCandidates: state.isCopyingCandidates,
    actionSuccessStatus: state.actionSuccessStatus,
});

const mapDispatchToProps = {
    closeModalSecond,
    copyCandidates,
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailCandidateFromTalentPoolModal);
