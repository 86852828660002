import Constants from '../helpers/Constants';

export const OPEN_NO_CANDIDATE_ACCESS_DIALOG                       = 'OPEN_NO_CANDIDATE_ACCESS_DIALOG';
export const CLOSE_NO_CANDIDATE_ACCESS_DIALOG                      = 'CLOSE_NO_CANDIDATE_ACCESS_DIALOG';
export const OPEN_NO_JOB_ACCESS_DIALOG                             = 'OPEN_NO_JOB_ACCESS_DIALOG';
export const CLOSE_NO_JOB_ACCESS_DIALOG                            = 'CLOSE_NO_JOB_ACCESS_DIALOG';
export const OPEN_NO_ORDER_ACCESS_DIALOG                           = 'OPEN_NO_ORDER_ACCESS_DIALOG';
export const CLOSE_NO_ORDER_ACCESS_DIALOG                          = 'CLOSE_NO_ORDER_ACCESS_DIALOG';
export const OPEN_CANDIDATE_FROM_UNPAID_JOB_DIALOG                 = 'OPEN_CANDIDATE_FROM_UNPAID_JOB_DIALOG';
export const CLOSE_CANDIDATE_FROM_UNPAID_JOB_DIALOG                = 'CLOSE_CANDIDATE_FROM_UNPAID_JOB_DIALOG';
export const OPEN_ALL_RECIPIENTS_WITHOUT_EMAILS_DIALOG             = 'OPEN_ALL_RECIPIENTS_WITHOUT_EMAILS_DIALOG';
export const CLOSE_ALL_RECIPIENTS_WITHOUT_EMAILS_DIALOG            = 'CLOSE_ALL_RECIPIENTS_WITHOUT_EMAILS_DIALOG';
export const OPEN_SOME_RECIPIENTS_WITHOUT_EMAILS_DIALOG            = 'OPEN_SOME_RECIPIENTS_WITHOUT_EMAILS_DIALOG';
export const OPEN_SOME_RECIPIENTS_WITHOUT_EMAILS_DIALOG_SUCCESS    = 'OPEN_SOME_RECIPIENTS_WITHOUT_EMAILS_DIALOG_SUCCESS';
export const OPEN_SOME_RECIPIENTS_WITHOUT_EMAILS_DIALOG_FAILURE    = 'OPEN_SOME_RECIPIENTS_WITHOUT_EMAILS_DIALOG_FAILURE';
export const CLOSE_SOME_RECIPIENTS_WITHOUT_EMAILS_DIALOG           = 'CLOSE_SOME_RECIPIENTS_WITHOUT_EMAILS_DIALOG';
export const OPEN_NOT_ALLOWED_FEATURE_DIALOG                       = 'OPEN_NOT_ALLOWED_FEATURE_DIALOG';
export const CLOSE_NOT_ALLOWED_FEATURE_DIALOG                      = 'CLOSE_NOT_ALLOWED_FEATURE_DIALOG';
export const OPEN_CLOSE_JOB_UNCONTACTED_CANDIDATE_DIALOG           = 'OPEN_CLOSE_JOB_UNCONTACTED_CANDIDATE_DIALOG';
export const CLOSE_JOB_CLOSE_UNCONTACTED_CANDIDATE_DIALOG          = 'CLOSE_JOB_CLOSE_UNCONTACTED_CANDIDATE_DIALOG';
export const OPEN_CLOSE_JOB_CONFIRMATION_DIALOG                    = 'OPEN_CLOSE_JOB_CONFIRMATION_DIALOG';
export const OPEN_CLOSE_JOB_CONFIRMATION_DIALOG_WITH_CHECK         = 'OPEN_CLOSE_JOB_CONFIRMATION_DIALOG_WITH_CHECK';
export const OPEN_CLOSE_JOB_CONFIRMATION_DIALOG_WITH_CHECK_SUCCESS = 'OPEN_CLOSE_JOB_CONFIRMATION_DIALOG_WITH_CHECK_SUCCESS';
export const OPEN_CLOSE_JOB_CONFIRMATION_DIALOG_WITH_CHECK_FAILURE = 'OPEN_CLOSE_JOB_CONFIRMATION_DIALOG_WITH_CHECK_FAILURE';
export const CLOSE_JOB_CLOSE_CONFIRMATION_DIALOG                   = 'CLOSE_JOB_CLOSE_CONFIRMATION_DIALOG';
export const OPEN_ACCEPTED_CANDIDATE_DIALOG                        = 'OPEN_ACCEPTED_CANDIDATE_DIALOG';
export const OPEN_ACCEPTED_CANDIDATE_DIALOG_SUCCESS                = 'OPEN_ACCEPTED_CANDIDATE_DIALOG_SUCCESS';
export const OPEN_ACCEPTED_CANDIDATE_DIALOG_FAILURE                = 'OPEN_ACCEPTED_CANDIDATE_DIALOG_FAILURE';
export const CLOSE_ACCEPTED_CANDIDATE_DIALOG                       = 'CLOSE_ACCEPTED_CANDIDATE_DIALOG';
export const OPEN_CANCEL_CLOSE_JOB_WIZARD_DIALOG                   = 'OPEN_CANCEL_CLOSE_JOB_WIZARD_DIALOG';
export const CLOSE_CANCEL_CLOSE_JOB_WIZARD_DIALOG                  = 'CLOSE_CANCEL_CLOSE_JOB_WIZARD_DIALOG';
export const OPEN_COPY_INTO_TALENT_POOL_DIALOG                     = 'OPEN_COPY_INTO_TALENT_POOL_DIALOG';
export const CLOSE_COPY_INTO_TALENT_POOL_DIALOG                    = 'CLOSE_COPY_INTO_TALENT_POOL_DIALOG';
export const OPEN_REMOVE_FROM_TALENT_POOL_DIALOG                   = 'OPEN_REMOVE_FROM_TALENT_POOL_DIALOG';
export const CLOSE_REMOVE_FROM_TALENT_POOL_DIALOG                  = 'CLOSE_REMOVE_FROM_TALENT_POOL_DIALOG';
export const OPEN_GDPR_AGREEMENT_FILTER_TESTING_DIALOG             = 'OPEN_GDPR_AGREEMENT_FILTER_TESTING_DIALOG';
export const CLOSE_GDPR_AGREEMENT_FILTER_TESTING_DIALOG            = 'CLOSE_GDPR_AGREEMENT_FILTER_TESTING_DIALOG';
export const OPEN_GDPR_AGREEMENT_FILTER_TESTING_NEWSLETTER_DIALOG  = 'OPEN_GDPR_AGREEMENT_FILTER_TESTING_NEWSLETTER_DIALOG';
export const CLOSE_GDPR_AGREEMENT_FILTER_TESTING_NEWSLETTER_DIALOG = 'CLOSE_GDPR_AGREEMENT_FILTER_TESTING_NEWSLETTER_DIALOG';
export const OPEN_DASHBOARD_STATISTICS_TESTING_DIALOG              = 'OPEN_DASHBOARD_STATISTICS_TESTING_DIALOG';
export const CLOSE_DASHBOARD_STATISTICS_TESTING_DIALOG             = 'CLOSE_DASHBOARD_STATISTICS_TESTING_DIALOG';
export const OPEN_DASHBOARD_STATISTICS_TESTING_NEWSLETTER_DIALOG   = 'OPEN_DASHBOARD_STATISTICS_TESTING_NEWSLETTER_DIALOG';
export const CLOSE_DASHBOARD_STATISTICS_TESTING_NEWSLETTER_DIALOG  = 'CLOSE_DASHBOARD_STATISTICS_TESTING_NEWSLETTER_DIALOG';
export const OPEN_DASHBOARD_NEWS_TESTING_DIALOG                    = 'OPEN_DASHBOARD_NEWS_TESTING_DIALOG';
export const CLOSE_DASHBOARD_NEWS_TESTING_DIALOG                   = 'CLOSE_DASHBOARD_NEWS_TESTING_DIALOG';
export const OPEN_DASHBOARD_NEWS_TESTING_NEWSLETTER_DIALOG         = 'OPEN_DASHBOARD_NEWS_TESTING_NEWSLETTER_DIALOG';
export const CLOSE_DASHBOARD_NEWS_TESTING_NEWSLETTER_DIALOG        = 'CLOSE_DASHBOARD_NEWS_TESTING_NEWSLETTER_DIALOG';

export const openNoCandidateAccessDialog = () => ({
    type: OPEN_NO_CANDIDATE_ACCESS_DIALOG,
});

export const closeNoCandidateAccessDialog = () => ({
    type: CLOSE_NO_CANDIDATE_ACCESS_DIALOG,
});

export const openNoJobAccessDialog = () => ({
    type: OPEN_NO_JOB_ACCESS_DIALOG,
});

export const closeNoJobAccessDialog = () => ({
    type: CLOSE_NO_JOB_ACCESS_DIALOG,
});

export const openNoOrderAccessDialog = () => ({
    type: OPEN_NO_ORDER_ACCESS_DIALOG,
});

export const closeNoOrderAccessDialog = () => ({
    type: CLOSE_NO_ORDER_ACCESS_DIALOG,
});

export const openAllRecipientsWithoutEmailsDialog = () => ({
    type: OPEN_ALL_RECIPIENTS_WITHOUT_EMAILS_DIALOG,
});

export const closeAllRecipientsWithoutEmailsDialog = () => ({
    type: CLOSE_ALL_RECIPIENTS_WITHOUT_EMAILS_DIALOG,
});

export const openSomeRecipientsWithoutEmailsDialog = dialogProps => ({
    type: OPEN_SOME_RECIPIENTS_WITHOUT_EMAILS_DIALOG,
    payload: {
        dialogProps,
    },
    meta: {
        requestRoute: 'v1/candidate/data/get-candidates',
        requestMethod: 'GET',
        requestData: {
            candidateIds: JSON.stringify(dialogProps.candidateIds),
            outputFlags: JSON.stringify([
                Constants.CANDIDATE_OUTPUT_FLAG_BASIC_INFO,
            ]),
        },
        onRequestSuccess: OPEN_SOME_RECIPIENTS_WITHOUT_EMAILS_DIALOG_SUCCESS,
        onRequestFailure: OPEN_SOME_RECIPIENTS_WITHOUT_EMAILS_DIALOG_FAILURE,
    },
});

export const closeSomeRecipientsWithoutEmailsDialog = () => ({
    type: CLOSE_SOME_RECIPIENTS_WITHOUT_EMAILS_DIALOG,
});

export const openNotAllowedFeatureDialog = (dialogProps = {}) => ({
    type: OPEN_NOT_ALLOWED_FEATURE_DIALOG,
    payload: {
        dialogProps,
    },
});

export const closeNotAllowedFeatureDialog = () => ({
    type: CLOSE_NOT_ALLOWED_FEATURE_DIALOG,
});

export const openCloseJobUncontactedCandidateDialog = (jobId, candidates) => ({
    type: OPEN_CLOSE_JOB_UNCONTACTED_CANDIDATE_DIALOG,
    payload: {
        jobId,
        candidates,
    },
});

export const openCloseJobConfirmationDialog = (jobId, candidates, onCloseJobCallback) => ({
    type: OPEN_CLOSE_JOB_CONFIRMATION_DIALOG,
    payload: {
        jobId,
        candidates,
        onCloseJobCallback,
    },
});

export const openCloseJobConfirmationDialogWithCheck = (jobId, onCloseJobCallback) => ({
    type: OPEN_CLOSE_JOB_CONFIRMATION_DIALOG_WITH_CHECK,
    payload: {
        jobId,
        onCloseJobCallback,
    },
    meta: {
        requestRoute: 'v1/candidate/data/get-candidates-from-job',
        requestMethod: 'GET',
        requestData: {
            jobId: jobId,
            outputFlags: JSON.stringify([
                Constants.CANDIDATE_OUTPUT_FLAG_BASIC_INFO,
                Constants.CANDIDATE_OUTPUT_FLAG_IS_CONTACTED,
                Constants.CANDIDATE_OUTPUT_FLAG_STAGE,
            ]),
        },
        onRequestSuccess: OPEN_CLOSE_JOB_CONFIRMATION_DIALOG_WITH_CHECK_SUCCESS,
        onRequestFailure: OPEN_CLOSE_JOB_CONFIRMATION_DIALOG_WITH_CHECK_FAILURE,
    },
});

export const closeJobCloseUncontactedCandidateDialog = () => ({
    type: CLOSE_JOB_CLOSE_UNCONTACTED_CANDIDATE_DIALOG,
});

export const closeJobCloseConfirmationDialog = () => ({
    type: CLOSE_JOB_CLOSE_CONFIRMATION_DIALOG,
});

export const openAcceptedCandidateDialog = jobId => ({
    type: OPEN_ACCEPTED_CANDIDATE_DIALOG,
    payload: {
        jobId,
    },
    meta: {
        requestRoute: 'v1/candidate/data/get-candidates-from-job',
        requestMethod: 'GET',
        requestData: {
            jobId: jobId,
            outputFlags: JSON.stringify([
                Constants.CANDIDATE_OUTPUT_FLAG_BASIC_INFO,
                Constants.CANDIDATE_OUTPUT_FLAG_IS_CONTACTED,
                Constants.CANDIDATE_OUTPUT_FLAG_STAGE,
            ]),
        },
        onRequestSuccess: OPEN_ACCEPTED_CANDIDATE_DIALOG_SUCCESS,
        onRequestFailure: OPEN_ACCEPTED_CANDIDATE_DIALOG_FAILURE,
    },
});

export const closeAcceptedCandidateDialog = () => ({
    type: CLOSE_ACCEPTED_CANDIDATE_DIALOG,
});

export const openCancelCloseJobWizardDialog = () => ({
    type: OPEN_CANCEL_CLOSE_JOB_WIZARD_DIALOG,
});

export const closeCancelCloseJobWizardDialog = () => ({
    type: CLOSE_CANCEL_CLOSE_JOB_WIZARD_DIALOG,
});

export const openCopyIntoTalentPoolDialog = (dialogProps = {}) => ({
    type: OPEN_COPY_INTO_TALENT_POOL_DIALOG,
    payload: {
        dialogProps,
    },
});

export const closeCopyIntoTalentPoolDialog = () => ({
    type: CLOSE_COPY_INTO_TALENT_POOL_DIALOG,
});

export const openRemoveFromTalentPoolDialog = (dialogProps = {}) => ({
    type: OPEN_REMOVE_FROM_TALENT_POOL_DIALOG,
    payload: {
        dialogProps,
    },
});

export const closeRemoveFromTalentPoolDialog = () => ({
    type: CLOSE_REMOVE_FROM_TALENT_POOL_DIALOG,
});

export const openGdprAgreementFilterTestingDialog = (dialogProps = {}) => ({
    type: OPEN_GDPR_AGREEMENT_FILTER_TESTING_DIALOG,
    payload: {
        dialogProps,
    },
});

export const closeGdprAgreementFilterTestingDialog = () => ({
    type: CLOSE_GDPR_AGREEMENT_FILTER_TESTING_DIALOG,
});

export const openGdprAgreementFilterTestingNewsletterDialog = (dialogProps = {}) => ({
    type: OPEN_GDPR_AGREEMENT_FILTER_TESTING_NEWSLETTER_DIALOG,
    payload: {
        dialogProps,
    },
});

export const closeGdprAgreementFilterTestingNewsletterDialog = () => ({
    type: CLOSE_GDPR_AGREEMENT_FILTER_TESTING_NEWSLETTER_DIALOG,
});

export const openDashboardStatisticsTestingDialog = (dialogProps = {}) => ({
    type: OPEN_DASHBOARD_STATISTICS_TESTING_DIALOG,
    payload: {
        dialogProps,
    },
});

export const closeDashboardStatisticsTestingDialog = () => ({
    type: CLOSE_DASHBOARD_STATISTICS_TESTING_DIALOG,
});

export const openDashboardStatisticsTestingNewsletterDialog = (dialogProps = {}) => ({
    type: OPEN_DASHBOARD_STATISTICS_TESTING_NEWSLETTER_DIALOG,
    payload: {
        dialogProps,
    },
});

export const closeDashboardStatisticsTestingNewsletterDialog = () => ({
    type: CLOSE_DASHBOARD_STATISTICS_TESTING_NEWSLETTER_DIALOG,
});

export const openDashboardNewsTestingDialog = (dialogProps = {}) => ({
    type: OPEN_DASHBOARD_NEWS_TESTING_DIALOG,
    payload: {
        dialogProps,
    },
});

export const closeDashboardNewsTestingDialog = () => ({
    type: CLOSE_DASHBOARD_NEWS_TESTING_DIALOG,
});

export const openDashboardNewsTestingNewsletterDialog = (dialogProps = {}) => ({
    type: OPEN_DASHBOARD_NEWS_TESTING_NEWSLETTER_DIALOG,
    payload: {
        dialogProps,
    },
});

export const closeDashboardNewsTestingNewsletterDialog = () => ({
    type: CLOSE_DASHBOARD_NEWS_TESTING_NEWSLETTER_DIALOG,
});
