import React from 'react';

const Container = props => {
    let {
        fluid,
        className,
        ...validProps
    } = props;
    
    fluid = props.fluid === true ? 'fluid ' : '';
    className = `${props.className ?  props.className + ' ' : ''}`;

    return <div {...validProps} className={`${fluid}${className}ui container`}>{props.children}</div>;
};

export default Container;
