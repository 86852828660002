import React from 'react';
import Translations from '../../../helpers/Translations';
import MarkDialog from '../../Modal/MarkDialog';
import {connect} from 'react-redux';
import {closeDashboardNewsTestingDialog, openDashboardNewsTestingNewsletterDialog} from '../../../actions/DialogActions';
import {saveUserTesting} from '../../../actions/UserTestingActions';
import WithRouterProp from '../../../helpers/WithRouterProp';
import Constants from '../../../helpers/Constants';
import SuccessMessage from '../../../helpers/SuccessMessage';

class DashboardNewsTestingDialog extends React.Component
{
    state = {
        testingAnswer: Constants.TESTING_DASHBOARD_NEWS_ANSWER_YES,
    };

    componentDidUpdate = prevProps => {
        if ((SuccessMessage.canShowSuccessMessage(prevProps, this.props, 'isSavingUserTesting'))
            && this.props.userTestingModel.hasOwnProperty('isSuccess') === true
            && this.props.userTestingModel.isSuccess === true
        ) {
            this.props.closeDashboardNewsTestingDialog();

            const {userTesting} = this.props.userTestingModel;
            const {testingData} = userTesting;

            if (parseInt(testingData.testingAnswer) === Constants.TESTING_DASHBOARD_NEWS_ANSWER_YES) {
                this.props.openDashboardNewsTestingNewsletterDialog(userTesting);
            }
        }
    };

    saveUserTesting = () => {
        let formData = new FormData();

        formData.append('testingType', Constants.TESTING_TYPE_DASHBOARD_NEWS);
        formData.append('hasActiveNewsletter', '0');
        formData.append('testingData', JSON.stringify({
            testingAnswer: this.state.testingAnswer,
        }));

        this.props.saveUserTesting(formData);
    };

    onChangeDashboardNewsTestingAnswer = value => {
        this.setState({
            testingAnswer: value,
        });
    };

    render() {
        const {dashboardNewsTestingDialog: {open}, isSavingUserTesting} = this.props;
        const {testingAnswer}                                           = this.state;

        if (open === false) {
            return null;
        }

        return (
            <MarkDialog
                isLoading={isSavingUserTesting === true}
                closeOnDimmerClick={false}
                message={{
                    iconName: 'icon-dialog-question',
                    title: Translations.getStatic('dashboardNewsTestingDialogTitle'),
                    infoLines: [
                        Translations.getStatic('dashboardNewsTestingDialogText'),
                    ],
                    radioBoxes: [
                        {
                            label: Translations.getStatic('userTestingConfirm'),
                            value: Constants.TESTING_DASHBOARD_NEWS_ANSWER_YES,
                            checked: testingAnswer === Constants.TESTING_DASHBOARD_NEWS_ANSWER_YES,
                            onChange: () => this.onChangeDashboardNewsTestingAnswer(Constants.TESTING_DASHBOARD_NEWS_ANSWER_YES),
                        },
                        {
                            label: Translations.getStatic('userTestingCancel'),
                            value: Constants.TESTING_DASHBOARD_NEWS_ANSWER_NO,
                            checked: testingAnswer === Constants.TESTING_DASHBOARD_NEWS_ANSWER_NO,
                            onChange: () => this.onChangeDashboardNewsTestingAnswer(Constants.TESTING_DASHBOARD_NEWS_ANSWER_NO),
                        },
                    ],
                }}

                actions={[
                    {
                        title: Translations.getStatic('send'),
                        action: this.saveUserTesting,
                        color: 'orange',
                        loading: isSavingUserTesting === true,
                        disabled: isSavingUserTesting === true,
                    },
                    {
                        title: Translations.getStatic('cancel'),
                        disabled: isSavingUserTesting === true,
                        action: () => this.props.closeDashboardNewsTestingDialog(),
                    },
                ]}
            />
        );
    }
}

const mapStateToProps = state => ({
    dashboardNewsTestingDialog: state.dashboardNewsTestingDialog,
    userTestingModel: state.userTestingModel,
    isSavingUserTesting: state.isSavingUserTesting,
});

const mapDispatchToProps = {
    closeDashboardNewsTestingDialog,
    openDashboardNewsTestingNewsletterDialog,
    saveUserTesting,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(WithRouterProp(DashboardNewsTestingDialog));


