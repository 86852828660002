import {
    REQUEST_CREATE_CANDIDATE_MODEL,
    RECEIVE_CREATE_CANDIDATE_MODEL,
    SAVE_CANDIDATE,
    SAVE_CANDIDATE_FAILURE,
    SAVE_CANDIDATE_SUCCESS,
    UPDATE_CANDIDATE_DATA,
    SET_CANDIDATE_ERRORS,
} from '../actions/CreateCandidateActions';

export const isFetchingCreateCandidateModel = (state = false, action) => {
    switch (action.type) {
        case REQUEST_CREATE_CANDIDATE_MODEL:
            return true;

        case RECEIVE_CREATE_CANDIDATE_MODEL:
            return false;

        default:
            return state;
    }
};

export const createCandidateModel = (state = {}, action) => {
    switch (action.type) {
        case RECEIVE_CREATE_CANDIDATE_MODEL:
            return Object.assign({}, {
                settings: action.payload.settings,
                candidateData: action.payload.candidateData,
                errors: [],
            });

        case UPDATE_CANDIDATE_DATA:
            return Object.assign({}, state, {
                candidateData: Object.assign({}, state.candidateData, action.payload.candidateData),
            });

        case SET_CANDIDATE_ERRORS:
            return Object.assign({}, state, {
                errors: action.payload.errors,
            });

        case SAVE_CANDIDATE_SUCCESS:
            return Object.assign({}, state, {
                errors: [],
            });

        default:
            return state;
    }
};

export const isSavingCandidate = (state = false, action) => {
    switch (action.type) {
        case SAVE_CANDIDATE:
            return true;

        case SAVE_CANDIDATE_SUCCESS:
        case SAVE_CANDIDATE_FAILURE:
            return false;

        default:
            return state;
    }
};
