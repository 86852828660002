import React, {Component} from 'react';
import {connect} from 'react-redux';
import Grid from '../Grid';
import {closeNoModalDashboard} from '../../actions/ModalActions';
import PropTypes from 'prop-types';
import Translations from '../../helpers/Translations';
import {getCandidates, getCandidatesDataForModal} from '../../actions/b2b/DashboardActions';
import Sort from '../../helpers/Sort';
import FormSkeleton from '../../skeletons/components/FormSkeleton';
import DashboardModalOffersList from '../B2b/Dashboard/DashboardModalOffersList';
import NotificationMessage from '../NotificationMessage';
import InfiniteScroll from 'react-infinite-scroll-component';
import messageLockImage from '../../../img/icons/message-lock.svg';

class DashboardCandidatesInOffersNoModal extends Component
{
    componentDidMount() {
        const {dashboardModel: {activeOffers, limits: {newCandidatesLimitForFirstLoad}}} = this.props;

        let limitForFullLoad = 0;

        const sortedOffers      = this.getSortedOffers(activeOffers);
        const jobIdsForFullLoad = sortedOffers.map(offer => {
            if (limitForFullLoad < newCandidatesLimitForFirstLoad) {
                limitForFullLoad += parseInt(offer.counts.newCandidatesCount);

                return offer.jobId;
            }

            return null;
        }).filter(jobId => jobId !== null);

        this.props.getCandidatesDataForModal(sortedOffers.map(offer => offer.jobId), jobIdsForFullLoad);
    }

    getSortedOffers = activeOffers => Sort.asText(activeOffers, 'createdAd', 'DESC').filter(offer => offer.hasLockedCandidates === false && parseInt(offer.counts.newCandidatesCount) > 0);

    getCandidateIdsForNextLoad = (sortedOffers, candidateIdsPairs, candidates, newCandidatesLimitForLoad) => {
        let candidateIds = [];

        sortedOffers.map(offer => offer.jobId).forEach(jobId => {
            candidateIds = candidateIds.concat(candidateIdsPairs.filter(pair => pair.jobId === jobId).map(pair => pair.id));
        });

        return candidateIds.filter(candidateId => candidates.map(fullCandidate => fullCandidate.id).indexOf(candidateId) === -1).slice(0, newCandidatesLimitForLoad);
    };

    render() {
        const {noModalDashboardIsOpen, dashboardModel: {activeOffers, candidates, candidateIdsPairs, limits: {newCandidatesLimitForLoad}}, isFetchingDashboardDataForModal, displaySize} = this.props;

        const visibility          = noModalDashboardIsOpen === true ? 'show' : 'hide';
        const hasLockedCandidates = activeOffers.find(offer => offer.hasLockedCandidates === true) !== undefined;

        return (
            <div className={('noModal-wrapper noModal-dashboard ' + visibility)}>
                <div className="template-header header-extended_light">
                    <div className="header__row">
                        <div className="col-left">
                            <div className="square-button">
                                <div role="link" onClick={this.props.closeNoModalDashboard}>{Translations.getStatic('close')}</div>
                            </div>
                            <div className="nav-grid flex align center">
                                <div className="nav-grid__title">
                                    <h3 className="ellipsis">{Translations.getStatic('candidatesInNewStage')}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="noModal__content modal__content" id={'dashboardModalOffersListScrollable'}>
                    {
                        hasLockedCandidates === true && displaySize.isMobileProfesia === true
                        && (
                            <NotificationMessage
                                isDashboard={true}
                                type={'message-lock'}
                                imageSrc={messageLockImage}
                                title={Translations.getStatic('dashboardNewCandidatesNotAccessTitle')}
                                description={Translations.getStatic('dashboardNewCandidatesNotAccessText')}
                            />
                        )
                    }

                    {
                        hasLockedCandidates === true && displaySize.isMobileProfesia === false
                        && (
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <NotificationMessage
                                            isDashboard={true}
                                            type={'message-lock'}
                                            imageSrc={messageLockImage}
                                            title={Translations.getStatic('dashboardNewCandidatesNotAccessTitle')}
                                            description={Translations.getStatic('dashboardNewCandidatesNotAccessText')}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        )
                    }

                    {
                        isFetchingDashboardDataForModal === true || candidates === undefined
                            ? <FormSkeleton itemsCount={20}/>
                            : (
                                <div>
                                    <InfiniteScroll
                                        className="overflow-default"
                                        dataLength={candidates.length}
                                        next={() => this.props.getCandidates(this.getCandidateIdsForNextLoad(this.getSortedOffers(activeOffers), candidateIdsPairs, candidates, parseInt(newCandidatesLimitForLoad)))}
                                        hasMore={candidates.length < candidateIdsPairs.length}
                                        loader={null}
                                        scrollableTarget={'dashboardModalOffersListScrollable'}
                                    >
                                        <DashboardModalOffersList hasLockedCandidates={hasLockedCandidates}/>
                                    </InfiniteScroll>
                                </div>
                            )

                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    noModalDashboardIsOpen: state.noModalDashboardLogic.noModalDashboardIsOpen,
    dashboardModel: state.b2bDashboardModel,
    isFetchingDashboardDataForModal: state.isFetchingB2bDashboardDataForModal,
    displaySize: state.displaySize,
});

const mapDispatchToProps = {
    closeNoModalDashboard,
    getCandidatesDataForModal,
    getCandidates,
};

DashboardCandidatesInOffersNoModal.propTypes = {
    closeNoModalDashboard: PropTypes.func.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DashboardCandidatesInOffersNoModal);
