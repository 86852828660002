import { Languages } from '@profesia/mark-offer-form/i18n';
import Url from '../../../helpers/Url';

type Response = {
    suggestions: string[];
};

const getJobTitleAiSuggestions = async (
    userInput: string,
    locale: Languages
): Promise<string[]> => {
    const response: Response = await fetch(
        `${Url.MARK_SERVER_PREFIX}/api/v1/ai/generate/job-title`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                userInput,
                language: locale,
            }),
        }
    ).then((response) => response.json());

    return response.suggestions;
};

export default getJobTitleAiSuggestions;
