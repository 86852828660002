import React from 'react';
import {toast} from 'react-semantic-toasts';
import WebSocketWatcher from '../../helpers/WebSocketWatcher';
import ToastMessageUpdate from '../ToastMessageUpdate';
import Constants from '../../helpers/Constants';

class WebSocketToastMessage extends React.Component
{
    state = {
        visibleTypes: [],
    };

    componentDidMount() {
        if (this.state.subscription !== undefined) {
            this.unsubscribe(this.state.subscription);
        }

        const subscription = WebSocketWatcher.subscribe(this.props.mercurePublishUrl, this.props.topic, data => {
            this.showToastMessage(data);
        });

        this.setState({
            subscription,
        });

        window.addEventListener('beforeunload', () => this.unsubscribe(subscription));
    }

    componentWillUnmount() {
        this.unsubscribe(this.state.subscription);
        window.removeEventListener('beforeunload', () => this.unsubscribe(this.state.subscription));
    }

    unsubscribe = subscription => {
        WebSocketWatcher.unsubscribe(subscription);
    };

    storeType = type => {
        if (this.state.visibleTypes.includes(type) === false) {
            const visibleTypes = this.state.visibleTypes;

            visibleTypes.push(type);

            this.setState({
                visibleTypes,
            });
        }
    };

    closeToastType = type => {
        if (this.state.visibleTypes.includes(type) === true) {
            this.setState({
                visibleTypes: this.state.visibleTypes.filter(visibleType => visibleType !== type),
            });
        }
    };

    showToastMessage = data => {
        if (this.state.visibleTypes.includes(data.action) === false
            && WebSocketWatcher.externalChange(data) === true
        ) {
            this.storeType(data.action);

            setTimeout(() => {
                toast({
                    type: 'info',
                    color: 'green',
                    title: '',
                    description: (
                        <ToastMessageUpdate
                            message={data.message}
                            userActions={data.userActions}
                            type={data.action}
                            closeToastType={() => this.closeToastType(data.action)}
                            update={() => this.props.update()}
                        />
                    ),
                    animation: 'fade left',
                    time: Constants.WEBSOCKET_TIME_DO_NOT_CLOSE,
                    size: 'small',
                });
            }, 400);
        }
    };

    render() {
        return this.props.children || null;
    }
}

export default WebSocketToastMessage;

