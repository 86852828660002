import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Select from '@profesia/adamui/components/common/dropdown/SelectDefault';
import Form from '../Form';
import Grid from '../Grid';
import Divider from '../Divider';
import {connect} from 'react-redux';
import Translations from '../../helpers/Translations';
import Arrays from '../../helpers/Arrays';
import InternalInfo from '../InternalInfo/InternalInfo';

class TemplateDropdownSelector extends React.Component
{
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.templatesForDropdownSelector.hasOwnProperty('templates') === false || this.props.templatesForDropdownSelector.templates === undefined || this.props.templateType !== this.props.templatesForDropdownSelector.type) {
            this.props.onStartFetchingData();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.hasOwnProperty('languageFilter') && this.props.hasOwnProperty('languageFilter') && this.props.languageFilter !== null && prevProps.languageFilter.languageId !== this.props.languageFilter.languageId) {
            this.props.onStartFetchingData();
        }
    }

    handleChange = (e, value) => {
        if (e.type === 'keydown' && e.keyCode === 13) {
            this.props.onSelectedValue(value);
        } else if (e.type === 'click' || e.type === 'touchend') {
            this.props.onSelectedValue(value);
        }
    };

    render() {
        if (this.props.isFetchingTemplatesForDropdownSelector === true) {
            return null;
        }

        const withLanguageFilter = this.props.hasOwnProperty('languageFilter') && this.props.languageFilter !== null;

        const {emailTemplateType} = this.props;

        let items = this.props.templatesForDropdownSelector.hasOwnProperty('templates') === true && this.props.templatesForDropdownSelector.templates !== undefined
            ? this.props.templatesForDropdownSelector.templates
            : [];

        if (emailTemplateType !== undefined) {
            let predefinedItems = items.filter(item => parseInt(item.typeId) === parseInt(emailTemplateType));

            predefinedItems.map((item, index) => {
                let className = '';

                if (index === 0) {
                    className += ' item-first-predefined';
                }

                if (index === predefinedItems.length - 1) {
                    className += ' item-last-predefined';
                }

                return {
                    ...item,
                    className,
                };
            });

            const otherItems = items.filter(item => parseInt(item.typeId) !== parseInt(emailTemplateType));

            items = predefinedItems.concat(otherItems).map(item => {
                const newItem = {
                    ...item,
                };

                delete newItem.typeId;

                return newItem;
            });
        }

        if (this.props.hasOwnProperty('emptyItem') === true && this.props.emptyItem !== undefined && this.props.isFetchingTemplatesForDropdownSelector === false) {
            items = Arrays.prepend({
                value: this.props.emptyItem,
                text: Translations.getStatic('emptyTemplate'),
            }, items);
        }

        if (this.props.changeSelectedItem === true) {
            items = items.map(item => ({
                ...item,
                text: item.text + (parseInt(item.value) === parseInt(this.props.defaultValue) ? (' (' + Translations.getStatic('editedItem') + ')') : ''),
            }));
        }

        let languageFilter = null;

        if (withLanguageFilter === true) {
            const {languageId, languages, onChangeLanguage} = this.props.languageFilter;

            const langOptions = languages.map(language => ({
                key: language.languageCode,
                label: language.language,
                id: language.languageId,
            }));

            languageFilter = (
                <Grid.Column computer={3} tablet={8} className="padding-right_none">
                    <Select
                        variant="dark"
                        value={languageId}
                        options={langOptions}
                        onChange={(value) => onChangeLanguage(value)}
                    />
                    {this.props.displaySize.isMobile === true && <Divider hidden className="size_8"/>}
                </Grid.Column>
            );
        }

        const options = items.map((item, i) => ({label: item.text, id: item.value, key: i}));

        return (
            <Form className={this.props.isFetchingTemplatesForDropdownSelector ? 'loading' : ''}>
                <Grid className={this.props.noSpacing === true ? 'padding-top_none' : ''}>
                    <Grid.Row>
                        {languageFilter}

                        <Grid.Column computer={withLanguageFilter === true ? 13 : 16} tablet={16}>
                            <Form.Field>
                                {
                                    withLanguageFilter === false
                                        ? <label>{this.props.templatesDropdownLabel}</label>
                                        : null
                                }

                                <Autocomplete
                                    style={{height: '32px'}}
                                    disabled={this.props.disableTemplateDropdownSelector}
                                    className="ui fluid search selection dropdown darker multiple-basic selection"
                                    noOptionsText={Translations.getStatic('notFoundTemplates')}
                                    value={options.find(option => option.id === this.props.defaultValue)?.label ?? null}
                                    options={options}
                                    onChange={(e, {id}) => this.handleChange(e, id)}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option.key}>
                                            {option.label}
                                            <InternalInfo text={option.id}/>
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} placeholder={Translations.getStatic('templateSelect')}/>
                                    )}
                                />
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>
        );
    }
}

const mapStateToProps = state => ({
    isFetchingTemplatesForDropdownSelector: state.isFetchingTemplatesForDropdownSelector,
    templatesForDropdownSelector: state.templatesForDropdownSelector,
    displaySize: state.displaySize,
});

const TemplateDropdownSelectorContainer = connect(mapStateToProps, {})(TemplateDropdownSelector);

TemplateDropdownSelectorContainer.propTypes = {
    onStartFetchingData: PropTypes.func.isRequired,
    onSelectedValue: PropTypes.func.isRequired,
    defaultValue: PropTypes.number,
    changeSelectedItem: PropTypes.bool,
    noSpacing: PropTypes.bool,
    disableTemplateDropdownSelector: PropTypes.bool,

    languageFilter: PropTypes.shape({
        languages: PropTypes.array.isRequired,
        languageId: PropTypes.number.isRequired,
        onChangeLanguage: PropTypes.func.isRequired,
    }),

    emailTemplateType: PropTypes.number,
};

TemplateDropdownSelectorContainer.defaultProps = {
    defaultValue: 0,
    changeSelectedItem: false,
    changedItemLabel: '',
    noSpacing: false,
    languageFilter: null,
    disableTemplateDropdownSelector: false,
};

export default TemplateDropdownSelectorContainer;
