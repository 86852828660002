export const REQUEST_CANDIDATE_QUESTIONNAIRE_MODEL = 'REQUEST_CANDIDATE_QUESTIONNAIRE_MODEL';
export const RECEIVE_CANDIDATE_QUESTIONNAIRE_MODEL = 'RECEIVE_CANDIDATE_QUESTIONNAIRE_MODEL';
export const SET_CANDIDATE_QUESTIONNAIRE_ERRORS    = 'SET_CANDIDATE_QUESTIONNAIRE_ERRORS';
export const SAVE_CANDIDATE_QUESTIONNAIRE          = 'SAVE_CANDIDATE_QUESTIONNAIRE';
export const SAVE_CANDIDATE_QUESTIONNAIRE_SUCCESS  = 'SAVE_CANDIDATE_QUESTIONNAIRE_SUCCESS';
export const SAVE_CANDIDATE_QUESTIONNAIRE_FAILURE  = 'SAVE_CANDIDATE_QUESTIONNAIRE_FAILURE';

export const requestCandidateQuestionnaireModel = (jobId, authHash) => ({
    type: REQUEST_CANDIDATE_QUESTIONNAIRE_MODEL,
    meta: {
        requestRoute: 'v1/candidate-questionnaire/model/form/' + jobId,
        requestData: {
            authHash,
        },
        onRequestSuccess: RECEIVE_CANDIDATE_QUESTIONNAIRE_MODEL,
    },
});

export const saveCandidateQuestionnaire = questionnaireData => ({
    type: SAVE_CANDIDATE_QUESTIONNAIRE,
    meta: {
        requestRoute: 'v1/candidate-questionnaire/action/save',
        requestMethod: 'POST',
        requestBody: questionnaireData,

        onRequestSuccess: SAVE_CANDIDATE_QUESTIONNAIRE_SUCCESS,
        onRequestFailure: SAVE_CANDIDATE_QUESTIONNAIRE_FAILURE,
    },
});

export const setCandidateQuestionnaireErrors = errors => ({
    type: SET_CANDIDATE_QUESTIONNAIRE_ERRORS,
    payload: {
        errors,
    },
});
