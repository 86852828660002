import React from 'react';
import {SkeletonRectangular} from '@profesia/adamui/components/common/skeleton';
import {Stack} from '@mui/material';

const JobDetailPositionSkeleton = () => (
    <div className="list">
        <div className="list__header_basic">
            <Stack>
                <Stack flex={1} mt={3}>
                    <SkeletonRectangular width={245} height={18} borderRadius={26}/>
                </Stack>
            </Stack>
        </div>

        <div className="list__data skeleton">
            <div className="ui grid">
                <div className="ten wide column">
                    <div className="ui grid data-block data-block_primary">
                        <div className="row">
                            <div className="column">
                                <div className="data-block__content">
                                    <Stack flex={1} spacing={4}>
                                        <Stack flex={1} spacing={2}>
                                            <SkeletonRectangular width={300} height={16} borderRadius={26}/>
                                            <SkeletonRectangular height={8} borderRadius={26}/>
                                        </Stack>

                                        <Stack flex={1} spacing={2}>
                                            <SkeletonRectangular width={300} height={16} borderRadius={26}/>
                                            <SkeletonRectangular height={8} borderRadius={26}/>
                                        </Stack>

                                        <Stack flex={1} spacing={2}>
                                            <SkeletonRectangular width={300} height={16} borderRadius={26}/>
                                            {[...Array(8)].map((e, i) => <SkeletonRectangular height={8} borderRadius={26} key={i}/>)}
                                            <SkeletonRectangular width={670} height={8} borderRadius={26}/>
                                        </Stack>

                                        {
                                            [...Array(2)].map((e, i) => (
                                                <Stack flex={1} spacing={2} key={i}>
                                                    <SkeletonRectangular width={300} height={16} borderRadius={26}/>
                                                    <SkeletonRectangular height={8} borderRadius={26}/>
                                                </Stack>
                                            ))
                                        }

                                        <Stack flex={1} spacing={2}>
                                            <SkeletonRectangular width={300} height={16} borderRadius={26}/>
                                            {[...Array(2)].map((e, i) => <SkeletonRectangular height={8} borderRadius={26} key={i}/>)}
                                            <SkeletonRectangular width={670} height={8} borderRadius={26}/>
                                        </Stack>
                                    </Stack>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="six wide column">
                    <div className="ui grid data-block data-block_secondary">
                        <div className="row">
                            <div className="column">
                                {
                                    [...Array(4)].map((e, i) => (
                                        <div className="data-block__content skeleton" key={i}>
                                            <Stack spacing={1}>
                                                <SkeletonRectangular width={245} height={18} borderRadius={26}/>
                                                <SkeletonRectangular width={116} height={10} borderRadius={26}/>
                                                <SkeletonRectangular width={188} height={10} borderRadius={26}/>
                                            </Stack>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default JobDetailPositionSkeleton;
