import React from 'react';
import PropTypes from 'prop-types';
import Popup from './Popup';

const CandidateAvatar = props => {
    const {profilePhoto, firstname, surname, isContacted, tooltip} = props;

    const candidateInitials = firstname.charAt(0) + surname.charAt(0);

    const avatar = profilePhoto !== null
        ? <span className={'candidate-avatar photo' + ((isContacted === true || isContacted === undefined) ? ' no-contacted' : '')}><img src={profilePhoto}/></span>
        : <span className={'candidate-avatar candidate-initials' + ((isContacted === true || isContacted === undefined) ? ' no-contacted' : '')}>{candidateInitials === '' ? '•' : candidateInitials}</span>;

    return (
        tooltip.length > 0 
            ? (
                <Popup
                    content={tooltip}
                    trigger={avatar}
                />
            ) : (
                <>
                    {avatar}
                </>
            )
    )
};

CandidateAvatar.propTypes = {
    profilePhoto: PropTypes.string,
    isContacted: PropTypes.bool,
    firstname: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired,
};

CandidateAvatar.defaultProps = {
    firstname: '',
    surname: '',
    tooltip: '',
    profilePhoto: null,
};

export default CandidateAvatar;
