import AttachmentAssistantHelper from '../helpers/components/AttachmentAssistantHelper';

export const REQUEST_RECOMMENDATION_FORM_MODEL = 'REQUEST_RECOMMENDATION_FORM_MODEL';
export const RECEIVE_RECOMMENDATION_FORM_MODEL = 'RECEIVE_RECOMMENDATION_FORM_MODEL';
export const SET_RECOMMENDATION_FORM_ERRORS    = 'SET_RECOMMENDATION_FORM_ERRORS';
export const SAVE_RECOMMENDATION               = 'SAVE_RECOMMENDATION';
export const SAVE_RECOMMENDATION_SUCCESS       = 'SAVE_RECOMMENDATION_SUCCESS';
export const SAVE_RECOMMENDATION_FAILURE       = 'SAVE_RECOMMENDATION_FAILURE';

export const requestRecommendationFormModel = (jobId, tag = '') => ({
    type: REQUEST_RECOMMENDATION_FORM_MODEL,
    meta: {
        requestRoute: 'v1/recommendation/model/form/' + jobId,
        requestData: {
            tag,
        },
        onRequestSuccess: RECEIVE_RECOMMENDATION_FORM_MODEL,
    },
});

export const saveRecommendation = recommendationData => ({
    type: SAVE_RECOMMENDATION,
    payload: {
        hashId: AttachmentAssistantHelper.createAttachmentAssistantHashIdForRecommendationForm(),
    },
    meta: {
        requestRoute: 'v1/recommendation/action/save',
        requestMethod: 'POST',
        requestBody: recommendationData,

        onRequestSuccess: SAVE_RECOMMENDATION_SUCCESS,
        onRequestFailure: SAVE_RECOMMENDATION_FAILURE,
    },
});

export const setRecommendationFormErrors = errors => ({
    type: SET_RECOMMENDATION_FORM_ERRORS,
    payload: {
        errors,
    },
});
