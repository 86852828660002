import React from 'react';

const DashboardDYTipsSpace = () => (
    <div id="dashboard-dy-tips-space"/>
);

export default DashboardDYTipsSpace;



