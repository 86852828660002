import {CLEAR_CURRENTLY_ACCEPTED_CANDIDATES, REMOVE_FROM_CURRENTLY_ACCEPTED_CANDIDATES, SET_CURRENTLY_ACCEPTED_CANDIDATES} from '../actions/CurrentlyAcceptedCandidatesActions';

const defaultState = {
    candidateIds: [],
    url: '',
    jobId: 0,
};

export const currentlyAcceptedCandidates = (state = defaultState, action) => {
    switch (action.type) {
        case SET_CURRENTLY_ACCEPTED_CANDIDATES:
            return {
                candidateIds: action.payload.candidateIds,
                url: action.payload.url,
                jobId: action.payload.jobId,
            };

        case CLEAR_CURRENTLY_ACCEPTED_CANDIDATES:
            return defaultState;

        case REMOVE_FROM_CURRENTLY_ACCEPTED_CANDIDATES:
            return {
                candidateIds: state.candidateIds.filter(candidateId => action.payload.candidateIds.indexOf(candidateId) === -1),
            };

        default:
            return state;
    }
};
