import React from 'react';
import PropTypes from 'prop-types';
import SendEmail from '../Email/SendEmail';
import Constants from '../../helpers/Constants';
import Form from '../Form';
import Checkbox from '@profesia/adamui/components/common/input/checkbox/Checkbox';
import Grid from '../Grid';
import Divider from '../Divider';
import Heading from '../Heading';
import Translations from '../../helpers/Translations';
import {getCandidatesForAction, setSendEmailParams} from '../../actions/ActionWithCandidateActions';
import {updateEmailTemplate} from '../../actions/SendEmailActions';
import {connect} from 'react-redux';
import SendEmailHelper from '../../helpers/components/SendEmailHelper';
import WysiwygHelper from '../../helpers/components/WysiwygHelper';
import Storages from '../../helpers/Storages';
import FormSkeleton from '../../skeletons/components/FormSkeleton';

class SendCandidatesToColleagues extends React.Component
{
    componentDidMount = () => {
        this.props.getCandidatesForAction(this.props.candidateIds, [Constants.CANDIDATE_OUTPUT_FLAG_HAS_EVENT, Constants.CANDIDATE_OUTPUT_FLAG_JOB_HAS_QUESTIONNAIRE]);
    };

    render = () => {
        if (this.props.isFetchingCandidatesForAction === true || this.props.candidateActionModel.hasOwnProperty('candidates') === false || this.props.candidateActionModel.candidates.length === 0) {
            return <FormSkeleton/>;
        }

        const {modalLogic: {modalType, modalProps}, candidateActionModel: {sendEmailParams}, sendEmailModel: {emailTemplateData}} = this.props;

        const isMassForwarding = SendEmailHelper.isMassForwarding(this.props.candidateIds, modalType, modalProps);

        let emailTemplateText = emailTemplateData?.text ?? '';

        return (
            <>
                {
                    this.props.multipleType === true
                    && (
                        <>
                            <Heading as="h3" className="highlighted border">
                                {Translations.getStatic('chosenCandidates')}: {this.props.candidateIds.length}
                            </Heading>
                            <Divider className="hidden size_4"/>
                            {
                                isMassForwarding === true
                                && (
                                    <>
                                        <Grid>
                                            <Grid.Row>
                                                <Grid.Column width={16}>
                                                    <Form.Field>
                                                        <Checkbox
                                                            checked={Storages.getLocal('multipleForwardingAsSingleEmail', sendEmailParams.multipleForwardingAsSingleEmail) === false}
                                                            label={Translations.getStatic('forwardCandidatesInSeparateEmails')}
                                                            onChange={(e) => {
                                                                this.props.setSendEmailParams({
                                                                    multipleForwardingAsSingleEmail: !e.target.checked,
                                                                });

                                                                Storages.setLocal('multipleForwardingAsSingleEmail', !e.target.checked);

                                                                const variableCandidatesList = WysiwygHelper.createVariableString(Translations.getStatic('highlightTextareaVariableCandidatesList'));

                                                                if (e.target.checked === true) {
                                                                    emailTemplateText = emailTemplateText.replace(variableCandidatesList, '');
                                                                } else if (emailTemplateText.includes(variableCandidatesList) === false) {
                                                                    emailTemplateText += '\n' + variableCandidatesList;
                                                                }

                                                                this.props.updateEmailTemplate({
                                                                    text: emailTemplateText,
                                                                });
                                                            }}
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                        <Divider className="hidden size_12"/>
                                    </>
                                )
                            }
                        </>
                    )
                }

                <SendEmail
                    errors={this.props.errors}
                    visibleFields={SendEmailHelper.getDefaultEmailVisibleFields(Constants.EMAIL_TYPE_TO_COLLEAGUE)}

                    recipients={
                        this.props.sendEmailModel.hasOwnProperty('recipients') === true && this.props.sendEmailModel.recipients.length > 0
                            ? this.props.sendEmailModel.recipients
                            : []
                    }

                    bcc={
                        this.props.sendEmailModel.hasOwnProperty('bcc') === true && this.props.sendEmailModel.bcc.length > 0
                            ? this.props.sendEmailModel.bcc
                            : []
                    }

                    candidateIds={this.props.candidateIds}
                    emailTemplateType={Constants.EMAIL_TEMPLATE_TYPE_COLLEAGUE_FORWARDING}
                    isMassForwarding={isMassForwarding}
                />
            </>
        );
    };
}

SendCandidatesToColleagues.propTypes = {
    candidateIds: PropTypes.array.isRequired,
    multipleType: PropTypes.bool.isRequired,
    errors: PropTypes.array,
};

SendCandidatesToColleagues.defaultProps = {
    multipleType: false,
    errors: [],
};

const mapStateToProps = state => ({
    sendEmailModel: state.sendEmailModel,
    candidateActionModel: state.candidateActionModel,
    isFetchingCandidatesForAction: state.isFetchingCandidatesForAction,
    modalLogic: state.modalLogic,
});

const mapDispatchToProps = {
    getCandidatesForAction,
    setSendEmailParams,
    updateEmailTemplate,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SendCandidatesToColleagues);
