import {
    REQUEST_GLOBAL_SEARCH_MODEL, RECEIVE_GLOBAL_SEARCH_MODEL,
    LIVE_SEARCH, LIVE_SEARCH_SUCCESS, LIVE_SEARCH_FAILURE,
    GLOBAL_SEARCH, GLOBAL_SEARCH_SUCCESS, GLOBAL_SEARCH_FAILURE,
    SET_SEARCH_TEXT,
    CLEAR_LIVE_SEARCH,
    LOAD_CANDIDATES, LOAD_CANDIDATES_SUCCESS, LOAD_CANDIDATES_FAILURE, RECEIVE_CANDIDATE_IDS, REQUEST_CANDIDATE_IDS, CLEAR_BASIC_CANDIDATES_DATA, CLEAR_CANDIDATES_DATA, CONFIRM_FILTER,
} from '../actions/GlobalSearchActions';
import {
    CANDIDATE_REMOVE_TAG_SUCCESS, CANDIDATE_ADD_TAG_SUCCESS, CANDIDATE_SET_NOTE_SUCCESS,
} from '../actions/CandidateActions';
import {CANCEL_FILTER_BY_CANDIDATE_IDS, FILTER_CANDIDATES_BY_ADD_DATE, FILTER_CANDIDATES_BY_CANDIDATE_IDS, FILTER_CANDIDATES_BY_NAME, FILTER_CANDIDATES_BY_NO_GDPR_AGREEMENT, FILTER_CANDIDATES_BY_NOT_CONTACTED, FILTER_CANDIDATES_BY_NOTE, FILTER_CANDIDATES_BY_SCORE_RANGE, FILTER_CANDIDATES_BY_SOURCE_TYPE_COLLECTION, FILTER_CANDIDATES_BY_TAG_LIST, RESET_FILTER} from '../actions/CandidatesFilterActions';
import {SORT_CANDIDATES_BY} from '../actions/CandidatesListActions';
import {ACTIVATE_JOB, ACTIVATE_JOB_FAILURE} from '../actions/JobsListActions';
import {ANONYMIZE_CANDIDATES_SUCCESS, SAVE_CANDIDATE_GDPR_AGREEMENT_SUCCESS} from '../actions/ActionWithCandidateActions';
import Constants from '../helpers/Constants';
import CandidatesReducer from '../helpers/generalReducers/CandidatesReducer';
import {SAVE_CANDIDATES_LIST_COLUMNS_ORDER_SUCCESS} from '../actions/CandidatesListColumnsOrderActions';
import Objects from '../helpers/Objects';
import {CANCEL_CHUNKS_LOAD} from '../actions/ChunkCancellationActions';
import CandidateFilterHelper from '../helpers/components/CandidateFilterHelper';

export const liveSearchModel = (state = {
    isFetching: false,
    results: {},
    searchText: '',
}, action) => {
    let newResults = state.results;

    switch (action.type) {
        case LIVE_SEARCH:
            newResults[action.payload.requestToken] = [];

            return {
                ...state,
                isFetching: true,
                results: newResults,
                requestToken: action.payload.requestToken,
            };
        case LIVE_SEARCH_SUCCESS:
            newResults[action.payload.requestToken] = action.payload.candidates;

            return {
                ...state,
                isFetching: false,
                results: newResults,
            };
        case LIVE_SEARCH_FAILURE:
            newResults[action.meta.requestPayload.requestToken] = [];

            return {
                ...state,
                isFetching: false,
                results: newResults,
            };
        case SET_SEARCH_TEXT:
            return {
                ...state,
                searchText: action.payload.searchText,
            };
        case CLEAR_LIVE_SEARCH:
            return {
                ...state,
                searchText: '',
                requestToken: '',
                results: {},
            };

        case ACTIVATE_JOB:
            Object.keys(state.results).forEach(key => {
                let candidates = state.results[key].map(candidate => candidate.jobId === action.payload.jobId
                    ? {
                        ...candidate,
                        jobStateId: action.payload.stateId,
                    }
                    : candidate,
                );

                return newResults[key] = candidates;
            });

            return {
                ...state,
                results: newResults,
            };

        case ACTIVATE_JOB_FAILURE:
            newResults = {};

            Object.keys(state.results).forEach(key => {
                let candidates = state.results[key].map(candidate => candidate.jobId === action.meta.requestPayload.jobId
                    ? {
                        ...candidate,
                        jobStateId: Constants.JOB_STATE_UNPAID,
                    }
                    : candidate,
                );

                return newResults[key] = candidates;
            });

            return {
                ...state,
                results: newResults,
            };

        default:
            return state;
    }
};

export const isFetchingGlobalSearchModel = (state = false, action) => {
    switch (action.type) {
        case REQUEST_GLOBAL_SEARCH_MODEL:
            return true;
        case RECEIVE_GLOBAL_SEARCH_MODEL:
            return false;
        default:
            return state;
    }
};

export const globalSearchModel = (state = {}, action) => {
    switch (action.type) {
        case REQUEST_GLOBAL_SEARCH_MODEL:
            return {};

        case REQUEST_CANDIDATE_IDS:
            return state;

        case RECEIVE_GLOBAL_SEARCH_MODEL:
            if (action.meta.requestPayload.candidateName !== '') {
                return {
                    ...state,
                    ...action.payload,
                    appliedFilter: true,
                };
            }

            if (action.meta.requestPayload.fromB2bDashboard === true) {
                return {
                    ...state,
                    ...action.payload,
                    appliedFilter: true,
                };
            }

            return {
                ...state,
                ...action.payload,
            };

        case RECEIVE_CANDIDATE_IDS:
            return {
                ...state,
                ...action.payload,
            };

        case GLOBAL_SEARCH:
            return {
                ...state,
                fetchedCandidateIds: undefined,
                basicCandidatesData: undefined,
                candidates: undefined,
            };

        case GLOBAL_SEARCH_FAILURE:
        case CLEAR_BASIC_CANDIDATES_DATA:
            return {
                ...state,
                basicCandidatesData: [],
            };

        case GLOBAL_SEARCH_SUCCESS:
            return {
                ...state,
                candidateIds: action.payload.basicCandidatesData.map(basicCandidateData => basicCandidateData.id),
                basicCandidatesData: action.payload.basicCandidatesData,
            };

        case LOAD_CANDIDATES_FAILURE:
            return {
                ...state,
                fetchedCandidateIds: undefined,
                candidates: undefined,
            };

        case LOAD_CANDIDATES_SUCCESS:
            return {
                ...state,

                candidates: state.hasOwnProperty('candidates') === true && state.candidates !== undefined
                    ? state.candidates.concat(action.payload.candidates)
                    : action.payload.candidates,

                fetchedCandidateIds: state.hasOwnProperty('fetchedCandidateIds') === true && state.fetchedCandidateIds !== undefined
                    ? state.fetchedCandidateIds.concat(action.payload.fetchedCandidateIds)
                    : action.payload.fetchedCandidateIds,
            };

        case CLEAR_CANDIDATES_DATA:
            return {
                ...state,
                fetchedCandidateIds: [],
                candidates: [],
            };

        case CANDIDATE_ADD_TAG_SUCCESS:
            if (state.hasOwnProperty('candidates') === true) {
                return {
                    ...state,

                    candidates: state.candidates.map(candidate => {
                        const candidateData        = action.payload.candidates.find(payloadCandidate => candidate.id === payloadCandidate.id);
                        const candidateHistoryData = action.payload.candidatesHistory.find(payloadCandidate => candidate.candidateId === payloadCandidate.id);

                        return candidateData === undefined
                            ? candidate
                            : {
                                ...candidate,
                                tags: candidateData.tags,
                                history: candidateHistoryData === undefined ? candidate.history : candidateHistoryData.history,
                            };
                    }),

                    settings: {
                        ...state.settings,
                        tags: action.payload.tags,
                    },
                };
            }

            return state;

        case CANDIDATE_REMOVE_TAG_SUCCESS:
            if (state.hasOwnProperty('candidates') === true) {
                return {
                    ...state,
                    candidates: state.candidates.map(candidate => {
                        if (candidate.id === action.payload.candidate.id) {
                            return {
                                ...candidate,
                                tags: action.payload.candidate.tags,
                            };
                        }
                        return candidate;
                    }),
                };
            }

            return state;

        case CANDIDATE_SET_NOTE_SUCCESS:
            if (state.hasOwnProperty('candidates') === true) {
                return {
                    ...state,
                    candidates: state.candidates.map(candidate => {
                        if (candidate.id === action.payload.candidate.id) {
                            return {
                                ...candidate,
                                ...action.payload.candidate,
                            };
                        }

                        return candidate;
                    }),
                };
            }

            return state;

        case CONFIRM_FILTER:
            return {
                ...state,

                previousState: {
                    candidateIds: state.candidateIds,
                    basicCandidatesData: state.basicCandidatesData,
                    candidates: state.candidates,
                    fetchedCandidateIds: state.fetchedCandidateIds,
                    appliedFilter: state.appliedFilter || false,
                    changedFilter: state.changedFilter || false,
                },

                candidateIds: undefined,
                basicCandidatesData: undefined,
                candidates: undefined,
                fetchedCandidateIds: undefined,
                appliedFilter: true,
                changedFilter: false,
            };

        case CANCEL_CHUNKS_LOAD:
            return {
                ...state,
                ...state.previousState,
                changedFilter: false,
            };

        case FILTER_CANDIDATES_BY_NAME:
        case FILTER_CANDIDATES_BY_TAG_LIST:
        case FILTER_CANDIDATES_BY_SCORE_RANGE:
        case FILTER_CANDIDATES_BY_SOURCE_TYPE_COLLECTION:
        case FILTER_CANDIDATES_BY_NOT_CONTACTED:
        case FILTER_CANDIDATES_BY_NO_GDPR_AGREEMENT:
        case FILTER_CANDIDATES_BY_ADD_DATE:
        case FILTER_CANDIDATES_BY_CANDIDATE_IDS:
        case FILTER_CANDIDATES_BY_NOTE:
        case RESET_FILTER:
            if (CandidateFilterHelper.isFilterSameAsPrevious(action.payload.candidatesFilter, action.payload) === true) {
                return {
                    ...state,
                    changedFilter: false,
                };
            }

            return {
                ...state,
                changedFilter: action.type !== FILTER_CANDIDATES_BY_TAG_LIST || action.payload.tagList.find(tag => tag.active === true) !== undefined || state.changedFilter === true,
            };

        case CANCEL_FILTER_BY_CANDIDATE_IDS:
            if (state.hasOwnProperty('candidateIds') === true) {
                return Objects.deleteProperty(state, 'candidateIds');
            }

            return state;

        case SORT_CANDIDATES_BY:
            if (state.hasOwnProperty('basicCandidatesData') === false || state.basicCandidatesData === undefined) {
                return state;
            }

            if (state.hasOwnProperty('candidates') === false || state.candidates === undefined) {
                return state;
            }

            if (action.payload.withRefresh === true) {
                return {
                    ...state,
                    basicCandidatesData: undefined,
                    candidates: undefined,
                };
            }

            return state;

        case ACTIVATE_JOB:
            if (state.hasOwnProperty('candidates') === false) {
                return state;
            }

            return {
                ...state,
                candidates: state.candidates.map(candidate => candidate.jobId === action.payload.jobId
                    ? {
                        ...candidate,
                        jobStateId: action.payload.stateId,
                    }
                    : candidate,
                ),
            };

        case ACTIVATE_JOB_FAILURE:
            if (state.hasOwnProperty('candidates') === false) {
                return state;
            }

            return {
                ...state,
                candidates: state.candidates.map(candidate => candidate.jobId === action.meta.requestPayload.jobId
                    ? {
                        ...candidate,
                        jobStateId: Constants.JOB_STATE_UNPAID,
                    }
                    : candidate,
                ),
            };

        case ANONYMIZE_CANDIDATES_SUCCESS:
            if (state.hasOwnProperty('basicCandidatesData') === false || state.basicCandidatesData === undefined) {
                return state;
            }

            return {
                ...state,
                basicCandidatesData: state.basicCandidatesData.filter(basicCandidate => action.payload.anonymizedCandidateIds.includes(basicCandidate.id) === false),
                candidates: state.candidates.filter(candidate => action.payload.anonymizedCandidateIds.includes(candidate.id) === false),
                fetchedCandidateIds: state.fetchedCandidateIds.filter(candidateId => action.payload.anonymizedCandidateIds.includes(candidateId) === false),
            };

        case SAVE_CANDIDATE_GDPR_AGREEMENT_SUCCESS:
            if (state.hasOwnProperty('basicCandidatesData') === false || state.basicCandidatesData === undefined) {
                return state;
            }

            if (state.hasOwnProperty('candidates') === false || state.candidates === undefined) {
                return state;
            }

            return {
                ...state,
                candidates: state.candidates.map(candidate => candidate.id === action.meta.requestPayload.candidateId
                    ? {
                        ...candidate,
                        gdprAgreement: action.payload.candidateGdprAgreement,
                        attachmentsCount: action.payload.candidateAttachmentsCount,
                    }
                    : candidate,
                ),
                basicCandidatesData: state.basicCandidatesData.map(candidate => candidate.id === action.meta.requestPayload.candidateId
                    ? {
                        ...candidate,
                        gdprAgreement: action.payload.candidateGdprAgreement,
                        attachmentsCount: action.payload.candidateAttachmentsCount,
                    }
                    : candidate,
                ),
            };

        case SAVE_CANDIDATES_LIST_COLUMNS_ORDER_SUCCESS:
            if (state.hasOwnProperty('settings') === false || state.settings.hasOwnProperty('candidatesListColumnsOrder') === false) {
                return state;
            }

            return {
                ...state,
                settings: {
                    ...state.settings,
                    candidatesListColumnsOrder: action.payload.candidatesListColumnsOrder,
                },
            };

        default:
            return CandidatesReducer.process(state, action);
    }
};

export const isFetchingGlobalSearchData = (state = false, action) => {
    switch (action.type) {
        case GLOBAL_SEARCH:
            return true;
        case GLOBAL_SEARCH_SUCCESS:
        case GLOBAL_SEARCH_FAILURE:
        case CANCEL_CHUNKS_LOAD:
            return false;
        default:
            return state;
    }
};

export const isFetchingCandidates = (state = false, action) => {
    switch (action.type) {
        case LOAD_CANDIDATES:
            return true;

        case LOAD_CANDIDATES_SUCCESS:
        case LOAD_CANDIDATES_FAILURE:
        case CANCEL_CHUNKS_LOAD:
            return false;

        default:
            return state;
    }
};

export const isFetchingCandidateIds = (state = false, action) => {
    switch (action.type) {
        case REQUEST_CANDIDATE_IDS:
            return true;

        case RECEIVE_CANDIDATE_IDS:
        case CANCEL_CHUNKS_LOAD:
            return false;

        default:
            return state;
    }
};


