import React from 'react';

const List = (props) => {
    const Tag = props.as || 'div';
    const className = `${props.className ? props.className + ' ' : ''}`;

    const validProps = {
        ...props,
        as: typeof props.as === 'string' ? props.as : 'div',
    };

    return (
        <Tag {...validProps} className={`list ${className}`}>
            {props.children}
        </Tag>
    );
};

const Item = (props) => {
    const Tag = props.as || 'div';

    const validProps = {
        ...props,
        as: typeof props.as === 'string' ? props.as : 'div',
    };

    return (
        <Tag {...validProps} className={`item ${props.className}`}>
            {props.children}
        </Tag>
    );
};

const Header = (props) => {
    const Tag = props.as || 'div';

    const validProps = {
        ...props,
        as: typeof props.as === 'string' ? props.as : 'div',
    };

    return (
        <Tag {...validProps} className={`header ${props.className}`}>
            {props.children}
        </Tag>
    );
};

List.Item = Item;
List.Header = Header;

export default List;
