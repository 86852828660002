import React, {Component} from 'react';
import Button from '../Button';
import Divider from '../Divider';
import Icon from '../Icon';
import {connect} from 'react-redux';
import Truncate from 'react-truncate';
import CandidateAvatar from '../CandidateAvatar';
import Constants from '../../helpers/Constants';
import DateFormatted from '../../helpers/DateFormatted';
import Translations from '../../helpers/Translations';
import Strings from '../../helpers/Strings';
import {openModal} from '../../actions/ModalActions';
import {setCandidateInboxMessageAsRead} from '../../actions/CandidateActions';

class CandidateInboxItem extends Component
{
    state = {
        isMessageExpanded: false,
        isHistoryExpanded: false,
    };

    sendReply = message => {
        this.props.openModal('SendEmailModal', {
            candidateIds: [message.candidate.id],
            messageHistory: message.messageHistory,
            emailType: Constants.EMAIL_TYPE_TO_CANDIDATE,
        });
    };

    handleClick = () => {
        this.setState({isMessageExpanded: true});

        if (this.props.message.isUnread === true) {
            this.props.setCandidateInboxMessageAsRead(this.props.message.candidate.id, this.props.message.id);
        }
    };

    render() {
        const {message}                              = this.props;
        const {isMessageExpanded, isHistoryExpanded} = this.state;

        const person = message.emailType === Constants.EMAIL_TYPE_FROM_CANDIDATE ? message.candidate : message.user;

        const htmlBody          = Strings.stringToHTML(message.body);
        const collapsedHtmlBody = Strings.stringToHTML(message.collapsedBody);

        return (
            <div
                className={
                    'inbox-item' +
                    (message.isUnread === true ? ' unread' : '') +
                    (isMessageExpanded === true ? ' expanded' : '')
                }
                onClick={this.handleClick}
            >
                <div className="item-avatar">
                    <CandidateAvatar
                        surname={person.surname}
                        firstname={person.firstname}
                        profilePhoto={person.profilePhoto}
                    />
                </div>
                <div className="item-content">
                    <div className="flex justify between">

                        <h5>{person.surname} {person.firstname}</h5>
                        <span className="txt-disabled flex align center">
                            {message.attachments.length > 0 && <Icon size="mini" className="icon-add_attachment"/>}
                            {DateFormatted.getDateTime(message.messageHistory.sentAt, 'j.n.Y H:i')}
                        </span>
                    </div>
                    <p className={message.isUnread === true ? 'txt-bold' : 'txt-disabled'} dangerouslySetInnerHTML={{__html: Strings.stringToHTML(message.subject).innerText}}/>
                    <Divider hidden className="size_6"/>

                    {
                        isMessageExpanded === true
                            ? (
                                <>
                                    <div className={'candidate-inbox-item' + (isHistoryExpanded === true ? ' history' : '')} dangerouslySetInnerHTML={{__html: htmlBody.innerHTML}}/>
                                    {
                                        message.attachments.length > 0
                                        && (
                                            <>
                                                <Divider hidden className="size_12"/>
                                                <h5 className="txt-navy">{Translations.getStatic('attachments')}</h5>
                                                {
                                                    message.attachments.map((attachment, i) => (
                                                        <div key={i} className="attachment-item flex justify between">
                                                            <h5>{attachment.attachmentFilename}</h5>
                                                            <Button as="a" className="secondary-link" onClick={() => window.open(attachment.url)}>
                                                                {Translations.getStatic('download')}
                                                            </Button>
                                                        </div>
                                                    ))
                                                }
                                            </>
                                        )
                                    }

                                    {
                                        isHistoryExpanded === false && message.hasHistory === true
                                        && (
                                            <>
                                                <Divider hidden className="size_16"/>
                                                <Button className="more-button" icon={{className: 'icon-more-button'}} onClick={() => this.setState({isHistoryExpanded: true})}/>
                                            </>
                                        )
                                    }

                                    {
                                        message.emailType === Constants.EMAIL_TYPE_FROM_CANDIDATE
                                        && (
                                            <>
                                                <Divider hidden className="size_16"/>
                                                <span className="primary-link" onClick={() => this.sendReply(message)}>
                                                    {Translations.getStatic('replyToCandidate')}
                                                </span>
                                            </>
                                        )
                                    }
                                </>
                            )
                            : (
                                <Truncate lines={2}>
                                    <p dangerouslySetInnerHTML={{__html: collapsedHtmlBody.innerHTML}}/>
                                </Truncate>
                            )
                    }
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    openModal,
    setCandidateInboxMessageAsRead,
};

export default connect(
    null,
    mapDispatchToProps,
)(CandidateInboxItem);
