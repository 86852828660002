import Url from '../../../helpers/Url';
import { AiDropdownTonesT } from '@profesia/adamui/components/common/input/textField/wysiwygEditor/AiDropdown';
import { Languages } from '@profesia/mark-offer-form/i18n';
import { Field } from '@profesia/mark-offer-form/configuration';

const generateTextWithAi = async (field: Field, jobTitle:string, userInput:string, tone: AiDropdownTonesT, language: Languages): Promise<string> => {
    const response: { suggestion:string } = await fetch(`${Url.MARK_SERVER_PREFIX}/api/v1/ai/generate/${field === 'jobTasks' ? 'job-tasks' : field}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            jobTitle,
            [field]: userInput ?? '',
            language,
            tone
        })
    }).then((response) => response.json());

    return response.suggestion
};

export default generateTextWithAi;
