import Url from '../helpers/Url';
import Authentication from '../helpers/Authentication';
import {useEffect} from 'react';

const LogoutPage = (): void => {
    useEffect(() => {
        Url.redirectTo(Url.isLocal() ? Url.build() : Url.buildLogoutLink());

        return Authentication.removeAuthenticationTokens();
    }, []);
};

export default LogoutPage;
