import React from 'react';
import DashboardJobsListItemSkeleton from './DashboardJobsListItemSkeleton';

const DashboardJobsListSkeleton = ({itemsCount = 4}) => (
    <div className="page-content_left height_full">
        <div className="list height_full">
            <div className="list">
                <div className="ui items list__jobs unstackable skeleton">
                    {[...Array(itemsCount)].map((e, i) => <DashboardJobsListItemSkeleton key={i}/>)}
                </div>
            </div>
        </div>
    </div>
);

export default DashboardJobsListSkeleton;
