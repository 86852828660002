export const REQUEST_AUTO_ANSWER_EDITOR_MODEL = 'REQUEST_AUTO_ANSWER_EDITOR_MODEL';
export const RECEIVE_AUTO_ANSWER_EDITOR_MODEL = 'RECEIVE_AUTO_ANSWER_EDITOR_MODEL';
export const UPDATE_AUTO_ANSWER               = 'UPDATE_AUTO_ANSWER';

export const requestAutoAnswerEditorModel = (autoAnswerId = null, predefinedLanguageId = null, copy = false) => ({
    type: REQUEST_AUTO_ANSWER_EDITOR_MODEL,
    meta: {
        requestRoute: 'v1/auto-answer/model/editor/' + (autoAnswerId === null ? 0 : autoAnswerId),
        requestData: {
            copy: copy ? 1 : 0,
            predefinedLanguageId: predefinedLanguageId === null ? 0 : predefinedLanguageId,
        },

        onRequestSuccess: RECEIVE_AUTO_ANSWER_EDITOR_MODEL,
    },
});

export const updateAutoAnswer = (autoAnswerData) => ({
    type: UPDATE_AUTO_ANSWER,
    payload: {
        autoAnswerData,
    },
});
