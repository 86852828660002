import React, {Component} from 'react';
import {connect} from 'react-redux';
import JobQuestionnaireEditor from '../Job/JobQuestionnaireEditor';
import CreateJobHelper from '../../helpers/components/CreateJobHelper';
import {updateQuestionnaireData} from '../../actions/JobDetailActions';
import {requestQuestionnaireEditorModel} from '../../actions/QuestionnaireEditorActions';
import {closeModal} from '../../actions/ModalActions';
import MarkModal from '../Modal/MarkModal';
import PropTypes from 'prop-types';
import {saveAsChangedQuestionnaireTemplate, saveAsNewQuestionnaireTemplate} from '../../actions/TemplateAssistantActions';
import {fetchQuestionnaireTemplates} from '../../actions/TemplateDropdownSelectorActions';
import TemplateAssistantHelper from '../../helpers/components/TemplateAssistantHelper';
import SuccessActionStatus from '../../helpers/SuccessActionStatus';
import Translations from '../../helpers/Translations';
import User from '../../helpers/User';

class JobDetailPositionQuestionnaireModal extends Component
{
    componentDidUpdate(prevProps) {
        if (SuccessActionStatus.isActionSuccessful(prevProps, this.props)) {
            this.props.closeModal();
        }
    };

    prepareFormDataForSave(jobId, questionnaireData, isUsingQuestionnaire) {
        const formData = new FormData();

        formData.append('jobId', JSON.stringify(jobId));
        formData.append('questionnaireData', JSON.stringify(questionnaireData));
        formData.append('isUsingQuestionnaire', isUsingQuestionnaire === true ? 1 : 0);
        formData.append('questionnaireSavingMode', TemplateAssistantHelper.getTemplateSavingMode(this.props.templateAssistant.questionnaire));

        return formData;
    };

    checkQuestionnaireData(questionnaireEditorModel, isUsingQuestionnaire) {
        const errors = CreateJobHelper.checkQuestionnaire(
            isUsingQuestionnaire,
            questionnaireEditorModel.questionnaireData,
            this.props.templateAssistant.questionnaire.isNewTemplate,
            this.props.templatesForDropdownSelector,
        );

        return errors.length === 0;
    };

    handleDisableQuestionnaire(jobId) {
        this.props.updateQuestionnaireData(this.prepareFormDataForSave(jobId, null, false));
        this.props.requestQuestionnaireEditorModel(0);
        this.props.closeModal();
    }

    handleEnableQuestionnaire(jobId, questionnaireData, isUsingQuestionnaire) {
        this.props.updateQuestionnaireData(this.prepareFormDataForSave(jobId, questionnaireData, isUsingQuestionnaire));
        this.props.requestQuestionnaireEditorModel(0);
        this.props.closeModal();
    }

    handleCloseModal() {
        this.props.requestQuestionnaireEditorModel(0);
        this.props.closeModal();
    }

    saveQuestionnaire(jobId, disableQuestionnaire) {
        if (this.checkQuestionnaireData(this.props.questionnaireEditorModel, true) === true) {
            const questionnaireData = this.props.questionnaireEditorModel.questionnaireData;

            disableQuestionnaire === true
                ? this.handleDisableQuestionnaire(jobId)
                : this.handleEnableQuestionnaire(jobId, questionnaireData, true);
        }
    };

    render() {
        const {templateAssistant, isOpen, jobModel, isFetchingQuestionnaireEditorModel} = this.props;

        const isUsingQuestionnaire = jobModel.job.questionnaireId !== null;
        const questionnaireId      = jobModel.job.questionnaireId === null ? 0 : jobModel.job.questionnaireId;

        return (
            <MarkModal
                isOpen={isOpen}
                closeModal={() => this.handleCloseModal()}
                isLoadingData={isFetchingQuestionnaireEditorModel}

                actionButton={{
                    text: Translations.getStatic('save'),
                    onClick: () => this.saveQuestionnaire(jobModel.job.id, false),
                }}

                entityOption={{
                    colorRed: true,
                    visible: isUsingQuestionnaire,
                    text: Translations.getStatic('turnOffQuestionnaire'),
                    onClick: () => this.saveQuestionnaire(jobModel.job.id, true),
                }}

                title={Translations.getStatic('editInfoAboutQuestionnaire')}

                content={(
                    <JobQuestionnaireEditor
                        isModal={true}
                        questionnaireId={questionnaireId}
                        profesiaReplysheet={jobModel.job.profesiaReplysheet}
                        profesiaReplysheetData={jobModel.job.profesiaReplysheetData}
                        isNewTemplate={templateAssistant.questionnaire.isNewTemplate}
                        isChangedTemplate={templateAssistant.questionnaire.isChangedTemplate}
                        onStartFetchingTemplates={() => this.props.fetchQuestionnaireTemplates(User.getUserId(this.props.loginManagerModel))}
                        onSaveAsNewTemplate={this.props.saveAsNewQuestionnaireTemplate}
                        onSaveAsChangedTemplate={this.props.saveAsChangedQuestionnaireTemplate}
                    />
                )}
            />
        );
    }
}

const mapStateToProps = state => ({
    jobModel: state.jobModel,
    questionnaireEditorModel: state.questionnaireEditorModel,
    loginManagerModel: state.loginManagerModel,
    isOpen: state.modalLogic.isOpen,
    templateAssistant: state.templateAssistant,
    templatesForDropdownSelector: state.templatesForDropdownSelector.templates,
    actionSuccessStatus: state.actionSuccessStatus,
    isFetchingQuestionnaireEditorModel: state.isFetchingQuestionnaireEditorModel,
});

const mapDispatchToProps = {
    requestQuestionnaireEditorModel,
    updateQuestionnaireData,
    fetchQuestionnaireTemplates,
    saveAsChangedQuestionnaireTemplate,
    saveAsNewQuestionnaireTemplate,
    closeModal: closeModal,
};

JobDetailPositionQuestionnaireModal.propTypes = {
    jobModel: PropTypes.object.isRequired,
    questionnaireEditorModel: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    updateQuestionnaireData: PropTypes.func.isRequired,
    loginManagerModel: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(JobDetailPositionQuestionnaireModal);



