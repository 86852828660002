import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Select from '@profesia/adamui/components/common/dropdown/SelectDefault';

class SenderSelect extends Component
{
    render() {
        const {senderId, senders, className} = this.props;

        return (
            <Select
                className={className}
                value={senderId}
                options={senders}
                onChange={(value) => this.props.onSetEmailSender(value)}
            />
        );
    }
}

SenderSelect.propTypes = {
    senderId: PropTypes.number.isRequired,
    senders: PropTypes.array.isRequired,
    onSetEmailSender: PropTypes.func.isRequired,
    className: PropTypes.string,
};

SenderSelect.defaultProps = {
    className: 'darker',
};

export default SenderSelect;
