import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {requestCreateCandidateModel, updateCandidateData} from '../../actions/CreateCandidateActions';
import {InputField} from '@profesia/adamui/components/common/input';
import MarkForm from '../Form';
import Grid from '../Grid';
import LoadingComponent from '../LoadingComponent';
import AttachmentAssistant from '../AttachmentsAssistant/AttachmentsAssistant';
import NotificationMessage from '../NotificationMessage';
import Translations from '../../helpers/Translations';
import Validation from '../../helpers/Validation';
import AttachmentAssistantHelper from '../../helpers/components/AttachmentAssistantHelper';
import Form from '../../helpers/Form';
import FormSkeleton from '../../skeletons/components/FormSkeleton';

class CreateCandidate extends React.Component
{
    componentDidMount = () => {
        this.props.requestCreateCandidateModel(this.props.jobId, this.props.candidateId);
    };

    render = () => {
        const {isFetchingCreateCandidateModel, isSavingCandidate, createCandidateModel, candidateId} = this.props;

        if (isFetchingCreateCandidateModel === true || createCandidateModel.hasOwnProperty('settings') === false) {
            return <FormSkeleton/>;
        }

        if (isSavingCandidate === true) {
            return <LoadingComponent text={Translations.getStatic('saving')}/>;
        }

        const {candidateData, errors} = createCandidateModel;

        return (
            <>
                <MarkForm onSubmit={e => e.preventDefault()}>
                    <Grid>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <MarkForm.Field>
                                    <InputField 
                                        maxLength={128}
                                        value={candidateData.title}
                                        label={Translations.getStatic('academicDegree')}
                                        onChange={(e) => this.props.updateCandidateData({
                                            title: e.target.value,
                                        })}
                                    />
                                </MarkForm.Field>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <MarkForm.Field
                                    error={
                                        errors.includes('firstname') &&
                                        Form.check({
                                            fieldType: 'text',
                                            fieldValue: candidateData.firstname,
                                        }) === false
                                            ? Validation.getMessage('empty', 'candidateName')
                                            : false
                                    }
                                >
                                    <InputField
                                        maxLength={128}
                                        value={candidateData.firstname}
                                        label={Translations.getStatic('candidateName') + '*'}
                                        onChange={(e) =>
                                            this.props.updateCandidateData({
                                                firstname: e.target.value,
                                            })
                                        }
                                    />
                                </MarkForm.Field>
                            </Grid.Column>
                            <Grid.Column>
                                <MarkForm.Field
                                    error={
                                        errors.includes('surname') &&
                                        Form.check({
                                            fieldType: 'text',
                                            fieldValue: candidateData.surname,
                                        }) === false
                                            ? Validation.getMessage('empty', 'surname')
                                            : false
                                    }
                                >
                                    <InputField 
                                        maxLength={128}
                                        value={candidateData.surname}
                                        label={Translations.getStatic('surname') + '*'}
                                        onChange={(e) => this.props.updateCandidateData({
                                            surname: e.target.value,
                                        })}
                                    />
                                </MarkForm.Field>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <MarkForm.Field
                                    error={
                                        errors.includes('email') &&
                                        Form.check({fieldType: 'emptyOrCheck', secondaryFieldType: 'email', fieldValue: candidateData.email}) === false
                                            ? Validation.getMessage('email')
                                            : false
                                    }
                                >
                                    <InputField 
                                        maxLength={128}
                                        value={candidateData.email}
                                        label={Translations.getStatic('email')}
                                        onChange={(e) => this.props.updateCandidateData({
                                            email: e.target.value,
                                        })}
                                    />
                                </MarkForm.Field>
                            </Grid.Column>
                            <Grid.Column>
                                <MarkForm.Field
                                    error={
                                        errors.includes('phone') &&
                                        Form.check({fieldType: 'emptyOrCheck', secondaryFieldType: 'phone', fieldValue: candidateData.phone}) === false
                                            ? Validation.getMessage('phone')
                                            : false
                                    }
                                >
                                    <InputField 
                                        maxLength={128}
                                        value={candidateData.phone}
                                        label={Translations.getStatic('telephoneNumber')}
                                        onChange={(e) => this.props.updateCandidateData({
                                            phone: e.target.value,
                                        })}
                                    />
                                </MarkForm.Field>
                            </Grid.Column>
                        </Grid.Row>

                        {
                            parseInt(candidateId) === 0
                            && (
                                <Grid.Row>
                                    <Grid.Column>
                                        <AttachmentAssistant
                                            modelHashId={AttachmentAssistantHelper.createAttachmentAssistantHashIdForForm()}
                                            candidateId={candidateId}
                                            title={Translations.getStatic('attachments')}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            )
                        }

                        {
                            parseInt(candidateId) === 0
                            && (
                                <Grid.Row>
                                    <Grid.Column>
                                        <NotificationMessage
                                            title={Translations.getStatic('personalDataProcessing')}
                                            description={Translations.getStatic('personalDataProcessingExplanationText')}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            )
                        }
                    </Grid>
                </MarkForm>
            </>
        );
    };
}

CreateCandidate.propTypes = {
    createCandidateModel: PropTypes.object.isRequired,
    isFetchingCreateCandidateModel: PropTypes.bool.isRequired,
    isSavingCandidate: PropTypes.bool.isRequired,
    jobId: PropTypes.number.isRequired,
    candidateId: PropTypes.number.isRequired,
};

const mapStateToProps = state => ({
    createCandidateModel: state.createCandidateModel,
    isFetchingCreateCandidateModel: state.isFetchingCreateCandidateModel,
    isSavingCandidate: state.isSavingCandidate,
    displaySize: state.displaySize,
});

const mapDispatchToProps = {
    requestCreateCandidateModel,
    updateCandidateData,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CreateCandidate);
