import {VoidFunctionType} from '../../interfaces/CommonTypes';

class AbortControllerHelper
{
    static getSignal(abortCallback: null | VoidFunctionType = null): AbortSignal {
        const controller: AbortController = new AbortController();
        const signal: AbortSignal         = controller.signal;

        signal.addEventListener('abort', () => {
            if (abortCallback !== null) {
                abortCallback();
            }
        });

        return signal;
    }
}

export default AbortControllerHelper;
