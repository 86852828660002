import {
    RECEIVE_AUTO_ANSWER_EDITOR_MODEL, REQUEST_AUTO_ANSWER_EDITOR_MODEL,
    UPDATE_AUTO_ANSWER,
} from '../actions/AutoAnswerEditorActions';
import AutoAnswerHelper from '../helpers/components/AutoAnswerHelper';
import WysiwygHelper from '../helpers/components/WysiwygHelper';
import {CLEAR_CREATE_JOB_DATA} from '../actions/CreateJobActions';

export const isFetchingAutoAnswerEditorModel = (state = false, action) => {
    switch (action.type) {
        case REQUEST_AUTO_ANSWER_EDITOR_MODEL:
            return true;
        case RECEIVE_AUTO_ANSWER_EDITOR_MODEL:
            return false;
        default:
            return state;
    }
};

export const autoAnswerEditorModel = (state = {}, action) => {
    switch (action.type) {
        case REQUEST_AUTO_ANSWER_EDITOR_MODEL:
        case CLEAR_CREATE_JOB_DATA:
            return {};
        case RECEIVE_AUTO_ANSWER_EDITOR_MODEL:
            const {text, subject, languageId} = action.payload.autoAnswerData;
            const converted                   = WysiwygHelper.convertOldToNewText(text);

            return {
                ...state,
                ...action.payload,
                autoAnswerData: {
                    ...action.payload.autoAnswerData,
                    text: converted,
                    changeHash: AutoAnswerHelper.createChangeHash(converted, subject, languageId),
                    currentChangeHash: AutoAnswerHelper.createChangeHash(converted, subject, languageId),
                },
            };
        case UPDATE_AUTO_ANSWER:
            const autoAnswerData = {
                ...state.autoAnswerData,
                ...action.payload.autoAnswerData
            };

            return {
                ...state,
                autoAnswerData: {
                    ...autoAnswerData,
                    currentChangeHash: AutoAnswerHelper.createChangeHash(autoAnswerData.text, autoAnswerData.subject, autoAnswerData.languageId),
                },
            };
        default:
            return state;
    }
};
