import React, {Component} from 'react';
import {connect} from 'react-redux';
import Select from '@profesia/adamui/components/common/dropdown/SelectDefault';
import Form from '../Form';
import Grid from '../Grid';
import Heading from '../Heading';
import MarkModal from '../Modal/MarkModal';
import {closeModal} from '../../actions/ModalActions';
import Translations from '../../helpers/Translations';
import Url from '../../helpers/Url';

class StatisticsModal extends Component
{
    state = {};

    componentDidMount() {
        this.prepareDateState();
    };

    componentDidUpdate(prevProps) {
        if (this.props.statisticsModel.hasOwnProperty('settings') === false && prevProps.statisticsModel.hasOwnProperty('settings') === true) {
            this.prepareDateState(this.props.statisticsModel.settings);
        }
    };

    prepareDateState = (settings = null) => {
        const currentDate = new Date();

        this.setState({
            year: currentDate.getFullYear(),
            fromMonth: currentDate.getMonth() + 1,
            fromDay: 1,
            untilMonth: currentDate.getMonth() + 1,
            untilDay: settings === null
                ? 30
                : settings.daysInCurrentMonth,
        });
    };

    setParameters = parametersObject => {
        this.setState(parametersObject);
    };

    downloadStats = () => {
        const params = {
            ...this.state,
            companyId: this.props.statisticsModel.settings.companyId,
            downloadAccessToken: this.props.statisticsModel.settings.downloadAccessToken,
        };

        window.open(Url.build('download-stats') + '?params=' + JSON.stringify(params));
    };

    render() {
        const {closeModal, isOpen, statisticsModel, isFetchingSendEmailModel} = this.props;

        const {settings} = statisticsModel;

        const months = [
            'january',
            'february',
            'march',
            'april',
            'may',
            'june',
            'july',
            'august',
            'september',
            'october',
            'november',
            'december',
        ];

        const yearOptions  = [];
        const monthOptions = [];
        const dayOptions   = [];

        for (let i = settings.yearUntil; i >= settings.yearFrom; i--) {
            yearOptions.push({
                id: i,
                label: i,
            });
        }

        months.forEach((month, index) => {
            monthOptions.push({
                id: index + 1,
                label: Translations.getStatic(month),
            });
        });

        for (let i = 1; i <= 31; i++) {
            dayOptions.push({
                id: i,
                label: i,
            });
        }

        return (
            <MarkModal
                isOpen={isOpen}
                closeModal={closeModal}
                title={Translations.getStatic('offersStatistics')}
                isLoadingData={isFetchingSendEmailModel}

                actionButton={{
                    visible: true,
                    className: 'primary-button',
                    text: Translations.getStatic('reportToExcel'),
                    onClick: this.downloadStats,
                }}

                content={(
                    <>
                        <Heading as="h3">{Translations.getStatic('statisticsReportSettings')}</Heading>
                        <Form className={isFetchingSendEmailModel ? 'loading' : ''}>
                            <Grid className="padding-top_none">
                                <Grid.Row>
                                    <Grid.Column width={8}>
                                        <Form.Field>
                                            <Select
                                                value={this.state.year}
                                                label={Translations.getStatic('year')}
                                                options={yearOptions}
                                                onChange={(value) => this.setParameters({
                                                    year: value,
                                                })}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column computer={6} tablet={8}>
                                        <Form.Field>
                                            <Select
                                                value={this.state.fromMonth}
                                                label={Translations.getStatic('emailFromShort')}
                                                options={monthOptions}
                                                onChange={(value) => this.setParameters({
                                                    fromMonth: value,
                                                })}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column computer={2} tablet={4} className="padding-left_none">
                                        <Form.Field>
                                            <label>&nbsp;</label>
                                            <Select
                                                value={this.state.fromDay}
                                                options={dayOptions}
                                                onChange={(value) => this.setParameters({
                                                    fromDay: value,
                                                })}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column computer={6} tablet={8}>
                                        <Form.Field>
                                            <Select
                                                value={this.state.untilMonth}
                                                label={Translations.getStatic('to')}
                                                options={monthOptions}
                                                onChange={(value) => this.setParameters({
                                                    untilMonth: value,
                                                })}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column computer={2} tablet={4} className="padding-left_none">
                                        <Form.Field>
                                            <label>&nbsp;</label>
                                            <Select
                                                value={this.state.untilDay}
                                                options={dayOptions}
                                                onChange={(value) => this.setParameters({
                                                    untilDay: value,
                                                })}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Form>
                    </>
                )}
            />
        );
    };
}

const mapStateToProps = state => ({
    isOpen: state.modalLogic.isOpen,
    isFetchingStatisticsModel: state.isFetchingStatisticsModel,
    statisticsModel: state.statisticsModel,
});

const mapDispatchToProps = {
    closeModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(StatisticsModal);
