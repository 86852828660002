import React from 'react';
import JobsListItemSkeleton from './JobsListItemSkeleton';
import {SkeletonRectangular} from '@profesia/adamui/components/common/skeleton';
import {Stack} from '@mui/material';

const JobsListTableSkeleton = ({itemsCount = 4}) => (
    <div className="list">
        <div className="list__controls_extended">
            <Stack flex={1} pt={1} direction="row" alignItems="center" justifyContent="space-between">
                <Stack spacing={1}>
                    <SkeletonRectangular width={244} height={18} borderRadius={26}/>
                    <SkeletonRectangular width={116} height={10} borderRadius={26}/>
                </Stack>

                <Stack spacing={2} direction="row" justifyContent="right">
                    <SkeletonRectangular width={120} height={48} borderRadius={4}/>
                    <SkeletonRectangular width={120} height={48} borderRadius={4}/>
                </Stack>
            </Stack>
        </div>

        <div className="page">
            <div className="page-content">
                <div className="ui items list__jobs">
                    {[...Array(itemsCount)].map((e, i) => <JobsListItemSkeleton key={i}/>)}
                </div>
            </div>
        </div>
    </div>
);

export default JobsListTableSkeleton;
