export const REQUEST_USER_ACTIONS_MODEL = 'REQUEST_USER_ACTIONS_MODEL';
export const RECEIVE_USER_ACTIONS_MODEL = 'RECEIVE_USER_ACTIONS_MODEL';

export const requestUserActionsModel = () => ({
    type: REQUEST_USER_ACTIONS_MODEL,

    meta: {
        requestRoute: 'v1/admin/model/user-actions',
        onRequestSuccess: RECEIVE_USER_ACTIONS_MODEL,
    },
});
