import React from 'react';
import FooterMenu from '../Menus/FooterMenu';
import Translations from '../../../../helpers/Translations';
import DateFormatted from '../../../../helpers/DateFormatted';

const Footer = () => {
    return (
        <div className="footer-section">
            <div className="section">
                <div className="section-content">
                    <FooterMenu/>
                    <div className="divider"/>
                    <div className="row">
                        <div className="col align center">
                            <p className="txt-center">{Translations.getStatic('copyright').replace('%currentYear%', DateFormatted.getDate('now', 'Y'))}. {Translations.getStatic('allRightsReserved')}</p>
                            <div className="footer-logo icon-powered-by-profesia"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
