import {
    RECEIVE_DASHBOARD_MODEL, REQUEST_DASHBOARD_MODEL,
    LOAD_MORE_EVENTS_SUCCESS,
    LOAD_CANDIDATES_FOR_JOBS, LOAD_CANDIDATES_FOR_JOBS_FAILURE, LOAD_CANDIDATES_FOR_JOBS_SUCCESS,
    LOAD_MORE_JOBS_SUCCESS,
    GET_DATA_FOR_USER, GET_DATA_FOR_USER_FAILURE, GET_DATA_FOR_USER_SUCCESS, CLOSE_JOBS, CLOSE_JOBS_SUCCESS, CLOSE_JOBS_FAILURE, LOAD_MORE_EVENTS_FAILURE, LOAD_MORE_EVENTS,
} from '../actions/DashboardActions';
import Arrays from '../helpers/Arrays';
import {NO_MODAL_OPEN} from '../actions/ModalActions';
import {ANONYMIZE_CANDIDATES_SUCCESS, DELETE_CANDIDATE_EVENT_SUCCESS, REFUSE_CANDIDATE_AND_SEND_EMAIL, REFUSE_CANDIDATE_AND_SEND_EMAIL_FAILURE, SCHEDULE_CANDIDATE_EVENT_SUCCESS} from '../actions/ActionWithCandidateActions';
import {CHANGE_CANDIDATE_STAGE, CHANGE_CANDIDATE_STAGE_FAILURE, CLOSE_JOB_DETAIL} from '../actions/JobDetailActions';
import Constants from '../helpers/Constants';
import CandidatesReducer from '../helpers/generalReducers/CandidatesReducer';

export const isFetchingDashboardModel = (state = false, action) => {
    switch (action.type) {
        case REQUEST_DASHBOARD_MODEL:
            return true;

        case RECEIVE_DASHBOARD_MODEL:
            return false;

        default:
            return state;
    }
};

export const isFetchingDashboardJobCandidates = (state = false, action) => {
    switch (action.type) {
        case LOAD_CANDIDATES_FOR_JOBS:
            return true;

        case LOAD_CANDIDATES_FOR_JOBS_SUCCESS:
        case LOAD_CANDIDATES_FOR_JOBS_FAILURE:
            return false;

        default:
            return state;
    }
};

export const isFetchingDashboardDataForUser = (state = false, action) => {
    switch (action.type) {
        case GET_DATA_FOR_USER:
            return true;

        case GET_DATA_FOR_USER_SUCCESS:
        case GET_DATA_FOR_USER_FAILURE:
            return false;

        default:
            return state;
    }
};

export const isClosingJobs = (state = false, action) => {
    switch (action.type) {
        case CLOSE_JOBS:
            return true;

        case CLOSE_JOBS_SUCCESS:
        case CLOSE_JOBS_FAILURE:
            return false;

        default:
            return state;
    }
};

const STATUS_LOADING = 1;
const STATUS_DONE    = 2;

export const isDashboardJobCandidatesLoading = (state, jobId) => {
    const key = jobId.toString();

    return state.hasOwnProperty(key) === true && state[key] === STATUS_LOADING;
};

export const isDashboardJobCandidatesLoadedDone = (state, jobId) => {
    const key = jobId.toString();

    return state.hasOwnProperty(key) === true && state[key] === STATUS_DONE;
};

export const dashboardJobCandidatesFetchingStatus = (state = {}, action) => {
    let status = {};

    switch (action.type) {
        case REQUEST_DASHBOARD_MODEL:
            return status;

        case RECEIVE_DASHBOARD_MODEL:
            action.payload.fetchedCandidatesFromJobIds.forEach(jobId => {
                status[jobId] = STATUS_DONE;
            });

            return {
                ...state,
                ...status,
            };

        case LOAD_CANDIDATES_FOR_JOBS:
            action.payload.jobIds.forEach(jobId => {
                status[jobId] = STATUS_LOADING;
            });

            return {
                ...state,
                ...status,
            };

        case LOAD_CANDIDATES_FOR_JOBS_SUCCESS:
        case LOAD_CANDIDATES_FOR_JOBS_FAILURE:
            action.meta.requestPayload.jobIds.forEach(jobId => {
                status[jobId] = STATUS_DONE;
            });

            return {
                ...state,
                ...status,
            };

        default:
            return state;
    }
};

const defaultState = {};

export const dashboardModel = (state = defaultState, action) => {
    let candidate = null;
    let failed    = null;

    switch (action.type) {
        case REQUEST_DASHBOARD_MODEL:
            return defaultState;

        case RECEIVE_DASHBOARD_MODEL:
            return {
                ...state,
                ...action.payload,
            };

        case GET_DATA_FOR_USER_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };

        case LOAD_MORE_JOBS_SUCCESS:
            return {
                ...state,
                jobs: state.hasOwnProperty('jobs') && state.jobs !== undefined
                    ? state.jobs.concat(action.payload.jobs)
                    : action.payload.jobs,

                fetchedJobIds: state.hasOwnProperty('fetchedJobIds') && state.fetchedJobIds !== undefined
                    ? state.fetchedJobIds.concat(action.payload.fetchedJobIds)
                    : action.payload.fetchedJobIds,
            };

        case LOAD_MORE_EVENTS_SUCCESS:
            return {
                ...state,
                events: Arrays.getUniqueArrayOfObjects(state.events.concat(action.payload.events), 'id'),
            };

        case LOAD_CANDIDATES_FOR_JOBS_SUCCESS:
            return {
                ...state,
                candidates: state.candidates.concat(action.payload.candidates),
                fetchedCandidatesFromJobIds: state.hasOwnProperty('fetchedCandidatesFromJobIds') && state.fetchedCandidatesFromJobIds !== undefined
                    ? state.fetchedCandidatesFromJobIds.concat(action.payload.fetchedCandidatesFromJobIds)
                    : action.payload.fetchedCandidatesFromJobIds,
            };

        case NO_MODAL_OPEN:
            if (state.hasOwnProperty('candidates') === false) {
                return state;
            }

            return {
                ...state,
                candidates: state.candidates.map(candidate => ({
                    ...candidate,
                    isClicked: candidate.id === action.payload.modalProps.candidateId
                        ? true
                        : candidate.isClicked,
                })),
            };

        case ANONYMIZE_CANDIDATES_SUCCESS:
            if (state.hasOwnProperty('candidates') === false) {
                return state;
            }

            const anonymizedIds                  = action.payload.candidates.map(candidate => candidate.id);
            const jobIdsFromAnonymizedCandidates = action.payload.candidates.map(candidate => candidate.jobId);

            return {
                ...state,
                candidates: state.candidates.filter(candidate => anonymizedIds.includes(candidate.id) === false),

                jobs: state.jobs.map(job => jobIdsFromAnonymizedCandidates.includes(job.id) === true
                    ? {
                        ...job,
                        countNewApplicants: job.countNewApplicants - 1,
                    }
                    : job,
                ),
            };

        case REFUSE_CANDIDATE_AND_SEND_EMAIL:
            if (state.hasOwnProperty('candidates') === false) {
                return state;
            }

            let refusedCandidates           = state.candidates.filter(candidate => action.payload.candidateIds.includes(candidate.id) === true);
            let refusedIds                  = refusedCandidates.map(c => c.id);
            let jobIdsFromRefusedCandidates = refusedCandidates.map(c => c.jobId);

            return {
                ...state,
                candidates: state.candidates.filter(candidate => refusedIds.includes(candidate.id) === false),
                removed: refusedCandidates,
                jobs: state.jobs.map(job => ({
                    ...job,
                    countNewApplicants: jobIdsFromRefusedCandidates.includes(job.id) === true
                        ? (job.countNewApplicants - 1)
                        : job.countNewApplicants,
                })),
            };

        case REFUSE_CANDIDATE_AND_SEND_EMAIL_FAILURE:
            if (state.hasOwnProperty('candidates') === false) {
                return state;
            }

            if (state.hasOwnProperty('removed') === false) {
                return state;
            }

            const failedRefusedCandidates           = state.removed.filter(candidate => action.meta.requestPayload.candidateIds.includes(candidate.id) === true);
            const failedRefusedIds                  = failedRefusedCandidates.map(c => c.id);
            const jobIdsFromFailedRefusedCandidates = failedRefusedCandidates.map(c => c.jobId);

            return {
                ...state,
                candidates: state.candidates.concat(failedRefusedCandidates),
                removed: state.removed.filter(candidate => failedRefusedIds.includes(candidate.id) === true),
                jobs: state.jobs.map(job => ({
                    ...job,
                    countNewApplicants: jobIdsFromFailedRefusedCandidates.includes(job.id) === true
                        ? (job.countNewApplicants + 1)
                        : job.countNewApplicants,
                })),
            };

        case CHANGE_CANDIDATE_STAGE:
            if (state.hasOwnProperty('candidates') === false) {
                return state;
            }

            if (state.hasOwnProperty('removed') === false) {
                return state;
            }

            if (parseInt(action.payload.stageId) === Constants.CANDIDATE_STAGE_NEW_ID) {
                const removed = state.removed.find(candidate => candidate.id === action.payload.candidateId);

                if (removed === undefined) {
                    return state;
                }

                return {
                    ...state,
                    candidates: state.candidates.concat([removed]),
                    removed: state.removed.filter(candidate => candidate.id === action.payload.candidateId),
                    jobs: state.jobs.map(job => ({
                        ...job,
                        countNewApplicants: job.id === removed.jobId
                            ? (job.countNewApplicants + 1)
                            : job.countNewApplicants,
                    })),
                };
            }

            candidate = state.candidates.find(candidate => candidate.id === action.payload.candidateId);

            if (candidate === undefined) {
                return state;
            }

            return {
                ...state,
                candidates: state.candidates.filter(candidate => candidate.id !== action.payload.candidateId),
                removed: state.candidates.filter(candidate => candidate.id === action.payload.candidateId),
                jobs: state.jobs.map(job => ({
                    ...job,
                    countNewApplicants: job.id === candidate.jobId
                        ? (job.countNewApplicants - 1)
                        : job.countNewApplicants,
                })),
            };

        case CHANGE_CANDIDATE_STAGE_FAILURE:
            if (state.hasOwnProperty('candidates') === false) {
                return state;
            }

            if (state.hasOwnProperty('removed') === false) {
                return state;
            }

            failed = state.removed.find(candidate => candidate.id === action.meta.requestPayload.candidateId);

            if (failed === undefined) {
                return state;
            }

            const change = (parseInt(action.meta.requestPayload.stageId) === Constants.CANDIDATE_STAGE_NEW_ID)
                ? -1
                : 1;

            return {
                ...state,
                candidates: state.candidates.concat([failed]),
                removed: state.removed.filter(candidate => candidate.id === action.meta.requestPayload.candidateId),
                jobs: state.jobs.map(job => ({
                    ...job,
                    countNewApplicants: job.id === failed.jobId
                        ? (job.countNewApplicants + change)
                        : job.countNewApplicants,
                })),
            };

        case SCHEDULE_CANDIDATE_EVENT_SUCCESS:
            if (state.hasOwnProperty('events') === false) {
                return state;
            }

            const isEditEvent = state.events.some(event => event.id === action.meta.requestPayload.eventId);

            if (isEditEvent) {
                return {
                    ...state,
                    events: state.events.map(event => {
                        if (event.id === action.meta.requestPayload.eventId) {
                            return action.payload.event;
                        }
                        return event;
                    }),
                };
            }

            return {
                ...state,
                events: state.events.concat([action.payload.event]),
            };

        case DELETE_CANDIDATE_EVENT_SUCCESS:
            return {
                ...state,
                events: state.hasOwnProperty('events') === true ? state.events.filter(event => event.id !== action.meta.requestPayload.eventId) : [],
            };

        case CLOSE_JOB_DETAIL:
            if (state.hasOwnProperty('jobs') === false || state.hasOwnProperty('fetchedJobIds') === false) {
                return state;
            }

            return {
                ...state,
                jobs: state.hasOwnProperty('jobs') && state.jobs !== undefined
                    ? state.jobs.filter(job => parseInt(job.id) !== parseInt(action.payload.jobId))
                    : state.jobs,

                fetchedJobIds: state.hasOwnProperty('fetchedJobIds') && state.fetchedJobIds !== undefined
                    ? state.fetchedJobIds.filter(id => parseInt(id) !== parseInt(action.payload.jobId))
                    : state.fetchedJobIds,
            };

        case CLOSE_JOBS_SUCCESS:
            if (state.hasOwnProperty('jobsBasicData') === false || state.hasOwnProperty('jobs') === false || state.hasOwnProperty('fetchedJobIds') === false) {
                return state;
            }

            return {
                ...state,
                jobs: state.hasOwnProperty('jobs') && state.jobs !== undefined
                    ? state.jobs.filter(job => action.meta.requestPayload.jobIds.includes(parseInt(job.id)) === false)
                    : state.jobs,

                fetchedJobIds: state.hasOwnProperty('fetchedJobIds') && state.fetchedJobIds !== undefined
                    ? state.fetchedJobIds.filter(id => action.meta.requestPayload.jobIds.includes(parseInt(id)) === false)
                    : state.fetchedJobIds,

                jobsBasicData: state.hasOwnProperty('jobsBasicData') && state.jobsBasicData !== undefined
                    ? state.jobsBasicData.filter(job => action.meta.requestPayload.jobIds.includes(parseInt(job.id)) === false)
                    : state.jobsBasicData,
            };

        default:
            return CandidatesReducer.process(state, action);
    }
};

export const isFetchingMoreEvents = (state = false, action) => {
    switch (action.type) {
        case LOAD_MORE_EVENTS:
            return true;

        case LOAD_MORE_EVENTS_SUCCESS:
        case LOAD_MORE_EVENTS_FAILURE:
            return false;

        default:
            return state;
    }
};
