import {SIDEBAR_CLOSE, SIDEBAR_RESET, SIDEBAR_OPEN} from '../actions/SidebarActions';
import {NO_MODAL_CLOSE, NO_MODAL_SECOND_OPEN, NO_MODAL_OPEN, MODAL_SECOND_OPEN} from '../actions/ModalActions';

const initialState = {
    fullDetailId: null,
    anonymizedDetailId: null,
    duplicatedDetailId: null,
    fullSidebar: {
        isOpen: false,
    },
    anonymizedSidebar: {
        isOpen: false,
    },
    duplicatedSidebar: {
        isOpen: false,
    },
};

const sidebarReducer = (state = {}, action) => {
    switch (action.type) {
        case SIDEBAR_OPEN:
            return {
                sidebarType: action.payload.sidebarType,
                sidebarProps: action.payload.sidebarProps,
                isOpen: true,
            };
        case SIDEBAR_RESET:
            return {
                isOpen: false,
            };
        case SIDEBAR_CLOSE:
        case NO_MODAL_CLOSE:
            return {
                ...state,
                isOpen: false,
            };
        default:
            return state;
    }
};

const canUpdateSidebarOfType = (action, compareId) => {
    return (compareId !== null
        && action.hasOwnProperty('payload')
        && action.payload.hasOwnProperty('sidebarProps')
        && action.payload.sidebarProps.hasOwnProperty('candidateId')
        && action.payload.sidebarProps.candidateId === compareId
    );
};

export const sidebarEditPanelLogic = (state = initialState, action) => {
    switch (action.type) {
        case NO_MODAL_OPEN:
            return {
                ...state,
                fullDetailId: action.payload.modalType === 'DetailCandidateNoModal'
                    ? action.payload.modalProps.candidateId
                    : state.fullDetailId,
                anonymizedDetailId: action.payload.modalType === 'DetailAnonymizedCandidateNoModal'
                    ? action.payload.modalProps.candidateId
                    : state.anonymizedDetailId,
                fullSidebar: {
                    ...state.fullSidebar,
                    isOpen: false,
                },
                anonymizedSidebar: {
                    ...state.anonymizedSidebar,
                    isOpen: false,
                },
            };
        case NO_MODAL_SECOND_OPEN:
        case MODAL_SECOND_OPEN:
            if (action.payload.modalProps === undefined || action.payload.modalProps.candidateId === undefined) {
                return state;
            }

            return {
                ...state,
                duplicatedDetailId: action.payload.modalProps.candidateId,

                duplicatedSidebar: {
                    ...state.duplicatedSidebar,
                    isOpen: false,
                },
            };

        default:
            return {
                ...state,
                fullSidebar: canUpdateSidebarOfType(action, state.fullDetailId) === true
                    ? sidebarReducer(state.fullSidebar, action)
                    : state.fullSidebar,
                anonymizedSidebar: canUpdateSidebarOfType(action, state.anonymizedDetailId) === true
                    ? sidebarReducer(state.anonymizedSidebar, action, false)
                    : state.anonymizedSidebar,
                duplicatedSidebar: canUpdateSidebarOfType(action, state.duplicatedDetailId) === true
                    ? sidebarReducer(state.duplicatedSidebar, action, false)
                    : state.duplicatedSidebar,
            };
    }
};
