class Number
{
    static format(number: number, precision: number = 2, fractionSeparator: string = ','): string {
        return number.toFixed(precision).replace('.', fractionSeparator);
    }

    static random(from: number, to: number, integer: boolean = false): number {
        if (integer) {
            from = Math.ceil(from);
            to   = Math.floor(to);

            return Math.floor(Math.random() * (to - from)) + from;
        }

        return Math.random() * (from - to) + to;
    }
}

export default Number;
