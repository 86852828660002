import Authentication from '../helpers/Authentication';
import Version from '../helpers/Version';

const authenticationMiddleware = store => next => action => {
    if (action.hasOwnProperty('payload') === true) {
        if (action.payload.hasOwnProperty('tokens') === true) {
            Authentication.setAuthenticationData(action.payload.tokens.accessToken, action.payload.tokens.refreshToken, action.payload.tokens.applicationId, action.payload.tokens.language);
        }

        if (action.payload.hasOwnProperty('markVersion') === true) {
            Version.set(action.payload.markVersion);
        }
    }

    return next(action);
};

export default authenticationMiddleware;
