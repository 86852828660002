import Storages from './Storages';
import {AnyFunctionType} from '../../interfaces/CommonTypes';

const uniqid: AnyFunctionType = require('uniqid');

class Browser
{
    static isInternetExplorer(): boolean {
        return document.hasOwnProperty('documentMode') || document.documentMode !== undefined;
    }

    static isEdge(): boolean {
        return /Edge/.test(navigator.userAgent);
    }

    static isEdgeOrIE(): boolean {
        return Browser.isInternetExplorer() || Browser.isEdge();
    }

    static getBrowserTabId(): string | null {
        let browserTabId: string | null = Storages.getSession('browserTabId');

        if (browserTabId === null) {
            browserTabId = uniqid();

            Storages.setSession('browserTabId', browserTabId);
        }

        return browserTabId;
    }

    static removeBrowserTabId(): void {
        Storages.removeSession('browserTabId');
    }
}

export default Browser;
