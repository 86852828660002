import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import MainMenu from '../Menus/MainMenu';
import HeaderLogin from '../../../HeaderLogin';
import {Sidebar, Visibility, Menu} from 'semantic-ui-react';
import Icon from '../../../Icon';
import Url from '../../../../helpers/Url';
import logoImage from '../../../../../img/logo-mark.svg';
import logoWebImage from '../../../../../img/logo.svg';
import Link from '../../../../helpers/overridden/Link';

const DesktopContainer = props => (
    <Visibility once={false}>
        <div className="navigation">
            <div className="row">
                <div className="logo">
                    {
                        props.isSubPage === true
                            ? (
                                <Link to={Url.build()} id="logo-image" gtmTag="logo-image">
                                    <img src={logoImage} alt="logo"/>
                                </Link>
                            )
                            : (
                                <Link to={Url.build()} id="logo-web-image" gtmTag="logo-web-image">
                                    <img src={logoWebImage} alt="logo"/>
                                </Link>
                            )
                    }
                </div>
                <MainMenu/>
                <div className="login-options">
                    <ul>
                        <li>
                            <HeaderLogin/>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </Visibility>
);

class MobileContainer extends Component
{
    state = {};

    handleSidebarHide = () => this.setState({sidebarOpened: false});

    handleToggle = () => this.setState({sidebarOpened: true});

    render() {
        const {sidebarOpened} = this.state;

        return (
            <Sidebar.Pushable>
                <Sidebar
                    as={Menu}
                    animation="push"
                    inverted
                    onHide={this.handleSidebarHide}
                    vertical
                    visible={sidebarOpened}
                >
                    <MainMenu/>
                </Sidebar>

                <Sidebar.Pusher>
                    <div className="navigation">
                        <div className="row">
                            <div className="logo">
                                <div role="link" onClick={this.handleToggle}>
                                    <Icon name="sidebar"/>
                                </div>
                            </div>
                            <div className="login-options">
                                <ul>
                                    <li>
                                        <HeaderLogin/>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Sidebar.Pusher>
            </Sidebar.Pushable>
        );
    }
}

const NavBar = props => (
    <>
        {
            props.displaySize.isMobile === true
                ? <MobileContainer history={props.history}/>
                : <DesktopContainer history={props.history} isSubPage={props.isSubPage}/>
        }
    </>
);

const mapStateToProps = state => ({
    displaySize: state.displaySize,
});

NavBar.defaultProps = {
    isSubPage: false,
};

NavBar.propTypes = {
    isSubPage: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(NavBar);
