import DateFormatted from './../DateFormatted';
import Sort from './../Sort';
import Strings from '../Strings';
import Constants from '../Constants';
import JobHelper from './JobHelper';

export default class JobsHelper
{
    static filterAndSortJobs(jobs, filter, sort, talentPoolAtLast = false, userData = {}) {
        jobs = this.filterJobs(jobs, filter, userData);
        jobs = this.sortJobs(jobs, sort, talentPoolAtLast);

        return jobs;
    }

    static sortJobs(jobs, sort, talentPoolAtLast = false) {
        if (sort === null || jobs.length === 0 || Object.keys(sort).length === 0) {
            return jobs;
        }

        const sortType = sort.hasOwnProperty('sortType') === false || [Constants.SORT_TYPE_NUMERIC, Constants.SORT_TYPE_TEXT].indexOf(sort.sortType) === -1 ? Constants.SORT_TYPE_TEXT : sort.sortType;

        let result = Sort.byColumn(jobs, sort.sortBy, sort.sortDirection, sortType);

        if (talentPoolAtLast === true) {
            return Sort.asBoolean(jobs, 'isTalentPool', 'ASC');
        }

        return result;
    }

    static filterJobs(jobs, filter, userData) {
        jobs = this.filterJobsByStateId(jobs, filter);
        jobs = this.filterJobsByStateReasonId(jobs, filter);
        jobs = this.filterJobsByUserId(jobs, filter, userData);
        jobs = this.filterJobsByAddDate(jobs, filter);
        jobs = this.filterJobsByJobTitle(jobs, filter);
        jobs = this.filterJobsByActiveOffer(jobs, filter);

        return jobs;
    }

    static filterJobsByActiveOffer(jobs, filter) {
        if (filter.hasOwnProperty('withActiveOffer') === false || filter.withActiveOffer === false) {
            return jobs;
        }

        return jobs.filter(job => {
            if (job.hasOwnProperty('jobOffers') === false) {
                return false;
            }

            if (job.jobOffers.length === 0) {
                return false;
            }

            return job.jobOffers.find(offer => offer.isActive === true) !== undefined;
        });
    }

    static filterJobsByStateId(jobs, filter) {
        if (filter === undefined) {
            return jobs;
        }

        if (filter.hasOwnProperty('stateId') === false || filter.stateId === Constants.JOBS_LIST_FILTER_JOB_STATE_ALL) {
            return jobs;
        }

        return jobs.filter(job => parseInt(job.stateId) === parseInt(filter.stateId));
    }

    static filterJobsByAddDate(jobs, filter) {
        if (filter.hasOwnProperty('addDate') === false) {
            return jobs;
        }

        if (filter.addDate.timespan === Constants.JOBS_LIST_FILTER_BY_ADD_DATE_CLEARED) {
            return jobs;
        }

        let timespanFrom = filter.addDate.timespan === Constants.JOBS_LIST_FILTER_BY_ADD_DATE_TIMESPAN_VALUE ? -365 : -filter.addDate.timespan;
        let timespanTo   = 0;

        if (filter.addDate.dateFrom !== null) {
            timespanFrom = DateFormatted.getDaysCountFromToday(filter.addDate.dateFrom, false);
        }

        if (filter.addDate.dateTo !== null) {
            timespanTo = DateFormatted.getDaysCountFromToday(filter.addDate.dateTo, false);
        }

        return jobs.filter(job => {
            const dateDifference = DateFormatted.getDaysCountFromToday(job.addDate, false);

            return timespanFrom <= dateDifference && dateDifference <= timespanTo;
        });
    }

    static filterJobsByStateReasonId(jobs, filter) {
        if (filter.hasOwnProperty('stateId') === false || JobHelper.isClosed(filter.stateId) === false) {
            return jobs;
        }

        if (filter.hasOwnProperty('stateReasonId') === false || filter.stateReasonId === Constants.JOBS_LIST_FILTER_JOB_STATE_REASON_ALL) {
            return jobs;
        }

        return jobs.filter(job => job.hasOwnProperty('stateReasonId') === true && parseInt(job.stateReasonId) === parseInt(filter.stateReasonId));
    }

    static filterJobsByUserId(jobs, filter, userData) {
        if (filter.hasOwnProperty('userId') === false || filter.userId === Constants.JOBS_LIST_FILTER_USER_ID_ALL) {
            return jobs;
        }

        return jobs.filter(job => {
            if (job.hasOwnProperty('user') === false || parseInt(job.user.id) !== parseInt(filter.userId)) {
                return false;
            }

            if (userData.hasOwnProperty('isManager') === false || userData.isManager === true) {
                return true;
            }

            if (userData.hasOwnProperty('userId') === false || parseInt(job.user.id) === userData.userId) {
                return true;
            }

            if (userData.hasOwnProperty('userEmail') === false || job.externalUserEmail.includes(userData.userEmail) === true) {
                return true;
            }
        });
    }

    static filterJobsByJobTitle(jobs, filter) {
        if (filter.usingSearch === true) {
            jobs = jobs
                .map(job => {
                    const searchResult = filter.searched.find(result => parseInt(result.id) === parseInt(job.id));

                    if (searchResult === undefined) {
                        return null;
                    }

                    return {
                        ...job,
                        jobTitle: searchResult.jobTitle,
                    };
                })
                .filter(job => job !== null);
        }

        return jobs;
    }

    static filterResults(jobs, searchText) {
        if (searchText === '') {
            return {
                searched: [],
                usingSearch: false,
                searchText: '',
            };
        }

        let keywords = searchText.split(' ');
        let searched = [];

        if (keywords.length > 0 && jobs.length > 0) {
            jobs.forEach(job => {
                let jobTitle = Strings.removeDiacritics(job.jobTitle.toLowerCase());
                let add      = true;

                keywords.forEach(keyword => {
                    keyword = Strings.removeDiacritics(keyword.toLowerCase());

                    if (keyword !== '' && jobTitle.indexOf(keyword) === -1) {
                        add = false;
                    }
                });

                if (add === true) {
                    searched.push({
                        id: job.id,
                        jobTitle: job.jobTitle,
                    });
                }
            });
        }

        return {
            searched,
            usingSearch: true,
            searchText,
        };
    }

    static filterDashboardRecentJobs(jobs, daysAgo = 90) {
        let timespanFrom = -daysAgo;
        let timespanTo   = 0;

        return jobs.filter(job => {
            if (job.isTalentPool === true) {
                return false;
            }

            const dateDifference = DateFormatted.getDaysCountFromToday(job.lastActionAt, false);

            return timespanFrom <= dateDifference && dateDifference <= timespanTo;
        });
    }
}
