import React from 'react';
import Translations from '../../helpers/Translations';
import MarkDialog from '../Modal/MarkDialog';
import {connect} from 'react-redux';
import {closeCancelCloseJobWizardDialog} from '../../actions/DialogActions';
import {useNavigate} from 'react-router-dom';
import Url from '../../helpers/Url';
import User from '../../helpers/User';
import Track from '../../helpers/Track';
import {TRACK_LOCATION_MARK, TRACK_NAME_CANCEL_WIZARD} from '../../helpers/ConstantsTrack';

const CancelCloseJobWizardDialog = props => {
    const navigate = useNavigate();

    return props.cancelCloseJobWizardDialog.open === true && (
        <MarkDialog
            closeOnDimmerClick={false}
            message={{
                iconName: 'icon-cancelJob',
                title: Translations.getStatic('cancelGuide'),
                infoLines: [Translations.getStatic('cancelGuideDescription')],
            }}

            actions={[
                {
                    title: Translations.getStatic('cancelGuide'),
                    action: () => {
                        props.closeCancelCloseJobWizardDialog();

                        Track.uxClick(TRACK_LOCATION_MARK, TRACK_NAME_CANCEL_WIZARD);

                        const backAction = props.closeJobModel.hasOwnProperty('jobId') === true
                            ? Url.buildJobLink(props.closeJobModel.jobId, User.getDefaultJobViewId(props.loginManagerModel))
                            : Url.build('jobs');

                        navigate(backAction);
                    },
                    type: 'positive',
                },
                {
                    title: Translations.getStatic('cancel'),
                    action: () => props.closeCancelCloseJobWizardDialog(),
                },
            ]}
        />
    );
};

const mapStateToProps = state => ({
    cancelCloseJobWizardDialog: state.cancelCloseJobWizardDialog,
    closeJobModel: state.closeJobModel,
    loginManagerModel: state.loginManagerModel,
});

const mapDispatchToProps = {
    closeCancelCloseJobWizardDialog,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CancelCloseJobWizardDialog);


