import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import {LoadingCircle} from '@profesia/adamui/components/common/loadings';
import Icon from '../../Icon';
import CandidateAvatar from '../../CandidateAvatar';
import DateFormatted from '../../../helpers/DateFormatted';
import CandidateDetailOpenHandler from '../../CandidateDetailOpenHandler';
import Translations from '../../../helpers/Translations';

class DashboardModalOffersListItem extends Component
{
    render = () => {
        const {
                  offer,
                  candidates,
                  existsNotLockedOfferWithCompleteCandidatesBeforeOffer,
                  allOffersAreLocked,
                  completeLoadedCandidates,
                  showLoader,
              } = this.props;

        if (offer.hasLockedCandidates === true && (allOffersAreLocked === true || existsNotLockedOfferWithCompleteCandidatesBeforeOffer === true)) {
            return (
                <div className="list__table">
                    <h4>{offer.title} ({offer.counts.newCandidatesCount})</h4>
                    <Table className="ui table unstackable" id={'dashboardModalOfferId' + offer.id.toString()}>
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={4} className="locked-table-cell">
                                    <div className="locked-table-content">
                                        <Icon className="icon-locked-red small"/>
                                        <p className="txt-medium">{Translations.getStatic('dashboardOfferDetailNotAccess ')}</p>
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
            );
        }

        if (candidates.length === 0 && showLoader === true) {
            return (
                <div className="list__table">
                    <h4>{offer.title} ({offer.counts.newCandidatesCount})</h4>
                    <LoadingCircle/>
                </div>
            );
        }

        if (candidates.length === 0) {
            return null;
        }

        return (
            <div className="list__table">
                <h4>{offer.title} ({offer.counts.newCandidatesCount})</h4>
                {
                    candidates.length > 0
                    && (
                        <>
                            <Table className="ui table unstackable" id={'dashboardModalOfferId' + offer.id.toString()}>
                                <TableBody>
                                    {
                                        candidates.map((candidate, rowIndex) => (
                                            <CandidateDetailOpenHandler
                                                as={'TableRow'}
                                                candidate={candidate}
                                                className={'clickable'}
                                                key={rowIndex}
                                                identifier="b2b-dashboard"
                                            >
                                                <TableCell>
                                                    <CandidateAvatar profilePhoto={candidate.profilePhoto} isContacted={false} surname={candidate.surname} firstname={candidate.firstname}/>
                                                    {candidate.candidateName}
                                                </TableCell>
                                                <TableCell>
                                                    {candidate.hasOwnProperty('questionnaireResult') && candidate.questionnaireResult !== null ? <span>{candidate.questionnaireResult}%</span> : ''}
                                                </TableCell>
                                                <TableCell>
                                                    {candidate.hasDuplicities === true ? <span className="tag tag-primary">{Translations.getStatic('duplicated')}</span> : ''}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <span className="text-small txt-disabled">{DateFormatted.getDate(candidate.addDate)}</span>
                                                </TableCell>
                                            </CandidateDetailOpenHandler>
                                        ))
                                    }
                                </TableBody>
                            </Table>

                            {
                                completeLoadedCandidates === false
                                && showLoader === true
                                && (
                                    <LoadingCircle />
                                )
                            }
                        </>
                    )
                }
            </div>
        );
    };
}

const mapStateToProps = state => ({
    dashboardModel: state.b2bDashboardModel,
});

const mapDispatchToProps = {};

DashboardModalOffersListItem.propTypes = {
    offer: PropTypes.object.isRequired,
    candidates: PropTypes.array.isRequired,
    existsNotLockedOfferWithCompleteCandidatesBeforeOffer: PropTypes.bool.isRequired,
    allOffersAreLocked: PropTypes.bool.isRequired,
    completeLoadedCandidates: PropTypes.bool.isRequired,
    showLoader: PropTypes.bool.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DashboardModalOffersListItem);



