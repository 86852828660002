export const REQUEST_INACTIVE_USERS_MODEL = 'REQUEST_INACTIVE_USERS_MODEL';
export const RECEIVE_INACTIVE_USERS_MODEL = 'RECEIVE_INACTIVE_USERS_MODEL';

export const requestInactiveUsersModel = (fromDaysAgo = 2, toDaysAgo = 3, jobIds = '') => ({
    type: REQUEST_INACTIVE_USERS_MODEL,

    meta: {
        requestRoute: 'v1/admin/model/inactive-users',

        requestData: {
            fromDaysAgo,
            toDaysAgo,
            jobIds,
        },

        onRequestSuccess: RECEIVE_INACTIVE_USERS_MODEL,
    },
});
