import Constants from '../../Constants';
import CandidatesHelper from '../../components/CandidatesHelper';
import Url from '../../Url';

export default class MoveCandidatesReducer
{
    static process(state, action) {
        if (state.hasOwnProperty('candidates') === false) {
            return state;
        }

        if (action.meta.requestPayload.reducerType === Constants.CANDIDATES_REDUCER_TYPE_B2B_DASHBOARD) {
            return this.processMoveActionOnB2bDashboard(state, action);
        }

        if (action.meta.requestPayload.reducerType === Constants.CANDIDATES_REDUCER_TYPE_JOB_DETAIL) {
            return this.processMoveActionOnJobDetail(state, action);
        }

        if (action.meta.requestPayload.reducerType === Constants.CANDIDATES_REDUCER_TYPE_GLOBAL_SEARCH) {
            return this.processMoveActionOnGlobalSearch(state, action);
        }

        return this.processMoveActionOnDashboard(state, action);
    }

    static prepareCandidateAfterMove(action, candidate) {
        let newCandidate = {
            ...candidate,
            ...CandidatesHelper.prepareWasCandidateSentToColleague(action.meta.requestPayload, action.payload.candidatesSentToColleague, candidate),
        };

        if (action.meta.requestPayload.candidateIds.includes(candidate.id) === true) {
            newCandidate = {
                ...newCandidate,
                stateId: Constants.CANDIDATE_STATE_DELETED,
            };
        }

        return newCandidate;
    }

    static processMoveActionOnB2bDashboard(state, action) {
        let result = {
            ...state,
        };

        if (action.payload.newCandidates.length > 0) {
            if (state.hasOwnProperty('activeOffers') === true) {
                const newCandidate = action.payload.newCandidates[0];

                const newJobId               = newCandidate.jobId;
                const newStageId             = newCandidate.stage.id;
                const firstOriginalCandidate = state.candidates.find(candidate => action.meta.requestPayload.candidateIds.includes(candidate.id) === true);

                result = {
                    ...result,
                    activeOffers: state.activeOffers.map(offer => {
                        if (newJobId === offer.jobId) {
                            return {
                                ...offer,

                                counts: {
                                    activeCandidatesCount: offer.counts.activeCandidatesCount + 1,
                                    allCandidatesCount: offer.counts.allCandidatesCount + 1,
                                    newCandidatesCount: newStageId === Constants.CANDIDATE_STAGE_NEW_ID
                                        ? offer.counts.newCandidatesCount + 1
                                        : offer.counts.newCandidatesCount,
                                },
                            };
                        }

                        if (firstOriginalCandidate !== undefined && firstOriginalCandidate.jobId === offer.jobId) {
                            return {
                                ...offer,
                                counts: {
                                    activeCandidatesCount: offer.counts.activeCandidatesCount - 1,
                                    allCandidatesCount: offer.counts.allCandidatesCount - 1,
                                    newCandidatesCount: offer.counts.newCandidatesCount - 1,
                                },
                            };
                        }

                        return offer;
                    }),
                };

                const isNewCandidateFromActiveOffers = state.activeOffers.map(offer => offer.jobId).includes(newCandidate.jobId) === true;

                result = {
                    ...result,
                    newCandidatesTotalCount: isNewCandidateFromActiveOffers === true
                        ? result.newCandidatesTotalCount
                        : result.newCandidatesTotalCount - 1,
                    candidatesInNewStage: isNewCandidateFromActiveOffers === true
                        ? result.candidatesInNewStage.filter(candidate => action.meta.requestPayload.candidateIds.includes(candidate.id) === false).concat([newCandidate])
                        : result.candidatesInNewStage.filter(candidate => action.meta.requestPayload.candidateIds.includes(candidate.id) === false),
                    candidates: isNewCandidateFromActiveOffers === true
                        ? result.candidates.filter(candidate => action.meta.requestPayload.candidateIds.includes(candidate.id) === false).concat([newCandidate])
                        : result.candidates.filter(candidate => action.meta.requestPayload.candidateIds.includes(candidate.id) === false),
                    candidateIdsPairs: isNewCandidateFromActiveOffers === true
                        ? result.candidateIdsPairs.filter(pair => action.meta.requestPayload.candidateIds.includes(pair.id) === false).concat([{
                            id: newCandidate.id,
                            jobId: newCandidate.jobId,
                        }])
                        : result.candidateIdsPairs.filter(pair => action.meta.requestPayload.candidateIds.includes(pair.id) === false),
                };
            }
        }

        return result;
    };

    static processMoveActionOnDashboard(state, action) {
        let result = {};

        if (action.payload.newCandidates.length > 0) {
            result = {
                ...state,
                candidates: state.candidates.map(candidate => this.prepareCandidateAfterMove(action, candidate)).concat(action.payload.newCandidates.filter(candidate => candidate.stage.id === Constants.CANDIDATE_STAGE_NEW_ID)),
            };

            if (state.hasOwnProperty('jobs') === true) {
                const newJobId               = action.payload.newCandidates[0].jobId;
                const newStageId             = action.payload.newCandidates[0].stage.id;
                const firstOriginalCandidate = state.candidates.find(candidate => action.meta.requestPayload.candidateIds.includes(candidate.id) === true);

                result = {
                    ...result,
                    jobs: state.jobs.map(job => {
                        if (newJobId === job.id) {
                            return {
                                ...job,
                                countActiveApplicants: job.countActiveApplicants + 1,
                                countAllApplicants: job.countAllApplicants + 1,
                                countNewApplicants: newStageId === Constants.CANDIDATE_STAGE_NEW_ID
                                    ? job.countNewApplicants + 1
                                    : job.countNewApplicants,
                            };
                        }

                        if (firstOriginalCandidate !== undefined && firstOriginalCandidate.jobId === job.id) {
                            return {
                                ...job,
                                countActiveApplicants: job.countActiveApplicants - 1,
                                countAllApplicants: job.countAllApplicants - 1,
                                countNewApplicants: job.countNewApplicants - 1,
                            };
                        }

                        return job;
                    }),
                    jobsBasicData: state.jobsBasicData.map(job => {
                        if (newJobId === job.id) {
                            return {
                                ...job,
                                countNewApplicants: job.countNewApplicants + 1,
                            };
                        }

                        if (firstOriginalCandidate !== undefined && firstOriginalCandidate.jobId === job.id) {
                            return {
                                ...job,
                                countNewApplicants: job.countNewApplicants - 1,
                            };
                        }

                        return job;
                    }),
                };
            }
        }

        return result;
    };

    static processMoveActionOnJobDetail(state, action) {
        if (state.hasOwnProperty('fullCandidates') === false) {
            return state;
        }

        let result = {};

        if (action.payload.newCandidates.length > 0) {
            result = {
                ...state,
                candidates: state.candidates.map(candidate => this.prepareCandidateAfterMove(action, candidate)),
                ...CandidatesHelper.mergeFullCandidatesDataIntoStateWithCallback(state, candidate => action.meta.requestPayload.candidateIds.includes(candidate.id) === true, candidate => ({
                    ...candidate,
                    ...this.prepareCandidateAfterMove(action, candidate),
                })),
            };

            if (parseInt(Url.getUrlParameter('job')) === parseInt(action.payload.newCandidates[0].jobId)) {
                result = {
                    ...result,
                    candidates: result.candidates.concat(CandidatesHelper.getBasicCandidatesDataForJobDetail(action.payload.newCandidates)),
                    fullCandidates: result.fullCandidates === undefined
                        ? action.payload.newCandidates
                        : result.fullCandidates.concat(action.payload.newCandidates),
                    fetchedCandidateIds: result.fetchedCandidateIds === undefined
                        ? action.payload.newCandidates.map(candidate => candidate.id)
                        : result.fetchedCandidateIds.concat(action.payload.newCandidates.map(candidate => candidate.id)),
                };
            }
        }

        return result;
    };

    static processMoveActionOnGlobalSearch(state, action) {
        if (state.hasOwnProperty('basicCandidatesData') === false) {
            return state;
        }

        let result = {};

        if (action.payload.newCandidates.length > 0) {
            result = {
                ...state,
                basicCandidatesData: state.basicCandidatesData
                    .map(candidate => this.prepareCandidateAfterMove(action, candidate))
                    .concat(CandidatesHelper.getBasicCandidatesDataForGlobalSearch(action.payload.newCandidates)),
                fetchedCandidateIds: state.fetchedCandidateIds === undefined
                    ? action.payload.newCandidates.map(candidate => candidate.id)
                    : state.fetchedCandidateIds.concat(action.payload.newCandidates.map(candidate => candidate.id)),
                ...CandidatesHelper.mergeFullCandidatesDataIntoStateWithCallback(state, candidate => action.meta.requestPayload.candidateIds.includes(candidate.id) === true, candidate => ({
                    ...candidate,
                    ...this.prepareCandidateAfterMove(action, candidate),
                }), 'candidates'),
                candidateIds: state.candidateIds.concat(action.payload.newCandidates.map(candidate => candidate.id)),
            };
        }

        return result;
    };
}
