import Time from './../helpers/Time';
import GoogleAnalytics from '../helpers/GoogleAnalytics';
import User from '../helpers/User';
import Log from '../helpers/Log';

const loggerMiddleware = store => next => action => {
    const startTime = Time.getTime();

    let result = '';

    if (User.consoleLogEnabled(store.getState().loginManagerModel) === true) {
        Log.state('State before ' + action.type + ': ', store.getState());
        Log.action(action.type, action);
    }

    try {
        result = next(action);
    } catch (error) {
        if (error.hasOwnProperty('fileName') === true) {
            Log.error(action.type + ': ' + JSON.stringify(error.message) + ' -> ' + error.fileName + ':' + error.lineNumber + ':' + error.columnNumber);
        } else if (error.hasOwnProperty('stack')) {
            Log.error(action.type + ': ' + JSON.stringify(error.message) + ':', error.stack);
        } else {
            Log.error(JSON.stringify(error.message));
        }

        result = error;
    }

    const actionDuration = Time.getTime(startTime, 'milliseconds', 2);

    GoogleAnalytics.sendActionDuration('Actions', action.type, actionDuration);

    if (User.consoleLogEnabled(store.getState().loginManagerModel) === true) {
        Log.time(action.type + ' performed in ' + actionDuration + ' ms');
        Log.state('State after ' + action.type + ': ', store.getState());
    }

    return result;
};

export default loggerMiddleware;
