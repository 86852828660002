import React from 'react';
import Translations from '../../../helpers/Translations';
import Url from '../../../helpers/Url';
import Link from '../../../helpers/overridden/Link';
import HeaderHelper from '../../../helpers/components/HeaderHelper';

const SidebarSubmenuListItem = ({submenuItem}) => {
    const menuItemsStrings = HeaderHelper.getMenuItemsStrings();

    const submenuItemTitle = submenuItem.hasOwnProperty('title') === true
        ? Translations.getStatic(submenuItem.title)
        : menuItemsStrings[submenuItem.action];

    if (submenuItem.hasOwnProperty('url') === true) {
        return (
            <li>
                <a href={submenuItem.url} id={submenuItem?.id ?? null}>{submenuItemTitle}</a>
            </li>
        );
    }

    const url = submenuItem.hasOwnProperty('action') === true
        ? Url.build(submenuItem.action)
        : Url.getUrl();

    return (
        <li>
            <Link
                to={url}
                gtmTag={'sidebar-menu-' + submenuItemTitle}
                id={submenuItem?.id ?? null}
                data-cc={submenuItem?.id === 'change-consent' ? 'c-settings' : ''}
            >
                {submenuItemTitle}
            </Link>
        </li>
    );
};

export default SidebarSubmenuListItem;
