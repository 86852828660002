import {
    REQUEST_SETTINGS_MODEL,
    RECEIVE_SETTINGS_MODEL,
    SET_USER_LANGUAGE,
    SET_NEW_CANDIDATES_EMAILS, SET_NEW_CANDIDATES_EMAILS_SUCCESS, SET_NEW_CANDIDATES_EMAILS_FAILURE,
    SET_USER_DEFAULT_JOB_VIEW, SET_USER_DEFAULT_JOB_VIEW_SUCCESS, SET_USER_DEFAULT_JOB_VIEW_FAILURE,
    SET_USER_DEFAULT_PUBLIC_NOTES, SET_USER_DEFAULT_PUBLIC_NOTES_SUCCESS, SET_USER_DEFAULT_PUBLIC_NOTES_FAILURE,
    SET_USER_EVENT_TO_EMAIL_FORWARDING, SET_USER_EVENT_TO_EMAIL_FORWARDING_FAILURE, SET_USER_EVENT_TO_EMAIL_FORWARDING_SUCCESS,
} from '../actions/SettingsActions';
import {
    REQUEST_GOOGLE_CALENDAR_DATA, RECEIVE_GOOGLE_CALENDAR_DATA,
    GOOGLE_LOG_OUT, GOOGLE_LOG_OUT_SUCCESS, AUTHENTICATE_GOOGLE, AUTHENTICATE_GOOGLE_SUCCESS, AUTHENTICATE_GOOGLE_FAILURE,
    AUTHENTICATE_MICROSOFT, AUTHENTICATE_MICROSOFT_SUCCESS, AUTHENTICATE_MICROSOFT_FAILURE,
    REQUEST_MICROSOFT_CALENDAR_DATA, RECEIVE_MICROSOFT_CALENDAR_DATA,
    MICROSOFT_LOG_OUT, MICROSOFT_LOG_OUT_SUCCESS,
    SET_GOOGLE_CALENDAR, SET_GOOGLE_CALENDAR_SUCCESS,
    SET_MICROSOFT_CALENDAR, SET_MICROSOFT_CALENDAR_SUCCESS,
} from '../actions/CalendarActions';
import {DELETE_USER_OUT_OF_OFFICE_EMAIL_SUCCESS, SAVE_USER_OUT_OF_OFFICE_EMAIL_SUCCESS} from '../actions/OutOfOfficeEmailActions';

export const settingsModel = (state = {}, action) => {
    switch (action.type) {
        case RECEIVE_SETTINGS_MODEL:
            return {
                ...state,
                ...action.payload,
            };

        case SET_USER_LANGUAGE:
            return {
                ...state,
                isSettingLanguage: true,

                user: {
                    ...state.user,
                    languageId: action.payload.languageId,
                },
            };

        case SET_NEW_CANDIDATES_EMAILS:
            return {
                ...state,
                isSettingNewCandidatesEmails: true,
            };

        case SET_NEW_CANDIDATES_EMAILS_SUCCESS:
            return {
                ...state,
                ...action.payload,
                isSettingNewCandidatesEmails: false,
            };

        case SET_NEW_CANDIDATES_EMAILS_FAILURE:
            return {
                ...state,
                isSettingNewCandidatesEmails: false,

                user: {
                    ...state.user,
                    newCandidatesEmails: action.meta.requestPayload.newCandidatesEmailsBefore,
                },
            };

        case GOOGLE_LOG_OUT:
            return {
                ...state,

                calendars: {
                    ...state.calendars,

                    google: {
                        ...state.calendars.google,
                        isLoggedInGoogle: false,
                        calendars: null,
                    },
                },
            };

        case RECEIVE_GOOGLE_CALENDAR_DATA:
        case GOOGLE_LOG_OUT_SUCCESS:
        case SET_GOOGLE_CALENDAR_SUCCESS:
            return {
                ...state,

                calendars: {
                    ...state.calendars,
                    google: action.payload.google,
                },
            };

        case SET_GOOGLE_CALENDAR:
            return {
                ...state,

                calendars: {
                    ...state.calendars,

                    google: {
                        ...state.calendars.google,

                        calendars: state.calendars.google.calendars.map(calendar => ({
                            ...calendar,
                            selected: calendar.calendarId === action.payload.calendarId,
                        })),
                    },
                },
            };

        case MICROSOFT_LOG_OUT:
            return {
                ...state,

                calendars: {
                    ...state.calendars,

                    microsoft: {
                        ...state.calendars.microsoft,
                        isLoggedInMicrosoft: false,
                        calendars: null,
                    },
                },
            };

        case RECEIVE_MICROSOFT_CALENDAR_DATA:
        case MICROSOFT_LOG_OUT_SUCCESS:
        case SET_MICROSOFT_CALENDAR_SUCCESS:
            return {
                ...state,

                calendars: {
                    ...state.calendars,
                    microsoft: action.payload.microsoft,
                },
            };

        case SET_USER_DEFAULT_JOB_VIEW_SUCCESS:
        case SET_USER_DEFAULT_PUBLIC_NOTES_SUCCESS:
            return {
                ...state,
                user: action.payload.user,
            };

        case SAVE_USER_OUT_OF_OFFICE_EMAIL_SUCCESS:
        case DELETE_USER_OUT_OF_OFFICE_EMAIL_SUCCESS:
            return {
                ...state,
                outOfOfficeEmails: action.payload.outOfOfficeEmails,
            };

        case SET_USER_EVENT_TO_EMAIL_FORWARDING_SUCCESS:
            return {
                ...state,
                user: action.payload.user,
            };

        default:
            return state;
    }
};

export const isFetchingSettingsModel = (state = false, action) => {
    switch (action.type) {
        case REQUEST_SETTINGS_MODEL:
            return true;

        case RECEIVE_SETTINGS_MODEL:
            return false;

        default:
            return state;
    }
};

export const isAuthenticatingGoogle = (state = true, action) => {
    switch (action.type) {
        case AUTHENTICATE_GOOGLE:
            return true;

        case AUTHENTICATE_GOOGLE_SUCCESS:
        case AUTHENTICATE_GOOGLE_FAILURE:
            return false;

        default:
            return false;
    }
};

export const isAuthenticatingMicrosoft = (state = true, action) => {
    switch (action.type) {
        case AUTHENTICATE_MICROSOFT:
            return true;

        case AUTHENTICATE_MICROSOFT_SUCCESS:
        case AUTHENTICATE_MICROSOFT_FAILURE:
            return false;

        default:
            return false;
    }
};

export const isSettingCalendar = (state = false, action) => {
    switch (action.type) {
        case REQUEST_GOOGLE_CALENDAR_DATA:
        case GOOGLE_LOG_OUT:
        case REQUEST_MICROSOFT_CALENDAR_DATA:
        case MICROSOFT_LOG_OUT:
            return true;

        case RECEIVE_GOOGLE_CALENDAR_DATA:
        case GOOGLE_LOG_OUT_SUCCESS:
        case RECEIVE_MICROSOFT_CALENDAR_DATA:
        case MICROSOFT_LOG_OUT_SUCCESS:
            return false;

        default:
            return false;
    }
};

export const isSynchronizingCalendar = (state = false, action) => {
    switch (action.type) {
        case SET_GOOGLE_CALENDAR:
        case SET_MICROSOFT_CALENDAR:
            return true;

        case SET_GOOGLE_CALENDAR_SUCCESS:
        case SET_MICROSOFT_CALENDAR_SUCCESS:
            return false;

        default:
            return false;
    }
};

export const isSettingDefaultJobView = (state = false, action) => {
    switch (action.type) {
        case SET_USER_DEFAULT_JOB_VIEW:
            return true;

        case SET_USER_DEFAULT_JOB_VIEW_SUCCESS:
        case SET_USER_DEFAULT_JOB_VIEW_FAILURE:
            return false;

        default:
            return false;
    }
};

export const isSettingDefaultPublicNotes = (state = false, action) => {
    switch (action.type) {
        case SET_USER_DEFAULT_PUBLIC_NOTES:
            return true;

        case SET_USER_DEFAULT_PUBLIC_NOTES_SUCCESS:
        case SET_USER_DEFAULT_PUBLIC_NOTES_FAILURE:
            return false;

        default:
            return false;
    }
};

export const isSettingEventToEmailForwarding = (state = false, action) => {
    switch (action.type) {
        case SET_USER_EVENT_TO_EMAIL_FORWARDING:
            return true;

        case SET_USER_EVENT_TO_EMAIL_FORWARDING_SUCCESS:
        case SET_USER_EVENT_TO_EMAIL_FORWARDING_FAILURE:
            return false;

        default:
            return false;
    }
};
