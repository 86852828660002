import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import MarkModal from '../Modal/MarkModal';
import {closeModal} from '../../actions/ModalActions';
import Translations from '../../helpers/Translations';
import {setUserEventToEmailForwarding} from '../../actions/SettingsActions';
import SettingsPageEventToEmailForwarding from '../Settings/SettingsPageEventToEmailForwarding';

class SettingsPageForwardEventToEmailSettingsModal extends Component
{
    state = {
        hasActiveEventForwarding: this.props.user.hasActiveEventForwarding,
    };

    componentDidUpdate(prevProps) {
        if (prevProps.isSettingEventToEmailForwarding === true && this.props.isSettingEventToEmailForwarding === false) {
            this.props.closeModal();
        }
    };

    setUserEventToEmailForwarding = () => {
        const formData = new FormData();

        formData.append('active', this.state.hasActiveEventForwarding === true ? '1' : '0');

        this.props.setUserEventToEmailForwarding(this.state.hasActiveEventForwarding, formData);
    };

    setActiveEventForwarding = isActive => {
        this.setState({
            hasActiveEventForwarding: isActive,
        });
    };

    render() {
        const {isOpen, closeModal, isSettingEventToEmailForwarding} = this.props;
        const {hasActiveEventForwarding}                            = this.state;

        return (
            <MarkModal
                isOpen={isOpen}
                closeModal={closeModal}
                closeButtonText={Translations.getStatic('close')}

                actionButton={{
                    loading: isSettingEventToEmailForwarding === true,
                    text: Translations.getStatic('save'),
                    disabled: isSettingEventToEmailForwarding === true,
                    onClick: this.setUserEventToEmailForwarding,
                }}

                title={Translations.getStatic('calendarSettingsChange')}

                content={(
                    <SettingsPageEventToEmailForwarding
                        hasActiveEventForwarding={hasActiveEventForwarding}
                        setActiveEventForwarding={this.setActiveEventForwarding}
                    />
                )}
            />
        );
    }
}

SettingsPageForwardEventToEmailSettingsModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    isOpen: state.modalLogic.isOpen,
    user: state.settingsModel.user,
    isSettingEventToEmailForwarding: state.isSettingEventToEmailForwarding || false,
});

const mapDispatchToProps = {
    closeModal,
    setUserEventToEmailForwarding,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SettingsPageForwardEventToEmailSettingsModal);
