import React from 'react';
import PropTypes from 'prop-types';
import SendEmail from '../Email/SendEmail';
import ErrorFocus from '../ErrorFocus';
import MarkForm from '../Form';
import {InputField} from '@profesia/adamui/components/common/input';
import Select from '@profesia/adamui/components/common/dropdown/SelectDefault'
import Checkbox from '@profesia/adamui/components/common/input/checkbox/Checkbox';
import Grid from '../Grid';
import Item from '../Item';
import Image from '../Image';
import Divider from '../Divider';
import Heading from '../Heading';
import {connect} from 'react-redux';
import {setScheduleEventParams, requestCandidateEventModel, getCandidatesForAction} from '../../actions/ActionWithCandidateActions';
import DatetimePicker from '../DatetimePicker';
import DateFormatted from '../../helpers/DateFormatted';
import Translations from '../../helpers/Translations';
import Constants from '../../helpers/Constants';
import Validation from '../../helpers/Validation';
import Form from '../../helpers/Form';
import messageInfoImage from '../../../img/icons/message-info.svg';
import SendEmailHelper from '../../helpers/components/SendEmailHelper';
import FormSkeleton from '../../skeletons/components/FormSkeleton';

class ScheduleCandidateEvent extends React.Component
{
    componentDidMount = () => {
        this.props.requestCandidateEventModel(this.props.candidateIds, this.props.eventId, this.props.eventType, [Constants.CANDIDATE_OUTPUT_FLAG_BASIC_INFO]);
    };

    onChangeDate = date => {
        if (date === null) {
            this.props.setScheduleEventParams({
                startAt: '',
                endAt: '',
                dateForCheck: '',
            });
        } else {
            const startTime = DateFormatted.getTime(this.props.candidateActionModel.scheduleEventParams.startAt);
            const endTime   = DateFormatted.getTime(this.props.candidateActionModel.scheduleEventParams.endAt);

            this.props.setScheduleEventParams({
                startAt: date + ' ' + startTime,
                endAt: date + ' ' + endTime,
                dateForCheck: date,
            });
        }
    };

    onChangeTime = (time, type = 'start') => {
        if (type === 'start') {
            const startDate = DateFormatted.getDate(this.props.candidateActionModel.scheduleEventParams.startAt, 'Y-m-d');
            const startAt   = startDate + ' ' + time + ':00';
            const endAt     = DateFormatted.getDateTime(DateFormatted.getTimestamp(startAt) + 60 * 60 * 1000, 'Y-m-d H:i:s');

            this.props.setScheduleEventParams({
                startAt,
                endAt,
            });
        } else {
            const endDate = DateFormatted.getDate(this.props.candidateActionModel.scheduleEventParams.endAt, 'Y-m-d');
            const endAt   = endDate + ' ' + time + ':00';

            if (time === DateFormatted.getTime(this.props.candidateActionModel.scheduleEventParams.startAt, 'H:i')) {
                const startAt = DateFormatted.getDateTime(DateFormatted.getTimestamp(endAt) - 60 * 60 * 1000, 'Y-m-d H:i:s');

                this.props.setScheduleEventParams({
                    startAt,
                    endAt,
                });
            } else if (DateFormatted.getTimestamp(endAt) < DateFormatted.getTimestamp(this.props.candidateActionModel.scheduleEventParams.startAt)) {
                this.props.setScheduleEventParams({
                    endAt: this.props.candidateActionModel.scheduleEventParams.endAt,
                });

                alert(Translations.getStatic('provideCorrectEndTime'));
            } else {
                this.props.setScheduleEventParams({
                    endAt,
                });
            }
        }
    };

    render = () => {
        if (this.props.isFetchingCandidatesForAction === true || this.props.isFetchingCandidateEventModel === true || this.props.candidateActionModel.scheduleEventParams.hasOwnProperty('id') === false) {
            return <FormSkeleton/>;
        }

        const {candidateActionModel, candidateIds, eventType, eventData, errors, loginManagerModel} = this.props;
        const {scheduleEventParams, candidates}                                                     = candidateActionModel;

        const sendEmailToCandidateTitle = candidateIds.length > 1
            ? Translations.getStatic('sendInviteToCandidates')
            : Translations.getStatic('sendInviteToCandidate');

        let bcc = [];

        if (this.props.sendEmailModel.hasOwnProperty('bcc') === true && this.props.sendEmailModel.bcc.length > 0) {
            bcc = this.props.sendEmailModel.bcc;
        } else if (loginManagerModel.user.hasActiveEventForwarding === true && loginManagerModel.user.contactEmail.trim() !== '') {
            bcc.push({value: loginManagerModel.user.contactEmail, text: loginManagerModel.user.contactEmail});
        }

        return (
            <ErrorFocus>
                <MarkForm onSubmit={e => e.preventDefault()}>
                    <Heading as="h3" className="highlighted">
                        {
                            candidateIds.length > 1
                            && (
                                <>
                                    {Translations.getStatic('chosenCandidates') + ': ' + candidateIds.length}
                                    <Divider hidden className="size_16"/>
                                </>
                            )
                        }

                        {Translations.getStatic('eventDetails')}
                    </Heading>
                    <Grid className="highlighted">
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <MarkForm.Field>
                                    <Select
                                        value={eventType}
                                        label={Translations.getStatic('selectEventType')}
                                        options={Constants.CANDIDATE_EVENT_TYPES_DROPDOWN_OPTIONS}
                                        onChange={(value) => this.props.setScheduleEventParams({
                                            eventType: value,
                                        })}
                                    />
                                </MarkForm.Field>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <MarkForm.Field
                                    error={
                                        errors.includes('eventName') &&
                                        Form.check({fieldType: 'text', fieldValue: scheduleEventParams.eventName}) === false
                                            ? Validation.getMessage('empty', 'eventName')
                                            : false
                                    }
                                >
                                    <InputField
                                        maxLength={128}
                                        value={scheduleEventParams.eventName}
                                        label={Translations.getStatic('eventName')}
                                        onChange={(e) => this.props.setScheduleEventParams({eventName: e.target.value})}
                                    />
                                </MarkForm.Field>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <MarkForm.Field>
                                    <InputField
                                        maxLength={128}
                                        value={scheduleEventParams.eventLocation}
                                        label={Translations.getStatic('eventLocation')}
                                        onChange={(e) => this.props.setScheduleEventParams({eventLocation: e.target.value})}
                                    />
                                </MarkForm.Field>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={this.props.displaySize.isMobile === true ? 8 : 4}>
                                <MarkForm.Field>
                                    <label>{Translations.getStatic('date')}</label>
                                    <DatetimePicker
                                        error={errors.includes('dateForCheck') ? Validation.getMessage('date') : false}
                                        placeholder={''}
                                        defaultValue={DateFormatted.getDate(scheduleEventParams.startAt, 'Y-m-d')}
                                        name={'eventDate'}
                                        dateInputNameSuffix={''}
                                        dateEnabled={true}
                                        timeEnabled={false}
                                        onChange={date => this.onChangeDate(date)}
                                    />
                                </MarkForm.Field>
                            </Grid.Column>
                            {
                                this.props.displaySize.isMobile === true
                                || (
                                    <Grid.Column width={6}>
                                        <Grid columns={2}>
                                            <Grid.Column width={8}>
                                                <MarkForm.Field>
                                                    <label>{Translations.getStatic('start')}</label>
                                                    <DatetimePicker
                                                        align={'center'}
                                                        defaultValue={DateFormatted.getDateTime(scheduleEventParams.startAt, 'Y-m-d H:i:s')}
                                                        name={'eventDateStart'}
                                                        dateInputNameSuffix={''}
                                                        dateEnabled={false}
                                                        timeEnabled={true}
                                                        onTimeChange={time => this.onChangeTime(time, 'start')}
                                                    />
                                                </MarkForm.Field>
                                            </Grid.Column>
                                            <Grid.Column width={8}>
                                                <MarkForm.Field>
                                                    <label>{Translations.getStatic('end')}</label>
                                                    <DatetimePicker
                                                        align={'center'}
                                                        defaultValue={DateFormatted.getDateTime(scheduleEventParams.endAt, 'Y-m-d H:i:s')}
                                                        name={'eventDateEnd'}
                                                        dateInputNameSuffix={''}
                                                        dateEnabled={false}
                                                        timeEnabled={true}
                                                        onTimeChange={time => this.onChangeTime(time, 'end')}
                                                    />
                                                </MarkForm.Field>
                                            </Grid.Column>
                                        </Grid>
                                    </Grid.Column>
                                )
                            }
                        </Grid.Row>
                        {
                            this.props.displaySize.isMobile === true
                            && (
                                <Grid.Row>
                                    <Grid.Column width={8}>
                                        <MarkForm.Field>
                                            <label>{Translations.getStatic('start')}</label>
                                            <DatetimePicker
                                                align={'center'}
                                                defaultValue={DateFormatted.getDateTime(scheduleEventParams.startAt, 'Y-m-d H:i:s')}
                                                name={'eventDateStart'}
                                                dateInputNameSuffix={''}
                                                dateEnabled={false}
                                                timeEnabled={true}
                                                onTimeChange={time => this.onChangeTime(time, 'start')}
                                            />
                                        </MarkForm.Field>
                                    </Grid.Column>
                                    <Grid.Column width={8}>
                                        <MarkForm.Field>
                                            <label>{Translations.getStatic('end')}</label>
                                            <DatetimePicker
                                                align={'center'}
                                                defaultValue={DateFormatted.getDateTime(scheduleEventParams.endAt, 'Y-m-d H:i:s')}
                                                name={'eventDateEnd'}
                                                dateInputNameSuffix={''}
                                                dateEnabled={false}
                                                timeEnabled={true}
                                                onTimeChange={time => this.onChangeTime(time, 'end')}
                                            />
                                        </MarkForm.Field>
                                    </Grid.Column>
                                </Grid.Row>
                            )
                        }
                    </Grid>
                </MarkForm>

                {
                    this.props.eventId > 0
                    && eventData !== null
                    && eventData.hasOwnProperty('highlightTextareaVariableVideoInterviewPin') === true
                    && eventData.hasOwnProperty('highlightTextareaVariableVideoInterviewLink') === true
                    && (
                        <>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <div className='ui items'>
                                            <Item className="message message-info">
                                                <Image size="large" src={messageInfoImage}/>
                                                <Item.Content>
                                                    <Item.Header as="h4" className="ellipsis">{Translations.getStatic('videoInterviewLoginData')}</Item.Header>
                                                    <Item.Meta className="ellipsis">
                                                        {Translations.getStatic('link') + ': '}
                                                        <a href={eventData.highlightTextareaVariableVideoInterviewLink} target="_blank">{eventData.highlightTextareaVariableVideoInterviewLink}</a>
                                                    </Item.Meta>
                                                    <Item.Meta className="ellipsis">{Translations.getStatic('pin') + ': ' + eventData.highlightTextareaVariableVideoInterviewPin}</Item.Meta>
                                                </Item.Content>
                                            </Item>
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            <Divider hidden/>
                        </>
                    )
                }

                <MarkForm>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <MarkForm.Field>
                                    <Checkbox
                                        checked={scheduleEventParams.sendEmail}
                                        label={sendEmailToCandidateTitle}
                                        onChange={(e) => this.props.setScheduleEventParams({
                                            sendEmail: e.target.checked,
                                        })}
                                        readOnly={scheduleEventParams.eventType === Constants.CANDIDATE_EVENT_TYPE_VIDEO_INTERVIEW}
                                    />
                                </MarkForm.Field>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </MarkForm>

                {
                    scheduleEventParams.sendEmail === true
                    && (
                        <SendEmail
                            visibleFields={SendEmailHelper.getDefaultEmailVisibleFields()}

                            recipients={
                                this.props.sendEmailModel.hasOwnProperty('recipients') === true && this.props.sendEmailModel.recipients.length > 0
                                    ? this.props.sendEmailModel.recipients
                                    : candidates
                                        .filter(candidate => candidateIds.includes(candidate.id) === true && candidate.isAnonymized === false)
                                        .map(candidate => ({
                                            value: candidate.email,
                                            text: candidate.candidateName,
                                            candidateId: candidate.id,
                                        }))
                            }

                            bcc={bcc}

                            noSpacing={true}
                            errors={errors}
                            emailTemplateType={Constants.EMAIL_TEMPLATE_TYPE_EVENT}
                            eventType={scheduleEventParams.eventType}
                        />
                    )
                }
            </ErrorFocus>
        );
    };
}

ScheduleCandidateEvent.propTypes = {
    candidateIds: PropTypes.array.isRequired,
    eventId: PropTypes.number,
    eventType: PropTypes.number,
    eventData: PropTypes.object,
};

ScheduleCandidateEvent.defaultProps = {
    eventId: 0,
    eventType: Constants.CANDIDATE_EVENT_TYPE_INTERVIEW,
};

const mapStateToProps = state => ({
    sendEmailModel: state.sendEmailModel,
    candidateActionModel: state.candidateActionModel,
    isFetchingCandidateEventModel: state.isFetchingCandidateEventModel,
    isFetchingCandidatesForAction: state.isFetchingCandidatesForAction,
    displaySize: state.displaySize,
    loginManagerModel: state.loginManagerModel,
});

const mapDispatchToProps = {
    setScheduleEventParams,
    requestCandidateEventModel,
    getCandidatesForAction,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ScheduleCandidateEvent);
