import React from 'react';
import {connect} from 'react-redux';
import {closeModalSecond} from '../../actions/ModalActions';
import DetailEmailModal from '../Modals/DetailEmailModal';
import DetailQuestionnaireModal from '../Modals/DetailQuestionnaireModal';
import ReactionIframeModal from '../Modals/ReactionIframeModal';
import SettingsPageTransformStagesModal from '../Modals/SettingsPageTransformStagesModal';
import GoogleAnalytics from '../../helpers/GoogleAnalytics';
import DetailCandidateFromTalentPoolModal from '../Modals/DetailCandidateFromTalentPoolModal';
import EditOutOfOfficeEmailModal from '../Modals/EditOutOfOfficeEmailModal';

const modalLookup = {
    DetailCandidateFromTalentPoolModal,
    DetailEmailModal,
    DetailQuestionnaireModal,
    EditOutOfOfficeEmailModal,
    ReactionIframeModal,
    SettingsPageTransformStagesModal,
};

class ModalSecondManager extends React.Component
{
    state = {
        openingModalSecondType: null,
        openingModalSecondProps: null,
    };

    componentDidMount() {
        this.bodyElement = document.getElementsByTagName('body')[0];
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentSecondModal.isOpen === false && this.props.currentSecondModal.isOpen === true) {
            this.bodyElement.classList.add('openingModalSecond');
            this.bodyElement.classList.remove('closingModalSecond');

            this.setState({
                openingModalSecondType: this.props.currentSecondModal.modalType,
                openingModalSecondProps: this.props.currentSecondModal.modalProps,
            });

            GoogleAnalytics.sendModalView(this.props.currentSecondModal.modalType);
        } else if (prevProps.currentSecondModal.isOpen === true && this.props.currentSecondModal.isOpen === false) {
            const closeWindowAnimationTime = 500;

            setTimeout(() => {
                this.setState({
                    openingModalSecondType: null,
                    openingModalSecondProps: null,
                });
            }, closeWindowAnimationTime);

            if (this.props.currentModal.isOpen === false) {
                this.bodyElement.classList.remove('openingModalSecond');
                this.bodyElement.classList.remove('closingModalSecond');
            } else if (prevProps.currentSecondModal.isOpen === true && this.props.currentSecondModal.isOpen === false) {
                this.bodyElement.classList.add('closingModalSecond');
            }
        }
    }

    render = () => {
        const {openingModalSecondType, openingModalSecondProps} = this.state;

        if (openingModalSecondType !== null) {
            const ModalComponent = modalLookup[openingModalSecondType];

            return <ModalComponent isModalSecond={true} {...openingModalSecondProps}/>;
        }

        return null;
    };
}

const mapStateToProps = state => ({
    currentModal: state.modalLogic,
    currentSecondModal: state.modalSecondLogic,
});

const mapDispatchToProps = {
    closeModalSecond,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ModalSecondManager);
