import React from 'react';

const Form = (props) => {
    let { onSubmit, className, ...validProps } = props;

    onSubmit = onSubmit ? onSubmit : e => e.preventDefault();
    className = `${className ? className + ' ' : ''}`;

    return (
        <form {...validProps} onSubmit={onSubmit} className={`${className}ui form`}>
            {props.children}
        </form>
    );
};

const Field = (props) => {
    let { error, className, ...validProps } = props;

    className = `${className ? className + ' ' : ''}`;

    return (
        <div {...validProps} className={`${className}${error ? 'error ' : ''}field`}>
            {props.children}
            {error?.length && (
                <div className="prompt label" role="alert">
                    {error}
                </div>
            )}
        </div>
    );
};

const Group = (props) => {
    let { grouped, className, ...validProps } = props;

    grouped = `${grouped ? 'grouped ' : ''}`;
    className = `${className ? className + ' ' : ''}`;

    return (
        <div {...validProps} className={`${className}${grouped}fields`}>
            {props.children}
        </div>
    );
};

Form.Field = Field;
Form.Group = Group;

export default Form;
