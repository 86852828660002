import {
    REQUEST_COPY_CANDIDATE_MODEL, RECEIVE_COPY_CANDIDATE_MODEL,
    SET_COPY_PARAMS,
    COPY_CANDIDATES, COPY_CANDIDATES_FAILURE, COPY_CANDIDATES_SUCCESS,
    MOVE_CANDIDATES, MOVE_CANDIDATES_FAILURE, MOVE_CANDIDATES_SUCCESS,
    COPY_CANDIDATES_INTO_TALENT_POOL, COPY_CANDIDATES_INTO_TALENT_POOL_SUCCESS, COPY_CANDIDATES_INTO_TALENT_POOL_FAILURE,
    SEND_EMAIL, SEND_EMAIL_SUCCESS, SEND_EMAIL_FAILURE,
    SET_CHANGE_CANDIDATES_STAGE_PARAMS,
    CHANGE_CANDIDATES_STAGE, CHANGE_CANDIDATES_STAGE_SUCCESS, CHANGE_CANDIDATES_STAGE_FAILURE,
    SCHEDULE_CANDIDATE_EVENT, SCHEDULE_CANDIDATE_EVENT_FAILURE, SCHEDULE_CANDIDATE_EVENT_SUCCESS,
    SET_SCHEDULE_EVENT_PARAMS,
    RECEIVE_CANDIDATE_EVENT_MODEL, REQUEST_CANDIDATE_EVENT_MODEL,
    DELETE_CANDIDATE_EVENT, DELETE_CANDIDATE_EVENT_SUCCESS, DELETE_CANDIDATE_EVENT_FAILURE,
    GET_CANDIDATES_FOR_ACTION, GET_CANDIDATES_FOR_ACTION_SUCCESS, GET_CANDIDATES_FOR_ACTION_FAILURE,
    ANONYMIZE_CANDIDATES_FAILURE, ANONYMIZE_CANDIDATES_SUCCESS, ANONYMIZE_CANDIDATES,
    REQUEST_CHANGE_STAGE_MODEL, RECEIVE_CHANGE_STAGE_MODEL,
    REFUSE_CANDIDATE_AND_SEND_EMAIL, REFUSE_CANDIDATE_AND_SEND_EMAIL_SUCCESS, REFUSE_CANDIDATE_AND_SEND_EMAIL_FAILURE, SET_REFUSE_CANDIDATE_PARAMS,
    REQUEST_REFUSE_CANDIDATES_MODEL, RECEIVE_REFUSE_CANDIDATES_MODEL,
    CANDIDATES_EXPORT_REQUEST, CANDIDATES_EXPORT_REQUEST_SUCCESS, CANDIDATES_EXPORT_REQUEST_FAILURE,
    SAVE_CANDIDATE_GDPR_AGREEMENT, SAVE_CANDIDATE_GDPR_AGREEMENT_SUCCESS, SAVE_CANDIDATE_GDPR_AGREEMENT_FAILURE, SET_CANDIDATE_GDPR_AGREEMENT_PARAMS,
    SEND_CANDIDATES_QUESTIONNAIRE_REQUEST, SEND_CANDIDATES_QUESTIONNAIRE_REQUEST_SUCCESS, SEND_CANDIDATES_QUESTIONNAIRE_REQUEST_FAILURE, REMOVE_CANDIDATES_FROM_TALENT_POOL, REMOVE_CANDIDATES_FROM_TALENT_POOL_FAILURE, REMOVE_CANDIDATES_FROM_TALENT_POOL_SUCCESS,
    REQUEST_MOVE_CANDIDATE_MODEL, RECEIVE_MOVE_CANDIDATE_MODEL, SET_MOVE_PARAMS,
    RECEIVE_ADD_TAGS_MODEL, SET_SEND_EMAIL_PARAMS,
} from '../actions/ActionWithCandidateActions';
import {REQUEST_CLOSE_JOB_MODEL} from '../actions/CloseJobActions';
import {MODAL_OPEN} from '../actions/ModalActions';
import Constants from '../helpers/Constants';
import Sort from '../helpers/Sort';
import Language from '../helpers/Language';
import {CANDIDATE_ADD_TAG_SUCCESS} from '../actions/CandidateActions';
import {REQUEST_SEND_EMAIL_MODEL} from '../actions/SendEmailActions';
import Storages from '../helpers/Storages';

export const isFetchingRefuseCandidatesModel = (state = false, action) => {
    switch (action.type) {
        case REQUEST_REFUSE_CANDIDATES_MODEL:
            return true;
        case RECEIVE_REFUSE_CANDIDATES_MODEL:
            return false;
        default:
            return state;
    }
};

export const isFetchingCopyCandidateModel = (state = false, action) => {
    switch (action.type) {
        case REQUEST_COPY_CANDIDATE_MODEL:
            return true;
        case RECEIVE_COPY_CANDIDATE_MODEL:
            return false;
        default:
            return state;
    }
};

export const isFetchingMoveCandidateModel = (state = false, action) => {
    switch (action.type) {
        case REQUEST_MOVE_CANDIDATE_MODEL:
            return true;
        case RECEIVE_MOVE_CANDIDATE_MODEL:
            return false;
        default:
            return state;
    }
};

export const isFetchingChangeStageModel = (state = false, action) => {
    switch (action.type) {
        case REQUEST_CHANGE_STAGE_MODEL:
            return true;

        case RECEIVE_CHANGE_STAGE_MODEL:
            return false;

        default:
            return state;
    }
};

export const isCopyingCandidates = (state = false, action) => {
    switch (action.type) {
        case COPY_CANDIDATES:
        case COPY_CANDIDATES_INTO_TALENT_POOL:
            return true;

        case COPY_CANDIDATES_FAILURE:
        case COPY_CANDIDATES_INTO_TALENT_POOL_FAILURE:
        case COPY_CANDIDATES_SUCCESS:
        case COPY_CANDIDATES_INTO_TALENT_POOL_SUCCESS:
            return false;

        default:
            return state;
    }
};

export const isMovingCandidates = (state = false, action) => {
    switch (action.type) {
        case MOVE_CANDIDATES:
            return true;

        case MOVE_CANDIDATES_FAILURE:
        case MOVE_CANDIDATES_SUCCESS:
            return false;

        default:
            return state;
    }
};

export const isRemovingCandidateFromTalentPool = (state = false, action) => {
    switch (action.type) {
        case REMOVE_CANDIDATES_FROM_TALENT_POOL:
            return true;

        case REMOVE_CANDIDATES_FROM_TALENT_POOL_FAILURE:
        case REMOVE_CANDIDATES_FROM_TALENT_POOL_SUCCESS:
            return false;

        default:
            return state;
    }
};

export const isSendingEmail = (state = false, action) => {
    switch (action.type) {
        case SEND_EMAIL:
        case REFUSE_CANDIDATE_AND_SEND_EMAIL:
            return true;

        case SEND_EMAIL_SUCCESS:
        case SEND_EMAIL_FAILURE:
        case REFUSE_CANDIDATE_AND_SEND_EMAIL_SUCCESS:
        case REFUSE_CANDIDATE_AND_SEND_EMAIL_FAILURE:
            return false;

        default:
            return state;
    }
};

export const isChangingStageOfCandidates = (state = false, action) => {
    switch (action.type) {
        case CHANGE_CANDIDATES_STAGE:
            return true;

        case CHANGE_CANDIDATES_STAGE_SUCCESS:
        case CHANGE_CANDIDATES_STAGE_FAILURE:
            return false;

        default:
            return state;
    }
};

export const isFetchingCandidateEventModel = (state = false, action) => {
    switch (action.type) {
        case REQUEST_CANDIDATE_EVENT_MODEL:
            return true;

        case RECEIVE_CANDIDATE_EVENT_MODEL:
            return false;

        default:
            return state;
    }
};

export const isSchedulingCandidateEvent = (state = false, action) => {
    switch (action.type) {
        case SCHEDULE_CANDIDATE_EVENT:
            return true;

        case SCHEDULE_CANDIDATE_EVENT_SUCCESS:
        case SCHEDULE_CANDIDATE_EVENT_FAILURE:
            return false;

        default:
            return state;
    }
};

export const isDeletingCandidateEvent = (state = false, action) => {
    switch (action.type) {
        case DELETE_CANDIDATE_EVENT:
            return true;

        case DELETE_CANDIDATE_EVENT_FAILURE:
        case DELETE_CANDIDATE_EVENT_SUCCESS:
            return false;

        default:
            return state;
    }
};

export const isFetchingCandidatesForAction = (state = false, action) => {
    switch (action.type) {
        case GET_CANDIDATES_FOR_ACTION:
            return true;
        case GET_CANDIDATES_FOR_ACTION_SUCCESS:
        case GET_CANDIDATES_FOR_ACTION_FAILURE:
            return false;
        default:
            return state;
    }
};

export const isAnonymizingCandidates = (state = false, action) => {
    switch (action.type) {
        case ANONYMIZE_CANDIDATES:
            return true;
        case ANONYMIZE_CANDIDATES_SUCCESS:
        case ANONYMIZE_CANDIDATES_FAILURE:
            return false;
        default:
            return state;
    }
};

export const isRequestingCandidatesExport = (state = false, action) => {
    switch (action.type) {
        case CANDIDATES_EXPORT_REQUEST:
            return true;
        case CANDIDATES_EXPORT_REQUEST_SUCCESS:
        case CANDIDATES_EXPORT_REQUEST_FAILURE:
            return false;
        default:
            return state;
    }
};

export const isSavingCandidateGdprAgreement = (state = false, action) => {
    switch (action.type) {
        case SAVE_CANDIDATE_GDPR_AGREEMENT:
            return true;
        case SAVE_CANDIDATE_GDPR_AGREEMENT_SUCCESS:
        case SAVE_CANDIDATE_GDPR_AGREEMENT_FAILURE:
            return false;
        default:
            return state;
    }
};

export const isSendingCandidatesQuestionnaireRequest = (state = false, action) => {
    switch (action.type) {
        case SEND_CANDIDATES_QUESTIONNAIRE_REQUEST:
            return true;
        case SEND_CANDIDATES_QUESTIONNAIRE_REQUEST_SUCCESS:
        case SEND_CANDIDATES_QUESTIONNAIRE_REQUEST_FAILURE:
            return false;
        default:
            return state;
    }
};

const sendEmailModelDefaultState = {
    multipleForwardingAsSingleEmail: Storages.getLocal('multipleForwardingAsSingleEmail', true),
};

const copyCandidateModelDefaultState = {
    jobId: 0,
    copyHistory: false,
    removeCandidate: false,
    sendEmail: false,
};

const moveCandidateModelDefaultState = {
    jobId: 0,
    sendEmail: false,
};

const candidateEventModelDefaultState = {
    sendEmail: true,
};

const changeStageModelDefaultState = {
    stageId: null,
    sendEmail: false,
};

const refuseCandidateModelDefaultState = {
    sendEmail: false,
    refusalReasonId: Constants.REFUSAL_REASON_EMPTY_ID,
    customRefusalReason: '',
};

const candidateGdprAgreementModelDefaultState = {
    hasConfirmedAgreement: false,
    agreementExpirationAt: null,
};

const modalTypesWithSendEmail = {
    RefuseCandidateWithEmailModal: {
        paramsType: 'refuseCandidateParams',
        default: refuseCandidateModelDefaultState,
    },
    CopyCandidateModal: {
        paramsType: 'copyParams',
        default: copyCandidateModelDefaultState,
    },
    MoveCandidateModal: {
        paramsType: 'moveParams',
        default: moveCandidateModelDefaultState,
    },
    ScheduleCandidateEventModal: {
        paramsType: 'scheduleEventParams',
        default: candidateEventModelDefaultState,
    },
    ChangeStageOfCandidatesModal: {
        paramsType: 'changeStageOfCandidatesParams',
        default: changeStageModelDefaultState,
    },
};

let firstCandidateName;
let eventTypeName;

const defaultState = {
    sendEmailParams: sendEmailModelDefaultState,
    changeStageOfCandidatesParams: changeStageModelDefaultState,
    copyParams: copyCandidateModelDefaultState,
    moveParams: moveCandidateModelDefaultState,
    scheduleEventParams: candidateEventModelDefaultState,
    refuseCandidateParams: refuseCandidateModelDefaultState,
    candidateGdprAgreementParams: candidateGdprAgreementModelDefaultState,
    refusalReasons: [],
    stages: [],
    isSuccess: false,
};

export const candidateActionModel = (state = defaultState, action) => {
    let eventName;

    switch (action.type) {
        case MODAL_OPEN:
            if (modalTypesWithSendEmail.hasOwnProperty(action.payload.modalType) === true || action.payload.modalType === 'SendEmailModal') {
                let resultState = {
                    ...state,
                };

                if (action.payload.modalType !== 'SendEmailModal') {
                    const paramsType = modalTypesWithSendEmail[action.payload.modalType].paramsType;

                    let params = {
                        ...state[paramsType],
                        sendEmail: modalTypesWithSendEmail[action.payload.modalType].default.sendEmail,
                    };

                    let paramsResult         = {};
                    paramsResult[paramsType] = params;

                    resultState = {
                        ...resultState,
                        ...paramsResult,
                    };
                }

                resultState = {
                    ...resultState,
                    candidates: [],
                };

                return resultState;
            }

            if (action.payload.modalType === 'CandidateGdprAgreementModal') {
                return {
                    ...state,
                    candidateGdprAgreementParams: candidateGdprAgreementModelDefaultState,
                };
            }

            return state;

        case REQUEST_SEND_EMAIL_MODEL:
            return {
                ...state,
                sendEmailParams: sendEmailModelDefaultState,
            };

        case REQUEST_COPY_CANDIDATE_MODEL:
            return {
                ...state,
                copyParams: copyCandidateModelDefaultState,
            };

        case REQUEST_MOVE_CANDIDATE_MODEL:
            return {
                ...state,
                moveParams: moveCandidateModelDefaultState,
            };

        case RECEIVE_COPY_CANDIDATE_MODEL:
        case RECEIVE_MOVE_CANDIDATE_MODEL:
            return {
                ...state,
                jobs: action.payload.jobs,
            };

        case REQUEST_CHANGE_STAGE_MODEL:
            return {
                ...state,
                changeStageOfCandidatesParams: changeStageModelDefaultState,
            };

        case RECEIVE_CHANGE_STAGE_MODEL:
            return {
                ...state,
                stages: action.payload.stages,
            };

        case RECEIVE_ADD_TAGS_MODEL:
            return {
                ...state,
                tags: action.payload.tags,
            };

        case SET_COPY_PARAMS:
            return {
                ...state,
                copyParams: {
                    ...state.copyParams,
                    ...action.payload.params,
                },
            };

        case SET_MOVE_PARAMS:
            return {
                ...state,
                moveParams: {
                    ...state.moveParams,
                    ...action.payload.params,
                },
            };

        case SET_CHANGE_CANDIDATES_STAGE_PARAMS:
            return Object.assign({}, state, {
                changeStageOfCandidatesParams: Object.assign({}, state.changeStageOfCandidatesParams, action.payload.params),
            });

        case SET_SCHEDULE_EVENT_PARAMS:
            const isChangingEventType = action.payload.params.hasOwnProperty('eventType') === true;
            const eventType           = isChangingEventType === true ? action.payload.params.eventType : state.scheduleEventParams.eventType;
            const sendEmail           = action.payload.params.hasOwnProperty('sendEmail') === true ? action.payload.params.sendEmail : state.scheduleEventParams.sendEmail;
            const isVideoInterview    = Constants.CANDIDATE_EVENT_TYPE_VIDEO_INTERVIEW === eventType;

            firstCandidateName = [state.candidates[0].title, state.candidates[0].firstname, state.candidates[0].surname].filter(item => item !== '').join(' ');
            eventTypeName      = Constants.CANDIDATE_EVENT_TYPES_DROPDOWN_OPTIONS.find(type => type.id === eventType).label;

            eventName = action.payload.params.hasOwnProperty('eventName') === true ? action.payload.params.eventName : state.scheduleEventParams.eventName;

            if (isChangingEventType === true) {
                if (state.candidates.length > 1) {
                    eventName = eventTypeName;
                } else if (action.payload.params.eventType === Constants.CANDIDATE_EVENT_TYPE_OTHER) {
                    eventName = firstCandidateName;
                } else {
                    eventName = eventTypeName + ' - ' + firstCandidateName;
                }
            }

            return {
                ...state,

                scheduleEventParams: {
                    ...state.scheduleEventParams,
                    ...action.payload.params,
                    eventName,
                    sendEmail: isVideoInterview === true || sendEmail === true,
                },
            };
        case REQUEST_CANDIDATE_EVENT_MODEL:
            return {
                ...state,
                scheduleEventParams: candidateEventModelDefaultState,
            };

        case RECEIVE_CANDIDATE_EVENT_MODEL:
            firstCandidateName = [action.payload.candidates[0].title, action.payload.candidates[0].firstname, action.payload.candidates[0].surname].filter(item => item !== '').join(' ');

            if (action.payload.event.id === null) {
                eventTypeName = Constants.CANDIDATE_EVENT_TYPES_DROPDOWN_OPTIONS.find(type => type.id === action.payload.event.eventType).label;

                if (action.payload.candidates.length > 1) {
                    eventName = eventTypeName;
                } else if (action.payload.event.eventType === Constants.CANDIDATE_EVENT_TYPE_OTHER) {
                    eventName = firstCandidateName;
                } else {
                    eventName = eventTypeName + ' - ' + firstCandidateName;
                }
            } else {
                eventName = action.payload.event.eventName;
            }

            return {
                ...state,

                scheduleEventParams: {
                    ...state.scheduleEventParams,
                    ...action.payload.event,
                    eventName,
                    sendEmail: action.payload.event.eventType === Constants.CANDIDATE_EVENT_TYPE_VIDEO_INTERVIEW || state.scheduleEventParams.sendEmail,
                    emailLanguageId: action.payload.languages.find(language => Language.get() === language.languageCode).languageId,
                },

                candidates: action.payload.candidates,
                languages: Sort.byColumn(action.payload.languages, 'language'),
                nextUserEvents: action.payload.nextUserEvents,
            };

        case COPY_CANDIDATES_SUCCESS:
        case COPY_CANDIDATES_INTO_TALENT_POOL_SUCCESS:
        case MOVE_CANDIDATES_SUCCESS:
        case SEND_EMAIL_SUCCESS:
        case CHANGE_CANDIDATES_STAGE_SUCCESS:
        case SCHEDULE_CANDIDATE_EVENT_SUCCESS:
        case DELETE_CANDIDATE_EVENT_SUCCESS:
        case ANONYMIZE_CANDIDATES_SUCCESS:
        case REFUSE_CANDIDATE_AND_SEND_EMAIL_SUCCESS:
        case SAVE_CANDIDATE_GDPR_AGREEMENT_SUCCESS:
        case SEND_CANDIDATES_QUESTIONNAIRE_REQUEST_SUCCESS:
        case REMOVE_CANDIDATES_FROM_TALENT_POOL_SUCCESS:
        case CANDIDATE_ADD_TAG_SUCCESS:
            return {
                ...state,
                isSuccess: true,
            };

        case GET_CANDIDATES_FOR_ACTION:
            return {
                ...state,
                candidates: [],
            };

        case GET_CANDIDATES_FOR_ACTION_SUCCESS:
            return {
                ...state,
                candidates: action.payload.candidates,
            };

        case SET_REFUSE_CANDIDATE_PARAMS:
            return {
                ...state,

                refuseCandidateParams: {
                    ...state.refuseCandidateParams,
                    ...action.payload.params,
                },
            };

        case REQUEST_REFUSE_CANDIDATES_MODEL:
            return {
                ...state,
                refuseCandidateParams: refuseCandidateModelDefaultState,
                refusalReasons: [],
            };

        case RECEIVE_REFUSE_CANDIDATES_MODEL:
            return {
                ...state,
                refusalReasons: action.payload.refusalReasons,
            };

        case SET_CANDIDATE_GDPR_AGREEMENT_PARAMS:
            return {
                ...state,

                candidateGdprAgreementParams: {
                    ...state.candidateGdprAgreementParams,
                    ...action.payload.params,
                },
            };

        case REQUEST_CLOSE_JOB_MODEL:
            return defaultState;

        case SET_SEND_EMAIL_PARAMS:
            return {
                ...state,

                sendEmailParams: {
                    ...state.sendEmailParams,
                    ...action.payload.params,
                },
            };

        default:
            return state;
    }
};
