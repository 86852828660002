import {UPDATE_CHUNK_STATUS, RESET_CHUNK_STATUS} from '../actions/ChunkStatusActions';

export const chunkStatus = (state = {}, action) => {
    switch (action.type) {
        case RESET_CHUNK_STATUS:
        case UPDATE_CHUNK_STATUS:
            return action.payload;

        default:
            return state;
    }
};
