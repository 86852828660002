import React from 'react';
import Translations from '../../helpers/Translations';
import MarkDialog from '../Modal/MarkDialog';
import DateFormatted from '../../helpers/DateFormatted';

const ConflictsInCalendarDialog = props => {
    const {conflicts} = props;

    let dialogBody = '';

    if (conflicts.length === 1) {
        dialogBody = Translations.getStatic('conflictInCalendar')
            .replace(/%eventName%/g, conflicts[0].eventName)
            .replace(/%eventStartAt%/g, DateFormatted.getDateTime(conflicts[0].startAt));
    } else if (conflicts.length >= 2 && conflicts.length <= 4) {
        dialogBody = Translations.getStatic('conflictsInCalendar').replace(/%count%/g, conflicts.length);
    } else {
        dialogBody = Translations.getStatic('conflictsInCalendarGenitive').replace(/%count%/g, conflicts.length);
    }

    return (
        <MarkDialog
            closeOnDimmerClick={false}
            message={{
                iconName: 'icon-event',
                title: Translations.getStatic('eventScheduling'),
                infoLines: [dialogBody],
            }}

            actions={[
                {
                    title: Translations.getStatic('edit'),
                    action: props.onClose,
                },
                {
                    type: 'positive',
                    title: Translations.getStatic('create'),
                    action: props.onCreate,
                },
            ]}
        />
    );
}

export default ConflictsInCalendarDialog;


