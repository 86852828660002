export const REQUEST_COMPANY_JOBS_MODEL = 'REQUEST_COMPANY_JOBS_MODEL';
export const RECEIVE_COMPANY_JOBS_MODEL = 'RECEIVE_COMPANY_JOBS_MODEL';

export const requestCompanyJobsModel = (companyId) => ({
    type: REQUEST_COMPANY_JOBS_MODEL,
    meta: {
        requestRoute: 'v1/company-jobs/model/' + companyId,
        onRequestSuccess: RECEIVE_COMPANY_JOBS_MODEL,
    },
});
