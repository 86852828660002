import Form from '../Form';
import Translations from '../Translations';

export default class CandidateValidatorHelper
{
    constructor(candidate) {
        this.candidate = candidate;
    }

    checkCandidate() {
        const fieldsToCheck = [
            {
                fieldName: 'firstname',
                fieldType: 'text',
                fieldValue: this.candidate.firstname,
            },
            {
                fieldName: 'surname',
                fieldType: 'text',
                fieldValue: this.candidate.surname,
            },
            {
                fieldName: 'email',
                fieldType: 'emptyOrCheck',
                secondaryFieldType: 'email',
                fieldValue: this.candidate.email,
            },
            {
                fieldName: 'phone',
                fieldType: 'emptyOrCheck',
                secondaryFieldType: 'phone',
                fieldValue: this.candidate.phone,
            },
        ];

        let errors = Form.multiCheck(fieldsToCheck);

        if (errors.length > 0) {
            return errors;
        }

        return [];
    }
}
