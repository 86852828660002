import React, {Component} from 'react';
import PropTypes from 'prop-types';

class Dropdown extends Component
{
    state = {
        listOpen: false,
    };

    componentDidUpdate() {
        const {listOpen} = this.state;

        setTimeout(() => {
            if (listOpen) {
                window.addEventListener('click', this.close);
            } else {
                window.removeEventListener('click', this.close);
            }
        }, 0);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.close);
    }

    close = () => {
        this.setState({
            listOpen: false,
        });
    };

    selectItem = value => {
        this.setState({
                listOpen: false,
            }, this.props.selectedItem(value),
        );
    };

    toggleList = () => {
        this.setState(prevState => ({
            listOpen: !prevState.listOpen,
        }));
    };

    render() {
        const {list, className, label, type, defaultValue, gtmTag} = this.props;
        const {listOpen}                                           = this.state;

        const selectedItem = this.props.list.find(item => item.value === defaultValue);

        if (type === 'icon') {
            return (
                <div role="listbox" className={'ui visible dropdown ' + (listOpen ? 'active ' : '') + className} onClick={this.toggleList}>
                    <div className="text" role="alert" aria-live="polite" aria-atomic="true"/>
                    <i aria-hidden="true" className="icon icon-more_small small vertical-align"/>

                    {
                        listOpen === true
                        && (
                            <div className="menu transition left visible" onClick={e => e.stopPropagation()} onMouseLeave={this.close}>
                                {
                                    list.map(item => (
                                        <div role="option" className="item" key={item.value} onClick={() => this.selectItem(item.value)}>
                                            <span className="text">{item.text}</span>
                                        </div>
                                    ))
                                }
                            </div>
                        )
                    }
                </div>
            );
        }

        return (
            <>
                {label && <label>{label}</label>}

                <div role="listbox" className={'ui selection dropdown ' + (listOpen ? 'active ' : '') + className} onClick={this.toggleList}>
                    <div className="text" role="alert" aria-live="polite" aria-atomic="true">
                        {selectedItem && selectedItem.text}
                    </div>
                    <i aria-hidden="true" className="dropdown icon"/>

                    {
                        listOpen === true
                        && (
                            <div className="menu transition left visible" onClick={e => e.stopPropagation()} onMouseLeave={this.close}>
                                {
                                    list.map(item => (
                                        <div role="option" className="item" key={item.value} onClick={() => this.selectItem(item.value)}>
                                            {item.description && <span className="description">{item.description}</span>}
                                            <span className="text">{item.text}</span>
                                        </div>
                                    ))
                                }
                            </div>
                        )}
                </div>
            </>
        );
    }
}

Dropdown.propTypes = {
    list: PropTypes.array.isRequired,
    className: PropTypes.string.isRequired,
    gtmTag: PropTypes.string.isRequired,
};

Dropdown.defaultProps = {
    className: '',
};

export default Dropdown;
