import React from 'react';
import Translations from '../../helpers/Translations';
import MarkDialog from '../Modal/MarkDialog';
import {connect} from 'react-redux';
import {closeNoCandidateAccessDialog} from '../../actions/DialogActions';

const NoCandidateAccessDialog = props => {
    return props.noCandidateAccessDialogOpen === true && (
        <MarkDialog
            closeOnDimmerClick={false}
            message={{
                iconName: 'icon-permission',
                title: Translations.getStatic('noCandidateAccessTitle'),
                infoLines: [Translations.getStatic('noCandidateAccessDescription')],
            }}
            actions={[
                {
                    title: Translations.getStatic('cancel'),
                    action: () => props.closeNoCandidateAccessDialog(),
                },
            ]}
        />
    );
}

const mapStateToProps = state => ({
    noCandidateAccessDialogOpen: state.noCandidateAccessDialogOpen,
});

const mapDispatchToProps = {
    closeNoCandidateAccessDialog,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(NoCandidateAccessDialog);


