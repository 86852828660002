import Constants from './Constants';

class Screen
{
    static getWidth(): number {
        const isSSR: boolean = typeof window === 'undefined';

        return isSSR ? Constants.RESOLUTION_MOBILE : window.innerWidth;
    };

    static scrollElementToTop(elementId: string): void {
        const element: HTMLElement | null = document.getElementById(elementId);

        if (!element) {
            return;
        }

        try {
            element.scrollTo({top: 0, behavior: 'smooth'});
        } catch (error) {
            element.scrollTop = 0;
        }

    }

    static isElementInViewport(element: HTMLElement | null): boolean {
        if (!element) {
            return false;
        }

        try {
            const rect: DOMRect = element.getBoundingClientRect();

            return (
                rect.top >= 0
                && rect.left >= 0
                && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
                && rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        } catch (error) {
            return true;
        }
    }

    static getElementDistanceFromBottom(element: HTMLElement | null): number {
        if (!element) {
            return 10000;
        }

        try {
            const rect: DOMRect = element.getBoundingClientRect();

            return (window.innerHeight || document.documentElement.clientHeight) - rect.bottom;
        } catch (error) {
            return 10000;
        }
    }
}

export default Screen;
