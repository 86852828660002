import React from 'react';
import {connect} from 'react-redux';
import Form from '../Form';
import Grid from '../Grid';
import MarkModal from '../Modal/MarkModal';
import {closeModal} from '../../actions/ModalActions';
import PropTypes from 'prop-types';
import Translations from '../../helpers/Translations';
import DateFormatted from '../../helpers/DateFormatted';
import Url from '../../helpers/Url';
import CandidateHelper from '../../helpers/components/CandidateHelper';
import Constants from '../../helpers/Constants';
import Link from '../../helpers/overridden/Link';

const DetailHistoryItemModal = props => {
    const {
              candidate,
              bulkAction,
              historyItem,
              title,
              isOpenModal,
              closeModal,
          } = props;

    const CopyHistoryItem = () => {
        if (bulkAction.type !== 'copy') {
            return null;
        }

        const fromLink = <Link to={Url.build('detail', {job: bulkAction.value.from.id})} gtmTag="candidate-history-copied-from-job">{bulkAction.value.from.title}</Link>;
        const intoLink = <Link to={Url.build('detail', {job: bulkAction.value.into.id})} gtmTag="candidate-history-copied-to-job">{bulkAction.value.into.title}</Link>;

        return (
            <>
                <Grid.Row className="horizontal-row">
                    <Grid.Column computer={4} tablet={16}>
                        <label>{Translations.getStatic('copyingFrom')}</label>
                    </Grid.Column>
                    <Grid.Column computer={12} tablet={16}>
                        {fromLink}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className="horizontal-row">
                    <Grid.Column computer={4} tablet={16}>
                        <label>{Translations.getStatic('copyingInto')}</label>
                    </Grid.Column>
                    <Grid.Column computer={12} tablet={16}>
                        {intoLink}
                    </Grid.Column>
                </Grid.Row>
            </>
        );
    };

    const MoveHistoryItem = () => {
        if (bulkAction.type !== 'move') {
            return null;
        }

        const fromLink = <Link to={Url.build('detail', {job: bulkAction.value.from.id})} gtmTag="candidate-history-moved-from-job">{bulkAction.value.from.title}</Link>;
        const intoLink = <Link to={Url.build('detail', {job: bulkAction.value.into.id})} gtmTag="candidate-history-moved-to-job">{bulkAction.value.into.title}</Link>;

        return (
            <>
                <Grid.Row className="horizontal-row">
                    <Grid.Column computer={4} tablet={16}>
                        <label>{Translations.getStatic('movingFrom')}</label>
                    </Grid.Column>
                    <Grid.Column computer={12} tablet={16}>
                        {fromLink}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className="horizontal-row">
                    <Grid.Column computer={4} tablet={16}>
                        <label>{Translations.getStatic('movingInto')}</label>
                    </Grid.Column>
                    <Grid.Column computer={12} tablet={16}>
                        {intoLink}
                    </Grid.Column>
                </Grid.Row>
            </>
        );
    };

    const RefuseHistoryItem = () => {
        if (bulkAction.type !== 'state') {
            return null;
        }

        return (
            <>
                <Grid.Row className="horizontal-row">
                    <Grid.Column computer={4} tablet={16}>
                        <label>{Translations.getStatic('refusalReason')}</label>
                    </Grid.Column>
                    <Grid.Column computer={12} tablet={16}>
                        {CandidateHelper.getRefusalReason(bulkAction.value.refusalReason)}
                    </Grid.Column>
                </Grid.Row>

                {
                    bulkAction.value.lastStage === null
                    || (
                        <Grid.Row className="horizontal-row">
                            <Grid.Column computer={4} tablet={16}>
                                <label>{Translations.getStatic('refusedProcessStep')}</label>
                            </Grid.Column>
                            <Grid.Column computer={12} tablet={16}>
                                {Translations.getStage(bulkAction.value.lastStage)}
                            </Grid.Column>
                        </Grid.Row>
                    )
                }
            </>
        );
    };

    const StageHistoryItem = () => {
        if (bulkAction.type !== 'stage') {
            return null;
        }

        return (
            <>
                {
                    bulkAction.previousStage === null
                    || (
                        <Grid.Row className="horizontal-row">
                            <Grid.Column computer={4} tablet={16}>
                                <label>{Translations.getStatic('previousStage')}</label>
                            </Grid.Column>
                            <Grid.Column computer={12} tablet={16}>
                                {Translations.getStage(bulkAction.previousStage)}
                            </Grid.Column>
                        </Grid.Row>
                    )
                }

                <Grid.Row className="horizontal-row">
                    <Grid.Column computer={4} tablet={16}>
                        <label>{Translations.getStatic('newStage')}</label>
                    </Grid.Column>
                    <Grid.Column computer={12} tablet={16}>
                        {Translations.getStage(bulkAction.value)}
                    </Grid.Column>
                </Grid.Row>
            </>
        );
    };

    const AttachmentsHistoryItem = () => {
        if (bulkAction.type !== 'attachment') {
            return null;
        }

        return (
            <Grid.Row className="horizontal-row">
                <Grid.Column computer={4} tablet={16}>
                    <label>{Translations.getStatic('attachment')}</label>
                </Grid.Column>
                <Grid.Column computer={12} tablet={16}>
                    {bulkAction.value.filename}
                </Grid.Column>
            </Grid.Row>
        );
    };

    const BasicDataHistoryItem = () => {
        if (bulkAction.type !== 'basicData') {
            return null;
        }

        return (
            <>
                <Grid.Row className="horizontal-row">
                    <Grid.Column computer={4} tablet={16}>
                        <label>{Translations.getStatic('newData')}</label>
                    </Grid.Column>
                    <Grid.Column computer={12} tablet={16}>
                        {bulkAction.value.valueAfter}
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row className="horizontal-row">
                    <Grid.Column computer={4} tablet={16}>
                        <label>{Translations.getStatic('oldData')}</label>
                    </Grid.Column>
                    <Grid.Column computer={12} tablet={16}>
                        {bulkAction.value.valueBefore}
                    </Grid.Column>
                </Grid.Row>
            </>
        );
    };

    const TagsHistoryItem = () => {
        if (bulkAction.type !== 'tag') {
            return null;
        }

        const currentTagsArray  = bulkAction.value.split(', ');
        const previousTagsArray = bulkAction.previousTags === null
            ? []
            : bulkAction.previousTags.split(', ');

        return (
            <>
                {
                    bulkAction.previousTags !== null && previousTagsArray.length > currentTagsArray.length
                    && (
                        <Grid.Row className="horizontal-row">
                            <Grid.Column computer={4} tablet={16}>
                                <label>{Translations.getStatic('removedTags')}</label>
                            </Grid.Column>
                            <Grid.Column computer={12} tablet={16}>
                                {previousTagsArray.filter(tag => currentTagsArray.includes(tag) === false).join(', ')}
                            </Grid.Column>
                        </Grid.Row>
                    )
                }

                {
                    bulkAction.previousTags !== null && previousTagsArray.length < currentTagsArray.length
                    && (
                        <Grid.Row className="horizontal-row">
                            <Grid.Column computer={4} tablet={16}>
                                <label>{Translations.getStatic('newTags')}</label>
                            </Grid.Column>
                            <Grid.Column computer={12} tablet={16}>
                                {currentTagsArray.filter(tag => previousTagsArray.includes(tag) === false).join(', ')}
                            </Grid.Column>
                        </Grid.Row>
                    )
                }

                {
                    bulkAction.previousTags === null
                    && (
                        <Grid.Row className="horizontal-row">
                            <Grid.Column computer={4} tablet={16}>
                                <label>{Translations.getStatic('newTags')}</label>
                            </Grid.Column>
                            <Grid.Column computer={12} tablet={16}>
                                {bulkAction.value}
                            </Grid.Column>
                        </Grid.Row>
                    )
                }
            </>
        );
    };

    const NotesHistoryItem = () => {
        if (bulkAction.type !== 'note') {
            return null;
        }

        return (
            <>
                <Grid.Row className="horizontal-row">
                    <Grid.Column computer={4} tablet={16}>
                        <label>{Translations.getStatic('newNote')}</label>
                    </Grid.Column>
                    <Grid.Column computer={12} tablet={16}>
                        {bulkAction.value}
                    </Grid.Column>
                </Grid.Row>

                {
                    bulkAction.lastNote === null
                    || (
                        <Grid.Row className="horizontal-row">
                            <Grid.Column computer={4} tablet={16}>
                                <label>{Translations.getStatic('previousNote')}</label>
                            </Grid.Column>
                            <Grid.Column computer={12} tablet={16}>
                                {bulkAction.lastNote}
                            </Grid.Column>
                        </Grid.Row>
                    )
                }
            </>
        );
    };

    const createdBy = () => {
        if (bulkAction.hasOwnProperty('isFirst') === true
            && bulkAction.isFirst === true
            && bulkAction.type === 'stage'
            && candidate.source.id === Constants.SOURCE_ID_RECOMMENDATION_FORM
            && candidate.hasOwnProperty('recommendation') === true
            && candidate.recommendation !== null
        ) {
            return candidate.recommendation.name + ', ' + candidate.recommendation.email;
        }

        if (historyItem.createdBy === null) {
            return '';
        }

        return historyItem.createdBy.name;
    };

    return (
        <MarkModal
            isOpen={isOpenModal}
            closeModal={closeModal}
            title={title}
            actionButton={{visible: false}}
            isLoadingData={false}

            content={(
                <Form className={'detail-email'}>
                    <Grid>
                        <Grid.Row className="horizontal-row">
                            <Grid.Column computer={4} tablet={16}>
                                <label>{Translations.getStatic('changeMadeBy')}</label>
                            </Grid.Column>
                            <Grid.Column computer={12} tablet={16}>
                                {createdBy()}
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row className="horizontal-row">
                            <Grid.Column computer={4} tablet={16}>
                                <label>{Translations.getStatic('date')}</label>
                            </Grid.Column>
                            <Grid.Column computer={12} tablet={16}>
                                {DateFormatted.getDateTime(historyItem.createdAt, 'j.n.Y ')}
                                {Translations.getStatic('at')}
                                {DateFormatted.getDateTime(historyItem.createdAt, ' H:i')}
                            </Grid.Column>
                        </Grid.Row>

                        <CopyHistoryItem/>
                        <MoveHistoryItem/>
                        <RefuseHistoryItem/>
                        <TagsHistoryItem/>
                        <NotesHistoryItem/>
                        <StageHistoryItem/>
                        <AttachmentsHistoryItem/>
                        <BasicDataHistoryItem/>
                    </Grid>
                </Form>
            )}
        />
    );
};

const mapStateToProps = state => ({
    isOpenModal: state.modalLogic.isOpen,
});

const mapDispatchToProps = {
    closeModal,
};

DetailHistoryItemModal.propTypes = {
    title: PropTypes.string.isRequired,
    historyItem: PropTypes.object.isRequired,
    bulkAction: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailHistoryItemModal);
