class MarkLiveAgent
{
    static getContactForm(): void {
        return (function (doc: Document, src: string, callback: (htmlScriptElement: HTMLScriptElement) => any): void {
            const scriptElement: any = doc.createElement('script');

            if (scriptElement == null) {
                return;
            }

            scriptElement.id    = 'la_x2s6df8d';
            scriptElement.async = true;
            scriptElement.src   = src;

            scriptElement.onload = scriptElement.onreadystatechange = function (): void {
                const rs = this.readyState;

                if (rs && !['complete', 'loaded'].includes(rs)) {
                    return;
                }

                callback(this);
            };

            doc.getElementById('liveAgentContactForm')?.appendChild(scriptElement);
        })(
            document,
            'https://podpora.profesia.sk/scripts/track.js',
            (e: HTMLScriptElement) => window.LiveAgent.createForm('z8dqz5y6', e),
        );
    }
}

export default MarkLiveAgent;
