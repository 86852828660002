import React, {Component} from 'react';
import PropTypes from 'prop-types';
import MarkModal from '../Modal/MarkModal';
import {closeModal} from '../../actions/ModalActions';
import {connect} from 'react-redux';
import {requestAutoAnswerEditorModel} from '../../actions/AutoAnswerEditorActions';
import LoadingComponent from '../LoadingComponent';
import EmailTemplatePreview from '../Email/EmailTemplatePreview';
import Translations from '../../helpers/Translations';

class AutoAnswerPreviewModal extends Component
{
    componentDidMount = () => {
        this.props.requestAutoAnswerEditorModel(this.props.autoAnswerId);
    };

    render() {
        const {isOpen, autoAnswerEditorModel, isFetchingAutoAnswerEditorModel} = this.props;

        return (
            <MarkModal
                isOpen={isOpen}
                closeModal={this.props.closeModal}
                title={Translations.getStatic('autoAnswer')}
                actionButton={{visible: false}}
                isLoadingData={this.props.isFetchingAutoAnswerEditorModel}

                content={
                    isFetchingAutoAnswerEditorModel === true || autoAnswerEditorModel.hasOwnProperty('autoAnswerData') === false
                        ? <LoadingComponent/>
                        : <EmailTemplatePreview emailTemplateData={autoAnswerEditorModel.autoAnswerData}/>
                }
            />
        );
    }
}

AutoAnswerPreviewModal.propTypes = {
    autoAnswerId: PropTypes.number.isRequired,
    isOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    isOpen: state.modalLogic.isOpen,
    autoAnswerEditorModel: state.autoAnswerEditorModel,
    isFetchingAutoAnswerEditorModel: state.isFetchingAutoAnswerEditorModel,
});

const mapDispatchToProps = {
    closeModal,
    requestAutoAnswerEditorModel,
};

export default connect(mapStateToProps, mapDispatchToProps)(AutoAnswerPreviewModal);




