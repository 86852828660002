import React, {Component} from 'react';
import {connect} from 'react-redux';
import Checkbox from '@profesia/adamui/components/common/input/checkbox/Checkbox';
import TextareaAutosize from 'react-autosize-textarea';
import Button from '../../Button';
import Item from '../../Item';
import Divider from '../../Divider';
import Icon from '../../Icon';
import {candidateSetNote, requestCandidateHistory} from '../../../actions/CandidateActions';
import {closeSidebarEditPanel} from '../../../actions/SidebarActions';
import PropTypes from 'prop-types';
import Translations from '../../../helpers/Translations';
import Storages from '../../../helpers/Storages';

class CandidateSidebarNoteEdit extends Component
{
    state = {
        note: this.props.note,
        isPublic: this.props.isPublic,
    };

    componentDidUpdate(prevProps) {
        const isPublic = this.props.isPublic === prevProps.isPublic ? this.state.isPublic : this.props.isPublic;
        const note     = this.props.candidateId === prevProps.candidateId ? this.state.note : this.props.note;

        if (note !== this.state.note || isPublic !== this.state.isPublic) {
            this.setState({
                note,
                isPublic,
            });
        }
    }

    handleChangeNote = (value) => {
        this.setState({
            note: value,
        });

        Storages.setLocal(`candidateNoteId_${this.props.candidateId}`, {note: value});
    };

    handleChangeNoteVisibility = () => {
        this.setState({
            isPublic: !this.state.isPublic,
        });
    };

    onCandidateSetNote = () => {
        const {candidateId} = this.props;

        let formData = new FormData();

        formData.append('candidateId', candidateId);
        formData.append('note', this.state.note);
        formData.append('isPublic', this.state.isPublic === true ? '1' : '0');

        this.props.candidateSetNote(formData, candidateId);

        this.props.closeSidebarEditPanel('CandidateSidebarNoteEdit', {candidateId});
    };

    render() {
        const handleClickSave = () => {
            this.onCandidateSetNote();
            Storages.removeLocal(`candidateNoteId_${this.props.candidateId}`);
        }

        return (
            <>
                <div className="ui items height unstackable">
                    <Item>
                        <Icon className="icon-notes_small" size="mini"/>
                        <Item.Content>
                            <Item.Header as="h4">{Translations.getStatic('myNotes')}</Item.Header>
                            <Item.Description>
                                <TextareaAutosize className="short" onChange={e => this.handleChangeNote(e.target.value)}  value={this.state.note}/>
                                <Divider hidden/>

                                <Checkbox
                                    name="isPublic"
                                    label={Translations.getStatic('shareNotesWithExternalUsers')}
                                    checked={this.state.isPublic}
                                    onChange={this.handleChangeNoteVisibility}
                                />
                            </Item.Description>
                        </Item.Content>
                    </Item>
                </div>

                <Button className="float-right secondary-button" onClick={() => this.props.closeSidebarEditPanel('CandidateSidebarNoteEdit', {candidateId: this.props.candidateId})}>{Translations.getStatic('cancel')}</Button>
                <Button className="float-right primary-button" onClick={handleClickSave}>{Translations.getStatic('save')}</Button>
            </>
        );
    }
}

const mapStateToProps = state => ({
    isFetchingCandidateNote: state.isFetchingCandidateNote,
    sidebarModel: state.sidebarEditPanelLogic.fullSidebar,
});

const mapDispatchToProps = {
    candidateSetNote,
    closeSidebarEditPanel,
    requestCandidateHistory,
};

CandidateSidebarNoteEdit.propTypes = {
    candidateId: PropTypes.number.isRequired,
    note: PropTypes.string.isRequired,
    isPublic: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateSidebarNoteEdit);
