import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '../Button';
import PropTypes from 'prop-types';
import Translations from '../../helpers/Translations';
import MarkModalActionButtonsSkeleton from '../../skeletons/components/MarkModalActionButtonsSkeleton';

class MarkModal extends Component {
    state = {
        isOpen: this.props.foldMode ? true : !!this.props.isOpen,
        wrapperClass: 'modal-wrapper--open',
        sliderClass: 'modal--open',
    };

    componentWillUnmount() {
        this.bodyElement.classList.remove('h-overflowHidden');
    }

    componentDidMount() {
        this.bodyElement = document.getElementsByTagName('body')[0];
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevState.isOpen && this.state.isOpen) {
            this.bodyElement.classList.add('h-overflowHidden');
        } else if (prevState.isOpen && !this.state.isOpen) {
            this.bodyElement.classList.remove('h-overflowHidden');
        }

        if (prevProps.isOpen && !this.props.isOpen) {
            this.setState({
                sliderClass: 'modal--close',
                wrapperClass: 'modal-wrapper--close',
            });
        } else if (!prevProps.isOpen && this.props.isOpen) {
            this.setState({
                isOpen: true,
                wrapperClass: 'modal-wrapper--open',
                sliderClass: 'modal--open',
            });
        }
    }

    onAnimationEnd = (e) => {
        if (e.animationName === 'slideOut--right' || e.animationName === 'slideOut--left') {
            this.setState({ isOpen: false });
        }
    };

    onWrapperClick = (e) => {
        const className = e.target.getAttribute('class') || '';

        if (
            className.includes('js-slideWrapper') &&
            this.props.onOutsideClick &&
            !this.props.foldMode
        ) {
            this.props.onOutsideClick();
        }
    };

    render() {
        let headerFooterHeight;

        const offset = this.props.verticalOffset;
        const verticalOffset = offset
            ? (offset.top ? offset.top : 0) + (offset.bottom ? offset.bottom : 0)
            : 0;

        if (!this.props.footer && !this.props.title && !this.props.header) {
            headerFooterHeight = 0;
        } else if (this.props.footer) {
            headerFooterHeight = this.props.footerHeight;
        } else if (this.props.header || this.props.title) {
            headerFooterHeight = this.props.headerHeight;
        } else {
            headerFooterHeight = this.props.footerHeight + this.props.headerHeight;
        }

        const modalClass = this.props.wide === true ? 'markModal wide' : 'markModal';
        const contentStyle = {
            height: 'calc(100% - ' + (headerFooterHeight + verticalOffset) + 'px)',
        };
        const sliderDirectionClassName = this.props.leftToRight
            ? modalClass + ' modal--left '
            : modalClass + ' modal--right ';

        const language = this.props?.language ?? '';

        if (this.state.isOpen || this.props.foldMode) {
            return (
                <div
                    onAnimationEnd={this.onAnimationEnd}
                    className={
                        'modal-wrapper js-slideWrapper' +
                        ' ' +
                        this.state.wrapperClass +
                        (this.props.isModalSecond ? ' second' : '')
                    }
                    onClick={this.onWrapperClick}
                >
                    <div className={sliderDirectionClassName + this.state.sliderClass}>
                        {(this.props.title || this.props.header) && (
                            <div
                                className="template-header header-basic modal__header"
                                style={{ height: this.props.headerHeight }}
                            >
                                <div className="header__row">
                                    <div className="square-button">
                                        <div role="link" onClick={this.props.closeModal}>
                                            {Translations.getStatic('close', language)}
                                        </div>
                                    </div>
                                    <div className="header__title">
                                        {this.props.title && (
                                            <h3 className="ellipsis">{this.props.title}</h3>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}

                        {this.props.content && (
                            <div className="modal__content" style={contentStyle}>
                                {this.props.content}
                            </div>
                        )}

                        {this.props.isFooterVisible === true && (
                            <div
                                className="modal__footer"
                                style={{ height: this.props.footerHeight }}
                            >
                                {this.props.isLoadingData === true ? (
                                    <MarkModalActionButtonsSkeleton />
                                ) : (
                                    <>
                                        {this.props.entityOption.visible === true && (
                                            <Button
                                                as="a"
                                                className={
                                                    'primary-link' +
                                                    (this.props.entityOption.colorRed === true
                                                        ? ' red'
                                                        : '')
                                                }
                                                loading={this.props.entityOption.loading}
                                                disabled={this.props.entityOption.disabled}
                                                onClick={this.props.entityOption.onClick}
                                                floated="left"
                                            >
                                                {this.props.entityOption.text}
                                            </Button>
                                        )}

                                        {this.props.entityOptionSecond.visible === true && (
                                            <Button
                                                as="a"
                                                className={
                                                    'primary-link' +
                                                    (this.props.entityOptionSecond.colorRed === true
                                                        ? ' red'
                                                        : '')
                                                }
                                                loading={this.props.entityOptionSecond.loading}
                                                disabled={this.props.entityOptionSecond.disabled}
                                                onClick={this.props.entityOptionSecond.onClick}
                                                floated="left"
                                            >
                                                {this.props.entityOptionSecond.text}
                                            </Button>
                                        )}

                                        {this.props.actionButton.visible === false || (
                                            <Button
                                                className={
                                                    'primary-button' +
                                                    (this.props.actionButton.colorRed === true
                                                        ? ' negative'
                                                        : '')
                                                }
                                                loading={this.props.actionButton.loading}
                                                disabled={this.props.actionButton.disabled}
                                                onClick={this.props.actionButton.onClick}
                                            >
                                                {this.props.actionButton.text}
                                            </Button>
                                        )}

                                        {this.props.displaySize.isMobile === true || (
                                            <Button
                                                className="secondary-button"
                                                onClick={this.props.closeModal}
                                            >
                                                {this.props?.closeButtonText ??
                                                    Translations.getStatic('close', language)}
                                            </Button>
                                        )}
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            );
        }

        return null;
    }
}

MarkModal.propTypes = {
    isOpen: PropTypes.bool,
    onOutsideClick: PropTypes.func,
    closeModal: PropTypes.func,
    title: PropTypes.string,
    footer: PropTypes.node,
    header: PropTypes.node,
    content: PropTypes.node,
    isModalSecond: PropTypes.bool,
    wide: PropTypes.bool,
    isFooterVisible: PropTypes.bool,

    verticalOffset: PropTypes.shape({
        top: PropTypes.number,
        bottom: PropTypes.number,
    }),

    foldWidth: PropTypes.string,
    foldMode: PropTypes.bool,
    leftToRight: PropTypes.bool,
    headerHeight: PropTypes.number,
    footerHeight: PropTypes.number,
    closeButtonText: PropTypes.string,
};

MarkModal.defaultProps = {
    isModalSecond: false,
    foldWidth: '140px',
    headerHeight: 92,
    footerHeight: 84,
    wide: false,
    isFooterVisible: true,
    isLoadingData: false,

    actionButton: {
        visible: true,
        text: '~strings.save~',
        loading: false,
        disabled: false,
        onClick: (e) => e.preventDefault(),
    },

    entityOption: {
        visible: false,
        text: '~strings.save~',
        loading: false,
        disabled: false,
        colorRed: false,
        onClick: (e) => e.preventDefault(),
    },

    entityOptionSecond: {
        visible: false,
        text: '~strings.save~',
        loading: false,
        disabled: false,
        colorRed: false,
        onClick: (e) => e.preventDefault(),
    },
};

const mapStateToProps = (state) => ({
    displaySize: state.displaySize,
});

export default connect(mapStateToProps)(MarkModal);
