import React from 'react';
import {SkeletonCircular, SkeletonRectangular} from '@profesia/adamui/components/common/skeleton';
import {Stack} from '@mui/material';

const JobPipelineColumnItemSkeleton = () => (
    <div className="candidate-card-wrapper">
        <div className="candidate-card candidate-universal">
            <div className="card-border">
                <Stack p={3} spacing={2}>
                    <Stack flex={1} spacing={1} direction="row">
                        <Stack flex={1} spacing={2} direction="row" alignItems="center">
                            <SkeletonCircular size={48}/>

                            <Stack flex={1} spacing={1} direction="row" justifyContent="space-between">
                                <Stack spacing={1}>
                                    <SkeletonRectangular width={89} height={18} borderRadius={26}/>
                                    <SkeletonRectangular width={61} height={10} borderRadius={26}/>
                                </Stack>

                                <SkeletonCircular size={18}/>
                            </Stack>
                        </Stack>
                    </Stack>

                    <Stack flex={1} direction="row" justifyContent="space-between">
                        <Stack spacing={1} direction="row">
                            <SkeletonRectangular width={40} height={4} borderRadius={26}/>
                            <SkeletonRectangular width={40} height={4} borderRadius={26}/>
                        </Stack>

                        <SkeletonRectangular width={40} height={4} borderRadius={26}/>
                    </Stack>
                </Stack>
            </div>
        </div>
    </div>
);

export default JobPipelineColumnItemSkeleton;
