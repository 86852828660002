export const REQUEST_COMPANY_RECOMMENDATIONS_MODEL = 'REQUEST_COMPANY_RECOMMENDATIONS_MODEL';
export const RECEIVE_COMPANY_RECOMMENDATIONS_MODEL = 'RECEIVE_COMPANY_RECOMMENDATIONS_MODEL';

export const requestCompanyRecommendationsModel = (companyId) => ({
    type: REQUEST_COMPANY_RECOMMENDATIONS_MODEL,
    meta: {
        requestRoute: 'v1/company-recommendations/model/' + companyId,
        onRequestSuccess: RECEIVE_COMPANY_RECOMMENDATIONS_MODEL,
    },
});
