import React from 'react';
import Translations from '../../helpers/Translations';
import SettingsMenuSkeleton from './SettingsMenuSkeleton';
import SettingsPageDataViewSkeleton from './SettingsPageDataViewSkeleton';

const SettingsPageSkeleton = () => (
    <div className="settings">
        <div className="template-header header-basic">
            <div className="header__title">
                <h1>{Translations.getStatic('settings')}</h1>
            </div>
        </div>
        <div className="settings-content">
            <SettingsMenuSkeleton/>

            <div className="settings-content_right">
                <SettingsPageDataViewSkeleton/>
            </div>
        </div>
    </div>
);

export default SettingsPageSkeleton;
