import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Item from '../../Item';
import Icon from '../../Icon';
import Translations from '../../../helpers/Translations';
import DateFormatted from '../../../helpers/DateFormatted';
import {openModal} from '../../../actions/ModalActions';
import Popup from '../../Popup';

class CandidateSidebarGdprAgreement extends Component
{
    openCandidateGdprAgreementModal = () => {
        const {candidateId, gdprAgreement} = this.props;

        this.props.openModal('CandidateGdprAgreementModal', {candidateId, gdprAgreement});
    };

    render() {
        const {gdprAgreement, jobTitle} = this.props;

        const readOnly = this.props.readOnly || false;

        const agreementLink = gdprAgreement.hasConfirmedAgreement === true
            ? (
                <Popup
                    width="fit-content"
                    content={Translations.getStatic('hasConfirmedGdprAgreementTooltipText')}
                    trigger={readOnly === true
                        ? (
                            <div>
                                <span>{Translations.getStatic('to') + ' ' + DateFormatted.getDate(gdprAgreement.agreementExpirationAt)}</span>
                            </div>
                        )
                        : (
                            <div className="secondary-link" role="link" onClick={this.openCandidateGdprAgreementModal}>
                                <span>{Translations.getStatic('to') + ' ' + DateFormatted.getDate(gdprAgreement.agreementExpirationAt)}</span>
                            </div>
                        )
                    }
                />
            )
            : (
                <Popup
                    width="fit-content"
                    content={Translations.getStatic('hasNotConfirmedGdprAgreementTooltipText').replace('%date%', DateFormatted.getDate(gdprAgreement.agreementExpirationAt)).replace('%jobTitle%', jobTitle)}
                    trigger={readOnly === true
                        ? (
                            <div className="txt-red txt-bold">
                                <span className={'txt-red'}>{Translations.getStatic('onlyInThisJob')}</span>
                            </div>
                        )
                        : (
                            <div className="secondary-link txt-red txt-bold" role="link" onClick={this.openCandidateGdprAgreementModal}>
                                <span className={'txt-red'}>{Translations.getStatic('onlyInThisJob')}</span>
                            </div>
                        )
                    }
                />
            );

        return (
            <div className="ui items unstackable">
                <Item>
                    <Icon className="icon-agreement_small" size="mini"/>
                    <Item.Content>
                        <Item.Header>{Translations.getStatic('personalDataProcessing')}</Item.Header>
                        <div>
                            {agreementLink}
                        </div>
                    </Item.Content>
                </Item>
            </div>
        );
    }
}

CandidateSidebarGdprAgreement.propTypes = {
    candidateId: PropTypes.number.isRequired,
    gdprAgreement: PropTypes.object,
};

const mapDispatchToProps = {
    openModal,
};

export default connect(null, mapDispatchToProps)(CandidateSidebarGdprAgreement);
