import React from 'react';
import Grid from '../Grid';
import Item from '../Item';
import Image from '../Image';
import PropTypes from 'prop-types';
import Translations from '../../helpers/Translations';
import waitingImage from '../../../img/icons/waiting.svg';

const LiveUploadAttachmentColumn = ({attachment, language}) => (
    <Item className="data-block__content attachment">
        <Image size="large" src={waitingImage}/>
        <Grid verticalAlign="middle" container>
            <Grid.Row>
                <Grid.Column>
                    <Item.Header as="h4" className="ellipsis">{Translations.getStatic('uploadingCandidateAttachment', language)}...</Item.Header>
                    <Item.Meta className="ellipsis"><span>{attachment.file.name}</span></Item.Meta>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </Item>
);

LiveUploadAttachmentColumn.propTypes = {
    attachment: PropTypes.object.isRequired,
};

export default LiveUploadAttachmentColumn;
