import { GetCodebooksResponse } from '@profesia/mark-offer-form/services';
import { Languages } from '@profesia/mark-offer-form/i18n';
import Url from '../../../helpers/Url';
import { remapOptionItem, remapPositions } from './helpers';
import { ApiResponse } from './types';

const getCodebooks = async (locale: Languages): Promise<GetCodebooksResponse> => {
    const response: ApiResponse = await fetch(
        `${Url.MARK_SERVER_PREFIX}/api/v1/codebook?locale=${locale}`,
        {
            method: 'GET',
        }
    ).then((response) => response.json());

    return {
        languages: {
            favourites: remapOptionItem(response.languages.favourites),
            others: remapOptionItem(response.languages.others),
        },
        regions: remapOptionItem(response.regions),
        jobPositions: remapPositions(response.positions),
    };
};

export default getCodebooks;
