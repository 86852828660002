import React, {Component} from 'react';
import PropTypes from 'prop-types';
import MarkModal from '../Modal/MarkModal';
import {closeModal} from '../../actions/ModalActions';
import {connect} from 'react-redux';
import SendEmailHelper from '../../helpers/components/SendEmailHelper';
import {copyCandidates} from '../../actions/ActionWithCandidateActions';
import {jobUncheckAllCandidates} from '../../actions/CandidatesListActions';
import {setErrorFocus} from '../../actions/ErrorActions';
import CopyCandidate from '../Candidate/CopyCandidate';
import Constants from '../../helpers/Constants';
import SuccessActionStatus from '../../helpers/SuccessActionStatus';
import Translations from '../../helpers/Translations';
import AttachmentAssistantHelper from '../../helpers/components/AttachmentAssistantHelper';
import CandidatesReducer from '../../helpers/generalReducers/CandidatesReducer';
import LoadingComponent from '../LoadingComponent';
import Variables from '../../helpers/Variables';
import User from '../../helpers/User';

class CopyCandidateModal extends Component
{
    state = {
        errors: [],
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (SuccessActionStatus.isActionSuccessful(prevProps, this.props)) {
            this.props.jobUncheckAllCandidates();
            this.props.closeModal();
        }
    }

    copyCandidates = () => {
        if (this.props.candidateActionModel.copyParams.jobId === 0) {
            const errors = this.state.errors.concat('jobId');

            this.setState({
                errors,
            });

            this.props.setErrorFocus();

            return;
        }

        try {
            const {candidateIds, candidateActionModel, sendEmailModel, attachmentAssistantModel, templateAssistant, templatesForDropdownSelector, loginManagerModel} = this.props;

            let actionData = new FormData();

            if (candidateActionModel.copyParams.sendEmail === true) {
                actionData = SendEmailHelper.prepareEmail(
                    sendEmailModel,
                    attachmentAssistantModel,
                    templateAssistant,
                    templatesForDropdownSelector,
                    candidateIds,
                    Constants.EMAIL_TYPE_TO_COLLEAGUE,
                    {
                        variables: sendEmailModel.settings.variables,
                        filledVariables: Variables.getFilledVariablesCheckParams(
                            [Constants.VARIABLE_ACTION_TYPE_USER, Constants.VARIABLE_ACTION_TYPE_EVENT, Constants.VARIABLE_ACTION_TYPE_VIDEO_INTERVIEW, Constants.VARIABLE_ACTION_TYPE_QUESTIONNAIRE],
                            candidateActionModel.candidates,
                            loginManagerModel,
                            sendEmailModel.settings.videoInterview,
                        ),
                        forbiddenVariables: Variables.getForbiddenVariablesCheckParams(
                            [Constants.VARIABLE_ACTION_TYPE_MULTIPLE_FORWARD],
                        ),
                        requiredVariables: {},
                    },
                );
            }

            actionData.append('copyParams', JSON.stringify(candidateActionModel.copyParams));
            actionData.append('candidateIds', JSON.stringify(candidateIds));

            this.props.copyCandidates(actionData, candidateIds, candidateActionModel.copyParams, CandidatesReducer.getReducerType());

            this.setState({
                errors: [],
            });
        } catch (error) {
            if (error !== false) {
                this.setState({
                    errors: error,
                });

                this.props.setErrorFocus();
            }
        }
    };

    render() {
        const {
                  isOpen, isCopyingCandidates, isFetchingMultiAttachmentAssistantModel, candidateIds,
                  loginManagerModel, isFetchingCandidatesForAction,
                  isGettingVideoInterviewData,
                  isFetchingAttachmentAssistantModel,
                  isFetchingSendEmailModel,
              } = this.props;

        const {errors} = this.state;

        const children = isCopyingCandidates === true
            ? (
                <LoadingComponent
                    text={Translations.getStatic('candidateCopy') + '...'}
                    showProgress={candidateIds.length > Constants.POST_CHUNK_SIZE}
                />
            )
            : (
                <CopyCandidate
                    errors={errors}
                    candidateIds={candidateIds}
                    isAdmin={loginManagerModel !== undefined && User.isAdmin(loginManagerModel) === true}
                />
            );

        return (
            <MarkModal
                isOpen={isOpen}
                closeModal={this.props.closeModal}

                isLoadingData={
                    isFetchingMultiAttachmentAssistantModel === true
                    || isFetchingCandidatesForAction === true
                    || isGettingVideoInterviewData === true
                    || isFetchingAttachmentAssistantModel === true
                    || isFetchingSendEmailModel === true
                }

                actionButton={{
                    text: Translations.getStatic('copy'),
                    disabled: isCopyingCandidates === true || isFetchingMultiAttachmentAssistantModel === true,
                    loading: isCopyingCandidates === true,
                    onClick: this.copyCandidates,
                }}

                title={Translations.getStatic('copyToAnotherJob')}
                content={children}
            />
        );
    }
}

CopyCandidateModal.propTypes = {
    candidateIds: PropTypes.array.isRequired,
    isOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    isOpen: state.modalLogic.isOpen,
    candidateActionModel: state.candidateActionModel,
    isCopyingCandidates: state.isCopyingCandidates,
    isFetchingMultiAttachmentAssistantModel: state.isFetchingMultiAttachmentAssistantModel,
    sendEmailModel: state.sendEmailModel,

    attachmentAssistantModel: AttachmentAssistantHelper.getActiveModelFromAssistantAttachmentModel(
        state.attachmentAssistantModel,
        AttachmentAssistantHelper.createAttachmentAssistantHashIdForEmail(),
    ),

    templateAssistant: state.templateAssistant,
    templatesForDropdownSelector: state.templatesForDropdownSelector.templates,
    actionSuccessStatus: state.actionSuccessStatus,
    loginManagerModel: state.loginManagerModel,
    isFetchingCandidatesForAction: state.isFetchingCandidatesForAction,
    isGettingVideoInterviewData: state.isGettingVideoInterviewData,
    isFetchingAttachmentAssistantModel: state.isFetchingAttachmentAssistantModel,
    isFetchingSendEmailModel: state.isFetchingSendEmailModel,
});

const mapDispatchToProps = {
    closeModal,
    copyCandidates,
    jobUncheckAllCandidates,
    setErrorFocus,
};

export default connect(mapStateToProps, mapDispatchToProps)(CopyCandidateModal);




