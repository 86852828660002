import {
    FETCH_AUTO_ANSWER_TEMPLATES, FETCH_AUTO_ANSWER_TEMPLATES_FAILURE, FETCH_AUTO_ANSWER_TEMPLATES_SUCCESS,
    FETCH_QUESTIONNAIRE_TEMPLATES, FETCH_QUESTIONNAIRE_TEMPLATES_FAILURE, FETCH_QUESTIONNAIRE_TEMPLATES_SUCCESS,
    FETCH_EMAIL_TEMPLATES, FETCH_EMAIL_TEMPLATES_SUCCESS, FETCH_EMAIL_TEMPLATES_FAILURE,
    FETCH_PIPELINE_TEMPLATES, FETCH_PIPELINE_TEMPLATES_FAILURE, FETCH_PIPELINE_TEMPLATES_SUCCESS,
} from '../actions/TemplateDropdownSelectorActions';
import Translations from '../helpers/Translations';
import {PROCESS_PIPELINE_TEMPLATE_FOR_JOB_SUCCESS, UPDATE_AUTOANSWER_DATA_SUCCESS, UPDATE_QUESTIONNAIRE_DATA} from '../actions/JobDetailActions';
import {CREATE_JOB_SUCCESS} from '../actions/CreateJobActions';
import {CHANGE_CANDIDATES_STAGE_SUCCESS, COPY_CANDIDATES_INTO_TALENT_POOL_SUCCESS, COPY_CANDIDATES_SUCCESS, MOVE_CANDIDATES_SUCCESS, REFUSE_CANDIDATE_AND_SEND_EMAIL_SUCCESS, SCHEDULE_CANDIDATE_EVENT_SUCCESS, SEND_CANDIDATES_QUESTIONNAIRE_REQUEST_SUCCESS, SEND_EMAIL_SUCCESS} from '../actions/ActionWithCandidateActions';

export const isFetchingTemplatesForDropdownSelector = (state = false, action) => {
    switch (action.type) {
        case FETCH_QUESTIONNAIRE_TEMPLATES:
        case FETCH_AUTO_ANSWER_TEMPLATES:
        case FETCH_EMAIL_TEMPLATES:
        case FETCH_PIPELINE_TEMPLATES:
            return true;

        case FETCH_QUESTIONNAIRE_TEMPLATES_SUCCESS:
        case FETCH_AUTO_ANSWER_TEMPLATES_SUCCESS:
        case FETCH_QUESTIONNAIRE_TEMPLATES_FAILURE:
        case FETCH_AUTO_ANSWER_TEMPLATES_FAILURE:
        case FETCH_EMAIL_TEMPLATES_SUCCESS:
        case FETCH_EMAIL_TEMPLATES_FAILURE:
        case FETCH_PIPELINE_TEMPLATES_SUCCESS:
        case FETCH_PIPELINE_TEMPLATES_FAILURE:
            return false;

        default:
            return state;
    }
};

export const templatesForDropdownSelector = (state = {type: null, templates: []}, action) => {
    switch (action.type) {
        case FETCH_QUESTIONNAIRE_TEMPLATES:
        case FETCH_AUTO_ANSWER_TEMPLATES:
        case FETCH_EMAIL_TEMPLATES:
        case FETCH_PIPELINE_TEMPLATES:
            return {
                ...state,
                type: action.payload.type,
            };

        case FETCH_QUESTIONNAIRE_TEMPLATES_SUCCESS:
        case FETCH_AUTO_ANSWER_TEMPLATES_SUCCESS:
        case FETCH_EMAIL_TEMPLATES_SUCCESS:
        case FETCH_PIPELINE_TEMPLATES_SUCCESS:
            return {
                ...state,
                templates: action.payload.templates,
            };

        case PROCESS_PIPELINE_TEMPLATE_FOR_JOB_SUCCESS:
            if (action.payload.hasOwnProperty('pipelineTemplate') === true && action.payload.pipelineTemplate.isTemplate === true) {
                const templates = state.templates;

                templates.push({
                    value: action.payload.pipelineTemplate.id,
                    text: action.payload.pipelineTemplate.name,
                    description: action.payload.pipelineTemplate.isPrivate === true ? '' : Translations.getStatic('sharedTemplate'),
                });

                return {
                    ...state,
                    templates,
                };
            }

            return state;

        case FETCH_QUESTIONNAIRE_TEMPLATES_FAILURE:
        case FETCH_AUTO_ANSWER_TEMPLATES_FAILURE:
        case FETCH_EMAIL_TEMPLATES_FAILURE:
        case FETCH_PIPELINE_TEMPLATES_FAILURE:
            return {
                ...state,
                templates: [],
            };

        case CREATE_JOB_SUCCESS:
        case UPDATE_QUESTIONNAIRE_DATA:
        case UPDATE_AUTOANSWER_DATA_SUCCESS:
        case COPY_CANDIDATES_INTO_TALENT_POOL_SUCCESS:
        case COPY_CANDIDATES_SUCCESS:
        case SEND_EMAIL_SUCCESS:
        case CHANGE_CANDIDATES_STAGE_SUCCESS:
        case SCHEDULE_CANDIDATE_EVENT_SUCCESS:
        case REFUSE_CANDIDATE_AND_SEND_EMAIL_SUCCESS:
        case SEND_CANDIDATES_QUESTIONNAIRE_REQUEST_SUCCESS:
        case MOVE_CANDIDATES_SUCCESS:
            return {
                ...state,
                type: null,
                templates: undefined,
            };

        default:
            return state;
    }
};
