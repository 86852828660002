import React from 'react';
import PropTypes from 'prop-types';
import MarkModal from '../Modal/MarkModal';
import {closeModal} from '../../actions/ModalActions';
import {connect} from 'react-redux';
import Translations from '../../helpers/Translations';
import Grid from '../Grid';

const PipelineTemplatePreviewModal = props => {
    const {isOpen, pipelineTemplate} = props;

    return (
        <MarkModal
            isOpen={isOpen}
            closeModal={props.closeModal}
            title={Translations.getStatic('pipelineTemplate')}
            actionButton={{visible: false}}

            content={
                <Grid>
                    <Grid.Column className="padding-top_none padding-left_none padding-right_none">
                        {
                            pipelineTemplate.pipeline.stages.map(stage => (
                                <Grid>
                                    <Grid.Row columns={1}>
                                        <Grid.Column width={16} verticalAlign="middle">
                                            <div className={'segment  stage' + (stage.isFixedOnPipeline === false ? ' newStage' : '')}>
                                                <div className="vertical-align">
                                                    <h4 className="txt-regular">{Translations.getStage(stage)}</h4>
                                                </div>
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            ))
                        }
                    </Grid.Column>
                </Grid>
            }
        />
    );
};

PipelineTemplatePreviewModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    isOpen: state.modalLogic.isOpen,
});

const mapDispatchToProps = {
    closeModal,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PipelineTemplatePreviewModal);




