import React, {Component} from 'react';
import {connect} from 'react-redux';
import DashboardCandidatesInOffersNoModal from '../Modals/DashboardCandidatesInOffersNoModal';
import {closeNoModalDashboard} from '../../actions/ModalActions';
import GoogleAnalytics from '../../helpers/GoogleAnalytics';

class NoModalDashboardManager extends Component
{
    state = {
        openingNoModalDashboardType: null,
        openingNoModalDashboardProps: null,
    };

    componentDidUpdate(prevProps) {
        if (prevProps.currentNoModalDashboard.noModalDashboardIsOpen === false && this.props.currentNoModalDashboard.noModalDashboardIsOpen === true) {
            this.setState({
                openingNoModalDashboardType: this.props.currentNoModalDashboard.noModalDashboardType,
                openingNoModalDashboardProps: this.props.currentNoModalDashboard.noModalDashboardProps,
            });

            GoogleAnalytics.sendModalView(this.props.currentNoModalDashboard.noModalDashboardType);
        } else if (prevProps.currentNoModalDashboard.noModalDashboardIsOpen === true && this.props.currentNoModalDashboard.noModalDashboardIsOpen === false) {
            const closeWindowAnimationTime = 500;

            setTimeout(() => {
                this.setState({
                    openingNoModalDashboardType: null,
                    openingNoModalDashboardProps: null,
                });
            }, closeWindowAnimationTime);
        }
    }

    render() {
        const {openingNoModalDashboardType, openingNoModalDashboardProps} = this.state;

        const modalLookup = {
            DashboardCandidatesInOffersNoModal,
        };

        if (openingNoModalDashboardType !== null) {
            const ModalComponent = modalLookup[openingNoModalDashboardType];

            return <ModalComponent {...openingNoModalDashboardProps}/>;
        }

        return null;
    }
}

const mapStateToProps = state => ({
    currentNoModalDashboard: state.noModalDashboardLogic,
});

const mapDispatchToProps = {
    closeNoModalDashboard,
};

export default connect(mapStateToProps, mapDispatchToProps)(NoModalDashboardManager);
