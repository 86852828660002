import React from 'react';
import Checkbox from '@profesia/adamui/components/common/input/checkbox/Checkbox';
import Grid from '../Grid';
import Item from '../Item';
import PropTypes from 'prop-types';
import Translations from '../../helpers/Translations';
import File from '../../helpers/File';

const CandidateEmailAttachmentColumn = ({attachment, removeAttachmentFromEmail, addAttachmentToEmail, attachmentToggleChecked}) => {
    const handleChange = checked => {
        attachmentToggleChecked(attachment.attachmentId, checked);

        if (checked === false) {
            removeAttachmentFromEmail();
        } else {
            addAttachmentToEmail();
        }
    };

    const size = attachment.size === 0 ? '' : <span className="file-size">&middot; {File.getReadableFileSize(attachment.size)}</span>;

    return (
        <Item className="data-block__content attachment basic">
            <Grid verticalAlign="middle" container>
                <Grid.Row>
                    <Grid.Column computer={12} tablet={11}>
                        <Item.Header as="h5" className="ellipsis">
                            <a href={attachment.url} target="_blank">
                                {attachment.filename}{size}
                            </a>
                        </Item.Header>
                    </Grid.Column>
                    <Grid.Column computer={4} textAlign="right">
                        {
                            attachmentToggleChecked === undefined
                                ? <a target="_blank" href={attachment.downloadUrl} className="link secondary-link">{Translations.getStatic('download')}</a>
                                : (
                                    <Checkbox
                                        className="checkbox-align"
                                        onChange={(e) => handleChange(e.target.checked)}
                                        label={Translations.getStatic('send')}
                                        checked={attachment.checked}
                                    />
                                )
                        }
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Item>
    );
};

CandidateEmailAttachmentColumn.propTypes = {
    attachment: PropTypes.object.isRequired,
    removeAttachmentFromEmail: PropTypes.func,
    addAttachmentToEmail: PropTypes.func,
    attachmentToggleChecked: PropTypes.func,
};

export default CandidateEmailAttachmentColumn;
