export const REQUEST_PIPELINE_TEMPLATE_EDITOR_MODEL = 'REQUEST_PIPELINE_TEMPLATE_EDITOR_MODEL';
export const RECEIVE_PIPELINE_TEMPLATE_EDITOR_MODEL = 'RECEIVE_PIPELINE_TEMPLATE_EDITOR_MODEL';
export const UPDATE_PIPELINE_TEMPLATE               = 'UPDATE_PIPELINE_TEMPLATE';
export const SET_PIPELINE_STAGES                    = 'SET_PIPELINE_STAGES';
export const ENABLE_SWITCHABLE_FIXED_STAGE          = 'ENABLE_SWITCHABLE_FIXED_STAGE';
export const DISABLE_SWITCHABLE_FIXED_STAGE         = 'DISABLE_SWITCHABLE_FIXED_STAGE';

export const requestPipelineTemplateEditorModel = (pipelineId = null, copy = false) => ({
    type: REQUEST_PIPELINE_TEMPLATE_EDITOR_MODEL,
    meta: {
        requestRoute: 'v1/pipeline-template/model/editor/' + (pipelineId === null ? 0 : pipelineId),
        requestData: {
            copy: copy ? 1 : 0,
        },

        onRequestSuccess: RECEIVE_PIPELINE_TEMPLATE_EDITOR_MODEL,
    },
});

export const updatePipelineTemplate = (pipelineTemplateData) => ({
    type: UPDATE_PIPELINE_TEMPLATE,
    payload: {
        pipelineTemplateData,
    },
});

export const setPipelineStages = (stages) => ({
    type: SET_PIPELINE_STAGES,
    payload: {
        stages,
    },
});

export const enableSwitchableFixedStage = (stage) => ({
    type: ENABLE_SWITCHABLE_FIXED_STAGE,
    payload: {
        stage,
    },
});

export const disableSwitchableFixedStage = (stage) => ({
    type: DISABLE_SWITCHABLE_FIXED_STAGE,
    payload: {
        stage,
    },
});
