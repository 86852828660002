import Language from '../helpers/Language';

export const SAVE_USER_OUT_OF_OFFICE_EMAIL           = 'SAVE_USER_OUT_OF_OFFICE_EMAIL';
export const SAVE_USER_OUT_OF_OFFICE_EMAIL_SUCCESS   = 'SAVE_USER_OUT_OF_OFFICE_EMAIL_SUCCESS';
export const SAVE_USER_OUT_OF_OFFICE_EMAIL_FAILURE   = 'SAVE_USER_OUT_OF_OFFICE_EMAIL_FAILURE';
export const DELETE_USER_OUT_OF_OFFICE_EMAIL         = 'DELETE_USER_OUT_OF_OFFICE_EMAIL';
export const DELETE_USER_OUT_OF_OFFICE_EMAIL_SUCCESS = 'DELETE_USER_OUT_OF_OFFICE_EMAIL_SUCCESS';
export const DELETE_USER_OUT_OF_OFFICE_EMAIL_FAILURE = 'DELETE_USER_OUT_OF_OFFICE_EMAIL_FAILURE';
export const REQUEST_OUT_OF_OFFICE_EMAIL_MODEL       = 'REQUEST_OUT_OF_OFFICE_EMAIL_MODEL';
export const RECEIVE_OUT_OF_OFFICE_EMAIL_MODEL       = 'RECEIVE_OUT_OF_OFFICE_EMAIL_MODEL';
export const SET_OUT_OF_OFFICE_EMAIL_PARAM           = 'SET_OUT_OF_OFFICE_EMAIL_PARAM';

export const requestOutOfOfficeEmailModel = (id = null, senderId = null, bcc = [], language = Language.get()) => ({
    type: REQUEST_OUT_OF_OFFICE_EMAIL_MODEL,

    meta: {
        requestRoute: 'v1/out-of-office-email/model/' + (id === null ? 0 : id),
        requestData: {
            language,
        },

        onRequestSuccess: RECEIVE_OUT_OF_OFFICE_EMAIL_MODEL,
    },

    payload: {
        bcc,
        senderId,
    },
});

export const saveUserOutOfOfficeEmail = (id, formData) => ({
    type: SAVE_USER_OUT_OF_OFFICE_EMAIL,

    payload: {
        id,
    },

    meta: {
        requestRoute: 'v1/out-of-office-email/action/save',
        requestMethod: 'POST',

        requestBody: formData,

        onRequestSuccess: SAVE_USER_OUT_OF_OFFICE_EMAIL_SUCCESS,
        onRequestFailure: SAVE_USER_OUT_OF_OFFICE_EMAIL_FAILURE,
    },
});

export const setOutOfOfficeEmailParam = param => ({
    type: SET_OUT_OF_OFFICE_EMAIL_PARAM,

    payload: {
        param,
    },
});

export const deleteUserOutOfOfficeEmail = id => ({
    type: DELETE_USER_OUT_OF_OFFICE_EMAIL,

    payload: {
        id,
    },

    meta: {
        requestRoute: 'v1/out-of-office-email/action/delete/' + id,
        requestMethod: 'POST',

        onRequestSuccess: DELETE_USER_OUT_OF_OFFICE_EMAIL_SUCCESS,
        onRequestFailure: DELETE_USER_OUT_OF_OFFICE_EMAIL_FAILURE,
    },
});
