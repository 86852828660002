export const OPEN_SIDEBAR_MENU    = 'OPEN_SIDEBAR_MENU';
export const CLOSE_SIDEBAR_MENU   = 'CLOSE_SIDEBAR_MENU';
export const OPEN_SIDEBAR_SEARCH  = 'OPEN_SIDEBAR_SEARCH';
export const CLOSE_SIDEBAR_SEARCH = 'CLOSE_SIDEBAR_SEARCH';

export const openSidebarMenu = () => ({
    type: OPEN_SIDEBAR_MENU,
});

export const closeSidebarMenu = () => ({
    type: CLOSE_SIDEBAR_MENU,
});

export const openSidebarSearch = () => ({
    type: OPEN_SIDEBAR_SEARCH,
});

export const closeSidebarSearch = () => ({
    type: CLOSE_SIDEBAR_SEARCH,
});
