import React from 'react';
import {connect} from 'react-redux';
import Select from '@profesia/adamui/components/common/dropdown/SelectDefault'
import Form from '../Form';
import Grid from '../Grid';
import Item from '../Item';
import Divider from '../Divider';
import Button from '../Button';
import LoadingComponent from '../LoadingComponent';
import CandidateAvatar from '../CandidateAvatar';
import {requestGoogleCalendarData} from '../../actions/CalendarActions';
import Translations from '../../helpers/Translations';

class SettingsPageCalendarSettingsGoogleCalendar extends React.Component
{
    componentDidMount = () => {
    };

    openGoogleLogin = () => {
        window.open(this.props.google.authUrl, 'googleLogin', 'width=640,height=480');
    };

    googleLoginClosed = () => {
        this.props.requestGoogleCalendarData();
    };

    googleClendarChanged = (value) => {
        this.props.googleCalendarChanged(value);
    };

    render = () => {
        const {google, isSettingCalendar, isSynchronizingCalendar} = this.props;

        if (isSettingCalendar === true) {
            return <LoadingComponent/>;
        }

        if (isSynchronizingCalendar === true) {
            return <LoadingComponent/>;
        }

        let selectedCalendar = null;

        if (google.isLoggedInGoogle === true) {
            selectedCalendar = this.props.calendarId === null ? google.calendars.find(calendar => calendar.hasOwnProperty('selected') === true && calendar.selected === true) : this.props;

            if (selectedCalendar === undefined) {
                selectedCalendar = null;
            } else {
                selectedCalendar = selectedCalendar.calendarId;
            }
        }

        return (
            <Form>
                {
                    google.isLoggedInGoogle === true
                        ? (
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <span className="text-form__label">{Translations.getStatic('connectedToGAccount')}</span>
                                        <Divider hidden className="size_4"/>
                                        <div className='ui items divided'>
                                            <Item>
                                                <Item.Content as="h5" verticalAlign="middle">
                                                    <CandidateAvatar
                                                        firstname={google.user.name.split(' ')[0]}
                                                        surname={google.user.name.split(' ').length > 0 ? google.user.name.split(' ')[1] : ''}
                                                        profilePhoto={google.user.picture}
                                                    />
                                                    {google.user.name}
                                                </Item.Content>
                                            </Item>
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                                <Divider hidden className="size_24"/>
                                <Grid.Row>
                                    <Grid.Column computer={8} tablet={16}>
                                        {
                                            google.isLoggedInGoogle === true &&
                                            (
                                                <Grid.Row>
                                                    <Grid.Column>
                                                        <Form.Field>
                                                            <Select
                                                                variant='dark'
                                                                value={selectedCalendar}
                                                                label={Translations.getStatic('targetGCal')}
                                                                options={
                                                                    google.calendars.map(calendar => ({
                                                                        key: calendar.calendarId,
                                                                        label: calendar.calendarName,
                                                                        id: calendar.calendarId,
                                                                    }))
                                                                }
                                                                onChange={this.googleClendarChanged}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            )
                                        }
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        )
                        : (
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Button className="primary-button" onClick={this.openGoogleLogin}>{Translations.getStatic('logInToGoogle')}</Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        )
                }
                <Button hidden id="refresh-google-calendar-data" content="refresh-google-calendar-data" onClick={this.googleLoginClosed}/>
            </Form>
        );
    };
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
    requestGoogleCalendarData,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SettingsPageCalendarSettingsGoogleCalendar);
