import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {
    disableSwitchableFixedStage, enableSwitchableFixedStage,
    requestPipelineTemplateEditorModel, setPipelineStages,
    updatePipelineTemplate,
} from '../../actions/PipelineTemplateEditorActions';
import {
    saveAsNewPipelineTemplate,
    saveAsChangedPipelineTemplate,
} from '../../actions/TemplateAssistantActions';
import TemplateAssistantTemplateHeader from '../TemplateAssistant/TemplateAssistantTemplateHeader';
import PipelineTemplateEditor from '../PipelineTemplate/Editor/PipelineTemplateEditor';
import Form from '../Form';
import Grid from '../Grid';
import Heading from '../Heading';
import Divider from '../Divider';
import LoadingComponent from '../LoadingComponent';
import Translations from '../../helpers/Translations';
import Constants from '../../helpers/Constants';
import UserRights from '../../helpers/UserRights';

class SettingsPipelineTemplateEditor extends React.Component
{
    componentDidMount = () => {
        this.props.requestPipelineTemplateEditorModel(this.props.pipelineTemplateId, this.props.isCopy);
    };

    componentDidUpdate = prevProps => {
        if (this.props.pipelineTemplateEditorModel.hasOwnProperty('pipelineTemplateData') === true && (
            prevProps.pipelineTemplateEditorModel.hasOwnProperty('pipelineTemplateData') === false
            || prevProps.pipelineTemplateEditorModel.pipelineTemplateData.id !== this.props.pipelineTemplateEditorModel.pipelineTemplateData.id
            || this.props.isCopy === true
        )) {
            if (this.props.pipelineTemplateEditorModel.pipelineTemplateData.id !== null && this.props.isCopy === false) {
                this.props.saveAsChangedPipelineTemplate(true);
            } else {
                this.props.saveAsNewPipelineTemplate(true);
            }
        }
    };

    render = () => {
        if (this.props.isFetchingPipelineTemplateEditorModel === true || this.props.pipelineTemplateEditorModel.hasOwnProperty('pipelineTemplateData') === false) {
            return <LoadingComponent/>;
        }

        const {pipelineTemplateEditorModel: {pipelineTemplateData}, errors} = this.props;

        return (
            <>
                {
                    UserRights.hasAllowedMultiPipelines(this.props.loginManagerModel.activePackage) === true
                    && (
                        <>
                            <Form>
                                <Heading as='h3'>{Translations.getStatic('pipelineTemplate')}</Heading>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <TemplateAssistantTemplateHeader
                                                errors={errors}
                                                templateData={pipelineTemplateData}
                                                templateType={Constants.TEMPLATE_TYPE_PIPELINE}
                                                onUpdateTemplate={this.props.updatePipelineTemplate}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Form>
                            < Divider/>
                        </>
                    )
                }

                <Form>
                    <PipelineTemplateEditor
                        pipelineTemplateEditorModel={this.props.pipelineTemplateEditorModel}
                        disableSwitchableFixedStage={this.props.disableSwitchableFixedStage}
                        enableSwitchableFixedStage={this.props.enableSwitchableFixedStage}
                        setPipelineStages={this.props.setPipelineStages}
                        loginManagerModel={this.props.loginManagerModel}
                        displaySize={this.props.displaySize}
                    />
                </Form>
            </>
        );
    };
}

SettingsPipelineTemplateEditor.propTypes = {
    pipelineTemplateId: PropTypes.number.isRequired,
    isCopy: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    loginManagerModel: state.loginManagerModel,
    pipelineTemplateEditorModel: state.pipelineTemplateEditorModel,
    isFetchingPipelineTemplateEditorModel: state.isFetchingPipelineTemplateEditorModel,
    displaySize: state.displaySize,
});

const mapDispatchToProps = {
    requestPipelineTemplateEditorModel,
    updatePipelineTemplate,
    saveAsNewPipelineTemplate,
    saveAsChangedPipelineTemplate,
    disableSwitchableFixedStage,
    enableSwitchableFixedStage,
    setPipelineStages,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SettingsPipelineTemplateEditor);
