export const REQUEST_AUTO_ANSWERS_SETTINGS_MODEL  = 'REQUEST_AUTO_ANSWERS_SETTINGS_MODEL';
export const RECEIVE_AUTO_ANSWERS_SETTINGS_MODEL  = 'RECEIVE_AUTO_ANSWERS_SETTINGS_MODEL';
export const PROCESS_AUTO_ANSWER                  = 'PROCESS_AUTO_ANSWER';
export const PROCESS_AUTO_ANSWER_SUCCESS          = 'PROCESS_AUTO_ANSWER_SUCCESS';
export const PROCESS_AUTO_ANSWER_FAILURE          = 'PROCESS_AUTO_ANSWER_FAILURE';
export const REMOVE_AUTO_ANSWER                   = 'REMOVE_AUTO_ANSWER';
export const REMOVE_AUTO_ANSWER_SUCCESS           = 'REMOVE_AUTO_ANSWER_SUCCESS';
export const REMOVE_AUTO_ANSWER_FAILURE           = 'REMOVE_AUTO_ANSWER_FAILURE';
export const SET_AUTO_ANSWER_AS_DEFAULT           = 'SET_AUTO_ANSWER_AS_DEFAULT';
export const SET_AUTO_ANSWER_AS_DEFAULT_SUCCESS   = 'SET_AUTO_ANSWER_AS_DEFAULT_SUCCESS';
export const SET_AUTO_ANSWER_AS_DEFAULT_FAILURE   = 'SET_AUTO_ANSWER_AS_DEFAULT_FAILURE';
export const UNSET_AUTO_ANSWER_AS_DEFAULT         = 'UNSET_AUTO_ANSWER_AS_DEFAULT';
export const UNSET_AUTO_ANSWER_AS_DEFAULT_SUCCESS = 'UNSET_AUTO_ANSWER_AS_DEFAULT_SUCCESS';
export const UNSET_AUTO_ANSWER_AS_DEFAULT_FAILURE = 'UNSET_AUTO_ANSWER_AS_DEFAULT_FAILURE';

export const requestAutoAnswersSettingsModel = () => ({
    type: REQUEST_AUTO_ANSWERS_SETTINGS_MODEL,

    meta: {
        requestRoute: 'v1/auto-answer/model/list',
        onRequestSuccess: RECEIVE_AUTO_ANSWERS_SETTINGS_MODEL,
    },
});

export const processAutoAnswer = (formData, id, savingMode) => ({
    type: PROCESS_AUTO_ANSWER,

    payload: {
        id: id,
        savingMode: savingMode,
    },

    meta: {
        requestRoute: 'v1/auto-answer/action/process',
        requestMethod: 'POST',
        requestBody: formData,

        onRequestSuccess: PROCESS_AUTO_ANSWER_SUCCESS,
        onRequestFailure: PROCESS_AUTO_ANSWER_FAILURE,
    },
});

export const removeAutoAnswer = id => ({
    type: REMOVE_AUTO_ANSWER,

    payload: {
        id: id,
    },

    meta: {
        requestRoute: 'v1/auto-answer/action/archive/' + id,
        requestMethod: 'POST',

        onRequestSuccess: REMOVE_AUTO_ANSWER_SUCCESS,
        onRequestFailure: REMOVE_AUTO_ANSWER_FAILURE,
    },
});

export const setAutoAnswerAsDefault = autoAnswerId => ({
    type: SET_AUTO_ANSWER_AS_DEFAULT,

    payload: {
        autoAnswerId,
    },

    meta: {
        requestRoute: 'v1/auto-answer/action/set-as-default/' + autoAnswerId,
        requestMethod: 'POST',

        onRequestSuccess: SET_AUTO_ANSWER_AS_DEFAULT_SUCCESS,
        onRequestFailure: SET_AUTO_ANSWER_AS_DEFAULT_FAILURE,
    },
});

export const unsetAutoAnswerAsDefault = autoAnswerId => ({
    type: UNSET_AUTO_ANSWER_AS_DEFAULT,

    payload: {
        autoAnswerId,
    },

    meta: {
        requestRoute: 'v1/auto-answer/action/unset-as-default/' + autoAnswerId,
        requestMethod: 'POST',

        onRequestSuccess: UNSET_AUTO_ANSWER_AS_DEFAULT_SUCCESS,
        onRequestFailure: UNSET_AUTO_ANSWER_AS_DEFAULT_FAILURE,
    },
});
