export const REQUEST_SUBSCRIPTIONS_MODEL     = 'REQUEST_SUBSCRIPTIONS_MODEL';
export const RECEIVE_SUBSCRIPTIONS_MODEL     = 'RECEIVE_SUBSCRIPTIONS_MODEL';
export const CHANGE_SUBSCRIPTION             = 'CHANGE_SUBSCRIPTION';
export const CHANGE_SUBSCRIPTION_SUCCESS     = 'CHANGE_SUBSCRIPTION_SUCCESS';
export const CREATE_NEW_SUBSCRIPTION         = 'CREATE_NEW_SUBSCRIPTION';
export const CREATE_NEW_SUBSCRIPTION_SUCCESS = 'CREATE_NEW_SUBSCRIPTION_SUCCESS';

export const requestSubscriptionsModel = () => ({
    type: REQUEST_SUBSCRIPTIONS_MODEL,

    meta: {
        requestRoute: 'v1/admin/model/subscriptions',
        onRequestSuccess: RECEIVE_SUBSCRIPTIONS_MODEL,
    },
});

export const changeSubscription = (formData, subscriptionId, serviceId, validFrom, validTill, prolong) => ({
    type: CHANGE_SUBSCRIPTION,

    payload: {
        id: subscriptionId,
        serviceId,
        validFrom,
        validTill,
        prolong,
    },

    meta: {
        requestRoute: 'v1/admin/action/change-subscription',
        requestMethod: 'POST',
        requestBody: formData,

        onRequestSuccess: CHANGE_SUBSCRIPTION_SUCCESS,
    },
});

export const createNewSubscription = formData => ({
    type: CREATE_NEW_SUBSCRIPTION,

    meta: {
        requestRoute: 'v1/admin/action/create-new-subscription',
        requestMethod: 'POST',
        requestBody: formData,

        onRequestSuccess: CREATE_NEW_SUBSCRIPTION_SUCCESS,
    },
});
