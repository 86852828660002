import { GetOtherSkillsResponse } from '@profesia/mark-offer-form/services';
import Url from '../../../helpers/Url';

const remapSkills = (otherSkills: OptionItem[]) =>
    otherSkills.map(({ id, name }) => ({ id: id.toString(), value: name }));

type OptionItem = {
    id: number;
    name: string;
};

type Response = OptionItem[];

const getOtherSkills = async (otherSkill: string): Promise<GetOtherSkillsResponse> => {
    const response: Response = await fetch(
        `${Url.MARK_SERVER_PREFIX}/api/v1/codebook/skill?locale=sk&query=${otherSkill}`,
        {
            method: 'GET',
        }
    ).then((response) => response.json());

    return remapSkills(response);
};

export default getOtherSkills;
