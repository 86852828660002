export const FILTER_CANDIDATES_BY_NAME                      = 'FILTER_CANDIDATES_BY_NAME';
export const FILTER_CANDIDATES_BY_PHASE                     = 'FILTER_CANDIDATES_BY_PHASE';
export const FILTER_CANDIDATES_BY_STATE                     = 'FILTER_CANDIDATES_BY_STATE';
export const FILTER_CANDIDATES_BY_STAGE_COLLECTION          = 'FILTER_CANDIDATES_BY_STAGE_COLLECTION';
export const FILTER_CANDIDATES_BY_STATE_RESET               = 'FILTER_CANDIDATES_BY_STATE_RESET';
export const FILTER_CANDIDATES_BY_TAG_LIST                  = 'FILTER_CANDIDATES_BY_TAG_LIST';
export const FILTER_CANDIDATES_BY_SCORE_RANGE               = 'FILTER_CANDIDATES_BY_SCORE_RANGE';
export const FILTER_CANDIDATES_BY_SOURCE_TYPE_COLLECTION    = 'FILTER_CANDIDATES_BY_SOURCE_TYPE_COLLECTION';
export const FILTER_CANDIDATES_BY_REFUSED_AND_NOT_CONTACTED = 'FILTER_CANDIDATES_BY_REFUSED_AND_NOT_CONTACTED';
export const FILTER_CANDIDATES_BY_ANONYMIZED                = 'FILTER_CANDIDATES_BY_ANONYMIZED';
export const FILTER_CANDIDATES_BY_STATE_ACTIVE              = 'FILTER_CANDIDATES_BY_STATE_ACTIVE';
export const FILTER_CANDIDATES_BY_STATE_PASSIVE             = 'FILTER_CANDIDATES_BY_STATE_PASSIVE';
export const FILTER_CANDIDATES_BY_NOT_CONTACTED             = 'FILTER_CANDIDATES_BY_NOT_CONTACTED';
export const FILTER_CANDIDATES_BY_NO_GDPR_AGREEMENT         = 'FILTER_CANDIDATES_BY_NO_GDPR_AGREEMENT';
export const FILTER_CANDIDATES_BY_ADD_DATE                  = 'FILTER_CANDIDATES_BY_ADD_DATE';
export const FILTER_CANDIDATES_BY_CANDIDATE_IDS             = 'FILTER_CANDIDATES_BY_CANDIDATE_IDS';
export const FILTER_CANDIDATES_BY_NOTE                      = 'FILTER_CANDIDATES_BY_NOTE';
export const CANCEL_FILTER_BY_CANDIDATE_IDS                 = 'CANCEL_FILTER_BY_CANDIDATE_IDS';
export const COLLAPSING_FILTERS                             = 'COLLAPSING_FILTERS';
export const RESET_FILTER                                   = 'RESET_FILTER';
export const UPDATE_DEFAULT_FILTER                          = 'UPDATE_DEFAULT_FILTER';

export const filterCandidatesByName = (searchText, candidatesFilter = undefined) => ({
    type: FILTER_CANDIDATES_BY_NAME,

    payload: {
        searchText,
        candidatesFilter,
    },
});

export const filterCandidatesByPhaseId = (phaseId, candidatesFilter = undefined) => ({
    type: FILTER_CANDIDATES_BY_PHASE,

    payload: {
        phaseId,
        candidatesFilter,
    },
});

export const filterCandidatesByStateId = (stateId, candidatesFilter = undefined) => ({
    type: FILTER_CANDIDATES_BY_STATE,

    payload: {
        stateId,
        candidatesFilter,
    },
});

export const filterCandidatesByStageCollectionIds = (stageIds, candidatesFilter = undefined) => ({
    type: FILTER_CANDIDATES_BY_STAGE_COLLECTION,

    payload: {
        stageIds,
        candidatesFilter,
    },
});

export const filterCandidatesByStateResetRules = (stateId, stageIds, candidatesFilter = undefined) => ({
    type: FILTER_CANDIDATES_BY_STATE_RESET,

    payload: {
        stateId,
        stageIds,
        candidatesFilter,
    },
});

export const filterCandidatesByTagList = (tagList, candidatesFilter = undefined) => ({
    type: FILTER_CANDIDATES_BY_TAG_LIST,

    payload: {
        tagList,
        candidatesFilter,
    },
});

export const filterCandidatesByScoreRange = (scoreRange, candidatesFilter = undefined) => ({
    type: FILTER_CANDIDATES_BY_SCORE_RANGE,

    payload: {
        scoreRange,
        candidatesFilter,
    },
});

export const filterCandidatesBySourceTypeCollection = (sourceTypeCollection, candidatesFilter = undefined) => ({
    type: FILTER_CANDIDATES_BY_SOURCE_TYPE_COLLECTION,

    payload: {
        sourceTypeCollection,
        candidatesFilter,
    },
});

export const filterCandidatesByRefusedAndNotContacted = (isRefusedAndNotContacted, candidatesFilter = undefined) => ({
    type: FILTER_CANDIDATES_BY_REFUSED_AND_NOT_CONTACTED,

    payload: {
        isRefusedAndNotContacted,
        candidatesFilter,
    },
});

export const filterCandidatesByStateActive = (isStateActive, candidatesFilter = undefined) => ({
    type: FILTER_CANDIDATES_BY_STATE_ACTIVE,
    payload: {
        isStateActive,
        candidatesFilter,
    },
});

export const filterCandidatesByStatePassive = (isStatePassive, candidatesFilter = undefined) => ({
    type: FILTER_CANDIDATES_BY_STATE_PASSIVE,
    payload: {
        isStatePassive,
        candidatesFilter,
    },
});

export const filterCandidatesByAnonymized = (isAnonymized, candidatesFilter = undefined) => ({
    type: FILTER_CANDIDATES_BY_ANONYMIZED,

    payload: {
        isAnonymized,
        candidatesFilter,
    },
});

export const filterCandidatesByNotContacted = (isNotContacted, candidatesFilter = undefined) => ({
    type: FILTER_CANDIDATES_BY_NOT_CONTACTED,

    payload: {
        isNotContacted,
        candidatesFilter,
    },
});

export const filterCandidatesByNoGdprAgreement = (noGdprAgreement, candidatesFilter = undefined) => ({
    type: FILTER_CANDIDATES_BY_NO_GDPR_AGREEMENT,

    payload: {
        noGdprAgreement,
        candidatesFilter,
    },
});

export const filterCandidatesByAddDate = (dateFrom, dateTo, timespan, candidatesFilter = undefined) => ({
    type: FILTER_CANDIDATES_BY_ADD_DATE,

    payload: {
        addDate: {
            dateFrom,
            dateTo,
            timespan,
        },
        candidatesFilter,
    },
});

export const filterCandidatesByCandidateIds = (candidateIds, candidatesFilter = undefined) => ({
    type: FILTER_CANDIDATES_BY_CANDIDATE_IDS,

    payload: {
        candidateIds,
        candidatesFilter,
    },
});

export const filterCandidatesByNote = (note, candidatesFilter = undefined) => ({
    type: FILTER_CANDIDATES_BY_NOTE,

    payload: {
        note,
        candidatesFilter,
    },
});

export const cancelFilterByCandidateIds = () => ({
    type: CANCEL_FILTER_BY_CANDIDATE_IDS,
});

export const collapsingFilters = filters => ({
    type: COLLAPSING_FILTERS,

    payload: {
        collapsedFilters: filters,
    },
});

export const resetFilter = (defaultFilter, candidatesFilter = undefined) => ({
    type: RESET_FILTER,

    payload: {
        ...defaultFilter,
        candidatesFilter,
    },
});

export const updateDefaultFilter = (filterParams) => ({
    type: UPDATE_DEFAULT_FILTER,

    payload: {
        filterParams,
    },
});
