import Form from '../Form';
import Translations from '../Translations';
import PipelineValidatorHelper from './PipelineValidatorHelper';

export default class PipelineTemplateValidatorHelper
{
    constructor(pipelineTemplateData, pipelineStages) {
        this.pipelineTemplateData    = pipelineTemplateData;
        this.pipelineValidatorHelper = new PipelineValidatorHelper(pipelineStages);
    }

    checkPipelineTemplateTitle() {
        const fieldsToCheck = [
            {
                fieldName: 'templateTitle',
                fieldType: 'text',
                fieldValue: this.pipelineTemplateData.name,
            },
        ];

        return Form.multiCheck(fieldsToCheck);
    }

    checkUniquePipelineTemplateTitle(against) {
        const fieldsToCheck = [
            {
                fieldName: 'uniquePipelineTemplateTitle',
                fieldType: 'unique',
                fieldValue: this.pipelineTemplateData.name,
                against: against,
            },
        ];

        return Form.multiCheck(fieldsToCheck);
    }

    checkPipelineTemplate(withAlerts = true, checkTitle = true, against = []) {
        let errors = [];

        if (checkTitle === true) {
            errors = this.checkPipelineTemplateTitle();

            if (errors.length > 0) {
                return errors;
            }

            if (against.length > 0) {
                errors = this.checkUniquePipelineTemplateTitle(against);

                if (errors.length > 0) {
                    if (errors.indexOf('uniquePipelineTemplateTitle') > -1 && withAlerts === true) {
                        alert(Translations.getStatic('uniquePipelineTemplateNameError'));
                    }

                    return errors;
                }
            }
        }

        errors = this.pipelineValidatorHelper.checkPipeline(withAlerts);

        if (errors.length > 0) {
            return errors;
        }

        return [];
    }
}
