import React, {Component} from 'react';
import {connect} from 'react-redux';
import Form from '../Form';
import Grid from '../Grid';
import Divider from '../Divider';
import MarkModal from '../Modal/MarkModal';
import {closeModal, closeModalSecond} from '../../actions/ModalActions';
import {prepareEmailToPreview} from '../../actions/SendEmailActions';
import PropTypes from 'prop-types';
import Translations from '../../helpers/Translations';
import sanitizeHtml from 'sanitize-html';
import DetailEmailAttachmentColumn from '../AttachmentsAssistant/DetailEmailAttachmentColumn';
import FormSkeleton from '../../skeletons/components/FormSkeleton';

class DetailEmailModal extends Component
{
    componentDidMount() {
        if (this.props.isOpenFromHistory === false) {
            let formData = new FormData();

            formData.append('emailText', this.props.emailData.body);
            formData.append('emailSubject', this.props.emailData.subject);
            formData.append('emailLanguageId', this.props.emailData.languageId);
            formData.append('candidateId', this.props.emailData.candidateId);
            formData.append('useCompanyTemplate', 0);
            formData.append('senderId', this.props.emailData.senderId);

            if (this.props.emailData.hasOwnProperty('customEventDateTime') === true) {
                formData.append('customEventDateTime', this.props.emailData.customEventDateTime);
            }

            this.props.prepareEmailToPreview(formData);
        }
    }

    render() {
        const {
                  isOpenFromHistory,
                  emailData,
                  title,
                  isModalSecond,
                  isOpenModal,
                  isOpenModalSecond,
                  closeModal,
                  closeModalSecond,
                  isFetchingPreviewEmailModel,
                  previewEmailModel,
              } = this.props;

        const ColumnTo = () => {
            if (emailData.hasOwnProperty('to') === false) {
                return <Grid.Column computer={12} tablet={16}/>;
            }

            return (
                <Grid.Column computer={12} tablet={16}>
                    {
                        isModalSecond === true && emailData.to.length > 1
                            ? Translations.getStatic('recipientBulk')
                            : emailData.to.join(', ')
                    }
                </Grid.Column>
            );
        };

        return (
            <MarkModal
                isModalSecond={isModalSecond}
                isOpen={isModalSecond ? isOpenModalSecond : isOpenModal}
                closeModal={isModalSecond ? closeModalSecond : closeModal}
                title={title}
                actionButton={{visible: false}}
                isLoadingData={isFetchingPreviewEmailModel}

                content={
                    isFetchingPreviewEmailModel === true
                        ? <FormSkeleton/>
                        : (
                            <Form className={'detail-email'}>
                                <Grid>
                                    <Grid.Row className="horizontal-row">
                                        <Grid.Column computer={4} tablet={16}>
                                            <label>{Translations.getStatic('emailFrom')}</label>
                                        </Grid.Column>
                                        <Grid.Column computer={12} tablet={16}>
                                            {emailData.from}
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row className="horizontal-row">
                                        <Grid.Column computer={4} tablet={16}>
                                            <label>{Translations.getStatic('emailRecipients')}</label>
                                        </Grid.Column>

                                        <ColumnTo/>
                                    </Grid.Row>
                                    {
                                        emailData.hasOwnProperty('bcc') === true
                                        && emailData.bcc.length > 0
                                        && (
                                            <Grid.Row className="horizontal-row">
                                                <Grid.Column computer={4} tablet={16}>
                                                    <label>{Translations.getStatic('bcc')}</label>
                                                </Grid.Column>
                                                <Grid.Column computer={12} tablet={16}>
                                                    {emailData.bcc.join(', ')}
                                                </Grid.Column>
                                            </Grid.Row>
                                        )
                                    }
                                    <Grid.Row className="horizontal-row">
                                        <Grid.Column computer={4} tablet={16}>
                                            <label>{Translations.getStatic('emailSubject')}</label>
                                        </Grid.Column>
                                        <Grid.Column computer={12} tablet={16}>
                                            {
                                                sanitizeHtml(isOpenFromHistory === false ? previewEmailModel.emailSubject : emailData.subject, {
                                                    allowedTags: [],
                                                })
                                            }
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row className="horizontal-row width_auto-children">
                                        <Grid.Column width={16}>
                                            <label>{Translations.getStatic('emailContent')}</label>
                                            <div className="email-content" dangerouslySetInnerHTML={{__html: isOpenFromHistory === false ? previewEmailModel.emailText : emailData.body}}/>
                                        </Grid.Column>
                                    </Grid.Row>
                                    {
                                        emailData.hasOwnProperty('attachments') === true
                                        && emailData.attachments.length > 0
                                        && (
                                            <>
                                                <Divider/>
                                                <Grid.Row className="horizontal-row">
                                                    <Grid.Column className="list attachment-assistant">
                                                        <Grid className="data-block data-block_primary">
                                                            <Grid.Row className="padding-bottom_none">
                                                                <Grid.Column className="padding-left_none padding-right_none">
                                                                    <label>{Translations.getStatic('attachments')}</label>
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                            <Grid.Row className="padding-top_none">
                                                                <Grid.Column className="padding-left_none padding-right_none">
                                                                    <div className='ui items'>
                                                                        {
                                                                            emailData.attachments
                                                                                .filter(attachment => attachment.hasOwnProperty('removed') === false || attachment.removed === false)
                                                                                .map((attachment, i) => (
                                                                                    <DetailEmailAttachmentColumn
                                                                                        key={'candidateAttachment' + i}
                                                                                        attachment={attachment}
                                                                                    />
                                                                                ))
                                                                        }
                                                                    </div>
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        </Grid>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </>
                                        )
                                    }
                                </Grid>
                            </Form>
                        )
                }
            />
        );
    }
}

const mapStateToProps = state => ({
    isOpenModal: state.modalLogic.isOpen,
    isOpenModalSecond: state.modalSecondLogic.isOpen,
    previewEmailModel: state.previewEmailModel,
    isFetchingPreviewEmailModel: state.isFetchingPreviewEmailModel,
});

const mapDispatchToProps = {
    closeModal,
    closeModalSecond,
    prepareEmailToPreview,
};

DetailEmailModal.defaultProps = {
    isOpenFromHistory: false,

    emailData: {
        useCompanyTemplate: true,
    },
};

DetailEmailModal.propTypes = {
    isOpenFromHistory: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    candidateId: PropTypes.number,
    emailData: PropTypes.shape({
        emailTemplate: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
        }),

        from: PropTypes.string.isRequired,
        to: PropTypes.array.isRequired,
        bcc: PropTypes.array,
        subject: PropTypes.string.isRequired,
        body: PropTypes.string.isRequired,
        attachments: PropTypes.array,
        useCompanyTemplate: PropTypes.bool,
    }),
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailEmailModal);
