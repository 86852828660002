import GoogleAnalytics from './GoogleAnalytics';
import Hotjar from './Hotjar';
import Url from './Url';

class Analytics
{
    static sendEvent(eventAction: string, eventLabel: string = 'click'): void {
        GoogleAnalytics.sendEvent(eventAction, eventLabel);
        Hotjar.sendEvent(this.getEventCategory(), eventAction);
    }

    static getEventCategory(): string {
        return Url.isPage('dashboard', true) ? 'New Company Account' : 'Mark';
    }
}

export default Analytics;
