import React from 'react';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import Icon from './Icon';
import MarkTimePicker from './MarkTimePicker';
import Language from '../helpers/Language';
import DateFormatted from '../helpers/DateFormatted';
import Screen from '../helpers/Screen';

export class DatetimePicker extends React.Component
{
    state = {
        locale: this.props.locale,
        timeEnabled: this.props.timeEnabled,
        dateEnabled: this.props.dateEnabled,
        name: this.props.name,
        dateInputNameSuffix: this.props.timeEnabled === true ? 'Date' : '',
        timeInputNameSuffix: 'Time',
        dateClassName: this.props.dateClassName,
        timeClassName: this.props.className,
        allowDatesInPast: this.props.allowDatesInPast,
        allowDatesAfter: this.props.allowDatesAfter,
        placeholder: this.props.placeholder,
    };

    shouldComponentUpdate(nextProps, nextState) {
        return (this.state.date !== nextState.date)
            || (this.state.time !== nextState.time)
            || (this.props.defaultValue !== nextProps.defaultValue)
            || (this.props.error !== nextProps.error);
    };

    componentDidMount() {
        this.setState({
            date: DateFormatted.getDate(this.props.defaultValue, 'Y-m-d'),
            time: DateFormatted.getTime(this.props.defaultValue, 'H:i'),
        });
    };

    componentDidUpdate() {
        if (this.props.hasOwnProperty('allowDatesAfter') === true && DateFormatted.getDate(this.props.allowDatesAfter, 'Y-m-d') !== DateFormatted.getDate(this.state.allowDatesAfter, 'Y-m-d')) {
            const allowDatesAfter = this.props.hasOwnProperty('allowDatesAfter') === true ? DateFormatted.getDate(this.props.allowDatesAfter, 'Y-m-d') : null;

            this.setState({
                allowDatesAfter,
                date: DateFormatted.getTimestamp(this.state.date) < DateFormatted.getTimestamp(allowDatesAfter) ? DateFormatted.getDate(allowDatesAfter, 'Y-m-d') : DateFormatted.getDate(this.state.date, 'Y-m-d'),
            });
        }

        this.setState({
            date: DateFormatted.getDate(this.props.defaultValue, 'Y-m-d'),
            time: DateFormatted.getTime(this.props.defaultValue, 'H:i'),
        });
    };

    handleDateChange = date => {
        if (typeof date === 'object') {
            date = DateFormatted.getDate(date.format('Y-MM-DD'), 'Y-m-d');

            if (DateFormatted.isValidFormat(date, 'Y-m-d') === true) {
                if (this.props.onChange !== null) {
                    this.props.onChange(date);
                }

                this.setState({
                    date,
                });

                return;
            }
        }

        if (this.props.onChange !== null) {
            this.props.onChange(null);
        }
    };

    handleTimeChange = time => {
        this.setState({
            time,
        });

        if (this.props.onTimeChange !== null) {
            this.props.onTimeChange(time);
        }
    };

    handleDirection = () => {
        const renderAbove = Screen.getElementDistanceFromBottom(document.getElementById('eventDate')) < 200;

        const eventDateElement = document.getElementById('eventDate');

        if (eventDateElement !== null) {
            if (renderAbove === true) {
                eventDateElement.parentElement.classList.add('upward');
            } else if (eventDateElement.parentElement.classList.contains('upward') === true) {
                eventDateElement.parentElement.classList.remove('upward');
            }
        }
    };

    renderDay(props, currentDate) {
        const isMonthBefore = props.className.includes('rdtOld') === true;
        const isMonthAfter  = props.className.includes('rdtNew') === true;

        if (isMonthBefore === true) {
            if (DateFormatted.isInLastIncompleteWeekOfMonth(props['data-value'], parseInt(props['data-month']), props['data-year']) === false) {
                props['className'] = [props['className'], 'rdtInvisible'].join(' ');
            }
        } else if (isMonthAfter === true) {
            if (DateFormatted.isInFirstIncompleteWeekOfMonth(props['data-value'], parseInt(props['data-month']), props['data-year']) === false) {
                props['className'] = [props['className'], 'rdtInvisible'].join(' ');
            }
        }

        return <td {...props}>{currentDate.date()}</td>;
    }

    render() {
        let allowedDate = DateFormatted.getTimestamp() - 1000 * 60 * 60 * 24;

        if (this.state.allowDatesInPast === true) {
            allowedDate = 0;
        }

        if (this.state.allowDatesAfter !== null) {
            allowedDate = DateFormatted.getTimestamp(this.state.allowDatesAfter) - 1000 * 60 * 60 * 24;
        }

        return (
            <>
                {
                    this.state.dateEnabled === true
                    && (
                        <>
                            <div className={'datepicker' + (this.props.error === false ? '' : ' error')}>
                                <Datetime
                                    renderDay={this.renderDay}
                                    autoComplete={'off'}
                                    className={'ui input datepicker-input ' + this.props.direction}
                                    value={this.state.date === null ? null : new Date(this.state.date)}
                                    timeFormat={false}
                                    dateFormat="D.M.Y"
                                    isValidDate={current => current.unix() * 1000 > allowedDate}
                                    locale={this.state.locale}
                                    inputProps={{
                                        id: this.state.name + this.state.dateInputNameSuffix,
                                        name: this.state.name + this.state.dateInputNameSuffix,
                                        placeholder: this.state.placeholder,
                                        className: this.state.dateClassName,
                                    }}
                                    closeOnSelect={true}
                                    onChange={this.handleDateChange}
                                    onOpen={this.handleDirection}
                                />

                                <div className="datepicker-icon">
                                    <div role="link" onClick={() => document.getElementById(this.state.name + this.state.dateInputNameSuffix).focus()}>
                                        <Icon className="icon-calendar_small mini"/>
                                    </div>
                                </div>
                            </div>

                            {
                                this.props.error === false
                                || <div className="prompt label">{this.props.error}</div>
                            }
                        </>
                    )
                }

                {
                    this.state.timeEnabled === true
                    && (
                        <MarkTimePicker
                            align={this.props.align}
                            name={this.state.name + this.state.timeInputNameSuffix}
                            defaultValue={this.state.time}
                            onChange={this.handleTimeChange}
                        />
                    )
                }
            </>
        );
    };
}

DatetimePicker.propTypes = {
    timeEnabled: PropTypes.bool,
    dateEnabled: PropTypes.bool,
    defaultValue: PropTypes.string,
    allowDatesInPast: PropTypes.bool,
    allowDatesAfter: PropTypes.string,
    dateClassName: PropTypes.string,
    className: PropTypes.string,
    direction: PropTypes.string,
    locale: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    onTimeChange: PropTypes.func,
};

DatetimePicker.defaultProps = {
    timeEnabled: true,
    dateEnabled: true,
    defaultValue: null,
    allowDatesInPast: false,
    allowDatesAfter: null,
    dateClassName: 'form-control',
    className: 'form-control',
    direction: 'right',
    locale: Language.get(),
    name: 'contactMe',
    placeholder: 'DD.MM.RRRR',
    align: 'right',
    error: false,
    onChange: null,
    onTimeChange: null,
};

export default DatetimePicker;
