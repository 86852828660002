import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Dropdown} from 'semantic-ui-react';
import MarkForm from '../Form';
import {InputField} from '@profesia/adamui/components/common/input';
import Select from '@profesia/adamui/components/common/dropdown/SelectDefault';
import Checkbox from '@profesia/adamui/components/common/input/checkbox/Checkbox';
import Button from '../Button';
import Grid from '../Grid';
import Item from '../Item';
import Image from '../Image';
import Divider from '../Divider';
import Heading from '../Heading';
import MarkModal from '../Modal/MarkModal';
import MarkDialog from '../Modal/MarkDialog';
import {closeModal} from '../../actions/ModalActions';
import Translations from '../../helpers/Translations';
import Form from '../../helpers/Form';
import {
    requestJobModel,
    addExternalUser,
    removeExternalUser,
    changeExternalUserRole,
} from '../../actions/JobDetailActions';
import Url from '../../helpers/Url';
import Constants from '../../helpers/Constants';
import linkImage from '../../../img/icons/user-background.svg';
import FormSkeleton from '../../skeletons/components/FormSkeleton';

class InviteColleagueModal extends Component
{
    state = {
        dialogOpen: false,
        errors: [],
        externalUsers: this.props.jobModel.hasOwnProperty('settings') === true ? this.props.jobModel.settings.externalUsers : [],

        newColleagueEmail: {
            email: '',
            role: Constants.USER_ROLE_EXTERNAL_VISITOR,
        },

        dropdownActive: false,
    };

    componentDidMount = () => {
        if (this.props.jobModel.hasOwnProperty('settings') === false
            || this.props.jobModel.settings.hasOwnProperty('externalUsers') === false
            || this.props.jobId !== this.props.jobModel.job.id
        ) {
            this.props.requestJobModel(this.props.jobId);
        }
    };

    componentDidUpdate = prevProps => {
        if (this.props.jobModel.hasOwnProperty('settings') === true
            && prevProps.jobModel.hasOwnProperty('settings') === true
            && this.props.jobModel.settings.hasOwnProperty('externalUsers') === true
            && prevProps.jobModel.settings.hasOwnProperty('externalUsers') === true
            && this.props.jobModel.settings.externalUsers !== prevProps.jobModel.settings.externalUsers
        ) {
            this.setState({
                externalUsers: this.props.jobModel.hasOwnProperty('settings') === true ? this.props.jobModel.settings.externalUsers : [],
            });

            if (this.state.dialogOpen === true) {
                this.closeDialog();
            }
        }
    };

    openDialog = email => {
        this.setState({
            dialogOpen: true,
            prepareRemoveExternalUserEmail: email,
        });
    };

    closeDialog = () => {
        this.setState({
            dialogOpen: false,
        });
    };

    updateNewColleagueEmail = data => {
        this.setState({
            newColleagueEmail: {
                ...this.state.newColleagueEmail,
                ...data,
            },
        });
    };

    check = email => {
        return Form.check({
            fieldValue: email,
            fieldType: 'email',
        });
    };

    removeExternalUser = email => {
        if (this.state.errors.length === 0 && this.check(email) === true) {
            const formData = new FormData();

            formData.append('jobId', this.props.jobId);
            formData.append('externalUserEmail', email);

            this.props.removeExternalUser(formData);
        }
    };

    addExternalUser = () => {
        if (this.check(this.state.newColleagueEmail.email) === true) {
            const formData = new FormData();

            formData.append('jobId', this.props.jobId);
            formData.append('externalUserEmail', this.state.newColleagueEmail.email);
            formData.append('externalUserRole', this.state.newColleagueEmail.role);

            this.props.addExternalUser(formData);

            this.setState({
                newColleagueEmail: {
                    email: '',
                    role: Constants.USER_ROLE_EXTERNAL_VISITOR,
                },
            });
        }
    };

    toggleActiveClass = externalUserId => {
        this.setState({
            dropdownActive: this.state.dropdownActive === externalUserId ? false : externalUserId,
        });
    };

    changeExternalUserRole = (externalUserId, externalUserRole) => {
        const formData = new FormData();

        formData.append('jobId', this.props.jobId);
        formData.append('externalUserId', externalUserId);
        formData.append('externalUserRole', externalUserRole);

        this.props.changeExternalUserRole(formData);
    };

    render() {
        const {isOpen}                                   = this.props;
        const {errors, externalUsers, newColleagueEmail} = this.state;

        const rightsOptions = [
            {
                key: Constants.USER_ROLE_EXTERNAL_VISITOR,
                id: Constants.USER_ROLE_EXTERNAL_VISITOR,
                label: Translations.getStatic('addingNotes'),
            },
            {
                key: Constants.USER_ROLE_EXTERNAL_EDITOR,
                id: Constants.USER_ROLE_EXTERNAL_EDITOR,
                label: Translations.getStatic('candidatesManagement'),
            },
        ];

        return (
            <>
                <MarkModal
                    isOpen={isOpen}
                    actionButton={{
                        visible: false,
                    }}

                    closeModal={this.props.closeModal}
                    title={Translations.getStatic('jobAccess')}
                    isLoadingData={this.props.isFetchingJobModel === true}

                    content={
                        this.props.isFetchingJobModel === true
                        || this.props.jobModel.hasOwnProperty('settings') === false
                            ? <FormSkeleton/>
                            : (
                                <>
                                    <Heading as="h3">
                                        {Translations.getStatic('jobAccessForExternalColleagues').replace('%channel%', Translations.getStatic('sourceId1').toLowerCase() + '.' + Url.getTld(window.location.href))}
                                    </Heading>

                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <p>{Translations.getStatic('jobAccessInfo')}</p>
                                            </Grid.Column>
                                        </Grid.Row>

                                        <Divider hidden className="size_8"/>
                                    </Grid>

                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <MarkForm>
                                                    <Grid className="padding_none">
                                                        <Grid.Row className="from-group__row">
                                                            <Grid.Column computer={7} tablet={16}>
                                                                <MarkForm.Field error={errors.includes(newColleagueEmail.email)}>
                                                                    <InputField
                                                                        maxLength={128}
                                                                        className="padding-bottom_none MuiSelect-light"
                                                                        label={Translations.getStatic('inviteColleague')}
                                                                        placeholder={Translations.getStatic('email')}
                                                                        value={newColleagueEmail.email}
                                                                        onChange={(e) => this.updateNewColleagueEmail({email: e.target.value})}
                                                                    />
                                                                </MarkForm.Field>
                                                            </Grid.Column>
                                                            <Grid.Column computer={7} tablet={16}>
                                                                <MarkForm.Field>
                                                                    <Select
                                                                        style={{height: '32px'}}
                                                                        name={'externalUserRight'}
                                                                        className="padding-bottom_none MuiSelect-light"
                                                                        value={newColleagueEmail.role}
                                                                        label={Translations.getStatic('jobRights')}
                                                                        options={rightsOptions}
                                                                        onChange={(value) => this.updateNewColleagueEmail({role: value})}
                                                                    />
                                                                </MarkForm.Field>
                                                            </Grid.Column>
                                                            <Grid.Column verticalAlign="bottom" computer={2} tablet={8}>
                                                                <MarkForm.Field>
                                                                    <Button className="secondary-button mini width_full" disabled={this.props.isSavingExternalUser} loading={this.props.isSavingExternalUser} onClick={() => this.addExternalUser(newColleagueEmail.name, newColleagueEmail.value)}>{Translations.getStatic('add')}</Button>
                                                                </MarkForm.Field>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    </Grid>
                                                </MarkForm>
                                                <Divider hidden className={'size_8'}/>
                                            </Grid.Column>
                                        </Grid.Row>

                                        {
                                            externalUsers.length > 0
                                            && (
                                                <>
                                                    <Grid.Row>
                                                        <Grid.Column>
                                                            <Divider className={'size_24 multiple'}/>
                                                        </Grid.Column>
                                                    </Grid.Row>

                                                    <Grid.Row>
                                                        <Grid.Column>
                                                            <div className="ui items attachment-list unstackable">
                                                                {
                                                                    externalUsers.map(externalUser => (
                                                                        <Item key={externalUser.id} className="attachment__box">
                                                                            <Image size="large" src={linkImage}/>
                                                                            <Grid verticalAlign="middle" container className={this.props.displaySize.isMobile === true ? 'overflow-hidden' : ''}>
                                                                                <Grid.Row>
                                                                                    <Grid.Column width={11}>
                                                                                        <Item.Header as="h4" className="ellipsis">{externalUser.email}</Item.Header>
                                                                                        <span className="ellipsis text-small">
                                                                                            {
                                                                                                externalUser.userRoleId === Constants.USER_ROLE_EXTERNAL_VISITOR
                                                                                                    ? Translations.getStatic('addingNotes')
                                                                                                    : Translations.getStatic('candidatesManagement')
                                                                                            }
                                                                                        </span>
                                                                                    </Grid.Column>
                                                                                    <Grid.Column width={4} textAlign="right">
                                                                                        <Item as="a" className="link secondary-link" disabled={this.props.isRemovingExternalUser} onClick={() => this.openDialog(externalUser.email)}>{Translations.getStatic('denyAccess')}</Item>
                                                                                    </Grid.Column>
                                                                                    <Grid.Column width={1}>
                                                                                        <div onClick={() => this.toggleActiveClass(externalUser.id)} className={'dropdown-menu divider ' + (this.state.dropdownActive === externalUser.id ? 'active' : 'inactive')}>
                                                                                            <Dropdown
                                                                                                fluid
                                                                                                direction="left"
                                                                                                icon={{className: 'icon-more_small mini vertical-align'}}
                                                                                                open={this.state.dropdownActive === externalUser.id}
                                                                                                onClick={() => this.toggleActiveClass(externalUser.id)}
                                                                                                onClose={() => this.toggleActiveClass(externalUser.id)}
                                                                                                className={'text-right'}
                                                                                            >
                                                                                                <Dropdown.Menu>
                                                                                                    {
                                                                                                        externalUser.userRoleId === Constants.USER_ROLE_EXTERNAL_VISITOR
                                                                                                            ? (
                                                                                                                <Dropdown.Item
                                                                                                                    key={'edit'}
                                                                                                                    text={Translations.getStatic('grantCandidatesManagementRights')}
                                                                                                                    onClick={() => this.changeExternalUserRole(externalUser.id, Constants.USER_ROLE_EXTERNAL_EDITOR)}
                                                                                                                />
                                                                                                            )
                                                                                                            : (
                                                                                                                <Dropdown.Item
                                                                                                                    key={'edit'}
                                                                                                                    text={Translations.getStatic('grantAddingNotesRights')}
                                                                                                                    onClick={() => this.changeExternalUserRole(externalUser.id, Constants.USER_ROLE_EXTERNAL_VISITOR)}
                                                                                                                />
                                                                                                            )
                                                                                                    }
                                                                                                </Dropdown.Menu>
                                                                                            </Dropdown>
                                                                                        </div>
                                                                                    </Grid.Column>
                                                                                </Grid.Row>
                                                                            </Grid>
                                                                        </Item>
                                                                    ))
                                                                }
                                                            </div>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </>
                                            )
                                        }
                                    </Grid>
                                </>
                            )
                    }
                />

                {
                    this.state.dialogOpen === true
                    && (
                        <MarkDialog
                            message={{
                                title: Translations.getStatic('removeFromJob'),
                                infoLines: [
                                    Translations.getStatic('removeFromJobQuestion')
                                        .replace(/%email%/g, this.state.prepareRemoveExternalUserEmail)
                                        .replace(/%processName%/g, this.props.jobModel.job.jobTitle),
                                ],
                                iconName: 'icon-removeJob',
                            }}

                            actions={[
                                {
                                    title: Translations.getStatic('remove'),
                                    action: () => this.removeExternalUser(this.state.prepareRemoveExternalUserEmail),
                                    type: 'negative',
                                    loading: this.props.isRemovingExternalUser,
                                },
                                {
                                    title: Translations.getStatic('cancel'),
                                    action: () => this.closeDialog(),
                                },
                            ]}
                        />
                    )
                }
            </>
        );
    }
}

InviteColleagueModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    isOpen: state.modalLogic.isOpen,
    jobModel: state.jobModel,
    isFetchingJobModel: state.isFetchingJobModel,
    isSavingExternalUser: state.isSavingExternalUser,
    isRemovingExternalUser: state.isRemovingExternalUser,
    displaySize: state.displaySize,
});

const mapDispatchToProps = {
    closeModal,
    requestJobModel,
    addExternalUser,
    removeExternalUser,
    changeExternalUserRole,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(InviteColleagueModal);




