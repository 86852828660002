import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import TemplateAssistantTemplateHeader from '../TemplateAssistant/TemplateAssistantTemplateHeader';
import {
    requestAutoAnswerEditorModel,
    updateAutoAnswer,
} from '../../actions/AutoAnswerEditorActions';
import {
    saveAsNewAutoAnswerTemplate,
    saveAsChangedAutoAnswerTemplate,
} from '../../actions/TemplateAssistantActions';
import Form from '../Form';
import Grid from '../Grid';
import Divider from '../Divider';
import Heading from '../Heading';
import LoadingComponent from '../LoadingComponent';
import Translations from '../../helpers/Translations';
import Dropdown from '../Dropdown';
import Language from '../../helpers/Language';
import Constants from '../../helpers/Constants';
import AttachmentAssistant from '../AttachmentsAssistant/AttachmentsAssistant';
import AttachmentAssistantHelper from '../../helpers/components/AttachmentAssistantHelper';
import Variables from '../../helpers/Variables';
import EmailMessageVariablesContainerEditor from '../Email/EmailMessageVariablesContainerEditor';

class SettingsAutoAnswerEditor extends React.Component
{
    componentDidMount() {
        const predefinedLanguageId = this.props.languages.find(language => language.languageCode === Language.get()).languageId;

        this.props.requestAutoAnswerEditorModel(this.props.autoAnswerId, predefinedLanguageId, this.props.isCopy);
    };

    componentDidUpdate(prevProps) {
        if (this.props.autoAnswerEditorModel.hasOwnProperty('autoAnswerData') === true && (
            prevProps.autoAnswerEditorModel.hasOwnProperty('autoAnswerData') === false
            || prevProps.autoAnswerEditorModel.autoAnswerData.id !== this.props.autoAnswerEditorModel.autoAnswerData.id
            || prevProps.isCopy === true
        )) {
            if (this.props.autoAnswerEditorModel.autoAnswerData.id !== null && prevProps.isCopy === false) {
                prevProps.saveAsChangedAutoAnswerTemplate(true);
            } else {
                prevProps.saveAsNewAutoAnswerTemplate(true);
            }
        }
    };

    updateEmailTemplateLanguage = (intoLanguage, fromLanguage) => {
        const {autoAnswerEditorModel: {autoAnswerData, settings}, languages} = this.props;

        const fromLanguageCode = languages.find(language => parseInt(language.languageId) === parseInt(fromLanguage)).languageCode;
        const intoLanguageCode = languages.find(language => parseInt(language.languageId) === parseInt(intoLanguage)).languageCode;

        let text    = autoAnswerData.text;
        let subject = autoAnswerData.subject;

        const originalVariables   = Variables.getEditorVariables(Variables.getVariablesForAction(settings.variables, fromLanguageCode), false);
        const translatedVariables = Variables.getEditorVariables(Variables.getVariablesForAction(settings.variables, intoLanguageCode), false);

        for (let i = 0; i < originalVariables.length; i++) {
            text    = text.replace(originalVariables[i], translatedVariables[i]);
            subject = subject.replace(originalVariables, translatedVariables[i]);
        }

        this.props.updateAutoAnswer({
            languageId: intoLanguage,
            text: text,
            subject: subject,
        });
    };

    render = () => {
        if (this.props.isFetchingAutoAnswerEditorModel === true || this.props.autoAnswerEditorModel.hasOwnProperty('autoAnswerData') === false) {
            return <LoadingComponent/>;
        }

        const {autoAnswerEditorModel: {autoAnswerData, settings}, languages, errors} = this.props;

        return (
            <>
                <Form>
                    <Heading as="h3">{Translations.getStatic('autoReplyTemplate')}</Heading>
                    <Grid>
                        <Grid.Row className="horizontal-row">
                            <Grid.Column width={8} verticalAlign="middle">
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <div className="field">
                                                <Dropdown
                                                    label={Translations.getStatic('emailLanguage')}
                                                    defaultValue={autoAnswerData.languageId}
                                                    list={languages.map(language => ({
                                                        value: language.languageId,
                                                        text: language.language,
                                                    }))}
                                                    selectedItem={(value) => this.updateEmailTemplateLanguage(value, autoAnswerData.languageId)}
                                                />
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                            <Grid.Column width={8}/>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <TemplateAssistantTemplateHeader
                                    errors={errors}
                                    templateData={autoAnswerData}
                                    templateType={Constants.TEMPLATE_TYPE_AUTO_ANSWER}
                                    onUpdateTemplate={this.props.updateAutoAnswer}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
                <Divider/>
                <Form>
                    <Heading as="h3">{Translations.getStatic('message')}</Heading>
                    <Grid>
                        <EmailMessageVariablesContainerEditor
                            errors={errors}
                            languageId={autoAnswerData.languageId}
                            emailData={autoAnswerData}
                            settings={settings}
                            onUpdateEmailTemplate={this.props.updateAutoAnswer}
                        />

                        <Grid.Row>
                            <Grid.Column>
                                <AttachmentAssistant
                                    candidateId={0}
                                    templateAttachments={autoAnswerData.attachments}
                                    modelHashId={AttachmentAssistantHelper.createAttachmentAssistantHashIdForEmail()}
                                    isFileUploadOnly={true}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            </>
        );
    };
}

SettingsAutoAnswerEditor.propTypes = {
    autoAnswerId: PropTypes.number.isRequired,
    isCopy: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    languages: state.loginManagerModel.languages,
    autoAnswerEditorModel: state.autoAnswerEditorModel,
    isFetchingAutoAnswerEditorModel: state.isFetchingAutoAnswerEditorModel,
});

const mapDispatchToProps = {
    requestAutoAnswerEditorModel,
    updateAutoAnswer,
    saveAsChangedAutoAnswerTemplate,
    saveAsNewAutoAnswerTemplate,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SettingsAutoAnswerEditor);
