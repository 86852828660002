export const JOB_CHECK_CANDIDATE             = 'JOB_CHECK_CANDIDATE';
export const JOB_UNCHECK_CANDIDATE           = 'JOB_UNCHECK_CANDIDATE';
export const JOB_UNCHECK_CANDIDATES          = 'JOB_UNCHECK_CANDIDATES';
export const JOB_CHECK_CANDIDATES            = 'JOB_CHECK_CANDIDATES';
export const JOB_CHECK_ALL_CANDIDATES        = 'JOB_CHECK_ALL_CANDIDATES';
export const JOB_UNCHECK_ALL_CANDIDATES      = 'JOB_UNCHECK_ALL_CANDIDATES';
export const SORT_CANDIDATES_BY              = 'SORT_CANDIDATES_BY';
export const JOB_CHECK_CANDIDATES_IN_STAGE   = 'JOB_CHECK_CANDIDATES_IN_STAGE';
export const JOB_UNCHECK_CANDIDATES_IN_STAGE = 'JOB_UNCHECK_CANDIDATES_IN_STAGE';
export const SELECTED_TABLE_ROW              = 'SELECTED_TABLE_ROW';

export const sortCandidatesBy = (sortBy, sortDirection, sortType, withRefresh = false) => ({
    type: SORT_CANDIDATES_BY,

    payload: {
        sortBy,
        sortDirection,
        sortType,
        withRefresh,
    },
});

export const restoreCandidatesSort = ({sortBy, sortDirection, sortType}) => ({
    type: SORT_CANDIDATES_BY,

    payload: {
        sortBy,
        sortDirection,
        sortType,
    },
});

export const jobCheckCandidate = candidateId => ({
    type: JOB_CHECK_CANDIDATE,

    payload: {
        candidateId,
    },
});

export const jobCheckCandidates = candidates => ({
    type: JOB_CHECK_CANDIDATES,

    payload: {
        candidates,
    },
});

export const jobCheckAllCandidates = candidates => ({
    type: JOB_CHECK_ALL_CANDIDATES,

    payload: {
        candidates,
    },
});

export const jobCheckCandidatesInStage = (candidates, stageId) => ({
    type: JOB_CHECK_CANDIDATES_IN_STAGE,

    payload: {
        candidates,
        stageId,
    },
});

export const jobUncheckCandidate = candidateId => ({
    type: JOB_UNCHECK_CANDIDATE,

    payload: {
        candidateId,
    },
});

export const jobUncheckCandidates = candidates => ({
    type: JOB_UNCHECK_CANDIDATES,

    payload: {
        candidates,
    },
});

export const jobUncheckCandidatesInStage = (candidates, stageId) => ({
    type: JOB_UNCHECK_CANDIDATES_IN_STAGE,

    payload: {
        candidates,
        stageId,
    },
});

export const jobUncheckAllCandidates = () => ({
    type: JOB_UNCHECK_ALL_CANDIDATES,
});

export const selectTableRow = index => ({
    type: SELECTED_TABLE_ROW,

    payload: {
        index,
    }
})
