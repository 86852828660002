export const ADD_NOTIFICATION_MESSAGE              = 'ADD_NOTIFICATION_MESSAGE';
export const SET_ALL_NOTIFICATIONS_AS_READ         = 'SET_ALL_NOTIFICATIONS_AS_READ';
export const SET_ALL_NOTIFICATIONS_AS_READ_SUCCESS = 'SET_ALL_NOTIFICATIONS_AS_READ_SUCCESS';
export const SET_ALL_NOTIFICATIONS_AS_READ_FAILURE = 'SET_ALL_NOTIFICATIONS_AS_READ_FAILURE';
export const SET_NOTIFICATION_AS_READ              = 'SET_NOTIFICATION_AS_READ';
export const SET_NOTIFICATION_AS_READ_SUCCESS      = 'SET_NOTIFICATION_AS_READ_SUCCESS';
export const SET_NOTIFICATION_AS_READ_FAILURE      = 'SET_NOTIFICATION_AS_READ_FAILURE';

export const addNotificationMessage = notificationData => ({
    type: ADD_NOTIFICATION_MESSAGE,
    payload: {
        notificationData,
    },
});

export const setAllNotificationsAsRead = () => ({
    type: SET_ALL_NOTIFICATIONS_AS_READ,

    meta: {
        requestRoute: 'v1/notifications-center/action/set-all-as-read',
        requestMethod: 'POST',
        onRequestSuccess: SET_ALL_NOTIFICATIONS_AS_READ_SUCCESS,
        onRequestFailure: SET_ALL_NOTIFICATIONS_AS_READ_FAILURE,
    },
});

export const setNotificationAsRead = (candidateId, notificationId) => ({
    type: SET_NOTIFICATION_AS_READ,

    payload: {
        candidateId,
        notificationId,
    },

    meta: {
        requestRoute: 'v1/notifications-center/action/set-as-read/' + notificationId,
        requestMethod: 'POST',

        requestData: {
            candidateId,
        },

        onRequestSuccess: SET_NOTIFICATION_AS_READ_SUCCESS,
        onRequestFailure: SET_NOTIFICATION_AS_READ_FAILURE,
    },
});
