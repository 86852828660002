import React from 'react';
import {SkeletonRectangular} from '@profesia/adamui/components/common/skeleton';
import {Stack} from '@mui/material';

const AttachmentsAssistantSkeleton = () => (
    <Stack flex={1} p={4} spacing={4.5}>
        {
            [...Array(3)].map((e, i) => (
                <Stack flex={1} spacing={1.5} key={i}>
                    <SkeletonRectangular width={165} height={18} borderRadius={26}/>

                    <Stack flex={1} spacing={1}>
                        {[...Array(8)].map((e, j) => <SkeletonRectangular height={10} borderRadius={26} key={j}/>)}
                    </Stack>
                </Stack>
            ))
        }
    </Stack>
);

export default AttachmentsAssistantSkeleton;
