import {
    RECEIVE_USER_ACTIONS_MODEL, REQUEST_USER_ACTIONS_MODEL,
} from '../actions/UserActionActions';

export const userActionsModel = (state = {}, action) => {
    switch (action.type) {
        case REQUEST_USER_ACTIONS_MODEL:
            return {};
        case RECEIVE_USER_ACTIONS_MODEL:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export const isFetchingUserActionsModel = (state = false, action) => {
    switch (action.type) {
        case REQUEST_USER_ACTIONS_MODEL:
            return true;

        case RECEIVE_USER_ACTIONS_MODEL:
            return false;

        default:
            return state;
    }
};
