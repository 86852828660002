import React, {Component} from 'react';
import {connect} from 'react-redux';
import JobPositionEditor from '../Job/JobPositionEditor';
import LoadingComponent from '../LoadingComponent';
import CreateJobHelper from '../../helpers/components/CreateJobHelper';
import {updateJob} from '../../actions/JobDetailActions';
import {closeModal} from '../../actions/ModalActions';
import PropTypes from 'prop-types';
import MarkModal from '../Modal/MarkModal';
import SuccessActionStatus from '../../helpers/SuccessActionStatus';
import Translations from '../../helpers/Translations';

class JobDetailPositionDescriptionModal extends Component
{
    state = {
        errors: [],
        jobData: this.props.jobModel.job,
    };

    componentDidUpdate = (prevProps) => {
        if (SuccessActionStatus.isActionSuccessful(prevProps, this.props)) {
            this.props.closeModal();
        }
    };

    prepareFormDataForSave() {
        let formData = new FormData();

        formData.append('jobData', JSON.stringify(this.state.jobData));

        return formData;
    };

    checkFunction() {
        const errors = CreateJobHelper.checkPosition(this.state.jobData);

        this.setState({
            errors,
        });

        return errors.length === 0;
    };

    saveJob() {
        if (this.checkFunction() === true) {
            this.props.updateJob(parseInt(this.state.jobData.id), this.prepareFormDataForSave());
        }
    };

    updateJobData = jobData => {
        this.setState({
            jobData: {
                ...this.state.jobData,
                ...jobData,
            },
        });
    };

    render() {
        const {jobModel: {settings}, isOpen, isSavingJob} = this.props;

        return (
            <MarkModal
                isOpen={isOpen}
                closeModal={this.props.closeModal}
                title={Translations.getStatic('editInfoAboutJob')}

                actionButton={{
                    text: Translations.getStatic('save'),
                    onClick: () => this.saveJob(),
                }}

                content={
                    isSavingJob === true
                        ? <LoadingComponent text={Translations.getStatic('saving')}/>
                        : (
                            <JobPositionEditor
                                settings={settings}
                                jobData={this.state.jobData}
                                languages={this.props.loginManagerModel.languages.filter(language => language.enabled === true)}
                                errors={this.state.errors}
                                onUpdateJob={jobData => this.updateJobData(jobData)}
                            />
                        )
                }
            />
        );
    }
}

const mapStateToProps = state => ({
    loginManagerModel: state.loginManagerModel,
    jobModel: state.jobModel,
    isSavingJob: state.isSavingJob,
    isOpen: state.modalLogic.isOpen,
    actionSuccessStatus: state.actionSuccessStatus,
});

const mapDispatchToProps = {
    updateJob,
    closeModal,
};

JobDetailPositionDescriptionModal.propTypes = {
    jobModel: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    updateJob: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(JobDetailPositionDescriptionModal);



