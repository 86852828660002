import AttachmentAssistantHelper from '../helpers/components/AttachmentAssistantHelper';

export const REQUEST_REACTION_FORM_MODEL = 'REQUEST_REACTION_FORM_MODEL';
export const RECEIVE_REACTION_FORM_MODEL = 'RECEIVE_REACTION_FORM_MODEL';
export const SET_REACTION_FORM_ERRORS    = 'SET_REACTION_FORM_ERRORS';
export const SAVE_REACTION               = 'SAVE_REACTION';
export const SAVE_REACTION_SUCCESS       = 'SAVE_REACTION_SUCCESS';
export const SAVE_REACTION_FAILURE       = 'SAVE_REACTION_FAILURE';

export const requestReactionFormModel = (jobId, offerId, tag = '') => ({
    type: REQUEST_REACTION_FORM_MODEL,

    meta: {
        requestRoute: 'v1/reaction/model/form',

        requestData: {
            jobId,
            offerId,
            tag,
        },

        onRequestSuccess: RECEIVE_REACTION_FORM_MODEL,
    },
});

export const saveReaction = reactionData => ({
    type: SAVE_REACTION,

    payload: {
        hashId: AttachmentAssistantHelper.createAttachmentAssistantHashIdForForm(),
    },

    meta: {
        requestRoute: 'v1/reaction/action/save',
        requestMethod: 'POST',

        requestBody: reactionData,

        onRequestSuccess: SAVE_REACTION_SUCCESS,
        onRequestFailure: SAVE_REACTION_FAILURE,
    },
});

export const setReactionFormErrors = errors => ({
    type: SET_REACTION_FORM_ERRORS,

    payload: {
        errors,
    },
});
