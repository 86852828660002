import React, {Component} from 'react';
import {connect} from 'react-redux';
import {unsetErrorFocus} from '../actions/ErrorActions';

class ErrorFocus extends Component 
{
    componentDidUpdate(prevProps, prevState) {
        if (this.props.isErrorFocused === true) {
            const errors = document.querySelectorAll('.error, .negative.message');

            if (errors.length > 0) {
                errors[0].scrollIntoView();
            } 

            this.props.unsetErrorFocus();
        }
    }

    render() {
        return this.props.children;
    }
}

const mapStateToProps = state => ({
    isErrorFocused: state.isErrorFocused,
});

const mapDispatchToProps = {
    unsetErrorFocus,
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorFocus);