import React, {Component} from 'react';
import PropTypes from 'prop-types';
import MarkModal from '../Modal/MarkModal';
import {closeModal, openModalSecond} from '../../actions/ModalActions';
import {connect} from 'react-redux';
import SendEmailHelper from '../../helpers/components/SendEmailHelper';
import {sendEmail} from '../../actions/ActionWithCandidateActions';
import Constants from '../../helpers/Constants';
import SendCandidatesToColleagues from '../Candidate/SendCandidatesToColleagues';
import SendEmailToCandidates from '../Candidate/SendEmailToCandidates';
import SuccessMessage from '../../helpers/SuccessMessage';
import Translations from '../../helpers/Translations';
import AttachmentAssistantHelper from '../../helpers/components/AttachmentAssistantHelper';
import {openAllRecipientsWithoutEmailsDialog, openSomeRecipientsWithoutEmailsDialog} from '../../actions/DialogActions';
import Arrays from '../../helpers/Arrays';
import LoadingComponent from '../LoadingComponent';
import Variables from '../../helpers/Variables';
import Url from '../../helpers/Url';
import Storages from '../../helpers/Storages';

class SendEmailModal extends Component
{
    state = {
        errors: [],
    };

    componentDidUpdate = prevProps => {
        if (SuccessMessage.canShowSuccessMessage(prevProps, this.props, 'isSendingEmail')
            && this.props.candidateActionModel.hasOwnProperty('isSuccess') === true
            && this.props.candidateActionModel.isSuccess === true
        ) {
            this.props.closeModal();
        }
    };

    sendCandidates = (excludedCandidateIdsFromSendEmail = []) => {
        const candidateIds = Arrays.getUniqueArray(this.props.candidateIds);

        const {candidateActionModel, emailType, sendEmailModel, attachmentAssistantModel, templateAssistant, templatesForDropdownSelector, loginManagerModel} = this.props;

        const isMassForwarding                       = emailType === Constants.EMAIL_TYPE_TO_COLLEAGUE && candidateIds.length > 1;
        const isMassForwardingWithAllowedSingleEmail = isMassForwarding === true && Storages.getLocal('multipleForwardingAsSingleEmail', candidateActionModel.hasOwnProperty('sendEmailParams') === true && candidateActionModel.sendEmailParams.multipleForwardingAsSingleEmail === true) === true;

        const requiredVariablesActionTypesToCheck = isMassForwardingWithAllowedSingleEmail === true
            ? [Constants.VARIABLE_ACTION_TYPE_MULTIPLE_FORWARD]
            : [];

        const forbiddenVariablesActionTypesToCheck = isMassForwardingWithAllowedSingleEmail === true
            ? [Constants.VARIABLE_ACTION_TYPE_CANDIDATE, Constants.VARIABLE_ACTION_TYPE_EVENT, Constants.VARIABLE_ACTION_TYPE_VIDEO_INTERVIEW, Constants.VARIABLE_ACTION_TYPE_QUESTIONNAIRE]
            : [Constants.VARIABLE_ACTION_TYPE_MULTIPLE_FORWARD];

        if (isMassForwardingWithAllowedSingleEmail === true && Url.getUrl().indexOf('search') > -1) {
            forbiddenVariablesActionTypesToCheck.push(Constants.VARIABLE_ACTION_TYPE_POSITION);
        }

        const filledVariablesActionTypesToCheck = isMassForwardingWithAllowedSingleEmail === true
            ? [Constants.VARIABLE_ACTION_TYPE_USER]
            : [Constants.VARIABLE_ACTION_TYPE_USER, Constants.VARIABLE_ACTION_TYPE_EVENT, Constants.VARIABLE_ACTION_TYPE_VIDEO_INTERVIEW, Constants.VARIABLE_ACTION_TYPE_QUESTIONNAIRE];

        try {
            const actionData = SendEmailHelper.prepareEmail(
                sendEmailModel,
                attachmentAssistantModel,
                templateAssistant,
                templatesForDropdownSelector,
                candidateIds,
                emailType,
                {
                    variables: sendEmailModel.settings.variables,
                    filledVariables: Variables.getFilledVariablesCheckParams(
                        filledVariablesActionTypesToCheck,
                        candidateActionModel.candidates,
                        loginManagerModel,
                        sendEmailModel.settings.videoInterview,
                        null,
                    ),
                    requiredVariables: Variables.getRequiredVariablesCheckParams(
                        requiredVariablesActionTypesToCheck,
                    ),
                    forbiddenVariables: Variables.getForbiddenVariablesCheckParams(
                        forbiddenVariablesActionTypesToCheck,
                    ),
                },
                excludedCandidateIdsFromSendEmail,
            );

            actionData.append('candidateIds', JSON.stringify(candidateIds));
            actionData.append('isMassForwardingWithAllowedSingleEmail', JSON.stringify(isMassForwardingWithAllowedSingleEmail));

            this.props.sendEmail(actionData, candidateIds, emailType === Constants.EMAIL_TYPE_TO_CANDIDATE, emailType === Constants.EMAIL_TYPE_TO_COLLEAGUE);

            this.setState({
                errors: [],
            });
        } catch (error) {
            if (error !== false && Array.isArray(error) === true) {
                SendEmailHelper.processRecipientsErrors(error, this.props.openAllRecipientsWithoutEmailsDialog, () => this.props.openSomeRecipientsWithoutEmailsDialog({
                    candidateIds: candidateIds,
                    onSkip: excludedCandidateIdsFromSendEmail => this.sendCandidates(excludedCandidateIdsFromSendEmail),
                }));

                this.setState({
                    errors: error,
                });
            }
        }
    };

    render() {
        const {
                  isOpen, emailType, candidateIds, attachmentAssistantModel, sendEmailModel,
                  multipleType, isSendingEmail, isFetchingSendEmailModel, isFetchingCandidatesForAction,
                  isGettingVideoInterviewData,
                  isFetchingAttachmentAssistantModel,
                  isFetchingMultiAttachmentAssistantModel,
              } = this.props;

        const {errors} = this.state;

        const sendEmailComponent = emailType === Constants.EMAIL_TYPE_TO_COLLEAGUE
            ? <SendCandidatesToColleagues errors={errors} candidateIds={candidateIds} multipleType={multipleType}/>
            : <SendEmailToCandidates errors={errors} candidateIds={candidateIds} multipleType={multipleType} messageHistory={this.props.messageHistory}/>;

        const children = this.props.isSendingEmail === true
            ? (
                <LoadingComponent
                    text={Translations.getStatic('sendingInProgress') + '...'}
                    showProgress={candidateIds.length > Constants.POST_CHUNK_SIZE}
                />
            )
            : sendEmailComponent;

        const emailData = {
            from: sendEmailModel.settings && sendEmailModel.settings.companyUsers ? sendEmailModel.settings.companyUsers.find(user => user.id === sendEmailModel.sender).email : '',
            to: sendEmailModel.recipients.map(recipient => recipient.value),
            bcc: sendEmailModel.bcc.map(bccEmail => bccEmail.value),
            subject: sendEmailModel.emailTemplateData ? sendEmailModel.emailTemplateData.subject : '',
            body: sendEmailModel.emailTemplateData ? sendEmailModel.emailTemplateData.text : '',
            attachments: AttachmentAssistantHelper.getAttachmentsForEmailPreview(attachmentAssistantModel),
            attachmentSettings: attachmentAssistantModel.settings,
            languageId: sendEmailModel.emailTemplateData ? sendEmailModel.emailTemplateData.languageId : 1,
            candidateId: candidateIds.length > 0 ? candidateIds[0] : 0,
            senderId: sendEmailModel.sender,
        };

        const totalAttachmentsSize  = AttachmentAssistantHelper.getTotalAttachmentsSize(attachmentAssistantModel);
        const oversizedCandidates   = AttachmentAssistantHelper.getCandidatesWithExceededAttachmentsSize(this.props.attachmentAssistantModel, this.props.jobModel);
        const disabledPrimaryButton = attachmentAssistantModel.settings === undefined || isSendingEmail === true || isFetchingSendEmailModel === true || totalAttachmentsSize > attachmentAssistantModel.settings.emailTotalAttachmentsMaxSize || oversizedCandidates.length > 0;

        return (
            <MarkModal
                isOpen={isOpen}
                closeModal={this.props.closeModal}

                isLoadingData={
                    isFetchingCandidatesForAction === true
                    || isFetchingSendEmailModel === true
                    || isGettingVideoInterviewData === true
                    || isFetchingAttachmentAssistantModel === true
                    || isFetchingMultiAttachmentAssistantModel === true
                }

                actionButton={{
                    text: Translations.getStatic('sendEmail'),
                    disabled: disabledPrimaryButton,
                    loading: this.props.isSendingEmail,
                    onClick: () => this.sendCandidates(),
                }}

                entityOption={{
                    text: Translations.getStatic('emailPreview'),
                    visible: true,
                    disabled: disabledPrimaryButton,
                    loading: this.props.isSendingEmail,
                    onClick: () => this.props.openModalSecond('DetailEmailModal', {title: Translations.getStatic('emailPreview'), emailData}),
                }}

                title={Translations.getStatic(emailType === Constants.EMAIL_TYPE_TO_COLLEAGUE ? 'sendEmailToColleague' : 'sendEmail')}
                content={children}
            />
        );
    }
}

SendEmailModal.propTypes = {
    candidateIds: PropTypes.array.isRequired,
    isOpen: PropTypes.bool.isRequired,
    emailType: PropTypes.number.isRequired,
    multipleType: PropTypes.bool,
    messageHistory: PropTypes.object,
};

const mapStateToProps = state => ({
    isOpen: state.modalLogic.isOpen,
    candidateActionModel: state.candidateActionModel,
    isSendingEmail: state.isSendingEmail,
    isFetchingSendEmailModel: state.isFetchingSendEmailModel,
    sendEmailModel: state.sendEmailModel,
    jobModel: state.jobModel,

    attachmentAssistantModel: AttachmentAssistantHelper.getActiveModelFromAssistantAttachmentModel(
        state.attachmentAssistantModel,
        AttachmentAssistantHelper.createAttachmentAssistantHashIdForEmail(),
    ),

    templateAssistant: state.templateAssistant,
    templatesForDropdownSelector: state.templatesForDropdownSelector.templates,
    loginManagerModel: state.loginManagerModel,
    isFetchingCandidatesForAction: state.isFetchingCandidatesForAction,
    isGettingVideoInterviewData: state.isGettingVideoInterviewData,
    isFetchingAttachmentAssistantModel: state.isFetchingAttachmentAssistantModel,
    isFetchingMultiAttachmentAssistantModel: state.isFetchingMultiAttachmentAssistantModel,
});

const mapDispatchToProps = {
    closeModal,
    sendEmail,
    openModalSecond,
    openAllRecipientsWithoutEmailsDialog,
    openSomeRecipientsWithoutEmailsDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(SendEmailModal);




