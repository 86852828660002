export const REQUEST_COMPANY_EMAIL_TEMPLATES_MODEL     = 'REQUEST_COMPANY_EMAIL_TEMPLATES_MODEL';
export const RECEIVE_COMPANY_EMAIL_TEMPLATES_MODEL     = 'RECEIVE_COMPANY_EMAIL_TEMPLATES_MODEL';
export const COMPANY_EMAIL_TEMPLATE_ACTIVATE           = 'COMPANY_EMAIL_TEMPLATE_ACTIVATE';
export const COMPANY_EMAIL_TEMPLATE_ACTIVATE_SUCCESS   = 'COMPANY_EMAIL_TEMPLATE_ACTIVATE_SUCCESS';
export const COMPANY_EMAIL_TEMPLATE_ACTIVATE_FAILURE   = 'COMPANY_EMAIL_TEMPLATE_ACTIVATE_FAILURE';
export const COMPANY_EMAIL_TEMPLATE_DEACTIVATE         = 'COMPANY_EMAIL_TEMPLATE_DEACTIVATE';
export const COMPANY_EMAIL_TEMPLATE_DEACTIVATE_SUCCESS = 'COMPANY_EMAIL_TEMPLATE_DEACTIVATE_SUCCESS';
export const COMPANY_EMAIL_TEMPLATE_DEACTIVATE_FAILURE = 'COMPANY_EMAIL_TEMPLATE_DEACTIVATE_FAILURE';
export const COMPANY_EMAIL_TEMPLATE_UPDATE             = 'COMPANY_EMAIL_TEMPLATE_UPDATE';
export const COMPANY_EMAIL_TEMPLATE_UPDATE_SUCCESS     = 'COMPANY_EMAIL_TEMPLATE_UPDATE_SUCCESS';
export const COMPANY_EMAIL_TEMPLATE_UPDATE_FAILURE     = 'COMPANY_EMAIL_TEMPLATE_UPDATE_FAILURE';
export const COMPANY_EMAIL_TEMPLATE_CREATE             = 'COMPANY_EMAIL_TEMPLATE_CREATE';
export const COMPANY_EMAIL_TEMPLATE_CREATE_SUCCESS     = 'COMPANY_EMAIL_TEMPLATE_CREATE_SUCCESS';
export const COMPANY_EMAIL_TEMPLATE_CREATE_FAILURE     = 'COMPANY_EMAIL_TEMPLATE_CREATE_FAILURE';
export const COMPANY_EMAIL_TEMPLATE_ADD                = 'COMPANY_EMAIL_TEMPLATE_ADD';
export const COMPANY_EMAIL_TEMPLATE_ADD_CANCEL         = 'COMPANY_EMAIL_TEMPLATE_ADD_CANCEL';

export const requestCompanyEmailTemplatesModel = () => ({
    type: REQUEST_COMPANY_EMAIL_TEMPLATES_MODEL,

    meta: {
        requestRoute: 'v1/admin/model/company-email-templates',
        onRequestSuccess: RECEIVE_COMPANY_EMAIL_TEMPLATES_MODEL,
    },
});

export const activateCompanyEmailTemplate = companyEmailTemplateId => ({
    type: COMPANY_EMAIL_TEMPLATE_ACTIVATE,

    payload: {
        companyEmailTemplateId,
    },

    meta: {
        requestMethod: 'POST',
        requestRoute: 'v1/admin/company-email-templates/action/activate',

        requestBody: JSON.stringify({
            companyEmailTemplateId,
        }),

        onRequestSuccess: COMPANY_EMAIL_TEMPLATE_ACTIVATE_SUCCESS,
        onRequestFailure: COMPANY_EMAIL_TEMPLATE_ACTIVATE_FAILURE,
    },
});

export const deactivateCompanyEmailTemplate = companyEmailTemplateId => ({
    type: COMPANY_EMAIL_TEMPLATE_DEACTIVATE,

    payload: {
        companyEmailTemplateId,
    },

    meta: {
        requestMethod: 'POST',
        requestRoute: 'v1/admin/company-email-templates/action/deactivate',

        requestBody: JSON.stringify({
            companyEmailTemplateId,
        }),

        onRequestSuccess: COMPANY_EMAIL_TEMPLATE_DEACTIVATE_SUCCESS,
        onRequestFailure: COMPANY_EMAIL_TEMPLATE_DEACTIVATE_FAILURE,
    },
});

export const updateCompanyEmailTemplate = (companyEmailTemplateId, companyId, templateContent, logo, logoWidth, logoHeight, isActive) => ({
    type: COMPANY_EMAIL_TEMPLATE_UPDATE,

    payload: {
        companyEmailTemplateId,
        companyId,
        templateContent,
        logo,
        logoWidth,
        logoHeight,
        isActive,
    },

    meta: {
        requestMethod: 'POST',
        requestRoute: 'v1/admin/company-email-templates/action/update',

        requestBody: JSON.stringify({
            companyEmailTemplateId,
            companyId,
            templateContent,
            logo,
            logoWidth,
            logoHeight,
            isActive,
        }),

        onRequestSuccess: COMPANY_EMAIL_TEMPLATE_UPDATE_SUCCESS,
        onRequestFailure: COMPANY_EMAIL_TEMPLATE_UPDATE_FAILURE,
    },
});

export const createCompanyEmailTemplate = (companyId, templateContent, logo, logoWidth, logoHeight, isActive) => ({
    type: COMPANY_EMAIL_TEMPLATE_CREATE,

    payload: {
        companyEmailTemplateId: 'new',
    },

    meta: {
        requestMethod: 'POST',
        requestRoute: 'v1/admin/company-email-templates/action/create',

        requestBody: JSON.stringify({
            companyId,
            templateContent,
            logo,
            logoWidth,
            logoHeight,
            isActive,
        }),

        onRequestSuccess: COMPANY_EMAIL_TEMPLATE_CREATE_SUCCESS,
        onRequestFailure: COMPANY_EMAIL_TEMPLATE_CREATE_FAILURE,
    },
});

export const addCompanyEmailTemplate = () => ({
    type: COMPANY_EMAIL_TEMPLATE_ADD,
});

export const cancelAddCompanyEmailTemplate = () => ({
    type: COMPANY_EMAIL_TEMPLATE_ADD_CANCEL,
});
