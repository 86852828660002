import React from 'react';
import Item from '../../Item';
import Icon from '../../Icon';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {openNoModalSecond} from '../../../actions/ModalActions';
import {openNoCandidateAccessDialog} from '../../../actions/DialogActions';
import Translations from '../../../helpers/Translations';
import DateFormatted from '../../../helpers/DateFormatted';
import Sort from '../../../helpers/Sort';

class CandidateSidebarDuplicates extends React.Component
{
    constructor(props) {
        super(props);
    };

    handleOnDuplicateClick = (duplicate, candidateName) => {
        if (duplicate.isLocked === true) {
            this.props.openNoCandidateAccessDialog();
        } else {
            this.props.openNoModalSecond('DetailCandidateDuplicateNoModal', {candidateId: duplicate.id, candidateName});
        }
    };

    render() {
        const {duplicates, candidateName} = this.props;

        return (
            <>
                <div className='ui items unstackable'>
                    <Item>
                        <Icon className="icon-duplicate_small" size='mini'/>
                        <Item.Content>
                            <Item.Header className='txt-red padding-bottom_none'>{Translations.getStatic('duplicityInOffers')}</Item.Header>
                        </Item.Content>
                    </Item>
                    {
                        Sort.byColumn(duplicates, 'addDate', 'DESC').map(duplicate => (
                                <Item key={duplicate.id}>
                                    <Icon className="icon-list-item" size='mini'/>
                                    <Item.Content>
                                        <h6>{DateFormatted.getDate(duplicate.addDate)}</h6>
                                        <div
                                            role="link"
                                            className="primary-link"
                                            onClick={() => this.handleOnDuplicateClick(duplicate, candidateName)}
                                        >
                                            <p className='ellipsis txt-blue txt-medium'>{duplicate.jobTitle}</p>
                                        </div>
                                    </Item.Content>
                                </Item>
                            ),
                        )
                    }
                </div>
            </>
        );
    }
}

const mapDispatchToProps = {
    openNoModalSecond,
    openNoCandidateAccessDialog,
};

CandidateSidebarDuplicates.propTypes = {
    duplicates: PropTypes.array.isRequired,
};

export default connect(null, mapDispatchToProps)(CandidateSidebarDuplicates);
