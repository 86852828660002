import React from 'react';
import {connect} from 'react-redux';
import {Image} from 'semantic-ui-react';
import Translations from '../helpers/Translations';
import addJobIcon from './../../img/icons/add-job.svg';
import Url from '../helpers/Url';

const Header4MenuExtendedButtonAddJob = props => (
    <div
        className="extended-action add-job"
        onClick={() => Url.redirectTo('/add_offer.php')}
    >
        <div className="extended-action__icon">
            <Image size="tiny" src={addJobIcon}/>
        </div>

        {
            props.displaySize.isDesktopProfesia === true
            && <div className="extended-action__desc">{Translations.getStatic('addJobOffer')}</div>
        }
    </div>
);

const mapStateToProps = state => ({
    displaySize: state.displaySize,
});

export default connect(
    mapStateToProps,
)(Header4MenuExtendedButtonAddJob);
