import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';

const Button = props => {
    const Tag      = props.as || 'button';
    const disabled = props.disabled === true ? 'disabled ' : '';

    const hasCustomIcon = () => {
        return props.icon?.hasOwnProperty('className') === true;
    };

    let {
            icon,
            floated,
            size,
            loading,
            primary,
            secondary,
            negative,
            positive,
            content,
            className,
            as,
            ...validProps
        } = props;

    icon      = (icon || hasCustomIcon) ? 'icon ' : '';
    floated   = floated ? props.floated + ' floated ' : '';
    size      = size ? (props.size + ' ') : '';
    loading   = loading === true ? 'loading ' : '';
    primary   = primary === true ? 'primary ' : '';
    secondary = secondary === true ? 'secondary ' : '';
    negative  = negative === true ? 'negative ' : '';
    positive  = positive === true ? 'positive ' : '';
    className = className ? (className + ' ') : '';

    return (
        <Tag {...validProps} className={`${icon}${floated}${size}${disabled}${loading}${primary}${secondary}${negative}${positive}${className}ui button`}>
            {hasCustomIcon() === true && <Icon className={props.icon.className}/>}
            {props.content && props.content}
            {props.children}
        </Tag>
    );
};

const Group = props => {
    let {
            className,
            ...validProps
        } = props;

    className = className ? (className + ' ') : '';

    return <div {...validProps} className={`${className}ui buttons `}>{props.children}</div>;
};

Button.Group = Group;

Button.defaultProps = {
    icon: {},
};

Button.propTypes = {
    icon: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
};

export default Button;
