import {SET_TAKE_ME_TO} from '../actions/TransitionActions';

export const transitionLogic = (state = {takeMeTo: ''}, action) => {
    switch (action.type) {
        case SET_TAKE_ME_TO:
            return {
                ...state,
                takeMeTo: action.payload.takeMeTo,
            };

        default:
            return state;
    }
};
