import React from 'react';
import {Loader} from '@profesia/adamui/components/common/loadings';
import Translations from '../helpers/Translations';
import {connect} from 'react-redux';
import Number from '../helpers/Number';

const LoadingComponent = props => {
    const {inverted, showProgress, chunkStatus: {processedChunksCount, totalChunksCount}, language} = props;

    let text = props?.text ?? (Translations.getStatic('loading', language ?? '') + '...');

    if (showProgress === true) {
        let percent = processedChunksCount / totalChunksCount * 100;

        if (isNaN(percent)) {
            percent = 0;
        }

        text += ' ' + Number.format(percent, 0) + '%';
    }

    if (props.hasOwnProperty('onCancel') === true) {
        return (
            <div className={`ui dimmer active ${inverted === true ? "inverted" : ""}`}>
                <Loader description={text}>
                    <span className={'primary-link'} onClick={props.onCancel}>{Translations.getStatic('cancel', language ?? '')}</span>
                </Loader>
            </div>
        );
    }

    return (
        <div className='ui dimmer inverted active'>
            <Loader description={text}/>
        </div>
    );
};

LoadingComponent.defaultProps = {
    inverted: true,
    showProgress: false,
};

const mapStateToProps = state => ({
    chunkStatus: state.chunkStatus,
});

export default connect(
    mapStateToProps,
)(LoadingComponent);
