import React from 'react';
import Url from './../helpers/Url';

const MetaTagsManager = ({children, customClassName}) => (
    <div className={Url.isWebPage() ? 'mark-web-container ' + customClassName : 'mark-app-container ' + customClassName}>
        {children}
    </div>
);

export default MetaTagsManager;
