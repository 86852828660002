import Url from './Url';
import Constants from './Constants';
import Translations from './Translations';
import Skeletons from './Skeletons';
import b2bDashboardCompanyIdsData from '../data/b2bDashboardCompanyIds.json';
import {LoginManagerModelType} from '../../interfaces/LoginManagerModelType';
import {JobModelType} from '../../interfaces/JobModelType';

class User
{
    static consoleLogEnabled(loginManagerModel: LoginManagerModelType): boolean {
        return Url.isNotOnline() || User.isAdmin(loginManagerModel);
    }

    static internalInfoEnabled(loginManagerModel: LoginManagerModelType): boolean {
        return Url.isNotOnline() || User.isAdmin(loginManagerModel);
    }

    static isAdmin(loginManagerModel: LoginManagerModelType): boolean {
        return (loginManagerModel?.adminId ?? 0) != 0;
    }

    static isSuperAdmin(loginManagerModel: LoginManagerModelType): boolean {
        return this.isAdmin(loginManagerModel) && Constants.SUPER_ADMINS.includes(loginManagerModel?.adminId ?? 0);
    }

    static isExternalUser(loginManagerModel: LoginManagerModelType): boolean {
        return loginManagerModel.loggedUser?.isExternalUser ?? false;
    }

    static getExternalUserRole(loginManagerModel: LoginManagerModelType, jobModel: JobModelType): number | null {
        if (!this.isExternalUser(loginManagerModel)) {
            return null;
        }

        if ((jobModel.settings?.externalUsers.length ?? 0) === 0) {
            return null;
        }

        return jobModel.settings.externalUsers.find(externalUser => externalUser.id === this.getUserId(loginManagerModel))?.userRoleId ?? null;
    }

    static isExternalVisitor(loginManagerModel: LoginManagerModelType, jobModel: JobModelType): boolean {
        return this.isExternalUser(loginManagerModel) && !this.isExternalEditor(loginManagerModel, jobModel);
    }

    static isExternalEditor(loginManagerModel: LoginManagerModelType, jobModel: JobModelType): boolean {
        const externalUserRoleId: number | null = this.getExternalUserRole(loginManagerModel, jobModel);

        return externalUserRoleId !== null && externalUserRoleId === Constants.USER_ROLE_EXTERNAL_EDITOR;
    }

    static isManager(loginManagerModel: LoginManagerModelType): boolean {
        return this.getUserRoleId(loginManagerModel) === Constants.USER_ROLE_MANAGER;
    }

    static getUserEmail(loginManagerModel: LoginManagerModelType): string {
        return loginManagerModel.user?.email ?? '';
    }

    static allowedToB2bDashboard(loginManagerModel: LoginManagerModelType): boolean {
        if (Url.isB2bDashboardTest()) {
            return true;
        }

        if (Skeletons.isSkeleton(loginManagerModel)) {
            return true;
        }

        const companyId: number = this.getCompanyId(loginManagerModel);

        return b2bDashboardCompanyIdsData.companyIds
            .replace(/\s/g, '')
            .split(',')
            .find(b2bDashboardCompanyId => b2bDashboardCompanyId === '*' || parseInt(b2bDashboardCompanyId) === companyId) !== undefined;
    }

    static isRecruiter(loginManagerModel: LoginManagerModelType): boolean {
        return this.getUserRoleId(loginManagerModel) === Constants.USER_ROLE_RECRUITER;
    }

    static isBasic(loginManagerModel: LoginManagerModelType): boolean {
        return this.getUserRoleId(loginManagerModel) === Constants.USER_ROLE_BASIC;
    }

    static isLoggedIn(loginManagerModel: LoginManagerModelType): boolean {
        return this.getUserId(loginManagerModel) !== 0;
    }

    static getUserId(loginManagerModel: LoginManagerModelType): number {
        if (loginManagerModel === undefined) {
            return 0;
        }

        return loginManagerModel.user?.id ?? 0;
    }

    static getProfesiaUserId(loginManagerModel: LoginManagerModelType): number {
        if (loginManagerModel === undefined) {
            return 0;
        }

        return loginManagerModel.user?.profesiaUserId ?? 0;
    }

    static getUserName(loginManagerModel: LoginManagerModelType, defaultString: string | null = null): string {
        defaultString = defaultString ?? (Translations.getStatic('nameNotSet') + ' (userId: ' + this.getUserId(loginManagerModel) + ')');

        if (loginManagerModel === undefined) {
            return defaultString;
        }

        return loginManagerModel.user?.name ?? defaultString;
    }

    static getUserRoleId(loginManagerModel: LoginManagerModelType): number {
        if (loginManagerModel === undefined) {
            return 0;
        }

        return loginManagerModel.user?.userRole?.id ?? 0;
    }

    static getCompanyId(loginManagerModel: LoginManagerModelType): number {
        if (loginManagerModel === undefined) {
            return 0;
        }

        return loginManagerModel.user?.companyId ?? 0;
    }

    static getCompanyName(loginManagerModel: LoginManagerModelType): string {
        return loginManagerModel.companyName
            ? loginManagerModel.companyName
            : (Translations.getStatic('nameNotSet') + ' (companyId: ' + (loginManagerModel.company?.companyId ?? 0) + ')');
    }

    static getDefaultJobViewId(loginManagerModel: LoginManagerModelType): number {
        if (loginManagerModel === undefined) {
            return Constants.JOB_VIEW_AUTOMATIC;
        }

        return loginManagerModel.user?.defaultJobViewId ?? Constants.JOB_VIEW_AUTOMATIC;
    }

    static getLoggedAdminParams(loginManagerModel: LoginManagerModelType): string {
        return User.isAdmin(loginManagerModel)
            ? '?company_id=' + User.getCompanyId(loginManagerModel) + '&contact_address_id=' + User.getUserId(loginManagerModel)
            : '';
    }
}

export default User;
