import React from 'react';

const Message = props => {
    let {
        icon,
        negative,
        visible,
        error,
        className,
        ...validProps
    } = props;
    
    icon = props.icon === true ? "icon " : "";
    negative = props.negative === true ? "negative " : "";
    visible = props.visible === true ? "visible " : "";
    error = props.error === true ? "error " : "";
    className = `${props.className ? props.className + " " : ""}`;

    return <div {...validProps} className={`${icon}${negative}${visible}${error}${className}ui message`}>{props.children}</div>;
};

const Content = props => {
    let {
        className,
        ...validProps
    } = props;
    
    className = `${props.className ? props.className + " " : ""}`;

    return <div {...validProps} className={`content ${className}`}>{props.children}</div>;
};

Message.Content = Content;

export default Message;
